module.exports = function(app) {
    app.controller('enrollment.other-insurance', ["$state", "$scope", "$sce", "$q", "teamsiteManager",
        function ($state, $scope, $sce, $q, teamsiteManager) {

            teamsiteManager.getContent("otherInsuranceV2", "DEFAULT").then(
                function(data){
                    $scope.content = data;
                }
            );

            $scope.toNext = function () {
                $state.go("enrollment.review-application");
            };

            $scope.medicareVarToggle = function(medicareBoolean){
                $scope.medicareVar = medicareBoolean;
            };

            $scope.govVarToggle = function(govBoolean){
                $scope.govVar = govBoolean;
            };

            $scope.replaceVarToggle = function(replaceBoolean){
                $scope.replaceVar = replaceBoolean;
            };

            $scope.isProducerToggle = function(isProducerBoolean){
                $scope.isProducer = isProducerBoolean;
            };

            $scope.workingWithProdToggle = function(workingWithProd){
                $scope.workingWithProd = workingWithProd;
            };
        }
    ]);
};