module.exports = function (app) {
    return app.factory("planDataUtility", ["$filter", "sessionData", "storage", "ratingInfoModel", function($filter, sessionData, storage, ratingInfoModel) {


        var forceRates = false;
        if (sessionData && sessionData.appConfig) {
            if (sessionData.appConfig.FORCE_RATES && sessionData.appConfig.FORCE_RATES.booleanValue) {
                forceRates = sessionData.appConfig.FORCE_RATES.booleanValue;
            } else if (sessionData.appConfig.SALES) {
                if (sessionData.appConfig.SALES.FORCE_RATES && sessionData.appConfig.SALES.FORCE_RATES.booleanValue) {
                    forceRates = sessionData.appConfig.SALES.FORCE_RATES.booleanValue;
                }
            }
        }
        console.log("force rates", forceRates);

        var serviceProperties = {
            transformPlanData           : transformPlanData,
            transformSinglePlanData     : transformSinglePlanData,
            transformAttributes         : transformAttributes,
            transformPrescriptionInfo   : transformPrescriptionInfo,
            transformPlanProduct        : transformPlanProduct
        };

        var cachedProducts = null;

        function transformPlanData(planData, isIndividual, args) {

            var self = this;

            angular.forEach(planData.products, function(product, index){
                planData.products[index] = self.transformSinglePlanData(product, isIndividual, args);
            });

            return planData.products || [];
        }

        function transformSinglePlanData(product, isIndividual, args){

            var self = this;

            // Want this to be a number, not a string.
            if (product.pricing && product.pricing.current) {
                product.pricing.current = Number(product.pricing.current);
            } else {
                product.pricing = {};
                product.pricing.current = "";
            }

            // Force rates if need be
            if (forceRates) {
                product.pricing.current = 20;
            }

            // 'FILTER' Attribute
            var newFilter = {};
            angular.forEach(product.attributes.filter, function(filter) {
                if (filter.values.length > 0) {
                    var newValue = [];
                    angular.forEach(filter.values, function(value) {
                        newValue.push(value.value);
                    });
                    newFilter[filter.id] = {'title': filter.title, 'value': newValue };
                }
            });
            product.attributes.filter = newFilter;

            // 'MISCATTRIBUTE' Attribute
            var newMisc = {};
            angular.forEach(product.attributes.miscattribute, function(filter) {
                if (filter.values.length > 1) {
                    var newValue = [];
                    angular.forEach(filter.values, function(value) {
                        newValue.push(value.value);
                    });
                    newMisc[filter.id] = {'title': filter.title, 'value': newValue };
                } else if (filter.values[0]) {
                    newMisc[filter.id] = {'title': filter.title, 'value': filter.values[0].value };
                }
            });
            product.attributes.miscattribute = newMisc;

            // 'IN-NETWORK' Attribute
            var newInNetwork = {};
            angular.forEach(product.attributes['in-network'], function(item) {
                if (item.values[0]) {
                    newInNetwork[item.id] = {'title': item.title, 'value': item.values[0].value };
                }
            });
            product.attributes['in-network'] = newInNetwork;

            // 'OUT-OF-NETWORK' Attribute
            var outOfNetwork = {};
            angular.forEach(product.attributes['out-of-network'], function(item) {
                if (item.values[0]) {
                    outOfNetwork[item.id] = {'title': item.title, 'value': item.values[0].value };
                }
            });
            product.attributes['out-of-network'] = outOfNetwork;


            // 'PLAN-SERVICES' Attribute
            var newPlanServices = {};
            angular.forEach(product.attributes['plan-services'], function(item) {
                if (item.values[0]) {
                    newPlanServices[item.id] = {'title': item.title, 'value': item.values[0].value };
                }
            });
            product.attributes['plan-services'] = newPlanServices;

            return self.transformPlanProduct(product, isIndividual, args);
        }

        function transformAttributes(attrs) {

            var attributes = {
                "filter"            : attrs.filter,
                "pricing"           : attrs.pricing,
                "plan-services"     : attrs['plan-services'],
                "miscattribute"     : attrs.miscattribute ,
                "in-network"        : {},
                "out-of-network"    : {}
            };

            /* The following commented out logic essentially flattens the attributes which contain FAM and INDV, such that
             * DEDUCTIBLE_FAM_IN and DEDUCTIBLE_INDV_IN would become DEDUCTIBLE_IN, and then those values would be used, so that the template could just use one
             * value, however, this is no longer being handled this way as we are displaying, currently, both the FAM and INDV on the template, simultaneously.
             * In the future, this section may prove to be a nice shortcut for an enhancer
             */
            // var indRegex = isIndividual ? /INDV/ : /FAM/

            // for (var key in attrs['in-network']) {
            //     if(!/INDV/.test(key) && !/FAM/.test(key)) {
            //         attributes['in-network'][key] = attrs['in-network'][key]
            //     } else if( indRegex.test(key) ) {
            //         attributes['in-network'][key.replace("_" + indRegex.source, '')] = attrs['in-network'][key]
            //     }
            // }

            // for (var key in attrs['out-of-network']) {
            //     if(!/INDV/.test(key) && !/FAM/.test(key)) {
            //         attributes['out-of-network'][key] = attrs['out-of-network'][key]
            //     } else if( indRegex.test(key) ) {
            //         attributes['out-of-network'][key.replace("_" + indRegex.source, '')] = attrs['out-of-network'][key]
            //     }
            // }

            attributes['in-network']        = attrs['in-network'];
            attributes['out-of-network']    = attrs['out-of-network'];

            return attributes;
        }

        function transformPrescriptionInfo(product) {
            var prescriptionDrugValues = {
                "retail": {
                    "tier1": (product.attributes.miscattribute.RX_INIT_PREF_GENERIC) ? product.attributes.miscattribute.RX_INIT_PREF_GENERIC.value : "N/A",
                    "tier2": (product.attributes.miscattribute.RX_INIT_PREF_BRAND) ? product.attributes.miscattribute.RX_INIT_PREF_BRAND.value : "N/A",
                    "tier3": (product.attributes.miscattribute.RX_INIT_NON_PREF_BRAND) ? product.attributes.miscattribute.RX_INIT_NON_PREF_BRAND.value : "N/A",
                    "tier4": (product.attributes.miscattribute.RX_INIT_SPECIALTY) ? product.attributes.miscattribute.RX_INIT_SPECIALTY.value : "N/A"
                },
                "mail": {
                    "tier1": (product.attributes.miscattribute.RX_MAIL_PREF_GENERIC) ? product.attributes.miscattribute.RX_MAIL_PREF_GENERIC.value : "N/A",
                    "tier2": (product.attributes.miscattribute.RX_MAIL_PREF_BRAND) ? product.attributes.miscattribute.RX_MAIL_PREF_BRAND.value : "N/A",
                    "tier3": (product.attributes.miscattribute.RX_MAIL_NON_PREF_BRAND) ? product.attributes.miscattribute.RX_MAIL_NON_PREF_BRAND.value : "N/A",
                    "tier4": (product.attributes.miscattribute.RX_MAIL_PREF_SPECIALTY) ? product.attributes.miscattribute.RX_MAIL_PREF_SPECIALTY.value : "N/A"
                }
            };

            return prescriptionDrugValues;
        }

        // todo: Look at MEDWEB to maybe utilized their productDeserializer

        function transformPlanProduct(planProduct, isIndividual, args) {

            var names = /(.*?)\s(\d+\s?.*)/.exec(planProduct.name);
            var planProductTransformed = {
                    "transformed"                   : true,
                    "name"                          : names && names[1] || planProduct.name,
                    "subName"                       : names && names[2] || '',
                    "attributes"                    : this.transformAttributes(planProduct.attributes, isIndividual, args),
                    "partNumber"                    : planProduct.partNumber,
                    "mfPartNumber"                  : planProduct.mfPartNumber,
                    "cpaCommunityBlue"              : ((planProduct.attributes.miscattribute.CPA_COMM_BLUE_FLAG && planProduct.attributes.miscattribute.CPA_COMM_BLUE_FLAG.value.toUpperCase() == "TRUE") ? true : false),// the json does not return true booleans convert strings to true and false
                    "is4TierProduct"                : ((planProduct.attributes.miscattribute.IS_4_TIER_PRODUCT && planProduct.attributes.miscattribute.IS_4_TIER_PRODUCT.value.toUpperCase() === "TRUE")? true : false),
                    "region"                        : planProduct.attributes.filter.PLAN_REGION.value[0],
                    "description"                   : planProduct.shortDescription,
                    "longDescription"               : planProduct.longDescription,
                    "productId"                     : planProduct.productId,
                    "intAppType"                    : planProduct.attributes.miscattribute.INT_APPLICATION_TYPE || null,
                    "groupNo"                       : planProduct.attributes.miscattribute.GROUP_NO || null,
                    "groupNoValue"                  : planProduct.attributes.miscattribute.GROUP_NO.value || null,
                    "CrossWalkedFromGroupNoValue"   : planProduct.attributes.miscattribute.LAST_YEAR_GROUP_NO ? planProduct.attributes.miscattribute.LAST_YEAR_GROUP_NO.value : null,
                    "monthlyPremium"                : (Number((Number(planProduct.pricing.current)).toFixed(2))) || "N/A",
                    "monthlyPremiumFormatted"       : $filter("currency")(Number(planProduct.pricing.current), "$") || "N/A",
                    "annualPremium"                 : (Number((Number(planProduct.pricing.current) * 12).toFixed(2))) || "N/A",
                    "annualPremiumFormatted"        : $filter("currency")((Number(planProduct.pricing.current) * 12), "$") || "N/A",
                    "levelOfCoverage"               : planProduct.attributes.miscattribute.METAL_LEVEL.value,
                    "productGroup"                  : planProduct.attributes.miscattribute.MARKETING_GRP_INDICATOR.value,
                    "healthSavingsEligible"         : planProduct.attributes.miscattribute.HSA_ELIGIBLE.value,
                    "lineOfBusiness"                : null,
                    "planArea"                      : null,
                    "planGridHref"                  : null,
                    "coinsurance"                   : [],
                    "coinsurance_display"           : '',
                    "links"                         : {},
                    "ratingEntity"                  : planProduct.attributes.miscattribute.RATING_ENTITY.value,
                    "coverage"                      : {},
                    "billMerchantName"              : planProduct.attributes.miscattribute.BILL_MERCHANT_NAME.value,
                    "EHB"                           : null,
                    "isCSREligible"                 : null,
                    "applicants"                    : planProduct.pricing.applicants,
                    "dependentSum"                  : planProduct.pricing.dependentSum,
                    "planYear"                      : planProduct.planYear ? planProduct.planYear : planProduct.attributes.miscattribute.PLAN_YEAR,
                    "pharmacyBenefits"              : planProduct.attributes.miscattribute.DRUG_FORMULARY && planProduct.attributes.miscattribute.DRUG_FORMULARY.value? planProduct.attributes.miscattribute.DRUG_FORMULARY.value : "N/A",
                    "prescriptionDrug"              : this.transformPrescriptionInfo(planProduct, args.teamsiteContent)
            };

            // var currentRatingInfo = storage.get("ratingInfoPayload");
            var currentRatingInfo   = ratingInfoModel.get();
            var currentRegion = (function getCurrentRegion(){
                if (currentRatingInfo) {
                    if (currentRatingInfo.region) {
                        return currentRatingInfo.region;
                    }
                    return brand.current.sharedBrandCode.toUpperCase();
                }
                return brand.current.sharedBrandCode.toUpperCase();
            })();

            planProductTransformed.outNetworkPharmacyBenefits = planProductTransformed.pharmacyBenefits;

            // todo: Appconfig this
            if (currentRegion.toUpperCase() !== "MINCR") {
                var coverageString = "No Coverage";

                planProductTransformed.prescriptionDrug.outNetwork = [];
                planProductTransformed.prescriptionDrug.outNetwork.push({
                    "displayValue" : "",
                    "value" : coverageString,
                    "key" : ""
                });
                planProductTransformed.outNetworkPharmacyBenefits = coverageString;
            }

            if (planProduct.attributes.miscattribute.HIOS_ID && planProduct.attributes.miscattribute.HIOS_ID.value) {
                planProductTransformed.hiosId =  planProduct.attributes.miscattribute.HIOS_ID.value;
            }

            // ON-EXCHANGE
            if (args && args.isOnExchange){
                planProductTransformed['EHB'] = planProduct.attributes.miscattribute.EHB.value;

                if (args.csr) {
                    switch (args.csrLevel) {
                        case '01':
                            // All plans are ineligible
                            planProductTransformed['isCSREligible'] = false;
                            break;
                        case '02':
                        case '03':
                            // Only Platinum, Gold, Silver, Bronze are eligible
                            if (planProductTransformed['levelOfCoverage'].toUpperCase() !== 'CATASTROPHIC') {
                                 planProductTransformed['isCSREligible'] = true;
                            }
                            //storage.set("isUserCSREligible", {
                            //    isEligible: true
                            //});

                            break;
                        case '04':
                        case '05':
                        case '06':
                            if (planProductTransformed['levelOfCoverage'].toUpperCase() === 'SILVER') {
                                // Only Silver is eligible
                                 planProductTransformed['isCSREligible'] = true;
                            }
                            //storage.set("isUserCSREligible", {
                            //    isEligible: true
                            //});
                            break;

                    }
                }
            }

            // OFF-EXCHANGE
            else {
                planProductTransformed['lineOfBusiness'] = planProduct.attributes.miscattribute.LINE_OF_BUSINESS.value;
                planProductTransformed['planArea']  = planProduct.attributes.miscattribute.PLAN_AREA.value;
                planProductTransformed['planGridHref'] = planProduct.attributes.miscattribute.BENEFIT_GRID_LOCATION.value;
            }

            if (planProductTransformed.attributes.miscattribute.MEDICAL_ICON &&
                planProductTransformed.attributes.miscattribute.MEDICAL_ICON.value === "TRUE") {
                planProductTransformed.coverage.medical = true;
            }

            if (planProductTransformed.attributes.miscattribute.PEDIATRIC_VISION_INCLUDED &&
                planProductTransformed.attributes.miscattribute.PEDIATRIC_VISION_INCLUDED.value === "TRUE") {
                planProductTransformed.coverage.vision = true;
            }

            if (planProductTransformed.attributes.miscattribute.INCLUDE_PEDIATRIC_DENTAL &&
                planProductTransformed.attributes.miscattribute.INCLUDE_PEDIATRIC_DENTAL.value === "TRUE") {
                planProductTransformed.coverage.dental = true;
            }

            // Strings-to-Numbers, if N/A -> 0. They come back as strings from the catalog for certain product groups
            // If a value is not a number, ie 'n/a', set to 0

            // ENHANCED + STANDARD
            if (planProductTransformed.cpaCommunityBlue) {
                // INDIVIDUAL

                //tier1_standard = standard
                planProductTransformed.individualDeductible                         = isNaN(planProduct.attributes['in-network'].DEDUCTIBLE_INDV_IN_TIER1_STANDARD.value) ? planProduct.attributes['in-network'].DEDUCTIBLE_INDV_IN_TIER1_STANDARD.value : Number(planProduct.attributes['in-network'].DEDUCTIBLE_INDV_IN_TIER1_STANDARD.value);
                planProductTransformed.individualDeductibleFormatted                = isNaN(planProductTransformed.individualDeductible)? planProductTransformed.individualDeductible : $filter("currency")(Number(planProductTransformed.individualDeductible), "$", 0);

                //tier2_standard = enhanced
                planProductTransformed.enhancedIndividualDeductible                 = isNaN(planProduct.attributes['in-network'].DEDUCTIBLE_INDV_IN_TIER2_STANDARD.value) ? planProduct.attributes['in-network'].DEDUCTIBLE_INDV_IN_TIER2_STANDARD.value : Number(planProduct.attributes['in-network'].DEDUCTIBLE_INDV_IN_TIER2_STANDARD.value);
                planProductTransformed.enhancedIndividualDeductibleFormatted        = isNaN(planProductTransformed.enhancedIndividualDeductible) ? planProductTransformed.enhancedIndividualDeductible : $filter("currency")(Number(planProductTransformed.enhancedIndividualDeductible), "$", 0);

                // Out-of-Pocket Maximum
                planProductTransformed.individualOutOfPocketMax                     = isNaN(planProduct.attributes['in-network'].OUT_OF_POCKET_INDV_IN_TIER1_STANDARD.value) ? planProduct.attributes['in-network'].OUT_OF_POCKET_INDV_IN_TIER1_STANDARD.value : Number(planProduct.attributes['in-network'].OUT_OF_POCKET_INDV_IN_TIER1_STANDARD.value);
                planProductTransformed.individualOutOfPocketMaxFormatted            = isNaN(planProductTransformed.individualOutOfPocketMax) ? planProductTransformed.individualOutOfPocketMax: $filter("currency")(Number(planProductTransformed.individualOutOfPocketMax), "$", 0);

                // Enhanced Out-of-Pocket Maximum
                planProductTransformed.enhancedIndividualOutOfPocketMax             = isNaN(planProduct.attributes['in-network'].OUT_OF_POCKET_INDV_IN_TIER2_STANDARD.value) ? planProduct.attributes['in-network'].OUT_OF_POCKET_INDV_IN_TIER2_STANDARD.value : Number(planProduct.attributes['in-network'].OUT_OF_POCKET_INDV_IN_TIER2_STANDARD.value);
                planProductTransformed.enhancedIndividualOutOfPocketMaxFormatted    = isNaN(planProductTransformed.enhancedIndividualOutOfPocketMax) ? planProductTransformed.enhancedIndividualOutOfPocketMax : $filter("currency")(Number(planProductTransformed.enhancedIndividualOutOfPocketMax), "$", 0);


                // FAMILY

                //tier1_standard = standard
                planProductTransformed.familyDeductible                             = isNaN(planProduct.attributes['in-network'].DEDUCTIBLE_FAM_IN_TIER1_STANDARD.value) ? planProduct.attributes['in-network'].DEDUCTIBLE_FAM_IN_TIER1_STANDARD.value : Number(planProduct.attributes['in-network'].DEDUCTIBLE_FAM_IN_TIER1_STANDARD.value);
                planProductTransformed.familyDeductibleFormatted                    = isNaN(planProductTransformed.familyDeductible) ? planProductTransformed.familyDeductible : $filter("currency")(Number(planProductTransformed.familyDeductible), "$", 0);

                //tier2_standard = enhanced
                planProductTransformed.enhancedFamilyDeductible                     = isNaN(planProduct.attributes['in-network'].DEDUCTIBLE_FAM_IN_TIER2_STANDARD.value) ? planProduct.attributes['in-network'].DEDUCTIBLE_FAM_IN_TIER2_STANDARD.value : Number(planProduct.attributes['in-network'].DEDUCTIBLE_FAM_IN_TIER2_STANDARD.value);
                planProductTransformed.enhancedFamilyDeductibleFormatted            = isNaN(planProductTransformed.enhancedFamilyDeductible) ? planProductTransformed.enhancedFamilyDeductible : $filter("currency")(Number(planProductTransformed.enhancedFamilyDeductible), "$", 0);

                // Out-of-Pocket Maximum
                planProductTransformed.familyOutOfPocketMax                         = isNaN(planProduct.attributes['in-network'].OUT_OF_POCKET_FAM_IN_TIER1_STANDARD.value) ? planProduct.attributes['in-network'].OUT_OF_POCKET_FAM_IN_TIER1_STANDARD.value : Number(planProduct.attributes['in-network'].OUT_OF_POCKET_FAM_IN_TIER1_STANDARD.value);
                planProductTransformed.familyOutOfPocketMaxFormatted                = isNaN(planProductTransformed.familyOutOfPocketMax) ? planProductTransformed.familyOutOfPocketMax : $filter("currency")(Number(planProductTransformed.familyOutOfPocketMax), "$", 0);

                // Enhanced Out-of-Pocket Maximum
                planProductTransformed.enhancedFamilyOutOfPocketMax                 = isNaN(planProduct.attributes['in-network'].OUT_OF_POCKET_FAM_IN_TIER2_STANDARD.value) ? planProduct.attributes['in-network'].OUT_OF_POCKET_FAM_IN_TIER2_STANDARD.value : Number(planProduct.attributes['in-network'].OUT_OF_POCKET_FAM_IN_TIER2_STANDARD.value);
                planProductTransformed.enhancedFamilyOutOfPocketMaxFormatted        = isNaN(planProductTransformed.enhancedFamilyOutOfPocketMax) ? planProductTransformed.enhancedFamilyOutOfPocketMax : $filter("currency")(Number(planProductTransformed.enhancedFamilyOutOfPocketMax), "$", 0);

                // STANDARD ONLY
            } else {

                // INDIVIDUAL

                //tier1_standard = standard
                planProductTransformed.individualDeductible               = isNaN(planProduct.attributes['in-network'].DEDUCTIBLE_INDV_IN_STANDARD.value) ? planProduct.attributes['in-network'].DEDUCTIBLE_INDV_IN_STANDARD.value : Number(planProduct.attributes['in-network'].DEDUCTIBLE_INDV_IN_STANDARD.value);
                planProductTransformed.individualDeductibleFormatted      = isNaN(planProductTransformed.individualDeductible) ? planProductTransformed.individualDeductible : $filter("currency")(Number(planProductTransformed.individualDeductible), "$", 0);

                // Out-of-Pocket Maximum
                planProductTransformed.individualOutOfPocketMax           = isNaN(planProduct.attributes['in-network'].OUT_OF_POCKET_INDV_IN_STANDARD.value) ? planProduct.attributes['in-network'].OUT_OF_POCKET_INDV_IN_STANDARD.value : Number(planProduct.attributes['in-network'].OUT_OF_POCKET_INDV_IN_STANDARD.value);
                planProductTransformed.individualOutOfPocketMaxFormatted  = isNaN(planProductTransformed.individualOutOfPocketMax) ? planProductTransformed.individualOutOfPocketMax : $filter("currency")(Number(planProductTransformed.individualOutOfPocketMax), "$", 0);

                // FAMILY

                //tier1_standard = standard
                planProductTransformed.familyDeductible                   = isNaN(planProduct.attributes['in-network'].DEDUCTIBLE_FAM_IN_STANDARD.value) ? planProduct.attributes['in-network'].DEDUCTIBLE_FAM_IN_STANDARD.value : Number(planProduct.attributes['in-network'].DEDUCTIBLE_FAM_IN_STANDARD.value);
                planProductTransformed.familyDeductibleFormatted          = isNaN(planProductTransformed.familyDeductible) ? planProductTransformed.familyDeductible : $filter("currency")(Number(planProductTransformed.familyDeductible), "$", 0);

                // Out-of-Pocket Maximum
                planProductTransformed.familyOutOfPocketMax               = isNaN(planProduct.attributes['in-network'].OUT_OF_POCKET_FAM_IN_STANDARD.value) ? planProduct.attributes['in-network'].OUT_OF_POCKET_FAM_IN_STANDARD.value : Number(planProduct.attributes['in-network'].OUT_OF_POCKET_FAM_IN_STANDARD.value);
                planProductTransformed.familyOutOfPocketMaxFormatted      = isNaN(planProductTransformed.familyOutOfPocketMax) ? planProductTransformed.familyOutOfPocketMax : $filter("currency")(Number(planProductTransformed.familyOutOfPocketMax), "$", 0);
            }

            // Out-of-Network values for common attributes
            var individualOutOfPocketMaxOutOfNetwork                                = planProductTransformed.attributes["out-of-network"].OUT_OF_POCKET_INDV_OUT.value;
            planProductTransformed.individualOutOfPocketMaxOutOfNetworkFormatted    = typeof individualOutOfPocketMaxOutOfNetwork === "string" ? individualOutOfPocketMaxOutOfNetwork : $filter("currency")(Number(individualOutOfPocketMaxOutOfNetwork), "$", 0);

            var familyOutOfPocketMaxOutOfNetwork                                    = planProductTransformed.attributes["out-of-network"].OUT_OF_POCKET_FAM_OUT.value;
            planProductTransformed.familyOutOfPocketMaxOutOfNetworkFormatted        = typeof familyOutOfPocketMaxOutOfNetwork === "string" ? familyOutOfPocketMaxOutOfNetwork : $filter("currency")(Number(familyOutOfPocketMaxOutOfNetwork), "$", 0);

            planProductTransformed.familyDeductibleOutFormatted                     = $filter("currency")(Number(planProductTransformed.attributes["out-of-network"].DEDUCTIBLE_FAM_OUT.value), "$", 0);
            planProductTransformed.individualDeductibleOutFormatted                 = $filter("currency")(Number(planProductTransformed.attributes["out-of-network"].DEDUCTIBLE_INDV_OUT.value), "$", 0);

            /****
             * Rules for Coinsurance:
             * 1) All plans have "in" and "out" of network
             * 2) CPA plans have a 3rd values:  "enhanced" (if CPA_COMM_BLUE_FLAG is TRUE)
             * 2a) Catalogue        === "standard"
             * 2b) Business Rules   === "enhanced"
             * 3) Order is:  Enhanced | In | Out
             ****/

            if (planProductTransformed.cpaCommunityBlue) {
                // Enhanced
                planProductTransformed.coinsurance.push(planProduct.attributes['in-network'].COINSURANCE_IN_TIER2_STANDARD.value);
                planProductTransformed.coinsuranceEhancedFormatted = planProduct.attributes['in-network'].COINSURANCE_IN_TIER2_STANDARD.value;

                // Standard
                planProductTransformed.coinsurance.push(planProduct.attributes['in-network'].COINSURANCE_IN_TIER1_STANDARD.value);
                planProductTransformed.coinsurnaceFormatted = planProduct.attributes['in-network'].COINSURANCE_IN_TIER1_STANDARD.value;
            } else {
                // Standard
                planProductTransformed.coinsurance.push(planProduct.attributes['in-network'].COINSURANCE_IN_STANDARD.value);
                planProductTransformed.coinsurnaceFormatted = planProduct.attributes['in-network'].COINSURANCE_IN_STANDARD.value;
            }

            // Out of network
            planProductTransformed.coinsurance.push(planProduct.attributes['out-of-network'].COINSURANCE_OUT.value);
            planProductTransformed.coinsuranceOutOfNetworkFormatted = planProduct.attributes['out-of-network'].COINSURANCE_OUT.value;

            // Format the Coinsurance Display
            planProductTransformed.coinsurance_display = planProductTransformed.coinsurance.join(' / ');


            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //
            // Formatted helper object for displaying attribute values
            //
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // RULES:
            // if cpaCommunityBlue == true, the plan is enhanced, so use the following
            //  enhanced value = tier2_standard
            //  standard value = tier1_standard
            //
            // if the flag is false, then use
            // standard value = _standard (no tierd in column name)
            //
            //the current view plans and compare pages could be using out of date columns
            //this new node in the obj is going to have the latest rules and formatted values for re-use
            //there are places like compare plans where rules were duplicated, they can be removed when refactored
            //
            //looking in the catalog, both individual and family products use the same column names, going forward we can simplify the code now
            //ie no more using _FAM_ columns
            //
            //
            // Additional items added
            // -split out subscriber, spouse, and dependents into separate fields so that they can be easily used with handlebar templates
            //
            // -added 'raw' versions of items that are converted formats. this was because we have situations where the raw value is needed.
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            var formatted = {
                "enhanced"      : null,
                "preferred"     : null,
                "inNetwork"     : null,
                "outOfNetwork"  : null,
                "limit"         : null,
                "pricing"       : {
                    "subscriber"    : null,
                    "spouse"        : null,
                    "dependents"    : null
                }
            };

            var inNetwork   = planProductTransformed.attributes["in-network"];
            // console.log("inNetwork = ", inNetwork);
            var outNetwork  = planProductTransformed.attributes["out-of-network"];
            //console.log("outNetwork = ", outNetwork);
            var preferred;

            formatted.limit = {
                "physicalMedicineLimit"                     : (inNetwork.PHYSICAL_MEDICINE_LIMIT ? inNetwork.PHYSICAL_MEDICINE_LIMIT.value : "Limit not applicable"),
                "speechAndOccupationalTherapyLimit"         : (inNetwork.SPEECH_OCCUPATION_THERAPY_LIMIT ? inNetwork.SPEECH_OCCUPATION_THERAPY_LIMIT.value : "Limit not applicable"),
                "chiropractorLimit"                         : (inNetwork.CHIROPRACTOR_IN_LIMIT ? inNetwork.CHIROPRACTOR_IN_LIMIT.value : "Limit not applicable"),
                "skilledNursingFacilityLimit"               : (inNetwork.SKILLED_NURSING_INN_LIMIT ? inNetwork.SKILLED_NURSING_INN_LIMIT.value : "Limit not applicable")
            };

            if (planProductTransformed.cpaCommunityBlue) {

                // TIER2_STANDARD === "enhanced"
                formatted.enhanced = {

                    "individualDeductible"                  : ($filter("currency")(Number(inNetwork.DEDUCTIBLE_INDV_IN_TIER2_STANDARD.value || {}), "$", 0)),
                    "individualDeductibleRaw"               : Number((inNetwork.DEDUCTIBLE_INDV_IN_TIER2_STANDARD || {}).value),
                    "familyDeductible"                      : ($filter("currency")(Number(inNetwork.DEDUCTIBLE_FAM_IN_TIER2_STANDARD.value || {}), "$", 0)),
                    "familyDeductibleRaw"                   : Number((inNetwork.DEDUCTIBLE_FAM_IN_TIER2_STANDARD || {}).value),
                    "individualOutOfPocketMax"              : ($filter("currency")(Number(inNetwork.OUT_OF_POCKET_INDV_IN_TIER2_STANDARD.value || {}), "$", 0)),
                    "individualOutOfPocketMaxRaw"           : Number((inNetwork.OUT_OF_POCKET_INDV_IN_TIER2_STANDARD || {}).value),
                    "familyOutOfPocketMax"                  : ($filter("currency")(Number(inNetwork.OUT_OF_POCKET_FAM_IN_TIER2_STANDARD.value || {}), "$", 0)),
                    "familyOutOfPocketMaxRaw"               : Number((inNetwork.OUT_OF_POCKET_FAM_IN_TIER2_STANDARD || {}).value),
                    "coinsurance"                           : (inNetwork.COINSURANCE_IN_TIER2_STANDARD || {}).value,
                    "primaryCareOfficeVisit"                : (inNetwork.PRIMARY_CARE_OFFICE_VISIT_IN_TIER2_STANDARD || {}).value,
                    "specialistOfficeVisit"                 : (inNetwork.SPECIALIST_OFFICE_VISIT_IN_TIER2_STANDARD || {}).value,
                    "emergencyRoomVisit"                    : (inNetwork.EMERGENCY_ROOM_VISIT_IN_TIER2_STANDARD || {}).value,
                    "preventiveCare"                        : (inNetwork.PREVENTIVE_CARE_IN_TIER2_STANDARD || {}).value,
                    "outPatientSurgery"                     : (inNetwork.OUTPATIENT_SURGERY_INN_TIER2_STANDARD || {}).value,
                    "inPatientSurgery"                      : (inNetwork.HOSPITAL_INPATIENT_INN_TIER2_STANDARD || {}).value,
                    "retailClinic"                          : (inNetwork.RETAIL_CLINIC_IN_TIER2_STANDARD || {}).value,
                    "telemedicine"                          : (inNetwork.TELEMED_IN_TIER2_STANDARD || {}).value,
                    "urgentCare"                            : (inNetwork.URGENT_CARE_IN_TIER2_STANDARD || {}).value,
                    "inpatientHospitalMaternity"            : (inNetwork.MATERNITY_IN_TIER2_STANDARD || {}).value,
                    "inpatientHospitalMedicalCare"          : (inNetwork.INPATIENT_MEDICAL_IN_TIER2_STANDARD || {}).value,
                    "ambulance"                             : (inNetwork.AMBULANCE_IN_TIER2_STANDARD || {}).value,
                    "physicalMedicine"                      : (inNetwork.PHYSICAL_MEDICINE_IN_TIER2_STANDARD || {}).value,
                    "speechAndOccupationalTherapy"          : (inNetwork.SPEECH_OCCUPATION_THERAPY_IN_TIER2_STANDARD || {}).value,
                    "chiropractor"                          : (inNetwork.CHIROPRACTOR_IN_TIER2_STANDARD || {}).value,
                    "mentalHealthVisits"                    : (inNetwork.MENTAL_HEALTH_VISIT_IN_TIER2_STANDARD || {}).value,
                    "mentalHealthSubstanceAbuseInpatient"   : (inNetwork.INPATIENT_MENTAL_HEALTH_IN_TIER2_STANDARD || {}).value,
                    "detoxAndRehabInpatient"                : (inNetwork.INPATIENT_DETOX_IN_TIER2_STANDARD || {}).value,
                    "mentalHealthSubstanceAbuseOutpatient"  : (inNetwork.OUTPATIENT_MENTAL_HEALTH_IN_TIER2_STANDARD || {}).value,
                    "basicDiagnosticServices"               : (inNetwork.BASIC_DIAGNOSTICS_IN_TIER2_STANDARD || {}).value,
                    "advancedImaging"                       : (inNetwork.ADV_IMAGING_XRAY_INN_TIER2_STANDARD || {}).value,
                    "laboratoryAndPathology"                : (inNetwork.LAB_PATHOLOGY_IN_TIER2_STANDARD || {}).value,
                    "pediatricVisionExam"                   : (inNetwork.PED_VISION_EXAM_INN_TIER2_STANDARD || {}).value,
                    "pediatricVisionFramesAndLenses"        : (inNetwork.PED_VISION_FRAME_INN_TIER2_STANDARD || {}).value,
                    "pediatricDentalExamAndCleaning"        : (inNetwork.PED_DENTAL_EXAM_INN_TIER2_STANDARD || {}).value,
                    "pediatricDentalBasicRestorative"       : (inNetwork.PED_DENTAL_SERVICES_INN_TIER2_STANDARD || {}).value,
                    "skilledNursingFacility"                : (inNetwork.SKILLED_NURSING_INN_TIER2_STANDARD || {}).value
                };

                // TIER1_STANDARD === "regular"
                formatted.inNetwork = {

                    "monthlyPremiumFormatted"               : $filter("currency")(Number(planProduct.pricing.current), "$") || "N/A",

                    "individualDeductible"                  : ($filter("currency")(Number(inNetwork.DEDUCTIBLE_INDV_IN_TIER1_STANDARD.value || {}), "$", 0)),
                    "individualDeductibleRaw"               : Number((inNetwork.DEDUCTIBLE_INDV_IN_TIER1_STANDARD || {}).value),
                    "familyDeductible"                      : ($filter("currency")(Number(inNetwork.DEDUCTIBLE_FAM_IN_TIER1_STANDARD.value || {}), "$", 0)),
                    "familyDeductibleRaw"                   : Number((inNetwork.DEDUCTIBLE_FAM_IN_TIER1_STANDARD || {}).value),
                    "individualOutOfPocketMax"              : ($filter("currency")(Number(inNetwork.OUT_OF_POCKET_INDV_IN_TIER1_STANDARD.value || {}), "$", 0)),
                    "individualOutOfPocketMaxRaw"           : Number((inNetwork.OUT_OF_POCKET_INDV_IN_TIER1_STANDARD || {}).value),
                    "familyOutOfPocketMax"                  : ($filter("currency")(Number(inNetwork.OUT_OF_POCKET_FAM_IN_TIER1_STANDARD.value || {}), "$", 0)),

                    "familyOutOfPocketMaxRaw"               : Number((inNetwork.OUT_OF_POCKET_FAM_IN_TIER1_STANDARD || {}).value),
                    "coinsurance"                           : (inNetwork.COINSURANCE_IN_TIER1_STANDARD || {}).value,
                    "primaryCareOfficeVisit"                : (inNetwork.PRIMARY_CARE_OFFICE_VISIT_IN_TIER1_STANDARD || {}).value,
                    "specialistOfficeVisit"                 : (inNetwork.SPECIALIST_OFFICE_VISIT_IN_TIER1_STANDARD || {}).value,
                    "emergencyRoomVisit"                    : (inNetwork.EMERGENCY_ROOM_VISIT_IN_TIER1_STANDARD || {}).value,
                    "preventiveCare"                        : (inNetwork.PREVENTIVE_CARE_IN_TIER1_STANDARD || {}).value,
                    "outPatientSurgery"                     : (inNetwork.OUTPATIENT_SURGERY_INN_TIER1_STANDARD || {}).value,
                    "inPatientSurgery"                      : (inNetwork.HOSPITAL_INPATIENT_INN_TIER1_STANDARD || {}).value,
                    "retailClinic"                          : (inNetwork.RETAIL_CLINIC_IN_TIER1_STANDARD || {}).value,
                    "telemedicine"                          : (inNetwork.TELEMED_IN_TIER1_STANDARD || {}).value,
                    "urgentCare"                            : (inNetwork.URGENT_CARE_IN_TIER1_STANDARD || {}).value,
                    "inpatientHospitalMaternity"            : (inNetwork.MATERNITY_IN_TIER1_STANDARD || {}).value,
                    "inpatientHospitalMedicalCare"          : (inNetwork.INPATIENT_MEDICAL_IN_TIER1_STANDARD || {}).value,
                    "ambulance"                             : (inNetwork.AMBULANCE_IN_TIER1_STANDARD || {}).value,
                    "physicalMedicine"                      : (inNetwork.PHYSICAL_MEDICINE_IN_TIER1_STANDARD || {}).value,
                    "speechAndOccupationalTherapy"          : (inNetwork.SPEECH_OCCUPATION_THERAPY_IN_TIER1_STANDARD || {}).value,
                    "chiropractor"                          : (inNetwork.CHIROPRACTOR_IN_TIER1_STANDARD || {}).value,
                    "mentalHealthVisits"                    : (inNetwork.MENTAL_HEALTH_VISIT_IN_TIER1_STANDARD || {}).value,
                    "mentalHealthSubstanceAbuseInpatient"   : (inNetwork.INPATIENT_MENTAL_HEALTH_IN_TIER1_STANDARD || {}).value,
                    "detoxAndRehabInpatient"                : (inNetwork.INPATIENT_DETOX_IN_TIER1_STANDARD || {}).value,
                    "mentalHealthSubstanceAbuseOutpatient"  : (inNetwork.OUTPATIENT_MENTAL_HEALTH_IN_TIER1_STANDARD || {}).value,
                    "basicDiagnosticServices"               : (inNetwork.BASIC_DIAGNOSTICS_IN_TIER1_STANDARD || {}).value,
                    "advancedImaging"                       : (inNetwork.ADV_IMAGING_XRAY_INN_TIER1_STANDARD || {}).value,
                    "laboratoryAndPathology"                : (inNetwork.LAB_PATHOLOGY_IN_TIER1_STANDARD || {}).value,
                    "pediatricVisionExam"                   : (inNetwork.PED_VISION_EXAM_INN_TIER1_STANDARD || {}).value,
                    "pediatricVisionFramesAndLenses"        : (inNetwork.PED_VISION_FRAME_INN_TIER1_STANDARD || {}).value,
                    "pediatricDentalExamAndCleaning"        : (inNetwork.PED_DENTAL_EXAM_INN_TIER1_STANDARD || {}).value,
                    "pediatricDentalBasicRestorative"       : (inNetwork.PED_DENTAL_SERVICES_INN_TIER1_STANDARD || {}).value,
                    "skilledNursingFacility"                : (inNetwork.SKILLED_NURSING_INN_TIER1_STANDARD || {}).value
                };
            } else {

                // REGULAR PRODUCT
                // STANRDARD === "regular"
                formatted.inNetwork = {
                    "monthlyPremiumFormatted"               : $filter("currency")(Number(planProduct.pricing.current), "$") || "N/A",

                    "individualDeductible"                  : ($filter("currency")(Number(inNetwork.DEDUCTIBLE_INDV_IN_STANDARD.value || {}), "$", 0)),
                    "individualDeductibleRaw"               : Number((inNetwork.DEDUCTIBLE_INDV_IN_STANDARD || {}).value),
                    "familyDeductible"                      : ($filter("currency")(Number(inNetwork.DEDUCTIBLE_FAM_IN_STANDARD.value || {}), "$", 0)),
                    "familyDeductibleRaw"                   : Number((inNetwork.DEDUCTIBLE_FAM_IN_STANDARD || {}).value),
                    "individualOutOfPocketMax"              : ($filter("currency")(Number(inNetwork.OUT_OF_POCKET_INDV_IN_STANDARD.value || {}), "$", 0)),
                    "individualOutOfPocketMaxRaw"           : Number((inNetwork.OUT_OF_POCKET_INDV_IN_STANDARD || {}).value),
                    "familyOutOfPocketMax"                  : ($filter("currency")(Number(inNetwork.OUT_OF_POCKET_FAM_IN_STANDARD.value || {}), "$", 0)),
                    "familyOutOfPocketMaxRaw"               : Number((inNetwork.OUT_OF_POCKET_FAM_IN_STANDARD || {}).value),

                    "coinsurance"                           : (inNetwork.COINSURANCE_IN_STANDARD || {}).value,
                    "primaryCareOfficeVisit"                : (inNetwork.PRIMARY_CARE_OFFICE_VISIT_IN_STANDARD || {}).value,
                    "specialistOfficeVisit"                 : (inNetwork.SPECIALIST_OFFICE_VISIT_IN_STANDARD || {}).value,
                    "emergencyRoomVisit"                    : (inNetwork.EMERGENCY_ROOM_VISIT_IN_STANDARD || {}).value,
                    "preventiveCare"                        : (inNetwork.PREVENTIVE_CARE_IN_STANDARD || {}).value,
                    "outPatientSurgery"                     : (inNetwork.OUTPATIENT_SURGERY_INN_STANDARD || {}).value,
                    "inPatientSurgery"                      : (inNetwork.HOSPITAL_INPATIENT_INN_STANDARD || {}).value,
                    "retailClinic"                          : (inNetwork.RETAIL_CLINIC_IN_STANDARD || {}).value,
                    "telemedicine"                          : (inNetwork.TELEMED_IN_STANDARD || {}).value,
                    "urgentCare"                            : (inNetwork.URGENT_CARE_IN_STANDARD || {}).value,
                    "inpatientHospitalMaternity"            : (inNetwork.MATERNITY_IN_STANDARD || {}).value,
                    "inpatientHospitalMedicalCare"          : (inNetwork.INPATIENT_MEDICAL_IN_STANDARD || {}).value,
                    "ambulance"                             : (inNetwork.AMBULANCE_IN_STANDARD || {}).value,
                    "physicalMedicine"                      : (inNetwork.PHYSICAL_MEDICINE_IN_STANDARD || {}).value,
                    "speechAndOccupationalTherapy"          : (inNetwork.SPEECH_OCCUPATION_THERAPY_IN_STANDARD || {}).value,
                    "chiropractor"                          : (inNetwork.CHIROPRACTOR_IN_STANDARD || {}).value,
                    "mentalHealthVisits"                    : (inNetwork.MENTAL_HEALTH_VISIT_IN_STANDARD || {}).value,
                    "mentalHealthSubstanceAbuseInpatient"   : (inNetwork.INPATIENT_MENTAL_HEALTH_IN_STANDARD || {}).value,
                    "detoxAndRehabInpatient"                : (inNetwork.INPATIENT_DETOX_IN_STANDARD || {}).value,
                    "mentalHealthSubstanceAbuseOutpatient"  : (inNetwork.OUTPATIENT_MENTAL_HEALTH_IN_STANDARD || {}).value,
                    "basicDiagnosticServices"               : (inNetwork.BASIC_DIAGNOSTICS_IN_STANDARD || {}).value,
                    "advancedImaging"                       : (inNetwork.ADV_IMAGING_XRAY_INN_STANDARD || {}).value,
                    "laboratoryAndPathology"                : (inNetwork.LAB_PATHOLOGY_IN_STANDARD || {}).value,
                    "pediatricVisionExam"                   : (inNetwork.PED_VISION_EXAM_INN_STANDARD || {}).value,
                    "pediatricVisionFramesAndLenses"        : (inNetwork.PED_VISION_FRAME_INN_STANDARD || {}).value,
                    "pediatricDentalExamAndCleaning"        : (inNetwork.PED_DENTAL_EXAM_INN_STANDARD || {}).value,
                    "pediatricDentalBasicRestorative"       : (inNetwork.PED_DENTAL_SERVICES_INN_STANDARD || {}).value,
                    "skilledNursingFacility"                : (inNetwork.SKILLED_NURSING_INN_STANDARD || {}).value
                };

                // In case of formatting issues ( DE and MN had issues before )
                if (isNaN(inNetwork.DEDUCTIBLE_INDV_IN_STANDARD.value))  {
                    formatted.inNetwork.individualDeductible      = (inNetwork.DEDUCTIBLE_INDV_IN_STANDARD || {}).value;
                    formatted.inNetwork.individualDeductibleRaw   = (inNetwork.DEDUCTIBLE_INDV_IN_STANDARD || {}).value;
                } else {
                    formatted.inNetwork.individualDeductible      = ($filter("currency")(Number(inNetwork.DEDUCTIBLE_INDV_IN_STANDARD.value || {}), "$", 0));
                    formatted.inNetwork.individualDeductibleRaw   = Number((inNetwork.DEDUCTIBLE_INDV_IN_STANDARD || {}).value);
                }

                if (isNaN(inNetwork.DEDUCTIBLE_FAM_IN_STANDARD.value))  {
                    formatted.inNetwork.familyDeductible      = (inNetwork.DEDUCTIBLE_FAM_IN_STANDARD || {}).value;
                    formatted.inNetwork.familyDeductibleRaw   = (inNetwork.DEDUCTIBLE_FAM_IN_STANDARD || {}).value;
                } else {
                    formatted.inNetwork.familyDeductible      = ($filter("currency")(Number(inNetwork.DEDUCTIBLE_FAM_IN_STANDARD.value || {}), "$", 0));
                    formatted.inNetwork.familyDeductibleRaw   = Number((inNetwork.DEDUCTIBLE_FAM_IN_STANDARD || {}).value);
                }

                if (isNaN(inNetwork.OUT_OF_POCKET_INDV_IN_STANDARD.value))  {
                    formatted.inNetwork.individualOutOfPocketMax     = (inNetwork.OUT_OF_POCKET_INDV_IN_STANDARD || {}).value;
                    formatted.inNetwork.individualOutOfPocketMaxRaw  = (inNetwork.OUT_OF_POCKET_INDV_IN_STANDARD || {}).value;
                } else {
                    formatted.inNetwork.individualOutOfPocketMax     = ($filter("currency")(Number(inNetwork.OUT_OF_POCKET_INDV_IN_STANDARD.value || {}), "$", 0));
                    formatted.inNetwork.individualOutOfPocketMaxRaw  = Number((inNetwork.OUT_OF_POCKET_INDV_IN_STANDARD || {}).value);
                }

                if (isNaN(inNetwork.OUT_OF_POCKET_FAM_IN_STANDARD.value))  {
                    formatted.inNetwork.familyOutOfPocketMax     = (inNetwork.OUT_OF_POCKET_FAM_IN_STANDARD || {}).value;
                    formatted.inNetwork.familyOutOfPocketMaxRaw  = (inNetwork.OUT_OF_POCKET_FAM_IN_STANDARD || {}).value;
                } else {
                    formatted.inNetwork.familyOutOfPocketMax     = ($filter("currency")(Number(inNetwork.OUT_OF_POCKET_FAM_IN_STANDARD.value || {}), "$", 0));
                    formatted.inNetwork.familyOutOfPocketMaxRaw  = Number((inNetwork.OUT_OF_POCKET_FAM_IN_STANDARD || {}).value);
                }
            }

            // PREFERRED | ENHANCED | STANDARD | OUT
            if (planProductTransformed.is4TierProduct) {

                preferred = {};

                // Deductible - Individual
                if (inNetwork.DEDUCTIBLE_INDV_IN_PREFERRED) {
                    if (isNaN(inNetwork.DEDUCTIBLE_INDV_IN_PREFERRED.value)) {
                        preferred.individualDeductible    = (inNetwork.DEDUCTIBLE_INDV_IN_PREFERRED || {}).value;
                        preferred.individualDeductibleRaw = (inNetwork.DEDUCTIBLE_INDV_IN_PREFERRED || {}).value;
                    } else {
                        preferred.individualDeductible    = ($filter("currency")(Number(inNetwork.DEDUCTIBLE_INDV_IN_PREFERRED.value || {}), "$", 0));
                        preferred.individualDeductibleRaw = Number((inNetwork.DEDUCTIBLE_INDV_IN_PREFERRED || {}).value);
                    }
                }
                planProductTransformed.preferredIndividualDeductible = preferred.individualDeductibleRaw;


                // Deductible - Family
                if (inNetwork.DEDUCTIBLE_FAM_IN_PREFERRED) {
                    if (isNaN(inNetwork.DEDUCTIBLE_FAM_IN_PREFERRED.value)) {
                        preferred.familyDeductible    = (inNetwork.DEDUCTIBLE_FAM_IN_PREFERRED || {}).value;
                        preferred.familyDeductibleRaw = (inNetwork.DEDUCTIBLE_FAM_IN_PREFERRED || {}).value;
                    } else {
                        preferred.familyDeductible    = ($filter("currency")(Number(inNetwork.DEDUCTIBLE_FAM_IN_PREFERRED.value || {}), "$", 0));
                        preferred.familyDeductibleRaw = Number((inNetwork.DEDUCTIBLE_FAM_IN_PREFERRED || {}).value);
                    }
                }

                planProductTransformed.preferredFamilyDeductible = preferred.familyDeductibleRaw;
                // Out-of-Pocket - Individual
                if (inNetwork.OUT_OF_POCKET_INDV_IN_PREFERRED) {
                    if (isNaN(inNetwork.OUT_OF_POCKET_INDV_IN_PREFERRED.value)){
                        preferred.individualOutOfPocketMax       = (inNetwork.OUT_OF_POCKET_INDV_IN_PREFERRED || {}).value;
                        preferred.individualOutOfPocketMaxRaw    = (inNetwork.OUT_OF_POCKET_INDV_IN_PREFERRED || {}).value;
                    } else {
                        preferred.individualOutOfPocketMax       = ($filter("currency")(Number(inNetwork.OUT_OF_POCKET_INDV_IN_PREFERRED.value || {}), "$", 0));
                        preferred.individualOutOfPocketMaxRaw    = Number((inNetwork.OUT_OF_POCKET_INDV_IN_PREFERRED || {}).value);
                    }
                }

                planProductTransformed.preferredIndividualOutOfPocketMax = preferred.individualOutOfPocketMaxRaw;

                // Out-of-Pocket - Family
                if (inNetwork.OUT_OF_POCKET_FAM_IN_PREFERRED) {
                    if (isNaN(inNetwork.OUT_OF_POCKET_FAM_IN_PREFERRED.value)){
                        preferred.familyOutOfPocketMax       = (inNetwork.OUT_OF_POCKET_FAM_IN_PREFERRED || {}).value;
                        preferred.familyOutOfPocketMaxRaw    = (inNetwork.OUT_OF_POCKET_FAM_IN_PREFERRED || {}).value;
                    } else {
                        preferred.familyOutOfPocketMax       = ($filter("currency")(Number(inNetwork.OUT_OF_POCKET_FAM_IN_PREFERRED.value || {}), "$", 0));
                        preferred.familyOutOfPocketMaxRaw    = Number((inNetwork.OUT_OF_POCKET_FAM_IN_PREFERRD || {}).value);
                    }
                }
                planProductTransformed.preferredFamilyOutOfPocketMax = preferred.familyOutOfPocketMaxRaw;

                // Coinsurance
                if (inNetwork.COINSURANCE_IN_PREFERRED) {
                    preferred.coinsurance = (inNetwork.COINSURANCE_IN_PREFERRED || {}).value;
                }

                // PCP Visit
                if (inNetwork.PRIMARY_CARE_OFFICE_VISIT_IN_PREFERRED) {
                    preferred.primaryCareOfficeVisit = (inNetwork.PRIMARY_CARE_OFFICE_VISIT_IN_PREFERRED || {}).value;
                }

                // Specialist Visit
                if (inNetwork.SPECIALIST_OFFICE_VISIT_IN_PREFERRED) {
                    preferred.specialistOfficeVisit = (inNetwork.SPECIALIST_OFFICE_VISIT_IN_PREFERRED || {}).value;
                }

                // Emergency Room Visit
                if (inNetwork.EMERGENCY_ROOM_VISIT_IN_PREFERRED) {
                    preferred.emergencyRoomVisit = (inNetwork.EMERGENCY_ROOM_VISIT_IN_PREFERRED || {}).value;
                }

                // Preventive Care
                if (inNetwork.PREVENTIVE_CARE_IN_PREFERRED) {
                    preferred.preventiveCare = (inNetwork.PREVENTIVE_CARE_IN_PREFERRED || {}).value;
                }
                // Out-Patient Surgery
                if (inNetwork.OUTPATIENT_SURGERY_INN_PREFERRED) {
                    preferred.outPatientSurgery = (inNetwork.OUTPATIENT_SURGERY_INN_PREFERRED || {}).value;
                }

                // In-Patient Hospital Stay
                if (inNetwork.HOSPITAL_INPATIENT_INN_PREFERRED) {
                    preferred.inPatientSurgery = (inNetwork.HOSPITAL_INPATIENT_INN_PREFERRED || {}).value;
                }

                // Retail Clinic
                if (inNetwork.RETAIL_CLINIC_IN_PREFERRED) {
                    preferred.retailClinic = (inNetwork.RETAIL_CLINIC_IN_PREFERRED || {}).value;
                }

                // Telemedicine
                if (inNetwork.TELEMED_IN_PREFERRED) {
                    preferred.telemedicine = (inNetwork.TELEMED_IN_PREFERRED || {}).value;
                }

                // In-Patient Urgent Care
                if (inNetwork.URGENT_CARE_IN_PREFERRED) {
                    preferred.urgentCare = (inNetwork.URGENT_CARE_IN_PREFERRED || {}).value;
                }

                // In-Patient Maternity
                if (inNetwork.MATERNITY_IN_PREFERRED) {
                    preferred.inpatientHospitalMaternity = (inNetwork.MATERNITY_IN_PREFERRED || {}).value;
                }

                // In-Patient Medical
                if (inNetwork.INPATIENT_MEDICAL_IN_PREFERRED) {
                    preferred.inpatientHospitalMedicalCare = (inNetwork.INPATIENT_MEDICAL_IN_PREFERRED || {}).value;
                }

                // In-Patient Ambulance
                if (inNetwork.AMBULANCE_IN_PREFERRED) {
                    preferred.ambulance = (inNetwork.AMBULANCE_IN_PREFERRED || {}).value;
                }

                // Physical Medicine
                if (inNetwork.PHYSICAL_MEDICINE_IN_PREFERRED) {
                    preferred.physicalMedicine = (inNetwork.PHYSICAL_MEDICINE_IN_PREFERRED || {}).value;
                }

                // Speech Occupation Therapy
                if (inNetwork.SPEECH_OCCUPATION_THERAPY_IN_PREFERRED) {
                    preferred.speechAndOccupationalTherapy = (inNetwork.SPEECH_OCCUPATION_THERAPY_IN_PREFERRED || {}).value;
                }

                // Chiropractor
                if (inNetwork.CHIROPRACTOR_IN_PREFERRED) {
                    preferred.chiropractor = (inNetwork.CHIROPRACTOR_IN_PREFERRED || {}).value;
                }

                if (inNetwork.MENTAL_HEALTH_VISIT_IN_PREFERRED) {
                    preferred.mentalHealthVisits = (inNetwork.MENTAL_HEALTH_VISIT_IN_PREFERRED || {}).value;
                }

                if (inNetwork.INPATIENT_MENTAL_HEALTH_IN_PREFERRED) {
                    preferred.mentalHealthSubstanceAbuseInpatient = (inNetwork.INPATIENT_MENTAL_HEALTH_IN_PREFERRED || {}).value;
                }

                if (inNetwork.INPATIENT_DETOX_IN_PREFERRED) {
                    preferred.detoxAndRehabInpatient = (inNetwork.INPATIENT_DETOX_IN_PREFERRED || {}).value;
                }

                if (inNetwork.OUTPATIENT_MENTAL_HEALTH_IN_PREFERRED) {
                    preferred.mentalHealthSubstanceAbuseOutpatient = (inNetwork.OUTPATIENT_MENTAL_HEALTH_IN_PREFERRED || {}).value;
                }

                if (inNetwork.ADV_IMAGING_XRAY_INN_PREFERRED) {
                    preferred.advancedImaging = (inNetwork.ADV_IMAGING_XRAY_INN_PREFERRED || {}).value;
                }

                if (inNetwork.BASIC_DIAGNOSTICS_IN_PREFERRED) {
                    preferred.basicDiagnosticServices = (inNetwork.BASIC_DIAGNOSTICS_IN_PREFERRED || {}).value;
                }

                if (inNetwork.LAB_PATHOLOGY_IN_PREFERRED) {
                    preferred.laboratoryAndPathology = (inNetwork.LAB_PATHOLOGY_IN_PREFERRED || {}).value;
                }

                if (inNetwork.PED_VISION_EXAM_INN_PREFERRED) {
                    preferred.pediatricVisionExam = (inNetwork.PED_VISION_EXAM_INN_PREFERRED || {}).value;
                }

                if (inNetwork.PED_VISION_FRAME_INN_PREFERRED) {
                    preferred.pediatricVisionFramesAndLenses = (inNetwork.PED_VISION_FRAME_INN_PREFERRED || {}).value;
                }

                if (inNetwork.PED_DENTAL_EXAM_INN_PREFERRED) {
                    preferred.pediatricDentalExamAndCleanin = (inNetwork.PED_DENTAL_EXAM_INN_PREFERRED || {}).value;
                }

                if (inNetwork.PED_DENTAL_SERVICES_INN_PREFERRED) {
                    preferred.pediatricDentalBasicRestorative = (inNetwork.PED_DENTAL_SERVICES_INN_PREFERRED || {}).value;
                }

                if (inNetwork.SKILLED_NURSING_INN_PREFERRED) {
                    preferred.skilledNursingFacility = (inNetwork.SKILLED_NURSING_INN_PREFERRED || {}).value;
                }

                formatted.preferred = preferred;
            }

            // All products have the same out of network columns
            formatted.outOfNetwork = {

                "individualDeductible"                  : ($filter("currency")(Number(outNetwork.DEDUCTIBLE_INDV_OUT.value || {}), "$", 0)),
                "individualDeductibleRaw"               : Number((outNetwork.DEDUCTIBLE_INDV_OUT || {}).value),
                "familyDeductible"                      : ($filter("currency")(Number(outNetwork.DEDUCTIBLE_FAM_OUT.value || {}), "$", 0)),
                "familyDeductibleRaw"                   : Number((outNetwork.DEDUCTIBLE_FAM_OUT || {}).value),
                "individualOutOfPocketMax"              : ($filter("currency")(Number(outNetwork.OUT_OF_POCKET_INDV_OUT.value || {}), "$", 0)),
                "individualOutOfPocketMaxRaw"           : Number((outNetwork.OUT_OF_POCKET_INDV_OUT || {}).value),
                "familyOutOfPocketMax"                  : ($filter("currency")(Number(outNetwork.OUT_OF_POCKET_FAM_OUT.value || {}), "$", 0)),
                "familyOutOfPocketMaxRaw"               : Number((outNetwork.OUT_OF_POCKET_FAM_OUT || {}).value),

                "coinsurance"                           : (outNetwork.COINSURANCE_OUT || {}).value,
                "primaryCareOfficeVisit"                : (outNetwork.PRIMARY_CARE_OFFICE_VISIT_OUT || {}).value,
                "specialistOfficeVisit"                 : (outNetwork.SPECIALIST_OFFICE_VISIT_OUT || {}).value,
                "emergencyRoomVisit"                    : (outNetwork.EMERGENCY_ROOM_VISIT_OUT || {}).value,
                "preventiveCare"                        : (outNetwork.PREVENTIVE_CARE_OUT || {}).value,
                "outPatientSurgery"                     : (outNetwork.OUTPATIENT_SURGERY_OON || {}).value,
                "inPatientSurgery"                      : (outNetwork.HOSPITAL_INPATIENT_OON || {}).value,
                "retailClinic"                          : (outNetwork.RETAIL_CLINIC_OUT || {}).value,
                "telemedicine"                          : (outNetwork.TELEMED_OUT || {}).value,
                "urgentCare"                            : (outNetwork.URGENT_CARE_OUT || {}).value,
                "inpatientHospitalMaternity"            : (outNetwork.MATERNITY_OUT || {}).value,
                "inpatientHospitalMedicalCare"          : (outNetwork.INPATIENT_MEDICAL_OUT || {}).value,
                "ambulance"                             : (outNetwork.AMBULANCE_OUT || {}).value,
                "physicalMedicine"                      : (outNetwork.PHYSICAL_MEDICINE_OUT || {}).value,
                "speechAndOccupationalTherapy"          : (outNetwork.SPEECH_OCCUPATION_THERAPY_OUT || {}).value,
                "chiropractor"                          : (outNetwork.CHIROPRACTOR_OUT || {}).value,
                "mentalHealthVisits"                    : (outNetwork.MENTAL_HEALTH_VISIT_OUT || {}).value,
                "mentalHealthSubstanceAbuseInpatient"   : (outNetwork.INPATIENT_MENTAL_HEALTH_OUT || {}).value,
                "detoxAndRehabInpatient"                : (outNetwork.INPATIENT_DETOX_OUT || {}).value,
                "mentalHealthSubstanceAbuseOutpatient"  : (outNetwork.OUTPATIENT_MENTAL_HEALTH_OUT || {}).value,
                "basicDiagnosticServices"               : (outNetwork.BASIC_DIAGNOSTICS_OUT || {}).value,
                "advancedImaging"                       : (outNetwork.ADV_IMAGING_XRAY_OON || {}).value,
                "laboratoryAndPathology"                : (outNetwork.LAB_PATHOLOGY_OUT || {}).value,
                "pediatricVisionExam"                   : (outNetwork.PED_VISION_EXAM_OUT || {}).value,
                "pediatricVisionFramesAndLenses"        : (outNetwork.PED_VISION_FRAME_OUT || {}).value,
                "pediatricDentalExamAndCleaning"        : (outNetwork.PED_DENTAL_EXAM_OUT || {}).value,
                "pediatricDentalBasicRestorative"       : (outNetwork.PED_DENTAL_SERVICES_OUT || {}).value,
                "skilledNursingFacility"                : (outNetwork.SKILLED_NURSING_OON || {}).value
            };

            // In case of formatting issues ( DE and MN had issues before )
            if (isNaN(outNetwork.DEDUCTIBLE_INDV_OUT.value))  {
                formatted.outOfNetwork.individualDeductible      = (outNetwork.DEDUCTIBLE_INDV_OUT || {}).value;
                formatted.outOfNetwork.individualDeductibleRaw   = (outNetwork.DEDUCTIBLE_INDV_OUT || {}).value;
            } else {
                formatted.outOfNetwork.individualDeductible      = ($filter("currency")(Number(outNetwork.DEDUCTIBLE_INDV_OUT.value || {}), "$", 0));
                formatted.outOfNetwork.individualDeductibleRaw   = Number((outNetwork.DEDUCTIBLE_INDV_OUT || {}).value);
            }

            if (isNaN(outNetwork.DEDUCTIBLE_FAM_OUT.value))  {
                formatted.outOfNetwork.familyDeductible      = (outNetwork.DEDUCTIBLE_FAM_OUT || {}).value;
                formatted.outOfNetwork.familyDeductibleRaw   = (outNetwork.DEDUCTIBLE_FAM_OUT || {}).value;
            } else {
                formatted.outOfNetwork.familyDeductible      = ($filter("currency")(Number(outNetwork.DEDUCTIBLE_FAM_OUT.value || {}), "$", 0));
                formatted.outOfNetwork.familyDeductibleRaw   = Number((outNetwork.DEDUCTIBLE_FAM_OUT || {}).value);
            }

            if (isNaN(outNetwork.OUT_OF_POCKET_INDV_OUT.value))  {
                formatted.outOfNetwork.individualOutOfPocketMax     = (outNetwork.OUT_OF_POCKET_INDV_OUT || {}).value;
                formatted.outOfNetwork.individualOutOfPocketMaxRaw  = (outNetwork.OUT_OF_POCKET_INDV_OUT || {}).value;
            } else {
                formatted.outOfNetwork.individualOutOfPocketMax     = ($filter("currency")(Number(outNetwork.OUT_OF_POCKET_INDV_OUT.value || {}), "$", 0));
                formatted.outOfNetwork.individualOutOfPocketMaxRaw  = Number((outNetwork.OUT_OF_POCKET_INDV_OUT || {}).value);
            }

            if (isNaN(outNetwork.OUT_OF_POCKET_FAM_OUT.value))  {
                formatted.outOfNetwork.familyOutOfPocketMax     = (outNetwork.OUT_OF_POCKET_FAM_OUT || {}).value;
                formatted.outOfNetwork.familyOutOfPocketMaxRaw  = (outNetwork.OUT_OF_POCKET_FAM_OUT || {}).value;
            } else {
                formatted.outOfNetwork.familyOutOfPocketMax     = ($filter("currency")(Number(outNetwork.OUT_OF_POCKET_FAM_OUT.value || {}), "$", 0));
                formatted.outOfNetwork.familyOutOfPocketMaxRaw  = Number((outNetwork.OUT_OF_POCKET_FAM_OUT || {}).value);
            }

            // Pricing Helper
            var dArray = [];
            angular.forEach(planProduct.pricing.applicants, function(item){

                // Currency Formatted Price
                item.rateFormatted = $filter("currency")(Number(item.rate), "$");

                switch (item.relationship && item.relationship.toUpperCase()) {

                    case "SUBSCRIBER":
                        formatted.pricing.subscriber = item;
                        break;
                    case "SPOUSE":
                        formatted.pricing.spouse = item;
                        break;
                    case "DEPENDENT":
                        dArray.push(item);
                        break;
                    default:
                        break;
                }
            });

            if (dArray.length > 0) {
                formatted.pricing.dependents = dArray;
            }

            // Save to planProductTransformed
            planProductTransformed.formatted = formatted;

            var formattedName = planProduct.name.replace(/ /g, "").replace(/\(/g,"").replace(/\)/g,"").replace(/,/g,"");

            planProductTransformed.formattedName = formattedName;

            var communityBlue           = planProduct.name.toLowerCase().indexOf("community blue") > -1 ? "YES" : "NO";
            var deepLinks               = args.teamsiteContent.deepLinks;
            var findProviderDeepLink    = null;

            if (deepLinks && deepLinks.findProvider && deepLinks.findProvider[planProductTransformed.productGroup]) {
                findProviderDeepLink = deepLinks.findProvider[planProductTransformed.productGroup];
            }

            /****
             * Drug Formulary links (drugFormulary) (links.drugFormulary)
             * We first steal from teamsite, then we - based on state and on/off-exchange status - determine which link to display
             * There are multiple link types (comprehensive, progressive, etc), of which one is currently chosen to be assigned to drugFormulary
             ****/
            planProductTransformed.links.findProvider       = findProviderDeepLink ? findProviderDeepLink : args.teamsiteContent.links.findProvider;
            planProductTransformed.links.findProviderFFM    = args.teamsiteContent.links.findProviderFFM;
            planProductTransformed.links.dental             = args.teamsiteContent.links.dental;
            planProductTransformed.links.pharmacy           = args.teamsiteContent.links.pharmacy;
            planProductTransformed.links.vision             = args.teamsiteContent.links.vision;
            planProductTransformed.links.progressiveDrug    = args.teamsiteContent.links.progressiveDrug;
            planProductTransformed.links.comprehensiveDrug  = args.teamsiteContent.links.comprehensiveDrug;
            planProductTransformed.links.essentialDrug      = args.teamsiteContent.links.essentialDrug;
            planProductTransformed.links.RXpharmacy         = args.teamsiteContent.links.RXpharmacy;
            planProductTransformed.links.RXdrugFormulary    = args.teamsiteContent.links.RXdrugFormulary;

            /****
             * OFF-EXCHANGE / ON-EXCHANGE (MINNESOTA)
             ****/
            if (planProductTransformed.region.toUpperCase() === 'MINCR') {
                if (parseInt(planProductTransformed.planYear) % 2 > 0) {
                    planProductTransformed.links.RXpharmacy         = args.teamsiteContent.links.oddYears.RXpharmacy;
                    planProductTransformed.links.RXdrugFormulary    = args.teamsiteContent.links.oddYears.RXdrugFormulary;
                    planProductTransformed.links.pharmacy           = args.teamsiteContent.links.oddYears.pharmacy;
                    planProductTransformed.links.drugFormulary      = args.teamsiteContent.links.oddYears.progressiveDrug;
                } else {
                    planProductTransformed.links.RXpharmacy         = args.teamsiteContent.links.evenYears.RXpharmacy;
                    planProductTransformed.links.RXdrugFormulary    = args.teamsiteContent.links.evenYears.RXdrugFormulary;
                    planProductTransformed.links.pharmacy           = args.teamsiteContent.links.evenYears.pharmacy;
                    planProductTransformed.links.drugFormulary      = args.teamsiteContent.links.evenYears.progressiveDrug;
                }
            }

            /****
             * ON-EXCHANGE (DELAWARE)
             ****/
            if (planProductTransformed.region.toUpperCase() == 'DE' && args && args.isOnExchange) {
                if (parseInt(planProductTransformed.planYear) % 2 > 0) {

                    // show odd years
                    if (planProductTransformed.attributes.miscattribute.DRUG_FORMULARY.value.indexOf('Progressive')     !== -1) {
                        planProductTransformed.links.drugFormulary = 'http://client.formularynavigator.com/Search.aspx?siteCode=1791332925';
                    }

                    if (planProductTransformed.attributes.miscattribute.DRUG_FORMULARY.value.indexOf('Comprehensive')   !== -1) {
                        planProductTransformed.links.drugFormulary = 'http://client.formularynavigator.com/Search.aspx?siteCode=1788724309';
                    }

                    if (planProductTransformed.attributes.miscattribute.DRUG_FORMULARY.value.indexOf('Essential')       !== -1) {
                        planProductTransformed.links.drugFormulary = 'http://client.formularynavigator.com/Search.aspx?siteCode=6571849149';
                    }
                } else {

                    // show even years
                    if (planProductTransformed.attributes.miscattribute.DRUG_FORMULARY.value.indexOf('Progressive')     !== -1) {
                        planProductTransformed.links.drugFormulary = 'http://client.formularynavigator.com/Search.aspx?siteCode=1791332925';
                    }

                    if (planProductTransformed.attributes.miscattribute.DRUG_FORMULARY.value.indexOf('Comprehensive')   !== -1) {
                        planProductTransformed.links.drugFormulary = 'http://client.formularynavigator.com/Search.aspx?siteCode=1788724309';
                    }

                    if (planProductTransformed.attributes.miscattribute.DRUG_FORMULARY.value.indexOf('Essential')       !== -1) {
                        planProductTransformed.links.drugFormulary = 'http://client.formularynavigator.com/Search.aspx?siteCode=6571849149';
                    }
                }
            }
            /****
             * ON-EXCHANGE (ALL)
             ****/
            else if (args && args.isOnExchange) {

                // data come back as HCR Progressive, causing compare fail, use indexOf
                if (planProductTransformed.attributes.miscattribute.DRUG_FORMULARY.value.indexOf('Progressive')     !== -1) {
                    planProductTransformed.links.drugFormulary = 'http://client.formularynavigator.com/Search.aspx?siteCode=1791332925';
                }
                if (planProductTransformed.attributes.miscattribute.DRUG_FORMULARY.value.indexOf('Comprehensive')   !== -1) {
                    planProductTransformed.links.drugFormulary = 'http://client.formularynavigator.com/Search.aspx?siteCode=1788724309';
                }
                if (planProductTransformed.attributes.miscattribute.DRUG_FORMULARY.value.indexOf('Essential')       !== -1) {
                    planProductTransformed.links.drugFormulary = 'http://client.formularynavigator.com/Search.aspx?siteCode=6571849149';
                }
            }
            /****
             * OFF-EXCHANGE (NON-MINNESOTA)
             ****/
            else if (planProductTransformed.region.toUpperCase() != 'MINCR'){

                if (planProductTransformed.attributes.miscattribute.DRUG_FORMULARY.value.indexOf("Comprehensive")       != -1) {
                    planProductTransformed.links.drugFormulary = planProductTransformed.links.comprehensiveDrug;
                } else if (planProductTransformed.attributes.miscattribute.DRUG_FORMULARY.value.indexOf("Essential")    != -1) {
                    planProductTransformed.links.drugFormulary = planProductTransformed.links.essentialDrug;
                } else {
                    planProductTransformed.links.drugFormulary = planProductTransformed.links.progressiveDrug;
                }
            }

            return planProductTransformed;
            }
        return serviceProperties;
    }]);
};