module.exports = function(app) {
    app.controller('findInsurance.special-enrollment', ["$state", "$scope", "teamsiteManager", "content", "sepConfigUtility","storage", "sessionData",
        function ($state, $scope, teamsiteManager, content, sepConfigUtility, storage, sessionData) {
            
            $scope.content = content.specialEnrollmentV2;
            $scope.questions = content.specialEnrollment;
            $scope.today = new Date();
            $scope.sixtyOneDaysAgo = new Date($scope.today - (61 * 24 * 60 * 60 * 1000));
            $scope.today = $scope.today.toISOString();
            $scope.sixtyOneDaysAgo = $scope.sixtyOneDaysAgo.toISOString();

            $scope.specialEnrollmentQualify = function () {
                if ($scope.birthDateInput.$valid) {
                    if($scope.birthQualification !== undefined){
                        var adoptionDate = new Date($scope.birthQualification);
                        var adoptionElement = {
                            displayEffDate: adoptionDate.toISOString()
                        };
                        sepConfigUtility.setAdoptionDate(adoptionElement);
                    }
                    $state.go('findInsurance.special-enrollment-qualify');
                }
            };

            $scope.gotoSpecialEnrollmentEvents = function(){
                $state.go("findInsurance.special-enrollment-events");
            };
            $scope.leaveHealthSherpaFlow = function() {
                storage.set("leftSherpaFlow", true);
                window.location.reload();
            }
            $scope.goToHealthSherpa = function() {
                window.open('https://highmark.healthsherpa.com/', '_self');
            }
        }
    ]);
};