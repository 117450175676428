module.exports = function(app){
    'use strict';
    app.directive('contactPreference', function () {
        return {
            restrict: 'A',
            replace: true,
            template: require('directives/enrollment/contactPreference.html'),
            scope: {
                titleLabel: "@",
                fieldLabel1: "@",
                fieldLabel2: "@",
                mailLabel: "@",
                emailLabel: "@",
                onlineLabel: "@",
                emailInfoLabel: "@",
                cellInfoLabel: "@",
                tooltipContent: "@",
                contactPreference: "=?",
                emailIndicator: "=?",
                cellIndicator: "=?"
            },
            link: function ($scope, elem, $attrs) { 
            }
        };
    });
};