module.exports = [
  '$scope',
  'ddSetup',
  'storage',
  'teamsiteManager',
  'brand',
  'modal',
  'appConfig',
  'sessionData',
  'brandConfig',
  function($scope, ddSetup, storage, teamsiteManager, brand, modal, appConfig, sessionData, brandConfig) {
    $scope.countyInfo = null;
    $scope.zipCode = null;
    $scope.content = null;

    $scope.onZipCountyResponse = function(response) {
      $scope.countyInfo = response;

      ddSetup.setDDTokenValueByRegion(response.region);

      var currentBYFPageInfo = storage.get('familyRatingInfo') || {
        childOnlyPolicy: null,
        currentMember: false,
        medicareEligible: {},
        family: {
          consumerEffectiveDate: null,
          members: []
        },
        zipCode: null,
        countyInfo: null
      };

      storage.set(
        'familyRatingInfo',
        angular.extend(currentBYFPageInfo, {
          zipCode: $scope.zipCode,
          countyInfo: $scope.countyInfo
        })
      );
    };

    $scope.enterPortal = function() {
      appConfig.getAll().then(function(response) {
        sessionData.appConfig = angular.extend({}, response);
        brandConfig.update();
        modal.close();
      });
    }

    $scope.onZipFailed = function() {
      $scope.badZip = true;
    }

    $scope.$watch('zipCode', function() {
      $scope.badZip = false;
    })
    
    teamsiteManager
      .getContent(['zipCountyModal', ], brand)
      .then(function(content) {
        $scope.content = content;
      });
    
      
  }
];
