/**
* Created by LIDA1FH on 11/03/2015.
*/
module.exports = function(app) {
    "use strict";

    app.controller('pages.sbcDisplay', ["$state", "$scope",
        function($state, $scope) {

            var parsedKeywords;

            $scope.region = $state.params.region;

            $scope.openSbc = function(sbc, isIndv){
                if(isIndv) {
                    window.open(sbc.link);
                } else {
                    if(sbc.hasNonErisaSbcDoc){
                        window.open("https://shop.highmark.com/cmcrst/x-services/sbc/file/" + sbc.NonErisaSbcDoc.fileName,  '_blank');
                    } else {
                        window.open("https://shop.highmark.com/cmcrst/x-services/sbc/file/" + sbc.ErisaSbcDoc.fileName,  '_blank');
                    }

                }
            };

            //refine
            $scope.sidebar.adjustRefineKeywords = function() {

                $scope.sidebar.emptySet = true;
                parsedKeywords          = $scope.sidebar.keywordSearch.replace(/\s+/, ' ').split(' ');

                return parsedKeywords;
            };

            $scope.refineFilter = function(item){

                var i           = parsedKeywords ? parsedKeywords.length : 0;
                var stringItem  = JSON.stringify(item);
                var show        = true;

                while (i--) {
                    if (stringItem.toLowerCase().indexOf(parsedKeywords[i].toLowerCase()) === -1) {
                        show = false;
                    }
                }

                if ($scope.sidebar) {
                    if (item) {
                        if ($scope.sidebar.marketplace) {
                            if ($scope.sidebar.marketplace != item.Marketplace) {
                                show = false;
                            }
                        }

                        if ($scope.sidebar.region) {  
                            if ($scope.sidebar.region.label != item.Region){
                                show = false;
                            }
                        }

                        if ($scope.sidebar.indvCounty !== "") {
                            if (item.County.indexOf($scope.sidebar.indvCounty) < 0 ){
                                show = false;
                            }
                        }

                        if ($scope.sidebar.variant) {
                            if ($scope.sidebar.variant.reference != item.Variant) {
                                show = false;
                            }
                        }

                        if ($scope.sidebar.emptySet && show) {
                            $scope.sidebar.emptySet = false;
                        }
                    }   
                }

                if ($scope.sidebar.language !== "") {
                    show = show && (item.Language === $scope.sidebar.language);
                }
                

                return show;
            };
        }
    ]);
};