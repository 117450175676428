module.exports = function(app) {
    app.controller('enrollment.review-application', ["$state", "$scope", "$q", "teamsiteManager",
        function ($state, $scope, $q, teamsiteManager) {

            teamsiteManager.getContent("reviewApplication", "DEFAULT").then(
                function(data){
                    $scope.content = data;
                }
            );
            $scope.toNext = function () {
                $state.go("enrollment.authorization");
            };
            $scope.goBack = function () {
                $state.go("enrollment.other-insurance");
            };
        }
    ]);
};