module.exports = function(app) {
    app.controller('pages.reviewApplication', ["$state", "$scope",
        function($state, $scope) {

        $scope.headings = "Review Application";
        $scope.backText = "Back To Last Page";
        $scope.backAction = function() {
            window.history.back();
        };

        $scope.goTo = function(page) {
            $state.go(page)
        };

        $scope.record = {
            fullName : {
                first: "Neil",
                last: "Kinnett"
            },
            dob : "5/27/1986",
            effectiveDate : "01/02/2016",
            status : "Pending",
            "documentTypeNeeded" :[
                "Marriage Certificate",
                "Birth Certificate"
            ]
        };
        $scope.document = [
            {
                name: "Neil's BC",
                uploadDate: "12/24/2015",
                docType: "Birth Certificate",
                size: "12 Mb",
                notes: "This is my birth certificate"
            },
            {
                name: "Neil's Social",
                uploadDate: "12/24/2015",
                docType: "Social Security Card",
                size: "7 Mb",
                notes: "This is my SS card"
            }
        ]
    }]);
    return [
        {
            state: "reviewApplication",
            url: "/review-application",
            views: {
                'body@': {
                    template: bootstrap.templates["page-templates/detailCustom.html"],
                    controller: "pages.reviewApplication"
                },
                "content@reviewApplication": {
                    template: require("pages/approvals/review.html")
                }
            }
        }
    ];
};