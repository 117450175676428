module.exports = function (app) {
    app.run(["Service", getSessionStorageData]);
    app.run(["Service", setSessionStorageData]);
    app.run(["Service", getUnsecuredSessionStorageData]);
    app.run(["Service", setUnsecuredSessionStorageData]);

    function getSessionStorageData(Service) {
        new Service({
            name: 'getSessionStorageData',
            group: "cmcrst",
            url: "/cmcrst/x-services/sessionstorage/:userType/:customerId",
            get: function () {
                return this.get();
            }
        });
    }

    function setSessionStorageData(Service) {
        new Service({
            name: 'setSessionStorageData',
            group: "cmcrst",
            url: "/cmcrst/x-services/sessionstorage",
            set: function (type, id, data) {
                var payload = JSON.stringify({
                    "payload": {
                        "sessionStorage": {
                            "userType"  : type,
                            "customerId": id,
                            "data"      : data
                        }
                    }
                });
                return this.put(payload);
            }
        });
    };

    /****
     * These 2 calls can be used pre-login, pre-LTPA2
     ****/
    function getUnsecuredSessionStorageData(Service) {
        new Service({
            name: 'getUnsecuredSessionStorageData',
            group: "cmcrst",
            url: "/cmcrst/x-services/visitorSessionstorage/VISITOR/:customerId",
            get: function () {
                return this.get();
            }
        });
    }

    function setUnsecuredSessionStorageData(Service) {
        new Service({
            name: 'setUnsecuredSessionStorageData',
            group: "cmcrst",
            url: "/cmcrst/x-services/visitorSessionstorage",
            set: function (id, data) {
                var payload = JSON.stringify({
                    "payload": {
                        "sessionStorage": {
                            "userType"  : "VISITOR",
                            "customerId": id,
                            "data"      : data
                        }
                    }
                });
                return this.put(payload);
            }
        });
    };

}
