module.exports = function(app) {
	app.controller('pages.login', ["$state", "$scope", "teamsiteManager",
		function($state, $scope, teamsiteManager) {

            teamsiteManager.getContent("login","HMBCBS").then(function(data) {

                $scope.headings = "SHOP";
                $scope.content = data;
                $scope.sidebar.links = [
                    {
                        heading: data.leftNav.desktopHeadlineText,
                        label: data.leftNav.links[0].linkText,
                        state: "login",
                        description: data.leftNav.subText
                    }
                ];

                $scope.loginModel = {username:"",password:""};
                $scope.login = function() {
                    //console.log('attempting to login...',$scope.loginModel);
                };
                $scope.goToForgotPassword = function() {
                    //console.log("attempting to forget password...");
                };
                $scope.createAccount = function() {
                    //console.log("attempting to createAccount...");
                }
            });
		} 
	]);
    return [{
        state: "login",
        url: "/login",
        views: {
            "body@": {
                template: bootstrap.templates["page-templates/category.html"],
                controller: "pages.login"
            },
            "content@login": {
                template: require("pages/login/login.html"),
                controller: "pages.login"
            }
        }
    }];
};