/**
 * Created by lid0vs0 on 8/10/2018.
 */
module.exports = function(bootstrap) {
    bootstrap.run(["Service", function (Service) {
        return new Service({
            name: 'groupNumberSearch',
            group: "cmcrst",
            url: "/cmcrst/x-services/sbc/documents",
            cache: false,

            postGroupNumberSearch: function(payload){
                var fullPayload = {
                    "baseMessage":{
                        "headerVersion":"1.0",
                    },
                    "payload": payload
                };
                return this.post(fullPayload);
            }
        });
    }]);
};