module.exports = function (app) {
    'use strict';
    return app.constant("errorMessages",  {
        "ERR0700": "Field is Required",
        "ERR0701": "Invalid entry. Response cannot contain all spaces, special characters or numbers.",
        "ERR0702": "Please enter a valid Phone Number. Phone Numbers should be 10 digits and include an area code.",
        "ERR0703": "The ZIP code you entered is outside the service areas of the states in which we offer plans. Please confirm you entered the ZIP code correctly or call 1-888-630-BLUE, 8 a.m. to 8 p.m, seven days a week. TTY call 711.",
        "ERR0705": "Email addresses must be at least 7 characters and contain an \"@\" and \".\" character.  Please re-enter your Email address.",
        "ERR0707": "Please enter a valid social security number.",
        "ERR0708": "Invalid address",
        "ERR0709": "Date cannot be a future date.",
        "ERR0710": "Please enter a valid Date in MM/DD/YYYY format.",
        "ERR0712": "Invalid entry. Response cannot contain any letters, special characters, or spaces.",
        "ERR0713": "Invalid entry. Response may only contain letters, spaces, - or '",
        "ERR0714": "Same-sex spouse or domestic partnership applications are temporarily only available in paper format.  To start the paper application process, please call your regional phone number listed in the \"Need Help?\" section at the top of the screen.",
        'ERR0763': 'Invalid entry. Response may only contain alphanumeric characters',
        "ERR0766": "Invalid entry. Response may only contain numbers",
        "ERR0767": "The date that you have entered is invalid. Please verify and update.",
        "ERR0768": "The value you entered is not valid.",
        "ERR0769": "The value you entered is not valid.",
        "ERR0770": "The value you entered is not valid.",
        "ERR0771": "The value you entered is not valid.",
        "ERR0772": "The value you entered is not valid.",
        "ERR0773": "The value you entered is not valid."
    });
};