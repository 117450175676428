module.exports = ["$scope", function ($scope) {

        $scope.cancel = function() {
            $scope.closeModal();
        };

        $scope.goToMedicare = function() {
        	window.open("https://" + window.location.hostname + "/medicare", "_blank");
        };

        (function onInit(){	        
	    })();
}];