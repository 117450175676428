module.exports = function(bootstrap) {
    bootstrap.run(["Service", function (Service) {
        return new Service({
            name: 'sepLifeEventDocUpload',
            group: "cmcrst",
            url: "/cmcrst/x-services/public/uploadSepDocument",
            cache: false,
            contentType: undefined,
            transformRequest: angular.identity,
            documentUpload: function(data){
                return this.post(data);
            }
        });
    }]);
};