module.exports = function (app) {

    app.filter('dateConversion', function () {
        return function (inputDate, outputFormat) {
            //Manager for converting dates to the correct format
            //Accept a date, and the format the user wants
            //This is ugly, fix it if you want to

            //temp fix to get around IE8 date issue
            //if the first 5 characters of the string are in the format "yyyy-" we will assume the input date format
            //is in the format yyyy-mm-dd
            //in this case, build out the date by parsing the string vs passing to the Date constructor
            var convertDate;
            if (typeof inputDate === "string" && inputDate.search(/[0-9]{4,4}-[0-9]{1,2}-[0-9]{1,2}/) === 0) {
                var parts = inputDate.split("-"); //yyyy-mm-dd
                convertDate = new Date(parts[0], (parts[1] - 1), parts[2].substring(0, 2));
            } else if (typeof inputDate === "string" && inputDate.search(/[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4,4}/) === 0) {
                var parts = inputDate.split("/"); //mm/dd/yyyy
                convertDate = new Date(parts[2], (parts[0] - 1), parts[1]);
            } else {
                convertDate = new Date(inputDate);
            }

            var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                mon = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                mmm = convertDate.getMonth(),
                d = convertDate.getDate(),
                yyyy = convertDate.getFullYear(),
                yy = yyyy.toString().substr(2, 2),
                hh = convertDate.getHours(),
                min = convertDate.getMinutes(),
                ss = convertDate.getSeconds(),
                returnDate,
                mm = mmm,
                dd = d,
                mo = mmm + 1;
            //Set all digits to their originators, then if they need formatting, that is done below.

            //LEGEND
            //mmm is a month on 0-11 scale (used to grab the mon/months from the mon/months arrays)
            //mo is month on 1-12 scale
            //mm is month on 01-12 scale
            //d is day on 1-31 scale
            //dd is day on 01-31 scale
            //If format isn"t displayed, add a version for - and for / so that both formats are supported

            //This section adds 0 to single digit numbers.
            if (d < 10) {
                dd = "0" + d;
            }
            if (mmm < 9) {
                mm = "0" + mo;
            }
            // For Oct, Nov and Dec
            else {
                mm = mo;
            }
            if (yy < 10) {
                yy = "0" + yy;
            }
            if (hh < 10) {
                hh = "0" + hh;
            }
            if (min < 10) {
                min = "0" + min;
            }
            if (ss < 10) {
                ss = "0" + ss;
            }

            outputFormat = outputFormat ?  outputFormat : "mm/dd/yyyy";

            switch (outputFormat) {
                case "mm/dd/yyyy":
                    returnDate = mm + "/" + dd + "/" + yyyy;
                    break;

                case "mm-dd-yyyy":
                    returnDate = mm + "-" + dd + "-" + yyyy;
                    break;

                case "m/d/yyyy":
                    returnDate = mo + "/" + d + "/" + yyyy;
                    break;

                case "m-d-yyyy":
                    returnDate = mo + "-" + d + "-" + yyyy;
                    break;

                case "mm/dd/yy":
                    returnDate = mm + "/" + dd + "/" + yy;
                    break;

                case "mm-dd-yy":
                    returnDate = mm + "-" + dd + "-" + yy;
                    break;

                case "m/d/yy":
                    returnDate = mo + "/" + d + "/" + yy;
                    break;

                case "m-d-yy":
                    returnDate = mo + "-" + d + "-" + yy;
                    break;

                case "mon/dd/yy":
                    returnDate = mon[mm] + "/" + dd + "/" + yy;
                    break;

                case "mon-dd-yy":
                    returnDate = mon[mm] + "-" + dd + "-" + yy;
                    break;

                case "mon/dd/yyyy":
                    returnDate = mon[mm] + "/" + dd + "/" + yyyy;
                    break;

                case "yy/mm/dd":
                    returnDate = yy + "/" + mm + "/" + dd;
                    break;

                case "yy-mm-dd":
                    returnDate = yy + "-" + mm + "-" + dd;
                    break;

                case "yy/m/d":
                    returnDate = yy + "/" + mo + "/" + d;
                    break;

                case "yy-m-d":
                    returnDate = yy + "-" + mo + "-" + d;
                    break;

                case "yyyy-mm-dd":
                    returnDate = yyyy + "-" + mm + "-" + dd;
                    break;

                case "yyyy/mm/dd":
                    returnDate = yyyy + "/" + mm + "/" + dd;
                    break;

                case "yyyy-m-d":
                    returnDate = yyyy + "-" + mo + "-" + d;
                    break;

                case "yyyy/mm/dd":
                    returnDate = yyyy + "/" + mo + "/" + d;
                    break;

                case "dd/mm/yyyy":
                    returnDate = dd + "/" + mm + "/" + yyyy;
                    break;

                case "dd-mm-yyyy":
                    returnDate = dd + "-" + mm + "-" + yyyy;
                    break;

                case "d/m/yyyy":
                    returnDate = d + "/" + mo + "/" + yyyy;
                    break;

                case "d-m-yyyy":
                    returnDate = d + "-" + mo + "-" + yyyy;
                    break;

                case "dd/mm/yy":
                    returnDate = dd + "/" + mm + "/" + yy;
                    break;

                case "dd-mm-yy":
                    returnDate = dd + "-" + mm + "-" + yy;
                    break;

                case "d/m/yy":
                    returnDate = d + "/" + mo + "/" + yy;
                    break;

                case "d-m-yy":
                    returnDate = d + "-" + mo + "-" + yy;
                    break;

                case "mon-dd-yy":
                    returnDate = mon[mm] + "-" + dd + "-" + yyyy;
                    break;

                case "iso":
                    returnDate = yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + min;
                    break;

                case "month dd, yyyy":
                    returnDate = months[mmm] + " " + dd + ", " + yyyy;
                    break;

                case "month dd, yy":
                    returnDate = months[mmm] + " " + dd + ", " + yy;
                    break;

                case "month d, yyyy":
                    returnDate = months[mmm] + " " + d + ", " + yyyy;
                    break;

                case "month d, yy":
                    returnDate = months[mmm] + " " + d + ", " + yy;
                    break;
            }
            return returnDate;
        };
    });

};