module.exports = function(app) {
	// Federally Facilitated Marketplace
	return app.factory("ffmEligibilityUtility", [
        "storage",
        "$q",
        "services",
        "sessionData",
        "configurationUtility",
        "prospectUtility",
        "$state",
        "modal",
        function(storage,
                 $q,
                 services,
                 sessionData,
                 configurationUtility,
                 prospectUtility,
                 $state,
                 modal) {

        var formattedSAMLResponse = null;

		var utilities = {
            "formatForFFM"               : _formatForFFM,
            "getSAML"                    : _getSAML,
            "setFormattedSAML"           : _setFormattedSAML,
            "getFormattedSAML"           : _getFormattedSAML,
            "updateProspectBeforeForward": _updateProspectBeforeForward,
            "sendToFFM"                  : _sendToFFM
		};

        /****
         * External Functions
         ****/

        function _formatForFFM(data) {

            // data is JUST the prospect object
            data.status = {
                "id": 6,
                "description": "New Customer",
                "details": "brand new customer"
            };

            if (data.homePhone) {
                data.homePhone = data.homePhone.replace(/[^a-zA-Z0-9]/g, "");
            }

            data.partnerAssignedConsumerID  = data.prospectId;
            data.ffmId                      = data.agent.ffmId;
            data.userType                   = sessionData.basicInfo.role ? "Agent" : "";
            data.userType                   = (function(){
                switch (sessionData.basicInfo.role.toUpperCase()) {
                    case "AGENT":
                    case "AGENCY_ADMIN":
                    case "INTERNAL":
                        return "Agent";
                        break;
                    case "MEMBER":
                    case "CONSUMER": 
                        return "Consumer";
                        break;
                    default: 
                        return "THIS IS WRONG";
                        break;
                }
            })()
            data.npnNo                      = data.userType === "Agent" ? data.agent.npn : "";
            
            // data.applicationType            = "ONEX";
            delete data.applicationType;
            delete data.prospectBYFInfo;

            return data;
        };

        function _getSAML(postData) {
            var getSamlDeferred = $q.defer();

            configurationUtility
                .getAppConfigValues()
                .then(function(data) {
                    console.log("getSAML data", JSON.stringify(data));
                    var restructuredPostData = _restructureDataForFFM(postData);

                    restructuredPostData.payload.InformationExchangeSystemID    = data.Information_exchange_system_id;
                    restructuredPostData.payload.NameID                         = data.NameID;
                    restructuredPostData.payload.ReturnURL                      = (function getRelevantURL(){
                        var env = null;
                        if (window.location.href.indexOf("shoptenv2") !== -1) {
                            env = "shoptenv2";
                        } else if (window.location.href.indexOf("shopdenv2") !== -1) {
                            env = "shopdenv2";
                        } else if (window.location.href.indexOf("shoptenv3") !== -1) {
                            env = "shoptenv3";
                        } else if (window.location.href.indexOf("shopdenv3") !== -1) {
                            env = "shopdenv3";
                        } else if (window.location.href.indexOf("shoptenv6") !== -1) {
                            env = "shoptenv6";
                        } else if (window.location.href.indexOf("shopdenv6") !== -1) {
                            env = "shopdenv6";
                        } else if (window.location.href.indexOf("shoptenv7") !== -1) {
                            env = "shoptenv7";
                        } else if (window.location.href.indexOf("shopdenv7") !== -1) {
                            env = "shopdenv7";
                        } else {
                            env = "shop";
                        }

                        return "https://"+ env + ".highmark.com/cmcrst/x-services/consumers/response";
                    })();

                    restructuredPostData.payload.TransferType                   = data.TransferType;
                    restructuredPostData.payload.KeepAliveURL                   = postData.keepAliveURL || data.Keep_alive_url;
                    
                    services
                        .dpsext
                        .getSAML()
                        .post(restructuredPostData)
                        .then(function(response) {
                            // data for form
                            var samlPostData = response.data.replace("SAMLResponse=","");
                            _setFormattedSAML(samlPostData);
                            getSamlDeferred.resolve(_getFormattedSAML());
                        })

                });

            return getSamlDeferred.promise;
        };

        function _setFormattedSAML(formattedSAML) {
            formattedSAMLResponse = formattedSAML;
        };

        function _getFormattedSAML() {
            return formattedSAMLResponse;
        };

        function _updateProspectBeforeForward(prospectId) {
            var updateProspectBeforeForwardDeferred = $q.defer();

            var payload = {
                "payload": {
                    "PartnerAssignedConsumerID" : prospectId,
                    "TransferType"              : "Transfer Consumer to FFM"
                }
            };
         
            services
                .cmcrst
                .recordUserTransferToFFM()
                .update(payload)
                .then(function(updateProspectBeforeForwardResponse) {
                    updateProspectBeforeForwardDeferred.resolve(updateProspectBeforeForwardResponse);
                    console.log("updateProspectBeforeForwardResponse", updateProspectBeforeForwardResponse);
                }, function(error){
                    updateProspectBeforeForwardDeferred.resolve(null);
                });

            return updateProspectBeforeForwardDeferred.promise;
        };

        /****
         * Utility Functions
         ****/
        function _restructureDataForFFM(data) {

            // Here are all the possible values that I'm aware of
            //  var payload = {
            //  "CityName": "Pittsburgh",
            //  "DateOfBirth": "01/01/2000",
            //  "Email": "test@highmark.com",
            //  "FFEAssignedConsumerID": "sdds",
            //  "FFEUserID": "AB_Issuer225",
            //  "FirstName": "FirstName",
            //  "InformationExchangeSystemID": "04.HBC.PA*.321.288",
            //  "KeepAliveURL": "",
            //  "LastName": "LastName",
            //  "mediaType": null,
            //  "MiddleName": "MiddleName",
            //  "NameID": "04.HBC.PA*.321.288",
            //  "NPN": "sd",
            //  "PartnerAssignedConsumerID": "sds",
            //  "PhoneNumber": "1234567890",
            //  "PlanResultsFilter": "",
            //  "ReturnURL": "https://servicestenv3.highmark.com/cmcrst/x-services/consumers/response",
            //  "SSN": "",
            //  "State": "PA",
            //  "StateExchangeCode": "PA0",
            //  "StreetName1": "fifth avenue place",
            //  "StreetName2": "suite 2",
            //  "SuffixName": "Jr.",
            //  "TransactionID": "11111",
            //  "TransferType": "Direct Enrollment",
            //  "url": null,
            //  "UserType": "Agent",
            //  "ZipCode": "15220"
            //  }


            var payload = {};

            if (typeof data === "object") {

                if (data.firstName) {
                    payload.FirstName = data.firstName;
                }
                if (data.lastName) {
                    payload.LastName = data.lastName;
                }
                if (data.dob) {
                    payload.DateOfBirth = (function(){

                        var dateOfBirthFull = new Date(data.dob);

                        var formattedMonth;
                        if ((dateOfBirthFull.getMonth() + 1).toString().length === 1) {
                            formattedMonth = "0" + (dateOfBirthFull.getMonth() + 1).toString()
                        } else {
                            formattedMonth = (dateOfBirthFull.getMonth() + 1).toString();
                        }

                        var formattedDayOfMonth;
                        if ((dateOfBirthFull.getDate()).toString().length === 1) {
                            formattedDayOfMonth = "0" + (dateOfBirthFull.getDate()).toString();
                        } else {
                            formattedDayOfMonth = (dateOfBirthFull.getDate()).toString();
                        }

                        var dateOfBirthFormatted = (dateOfBirthFull.getFullYear()).toString();
                            dateOfBirthFormatted += "-";
                            dateOfBirthFormatted += formattedMonth;
                            dateOfBirthFormatted += "-";
                            dateOfBirthFormatted += formattedDayOfMonth;

                        return dateOfBirthFormatted;
                    })();
                }
                if (data.address1) {
                    payload.StreetName1 = data.address1;
                }
                if (data.address2) {
                    payload.StreetName2 = data.address2;
                }
                if (data.city && data.city.length >= 5) {
                    payload.CityName = data.city;
                }
                if (data.zipCode) {
                    payload.ZipCode = data.zipCode;
                }
                if (data.homePhone) {
                    payload.PhoneNumber = data.homePhone;
                }
                if (data.email) {
                    payload.Email = data.email;
                }
                if (data.state) {
                    payload.State = data.state;
                    payload.StateExchangeCode = data.state + "0";
                }
                if (data.userType) {
                    payload.UserType = data.userType;
                }
                // I think this will only exist for editing, I'm not sure what it will be called yet
                if (data.ffmAssignedConsumerID) {
                    payload.FFEAssignedConsumerID = data.ffmAssignedConsumerID;
                }
                if (data.ffmId) {
                    payload.FFEUserID = data.ffmId;
                }
                if (data.npnNo) {
                    payload.NPN = data.npnNo;
                }
                if (data.partnerAssignedConsumerID) {
                    payload.PartnerAssignedConsumerID = data.partnerAssignedConsumerID;
                }

            }

            return {"payload": payload};
        };
            function _sendToFFM(){
                var isLoggedIn = sessionData && sessionData.loggedIn;
                var producerRoles   = ["INTERNAL", "AGENT", "AGENCY_ADMIN"];
                var isProducer = sessionData && sessionData.basicInfo && sessionData.basicInfo.role && producerRoles.indexOf(sessionData.basicInfo.role) !== -1;
                var isConsumer = sessionData && sessionData.basicInfo && sessionData.basicInfo.role && producerRoles.indexOf(sessionData.basicInfo.role) === -1;

                if (isLoggedIn) {

                    // Consumer Prospect
                    if (isConsumer) {
                        var onExchangeObject = storage.get("onExchangeObject");
                        localStorage.setItem("ffmObject", JSON.stringify(onExchangeObject));
                        //set onX object to something(sessionStorage?) to be retrieved in 2.5 eligibility as a flag
                        prospectUtility
                            .doesProspectExist()
                            .then(function (existsResponse) {
                                      var exists = existsResponse.prospects.length > 0;
                                      if (!exists) {
                                          prospectUtility
                                              .createProspect(true)
                                              .then(function (createdProspect) {
                                                        prospectUtility
                                                            .saveProspect(createdProspect)
                                                            .then(function (prospectResponse) {
                                                                      // route to 2.5
                                                                      window.location.href = "/#!/marketplace/application-status";
                                                                  });
                                                    });
                                      } else {
                                          window.location.href = "/#!/marketplace/application-status";
                                      }
                                  });
                    } else if (isProducer) {
                        var onExchangeObject = storage.get("onExchangeObject");
                        localStorage.setItem("ffmObject", JSON.stringify(onExchangeObject));
                        //set onX object to something(sessionStorage?) to be retrieved in 2.5 eligibility as a flag
                        // Get from initial login call
                        var prospectData = angular.copy(storage.get("producerAttributeHelper").prospectData.payload.prospect);
                        // reformat prospectData
                        var ffmFormattedProspect = _formatForFFM(angular.copy(prospectData));

                        // get the SML value returned from prospect


                        var ratingInfoPayload = storage.get("ratingInfoPayload");

                        //set appropriate data for producer portal to use
                        prospectData.status = {
                            "id": 6,
                            "description": "New Quote",
                            "details": "New quote"
                        };

                        prospectData.applicationType = "ONEX";

                        delete prospectData.umi;

                        prospectUtility
                            .updateProspect(prospectData)
                            .then(function (prospectResponse) {
                                      _updateProspectBeforeForward(prospectData.prospectId)
                                          .then(function () {

                                                    services
                                                        .cmcrst
                                                        .setSessionStorageData()
                                                        .set("PROSPECT", prospectData.prospectId, sessionData.loginData)
                                                        .then(function () {
                                                                  _getSAML(angular.copy(ffmFormattedProspect))
                                                                      .then(
                                                                      function (formattedSAMLResponse) {

                                                                          storage.set("saml", formattedSAMLResponse);


                                                                          modal.open({
                                                                              template: require("modals/ffmPreSSOModal.html"),
                                                                              controller: require("modals/ffmPreSSOModal"),
                                                                          });
                                                                      });
                                                              });
                                                });
                                  });

                    }
                }
                // Not Logged In, force login
                else {
                    // hook.execute("triggerLoginBox");
                    $state.go("registration.register");
                }
            };

		return utilities;
	}]);
}