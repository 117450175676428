module.exports = function(app) {
    "use strict";
    
    app.controller('pages.sbcs', ["$state", "$scope", "sbcsData", "brand", "brandConfig", "navigation","services", "appConfig","sessionData", "modal",
        function($state, $scope, sbcsData, brand, brandConfig, navigation, services, appConfig, sessionData,modal) {
        //set the line of business
            $scope.sidebar.setLob = function() {
                $scope.sidebar.lobSet = true;
            };

            $scope.sidebar.isWY = (brand &&
            brand.current &&
            brand.current.sharedBrandCode &&
            (brand.current.sharedBrandCode == "wybcbs"));

            $scope.headings = "Summary of Benefits and Coverage";
            $scope.sidebar.year = "";
            $scope.sidebar.lob = "";
            $scope.sidebar.language = "";
            if(window.location.href.includes("highmark.com")){

                $scope.sidebar.showLobBtns = true;
            } else {
                $scope.sidebar.lob="indv";
                $scope.sidebar.setLob();
            }
            //statically create months array for use in the smallgroup view of sbcs
            $scope.sidebar.months = [
                {
                    "name": "JANUARY",
                    "num": "01"
                },
                {
                    "name": "FEBRUARY",
                    "num": "02"
                },
                {
                    "name": "MARCH",
                    "num": "03"
                },
                {
                    "name": "APRIL",
                    "num": "04"
                },
                {
                    "name": "MAY",
                    "num": "05"
                },
                {
                    "name": "JUNE",
                    "num": "06"
                },
                {
                    "name": "JULY",
                    "num": "07"
                },
                {
                    "name": "AUGUST",
                    "num": "08"
                },
                {
                    "name": "SEPTEMBER",
                    "num": "09"
                },
                {
                    "name": "OCTOBER",
                    "num": "10"
                },
                {
                    "name": "NOVEMBER",
                    "num": "11"
                },
                {
                    "name": "DECEMBER",
                    "num": "12"
                }
            ];

            $scope.sidebar.groupNumberSearch = function(){
                var x = $scope;
                var groupNo = $scope.sidebar.groupNo;
                if(groupNo) {
                    $scope.sidebar.groupNo = "";
                    var payload = {};
                    //group number search is only for small group plans
                    payload.productType = "S";
                    payload.groupNumber = groupNo;
                    payload.effectiveDate = "01/" + $scope.sidebar.year;
                    payload.effectiveCovStartDate = "";
                    payload.erisaIndicator = "Y";
                    payload.productIds = [""];
                    services.cmcrst.groupNumberSearch().postGroupNumberSearch(payload).then(function (response){
                        $scope.sidebar.showLoading = false;

                        var sbcProducts = [];
                        angular.forEach( response.data.payload.products, function(value, key){
                            value.hasNonErisaSbcDoc = false;
                            sbcProducts.push(value);
                        });

                        $scope.sidebar.sgProducts = sbcProducts;

                    }, function (error){
                        $scope.sidebar.showLoading = false;
                        modal.open({
                            template: require('modals/sbc-groupNo-error.html'),
                            controller: require('modals/sbc-smallgroup-error-modal'),
                            onClose: function () {
                                //if no sbcs with the provided group number were able to be found,
                                //alert the user
                            }
                        });
                    });
                    $scope.sidebar.showLoading = true;
                }
            };

            $scope.sidebar.searchCounty = function() {
                var x = $scope;
                x = services;
                //if all the information needed to access the county service is available, move forward and create a request
                $scope.sidebar.activeSearch = $scope.sidebar.sgCounty && $scope.sidebar.effectiveDate && $scope.sidebar.zipCode;
                if($scope.sidebar.activeSearch){
                    //format the county code to be appropriate for the payload
                    var codeLength = 3;
                    var formattedCountyCode = "" + $scope.sidebar.sgCounty.code;
                    var selectedCountyName = $scope.sidebar.sgCounty.county;
                    while (formattedCountyCode.length < codeLength) {
                        formattedCountyCode = "0" + formattedCountyCode;
                    }
                    var payload = {};
                    payload.countycode = formattedCountyCode;
                    payload.county = selectedCountyName;
                    payload.county = $scope.sidebar.sgCounty.county;
                    payload.region = $scope.sidebar.region.nickname;
                    payload.ddvalue = "";
                    payload.erisaIndicator = "Y";
                    payload.effectiveCovStartDate = $scope.sidebar.effectiveDate + "/" + $scope.sidebar.year;
                    //call the county search backend service to obtain small group documents
                    services.cmcrst.smallGroupCountySearch().postSmallGroupCountySearch(payload).then(function (response){

                        $scope.sidebar.showLoading = false;
                        if (response.data.length < 1){
                            modal.open({
                                template: require('modals/sbc-county-error.html'),
                                controller: require('modals/sbc-smallgroup-error-modal'),
                                onClose: function () {
                                    $scope.sidebar.sgProducts = [];
                                    $scope.countyList = [];
                                    $scope.sidebar.zipCode = "";
                                    $scope.sidebar.sgCounty = "";
                                    $scope.sidebar.effectiveDate = "";
                                    $scope.sidebar.erisaIndicator = "";
                                }
                            });

                            //no sbcs were found with the information provided
                        } else {
                            // $scope.sidebar.sgSearchResults = true;
                            $scope.sidebar.sgProducts = response.data;
                        }
                    }, function (error){
                        $scope.sidebar.showLoading = false;
                        //no sbcs matched all of the input from the county search
                        //inform user
                        modal.open({
                            template: require('modals/sbc-county-error.html'),
                            controller: require('modals/sbc-smallgroup-error-modal'),
                            onClose: function () {
                                $scope.sidebar.sgProducts = [];
                                $scope.countyList = [];
                                $scope.sidebar.zipCode = "";
                                $scope.sidebar.sgCounty = "";
                                $scope.sidebar.effectiveDate = "";
                                $scope.sidebar.erisaIndicator = "";
                            }
                        });
                    });
                    $scope.sidebar.showLoading = true;
                } else {
                    //if not all information is available, wait until all the information is made available (all fields filled out)
                    //$scope.sidebar.sgSearchResults = false;
                    $scope.sidebar.sgProducts = [];
                }
            };

            $scope.sidebar.switchRegion = function() {
                $scope.sidebar.emptySet = true;
                if ($scope.sidebar.region) {
                    $state.go('sbcs.region', {
                        region: $scope.sidebar.region.referenceId.toLowerCase()
                    });
                    $scope.sidebar.sgProducts = [];
                    $scope.sidebar.regionSet        = true;
                    $scope.sidebar.displayFilter    = $scope.sidebar.yearSet && $scope.sidebar.regionSet && $scope.sidebar.lobSet;
                    $scope.sidebar.countyCollection = [];
                    if ($scope.sidebar.variant) {
                        $scope.sidebar.variant = "";
                    }
                    if ($scope.sidebar.marketplace) {
                        $scope.sidebar.marketplace = "";
                    }
                    if ($scope.sidebar.keywordSearch) {
                        $scope.sidebar.keywordSearch = "";
                    }
                    for (var i = 0; i < $scope.sbcsData.labelList.length; i++) {
                        // Find when Region from the Json Matches the Region selected on the UI
                        if ($scope.sbcsData.labelList[i].referenceId == $scope.sidebar.region.referenceId) {
                            // Grab all the counties for that region. 
                            for (var j = 0; j < $scope.sbcsData.labelList[i].countyList.length; j++) {  
                                $scope.sidebar.countyCollection.push($scope.sbcsData.labelList[i].countyList[j].label);
                            }
                        }               
                    }
                    $scope.sidebar.indvCounty = "";
                    $scope.sidebar.sgCounty = "";
                    $scope.sidebar.zipCode = "";
                    $scope.sidebar.effectiveDate = "";
                    $scope.sidebar.erisaIndicator = "";
                    $scope.countyList = [];
                } else {
                    $state.go('sbcs');
                    $scope.sidebar.regionSet        = false;
                    $scope.sidebar.displayFilter    = ($scope.sidebar.yearSet && $scope.sidebar.regionSet && $scope.sidebar.lobSet);
                }
            };


            $scope.$watch('sidebar.zipCode', function(){
                var x = $scope;
                if($scope.sidebar.zipCode && $scope.sidebar.zipCode.length != 5){
                    //if we are resetting the zip code to enter a different one,
                    //should we reset?
                    $scope.sidebar.sgCounty = "";
                    $scope.countyList = [];
                    $scope.sidebar.searchCounty();
                    return;
                }
                var validZip = /^[0-9]{5}$/;
                //check to make sure it is a properly formatted zip code
                if(validZip.test($scope.sidebar.zipCode)){
                    var payload = {
                        "zipCode": $scope.sidebar.zipCode
                    };
                    services.cmcrst.countyList().postData(payload).then( function(countyResponse){
                        //make sure that we are only showing counties that actually fall within the selected region
                        var counties = countyResponse.data.payload.countyList;
                        //make sure that there is at least one county returned (might be handled by service, check)
                        if(counties.length < 1) {
                            $scope.sidebar.noCountyAlert();
                        } else {
                            //check to make sure that there is at least one county that matched the selected region
                            //if there isn't, do something (modal)

                            var countyInRegion = false;
                            var countiesInRegion= [];
                            for (var i = 0; i < counties.length; i++) {
                                //ensure at least one of the returned counties falls within the selected region
                                if(counties[i].region === $scope.sidebar.region.nickname){
                                    countyInRegion = true;
                                    countiesInRegion.push(counties[i]);
                                }
                            }
                            //if at least one county is located within the selected region
                            if(countyInRegion){
                                $scope.countyList = countiesInRegion;
                                //set the $scope variables, if only one, set as selected county
                                if (counties.length === 1) {
                                    $scope.sidebar.sgCounty = $scope.countyList[0];
                                }
                            } else {
                                //no counties in region associated with inputted zip code
                                $scope.sidebar.noCountyAlert();
                            }
                        }
                        $scope.sidebar.searchCounty();
                    }, function(error){
                        //bad zip code
                        if ($scope.sidebar.zipCode && $scope.sidebar.zipCode.length === 5) {
                            modal.open({
                                template: require('modals/zipcode-error-modal.html'),
                                controller: require('modals/zipcode-error-modal'),
                                onClose: function () {
                                    $scope.sidebar.zipCode = "";
                                }
                            });
                        }
                        $scope.sidebar.searchCounty();
                    });
                }
            });
            //use the modal informing the user that there are no counties in the selected region
            //associated with the inputted zip code
            $scope.sidebar.noCountyAlert = function() {
                modal.open({
                    template: require('modals/sbc-zipcode-region-error.html'),
                    controller: require('modals/sbc-smallgroup-error-modal'),
                    onClose: function () {
                        $scope.sidebar.zipCode = "";
                    }
                });
            };

            $scope.sidebar.getYearJsonData = function() {
                sbcsData
                    .get($scope.sidebar.year)
                    .then(function(regions) {
                        
                        // if call is successful:  set flags and create arrays.
                        if (regions != null && regions.labelList) {
                            
                            $scope.sidebar.emptySet     = true;
                            $scope.sidebar.yearSet      = true;
                            $scope.sidebar.yearError    = false;
                            $scope.sidebar.regionSet    = false;
                            if($scope.sidebar.showLobBtns){
                                $scope.sidebar.lobSet       = false;
                            }

                            if ($scope.sidebar.region) {
                                $scope.sidebar.region = "";
                            }
                            if($scope.sidebar.showLobBtns && $scope.sidebar.lob){
                                $scope.sidebar.lob = "";
                            }
                            $scope.sbcsData                     = regions;
                            if($scope.sidebar.isWY){
                                $scope.sidebar.variantsToReference = $scope.sbcsData.WYvariantList;
                            } else {
                                $scope.sidebar.variantsToReference = $scope.sbcsData.variantList;
                            }
                            $scope.sidebar.regionsCollection    = [];
                            $scope.sidebar.countyCollection     = [];
                            $scope.sidebar.variantCollection    = [];

                            for (var i=0; i < regions.labelList.length; i++ ) {
                                if (regions.labelList[i].requiredBrand === brand.current.sharedBrandCode) {
                                    $scope.sidebar.regionsCollection.push(regions.labelList[i]);
                                }
                            }
                            for (i=0; i < $scope.sidebar.variantsToReference.length; i++ ) {
                                    $scope.sidebar.variantCollection.push($scope.sidebar.variantsToReference[i]);
                            }



                            $state.go('sbcs');
                        } else { // if call fails or no data in json set to Error Mode
                            $scope.sidebar.regionsCollection    = [];
                            $scope.sidebar.yearError            = true;
                            $scope.sidebar.yearSet              = false;
                            $scope.sidebar.regionSet            = false;
                            $state.go('sbcs');
                        }
                        // Hide or Show Filter based on if year json is return and we are in a region and know the line of business.
                         $scope.sidebar.displayFilter = ($scope.sidebar.yearSet && $scope.sidebar.regionSet && $scope.sidebar.lobSet);
                                          });

            };

            $scope.sidebar.getLanguageJsonData = function() {
                $scope.sidebar.LanguageList = [];
            
                if ($scope.sidebar.language === "English" || $scope.sidebar.language === "Spanish") {
                    var sbcDataList = $scope.sbcDataList && $scope.sbcDataList.filter(function(sbc) {
                        return sbc.Language === $scope.sidebar.language;
                    });
            
                    if (sbcDataList) {
                        $scope.sidebar.LanguageList = sbcDataList;
                    }
                }
            };



            // Depending whan Plan Availability is selected, populate Plan Variant with correct Options from JSON
            $scope.sidebar.filterVariant = function() {
                $scope.sidebar.emptySet             = true;
                $scope.sidebar.variantCollection    = [];
                //WYvariantList
                if ($scope.sidebar.variant) {
                    $scope.sidebar.variant = "";
                }
                if ($scope.sidebar.marketplace == "On Marketplace") {
                // if On exchange is selected add everything but Off Market Plan Base Plan to the List
                    for (var i=0; i < $scope.sbcsData.variantList.length; i++ ) {
                        if ($scope.sbcsData.variantList[i].reference.localeCompare("Off Marketplace Base Plan") != 0) {
                            $scope.sidebar.variantCollection.push($scope.sbcsData.variantList[i]);
                        }
                    }
                } else if ($scope.sidebar.marketplace == "Off Marketplace") {
                // if Off exchange is selected only add Off Market Plan Base Plan to the List
                    for (var i=0; i < $scope.sbcsData.variantList.length; i++ ) {
                        if($scope.sidebar.variantsToReference[i].reference.localeCompare("Off Marketplace Base Plan") == 0) {
                            $scope.sidebar.variantCollection.push($scope.sidebar.variantsToReference[i]);
                        }
                    }
                } else {
                //If Nothing is Selected Show All
                     for (var i=0; i < $scope.sidebar.variantsToReference.length; i++ ) {
                        $scope.sidebar.variantCollection.push($scope.sidebar.variantsToReference[i]);
                    }
                }
            };
            if ($state.params.region) {
            //set the initial region on url location request
                $scope.sidebar.region = $state.params.region;
            }
            $scope.sidebar.resetEmptySet =  function(){
                $scope.sidebar.emptySet = true;
            };

            var updateSidebarRegion = function() {
                var tmp;
                for(var i = $scope.sidebar.regionsCollection && $scope.sidebar.regionsCollection.length -1; i >= 0; i--) {
                    var value = $scope.sidebar.regionsCollection[i];
                    if($state.params.region === value.referenceId) {
                        tmp = value;
                        break;
                    }
                }
                $scope.sidebar.region = tmp;
            };

            $scope.$on("$stateChangeSuccess", function(){
                updateSidebarRegion();
            });

            $scope.sidebar.links = [{
                "label": "Home",
                "state": "sbcs",
                "refineTemplate": "pages/sbcs/sbcs.refine.html"
            }];
            updateSidebarRegion();
            $scope.sidebar.isOpenSearch = true;
        }
    ]);
    return [
        {
            state: "sbcs",
            url: "/sbcs",
            views: {
                'body@': {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.sbcs"
                },
                "content@sbcs": {
                    template: require("pages/sbcs/sbcs.html")
                }
            }
        },
        {
            state: "sbcs.region",
            url: "/:region",
            views: {
                "content@sbcs": {
                    template: require("pages/sbcs/sbcsTemplate.html"),
                    controller: "pages.sbcDisplay"
                }
            }
        }
    ];
};