module.exports = function (app) {
    require("./selectUserType")(app);

    app.controller('pages.registration', ["$state", "$scope", "sidebarPageContent",
        function ($state, $scope, sidebarPageContent) {
            $scope.headings = "Register";
            $scope.megaBackButton = {hide: true};
            $scope.sidebar.links = [];

            angular.forEach(sidebarPageContent.viewPlansFindInsurance.leftNavLinks, function(link){
                $scope.sidebar.links.push({
                    "heading"   : link["text"],
                    "label"     : link["text"],
                    "href"      : link["URL"],
                    "newWindow" : link["newWindow"]
                })
            });
        }]);
    return [
        {
            state: "registration",
            postPassword: false,
            url: "/registration",
            views: {
                'body@': {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.registration"
                },
                "content@registration": {
                    template: require("./selectUserType.html"),
                    controller: "pages.registration.selectUserType"
                }
            },
            resolve: {
                "sidebarPageContent": function(teamsiteManager, brand) {
                    return teamsiteManager.getMultiContent(["viewPlansFindInsurance"], brand);
                }
            }
        },
        {
            state: "registration.register",
            postPassword: false,
            url: "/register"
        }
    ];

};