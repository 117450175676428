module.exports = function(app){ 'use strict';
    app.directive('compareGrid',['$document','$state', function($document, $state) {
        return {
            template: require('./compareGrid.html'),
            scope: {
                compareGrid: '=',
                ratingInfo: '=',
                content: '='
            },
            link: function (scope, elem) {
                // scope.compareGrid.networkType = "in";
                scope.tooltip = "Tom's a ninny pants";
                scope.highlightDifferences = false;
                scope.toggleHighlight = function() {
                    scope.highlightDifferences = !scope.highlightDifferences;
                };
                window.compareScope = scope;
                scope.enrollInPlan = function() {
                    /*TODO
                    * SETUP PLAN ENROLLMENT LOGIC
                    */
                    $state.go('enrollment.instructions');
                };
                scope.removeColumn = function(index) {
                    angular.forEach(scope.compareGrid.rowList, function(value,key) {
                        value.data.splice(index,1);
                    });
                    scope.compareGrid.headerList.splice(index,1);
                };
                scope.$watch('compareGrid', function () {
                    if(scope && scope.compareGrid && scope.compareGrid.rowList) {
                        angular.forEach(scope.compareGrid.rowList, function(value, key) {
                            var isDifferent = false;
                            var refData = value.data[0];
                            for(var i = 1; i < value.data.length; i++) {
                                var tmpData = value.data[i];
                                if(tmpData[scope.compareGrid.networkType] != refData[scope.compareGrid.networkType]) {
                                    isDifferent = true;
                                    i = value.data.length;
                                }
                            }
                            value.isDifferent = isDifferent;
                        });
                    }
                },true);
            }
        };
    }]);
};