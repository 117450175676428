module.exports = function(app) {
  'use strict';

  app.controller('pages.findInsurance.build-your-family', [
    '$state',
    '$scope',
    '$filter',
    '$timeout',
    '$cookies',
    'teamsiteManager',
    'modal',
    'brand',
    'services',
    'sepConfigUtility',
    'storage',
    'pageContent',
    'sessionData',
    'loginManager',
    'hook',
    '$q',
    'hmShopDateAndTimeUtility',
    'blankEmailApplicationUtility',
    '$rootScope',
    'ratingInfoModel',
    'sharedBYFService',
    'SALES_CONSTANTS',
    'producerFlowBYFService',
    'consumerFlowBYFService',
    function(
      $state,
      $scope,
      $filter,
      $timeout,
      $cookies,
      teamsiteManager,
      modal,
      brand,
      services,
      sepConfigUtility,
      storage,
      pageContent,
      sessionData,
      loginManager,
      hook,
      $q,
      hmShopDateAndTimeUtility,
      blankEmailApplicationUtility,
      $rootScope,
      ratingInfoModel,
      sharedBYFService,
      SALES_CONSTANTS,
      producerFlowBYFService,
      consumerFlowBYFService
    ) {
      $scope.content = pageContent['buildYourFamily'];
      $scope.showLoadingIndicator = false;
      $scope.disableNextButton = false;
      $scope.toggleStorage = {};
      $scope.display = {};
      $scope.display.shopDirectly = false;
      $scope.currentMember = false;
      $scope.countyInfo = null;
      $scope.zipCode = null;
      $scope.childOnlyPolicy = null;
      $scope.medicareEligible = {};
      $scope.loggedInUserRole;
      $scope.editFamilyMember = false;
      // $scope.toggleLimitedCounty = false;
      $scope.zipCodeResponseReturned = false;
      window.document.title = 'Shop';

      var member = {
        fullName: {
          first: ''
        },
        gender: '',
        dob: '',
        relationship: '',
        tobaccoInd: ''
      };

      var isPrePopulating = false;
      var familyDetails;

      $scope.onZipCodeRetrieved = function() {
        /** Only show interstitial if not SEP or PRODUCER flows */
        if (sepConfigUtility.isSepFlow) {
            $scope.showInterstitial = false;
            $scope.display.shopDirectly = true;
        } else {
          if (loginManager.isProducer()) {
              $scope.showInterstitial = false;
              $scope.display.shopDirectly = true;
            } else {
              $scope.showInterstitial = true;
            }
        }
      };

      $scope.onContinueWithPortal = function() {
        $scope.display.shopDirectly = true;
      }

      $scope.exitSep = function() {
        //TODO: VERIFY that all necessary actions are being taken to exit SEP flow
        //to exit SEP, clear out the page info
        sepConfigUtility.exitSepFlow();
        _resetFormData();
        _initSpecialEnrollmentPeriodRouting();
      };

      // Show only the subscriber box if childOnly, otherwise display the add dependent button
      $scope.toggleChildOnly = function(childOnlyPolicy) {
        // Set or Toggle
        if (!$scope.childOnlyPolicy) {
          $scope.childOnlyPolicy = childOnlyPolicy;
        } else {
          $scope.childOnlyPolicy = !$scope.childOnlyPolicy;
        }

        // Add at least 1 member
        if ($scope.family.members.length === 0) {
          $scope.addMember();
        }

        // If Child only, only have 1 member listed
        if (childOnlyPolicy === true) {
          $scope.family.members.splice(1);
        }

        // Force first card as older than child
        if (
          $scope.ctrl &&
          $scope.ctrl.memberForm_0 &&
          $scope.ctrl.memberForm_0.dobInput_0
        ) {
          $scope.ctrl.memberForm_0.dobInput_0.$setValidity(
            'overChildAge',
            true
          );
        }
      };

      //If customer lookup involve allow user to manage their family details
      $scope.editFamilyMemberDetails = function(editFamilyMember) {
        $scope.disableAddDependent = !editFamilyMember;
        if (!editFamilyMember) {
          console.log(
            'Customer lookup details :\t' + JSON.stringify(familyDetails)
          );
          //remove all members except for the dependent
          $scope.family.members.splice(1);
          //restore values of the subscriber
          collectSelectedRelationshipsAndBroadcast();
          //wait for angular to update, then
          $timeout(function() {
            var familyDetailsCopy = angular.copy(familyDetails);
            familyDetailsCopy.splice(0, 1);
            //add back the members that weren't removed and update relationships
            angular.forEach(familyDetailsCopy, function(value, key) {
              $scope.family.members.push(value);
            });
            collectSelectedRelationshipsAndBroadcast();
          });
        }
      };

      // retrieves date object representing the first day of the month following date input parameter
      function getFirstOfNextMonth(curDate) {
        var firstOfNext = new Date(
          curDate.getFullYear(),
          curDate.getMonth(),
          1
        );
        firstOfNext.setMonth(firstOfNext.getMonth() + 1);
        return firstOfNext;
      }
      function generateUtcDateString(date) {
        var utcDateArray = [];
        utcDateArray[0] = date.getUTCMonth() + 1;
        utcDateArray[1] = date.getUTCDate();
        utcDateArray[2] = date.getUTCFullYear();
        return utcDateArray[0] + '/' + utcDateArray[1] + '/' + utcDateArray[2];
      }
      // Retrieves and formats the effective dates
      function getConsumerEffectiveDates() {
        if ($scope.countyInfo !== null) {
          var adoptionDate;
          if ($scope.isSep) {
            var adoptionData = sepConfigUtility.getAdoptionDate();
            if (adoptionData.length > 0) {
              adoptionDate = new Date(adoptionData[0].displayEffDate);
            }
          }

          var iso8601CompliantDate = hmShopDateAndTimeUtility.getISO8601CompliantDatetimeString();

          var payload = {
            effectiveDateValue: iso8601CompliantDate,
            isOffSep: $scope.isSep,
            offExchangeIndicator: 'true',
            regionCd: $scope.countyInfo.region
          };

          services.cmcrst
            .consumerEffectiveDate()
            .postConsumerEffectiveDate(payload)
            .then(
              function(response) {
                $scope.effectiveDates = [];
                var effectiveDates =
                  response.data.payload.ConsumerEffectiveDateSingle;
                if (!adoptionDate) {
                  for (var i = 0; i < effectiveDates.length; i++) {
                    $scope.effectiveDates.push(
                      generateUtcDateString(
                        new Date(effectiveDates[i].displayEffDate)
                      )
                    ); // Meant for human readable output
                  }
                } else {
                  var nextMonth = getFirstOfNextMonth(adoptionDate);
                  //dont need to check next month against adoption date
                  //b/c they will never be the same
                  $scope.effectiveDates.push(
                    generateUtcDateString(adoptionDate)
                  );
                  $scope.effectiveDates.push(generateUtcDateString(nextMonth));

                  //check to see if the date returned from the effective date service
                  //is already included in the array
                  //if it is not already present, include it
                  var effDateString = generateUtcDateString(
                    new Date(effectiveDates.pop().displayEffDate)
                  );
                  if ($scope.effectiveDates.indexOf(effDateString) < 0) {
                    $scope.effectiveDates.push(effDateString);
                  }
                }

                if (!$scope.family.consumerEffectiveDate) {
                  $scope.family.consumerEffectiveDate =
                    $scope.effectiveDates[0];
                } else {
                  $scope.family.consumerEffectiveDate =
                    $scope.effectiveDates[
                      $scope.effectiveDates.indexOf(
                        $scope.family.consumerEffectiveDate
                      )
                    ];
                }

                $scope.showLoadingIndicator = false;
              },
              function(error) {
                console.log(error);

                $scope.showLoadingIndicator = false;
              }
            );
        }
      }

      // Reset the radio button for currentMember if they select the "Continue as Guest" button
      $scope.continueAsGuest = function() {
        $scope.currentMember = false;
        // $scope.family.currentMember = false;
      };

      // Creates new members and puts them into the members array
      $scope.addMember = function() {
        $scope.family.members.push(angular.copy(member));
        $scope.editFamilyMember = true;
        collectSelectedRelationshipsAndBroadcast();
      };

      // Modal to ask if they would like to go to the subsidity flow, only if they are Highmark
      $scope.popupSubsidyModal = function() {
        var valid = true;

        for (var i = 0; i < $scope.family.members.length; i++) {
          var member = $scope.family.members[i];
          var memberValid = member.isValid();
          valid = valid && memberValid;
        }

        if (valid) {
          var applicants = [];
          setDataToSessionStorage();

          // Matching the old payload structure
          angular.forEach($scope.family.members, function(member) {
            applicants.push({
              name: member.fullName.first,
              dob:
                member.dob.split('/')[2] +
                '-' +
                member.dob.split('/')[0] +
                '-' +
                member.dob.split('/')[1],
              tobaccoStatus: member.tobaccoInd,
              relationship: member.relationship
            });
          });

          storage.set(
            'selectedEffectiveDateISO8601',
            hmShopDateAndTimeUtility.getISO8601CompliantDatetimeString(
              new Date(
                angular.copy(
                  $scope.family.consumerEffectiveDate.replace(/[^ -~]|/g, '')
                )
              )
            )
          );

          var dateArray = angular.copy(
            $scope.family.consumerEffectiveDate.split('/')
          );

          var year = dateArray[2].toString();
          year = year.replace(/[^ -~]|/g, ''); // old IE fix, use of toLocaleDateString() in IE isn't as forgiving as in Chrome

          var month = dateArray[0].toString();
          month = month.replace(/[^ -~]/g, ''); // old IE fix, use of toLocaleDateString() in IE isn't as forgiving as in Chrome
          month = month.length === 1 ? '0' + month : month;

          var dayOfMonth = dateArray[1];
          dayOfMonth = dayOfMonth.replace(/[^ -~]/g, ''); // old IE fix, use of toLocaleDateString() in IE isn't as forgiving as in Chrome
          dayOfMonth = dayOfMonth.length === 1 ? '0' + dayOfMonth : dayOfMonth;

          var effectiveDate = year + '-' + month + '-' + dayOfMonth;

          /****
           * Populate serviceZones with following logic:
           * 1) PA = service zones by plan year, where plan year is effective date year (NOT JUST CURRENT YEAR
           * 2) All others = serviceZones
           ****/

          var serviceZoneValue = (function configureServiceZones() {
            var serviceZones = {};

            var effectiveDateYear = $scope.family.consumerEffectiveDate.substring(
              $scope.family.consumerEffectiveDate.length - 4
            );
            $scope.effectiveDateYear = effectiveDateYear;

            if (
              $scope.countyInfo.state === 'PA' &&
              $scope.countyInfo.serviceZonesByPlanYear &&
              $scope.countyInfo.serviceZonesByPlanYear[effectiveDateYear]
            ) {
              serviceZones =
                $scope.countyInfo.serviceZonesByPlanYear[effectiveDateYear];
            } else {
              serviceZones = $scope.countyInfo.serviceZones;
            }

            return serviceZones;
          })();

          var payload = {
            zipCode: $scope.zipCode,
            county: $scope.countyInfo.county,
            region: $scope.countyInfo.region,
            effectiveDate: effectiveDate,
            state: $scope.countyInfo.state,
            serviceZones: serviceZoneValue,
            ratingArea: '' + $scope.countyInfo.ratingArea,
            applicants: applicants,
            ffmRatingArea: $scope.countyInfo.ffmRatingArea
          };

          if ($scope.childOnlyPolicy) {
            // First applicant in child-only flow must have relationship role as "Subscriber"
            payload.applicants[0].relationship = 'Subscriber';
          }

          // move this into familyMemberInput.js
          angular.forEach(payload.applicants, function(applicant, index) {
            if (applicant.relationship.toUpperCase() === 'DOMESTIC PARTNER') {
              applicant.relationship = 'Spouse';
            }
          });
          console.log('applicants', applicants);

          // Use the singleton to push the rating info service payload
          ratingInfoModel.set(payload);

          /****
           * Check if any applicant is over 65, if so, display modal offering opportunity to navigate to
           * Medicare site
           ****/
          var over65ModalDeferred = $q.defer();

          var displayOver65Modal = function() {
            var anyMembersOver65 = false;

            angular.forEach($scope.family.members, function(member) {
              if (
                hmShopDateAndTimeUtility.isDateFromTodayYoungerOrOlder(
                  member.dob,
                  65,
                  'older'
                )
              ) {
                anyMembersOver65 = true;
              }
            });

            if (anyMembersOver65) {
              modal.open({
                template: require('modals/over-sixty-five-byf.html'),
                controller: require('modals/over-sixty-five-byf'),
                scope: $scope,
                onClose: function() {
                  over65ModalDeferred.resolve(true);
                }
              });
            } else {
              over65ModalDeferred.resolve(false);
            }

            return over65ModalDeferred.promise;
          };

          displayOver65Modal().then(function(over65) {
            /**
             * Moving ratingInfo xhr callback functionality into its own function
             * will be called independent o fthe ratingInfo service for new product 
             * service
             */
            var subsidyAndMedicareCallbacks = {
              successCallback: function (response) {

                storage.set('isChildOnly', angular.copy($scope.childOnlyPolicy) );

                // Highmark only uses the modal for subsidy at this time
                if (_isHighmark() && !_isSubsidySupressed()) {

                  if ($scope.childOnlyPolicy) {

                    modal.open({
                      template: require('modals/child-only-subsidy-modal.html'),
                      controller: require('modals/child-only-subsidy-modal'),
                      onClose: function() {
                        $scope.disableNextButton = false;
                      },
                      scope: $scope
                    });

                  } else {

                    modal.open({
                      template: require('modals/subsidy-modal.html'),
                      controller: require('modals/subsidy-modal'),
                      onClose: function() {
                        $scope.disableNextButton = false;
                      },
                      scope: $scope
                    });
                    
                  }

                } else {

                  $state.go('findInsurance.view-plans', {
                    noCacheProductService: true
                  });

                }
              },
              failureCallback: function(error) {
                console.log(error);
                $scope.disableNextButton = false;
              }
            };

            if (!over65) {
              $scope.disableNextButton = true;

              if (
                storage.get(SALES_CONSTANTS.commerce.useProductService)
              ) {
                services.wcs
                  .ratingInfoService({ storeId: brand.current.storeId })
                  .putRatingInfoService(payload)
                  .then(subsidyAndMedicareCallbacks.successCallback, subsidyAndMedicareCallbacks.failureCallback);
              } else {
                /** Not a real callback */
                subsidyAndMedicareCallbacks.successCallback();
              }


            } else {
              $scope.medicareEligible = {
                members: true
              };
            }
          });
        } else {
          console.log('Some items are invalid');
        }
      };

      function collectSelectedRelationshipsAndBroadcast() {
        var selectedRelationships = [];
        angular.forEach($scope.family.members, function(member) {
          selectedRelationships.push(member.relationship);
        });
        $timeout(function() {
          $scope.$broadcast('updateRelationshipOptions', selectedRelationships);
        });
      }

      // This will set the data into the session
      function setDataToSessionStorage() {
        var data = {
          zipCode: $scope.zipCode,
          countyInfo: $scope.countyInfo,
          childOnlyPolicy: $scope.childOnlyPolicy,
          currentMember: $scope.currentMember,
          medicareEligible: $scope.medicareEligible,
          family: $scope.family
        };
        storage.set('familyRatingInfo', data);
        return storage.get('familyRatingInfo');
      }

      // This will get the data from session to scope
      function getDataToScope(data) {
        $scope.countyInfo = data.countyInfo;
        $scope.zipCode = data.zipCode;
        $scope.childOnlyPolicy = data.childOnlyPolicy;
        $scope.currentMember = data.currentMember;
        $scope.medicareEligible = data.medicareEligible;
        $scope.family = data.family;

        collectSelectedRelationshipsAndBroadcast();
        getConsumerEffectiveDates();
      }

      /****
       * Utility Functions
       ****/

      function _isHighmark() {
        switch (brand.current.sharedBrandCode.toUpperCase()) {
          case 'DE': /* FALL THROUGH */
          case 'DEFAULT': /* FALL THROUGH */
          case 'HBCBS': /* FALL THROUGH */
          case 'HBS': /* FALL THROUGH */
          case 'HIGHMARKBCBS': /* FALL THROUGH */
          case 'HIGHMARKBCBSDE': /* FALL THROUGH */
          case 'HIGHMARKBCBSWV': /* FALL THROUGH */
          case 'HIGHMARKBLUESHIELD': /* FALL THROUGH */
          case 'HMBCBS': /* FALL THROUGH */
          case 'HMBCBSDE': /* FALL THROUGH */
          case 'HMBCBSWV': /* FALL THROUGH */
          case 'MS': /* FALL THROUGH */
          case 'NEPA': /* FALL THROUGH */
          case 'PBS': /* FALL THROUGH */
          case 'UNBRANDEDHM': /* FALL THROUGH */
          case 'WV':
            return true;
            break;
          default:
            return false;
            break;
        }
      }

      function _isSubsidySupressed() {
        //use the effective date selected by the user to check if the subsidy calculator should be hidden or not
        var countiesToSupress =
          $scope.content.subsidyCalculatorSection.supressedCounties[
            $scope.effectiveDateYear
          ];
        //ensure that both county in scope and list of counties to supress are both uppercased to ensure comparisons are being made correctly
        angular.forEach(countiesToSupress, function(value, key) {
          countiesToSupress[key] = value.toUpperCase();
        });
        //return true if scoped county is on supressed list, false if not
        return (
          (sessionData &&
            sessionData.appConfig &&
            sessionData.appConfig.SALES &&
            sessionData.appConfig.SALES.HEALTH_SHERPA_ACTIVE &&
            sessionData.appConfig.SALES.HEALTH_SHERPA_ACTIVE.booleanValue) ||
          (countiesToSupress.indexOf($scope.countyInfo.county) > -1 ||
            sepConfigUtility.isSepFlow)
        );
      }

      $scope.onEditZipClicked = function() {
        _onInit();
      }

      var _initSpecialEnrollmentPeriodRouting = function() {
        var initSpecialEnrollmentPeriodRoutingDeferred = $q.defer();
        
        // retrieve SEP status here, and appropriately update scope value for consumption upon return from the Special Enrollment Flow
        sepConfigUtility.getSepServiceData().then(

          function() {

            /** Being regionally branded - we should now refresh the content */
            console.log('brand', brand);
            pageContent = teamsiteManager.getMultiContent(["buildYourFamily"], brand);
            
            //continue through as normal
            if (sepConfigUtility.isOffSep && !sepConfigUtility.isSepFlow) {

              /****
               * If it is currently SEP  (offSEP is true [ the boolean logic behind this may be wrong from backend, unlikely to be changed....]) && we are not coming from the SEP flow
               * boot them back to the SEP page
               ****/
              $state.go('findInsurance.special-enrollment');

            } else {

              $scope.isSep =
                sepConfigUtility.isOffSep || sepConfigUtility.isSepFlow;

              if (!$scope.isSep) {

                // Teamsite config item: sepContentHREF_sendToDOJO_codebase
                if ($scope.content.sepContentHREF_sendToDOJO_codebase) {

                  $scope.sepContent = $scope.content.sepContent.replace(
                    /href=.*?#!.*?>/,
                    'href="' +
                      window.location.origin +
                      '/#!/find-insurance/special-enrollment">'
                  );

                } else {

                  /****
                   * href=lksjdfklsjdlfkjsdlfj>
                   * href=2.5 site
                   ****/
                  $scope.sepContent = $scope.content.sepContent;

                }

              } else {

                $scope.sepWarning = $scope.content.sepWarningAngular;
                //mess around with the html of the warning boi here
                //so that we can have it do what we want to do

              }
            }

            initSpecialEnrollmentPeriodRoutingDeferred.resolve();
          },
          function sepServiceError(error) {
            console.log('error', error);
            $scope.showLoadingIndicator = false;
          }
        );

        return initSpecialEnrollmentPeriodRoutingDeferred.promise;
      };
      
      var _resetFormData = function() {
        console.log('resetdata');
        $scope.childOnlyPolicy = null;
        $scope.countyInfo = null;
        $scope.currentMember = false;
        $scope.display = {
          shopDirectly: false
        };
        $scope.family = {
          consumerEffectiveDate: null,
          members: []
        };
        $scope.family.members = [];
        $scope.medicareEligible = {};
        $scope.toggleStorage = {};
        $scope.zipCode = null;
      };

      function _onInit() {
        // This is only reutilized when we land directly on view plans
        localStorage.removeItem('ffmObject');
        localStorage.removeItem('taxCreditInfo');
        localStorage.removeItem('ratingInfoFrom2Dot5');
        storage.del('ffmObject');
        storage.del('taxCreditInfo');
        storage.del('ratingInfoFrom2Dot5');

        $rootScope.isOnX = false;

        $scope.isProducer = loginManager.isProducer();
        $scope.isConsumer = loginManager.isConsumer();
        $scope.loggedInUserRole = loginManager.getUserRole();

        if ($scope.content.displayExistingMemberQuestion) {
          $scope.displayMemberQuestion = true;
        } else {
          $scope.displayMemberQuestion = false;
          $scope.currentMember = false;
        }

        /****
         * Determine if application is from a producer-generated email
         ****/
        blankEmailApplicationUtility.getBlankEmailData(
          blankEmailApplicationUtility.getToken()
        );

        $scope.showLoadingIndicator = true;
        
        _initSpecialEnrollmentPeriodRouting().then(function() {
          console.log('BYF- non-SPECIAL ENROLLMENT');
          /****
           * LOGGED IN or NOT LOGGED IN setup
           * Attempt to collect producer information,
           * Attempt to collect consumer information,
           * Set Default values if no information to collect
           ****/
          if ($scope.isProducer && sessionData.loginData) {
            
            isPrePopulating = true;
            producerFlowBYFService.initialize()
            .then(
              function(success) {
                $scope.family = sharedBYFService.getFamilyValues();
                $scope.isCustomerLookup = producerFlowBYFService.isCustomerLookup;
                $scope.disableAddDependent = !producerFlowBYFService.canAddDependent;
              }, 
              function(failure) {
                console.error('Something went wrong during Producer initialization');
              }
            )
            .finally(function() {
              $scope.showLoadingIndicator = false;
              isPrePopulating = false;
              getDataToScope(storage.get('familyRatingInfo'));
              console.log('BYF-END PRODUCER FLOW');
            });
          } else if ($scope.isConsumer) {
            console.log('BYF-BEGIN CONSUMER FLOW');
            // Consumer - logged in
            // _initLoggedInConsumerFlow();
            consumerFlowBYFService.initialize()
            .then(
              function(success) {
                $scope.family = sharedBYFService.getFamilyValues();
              },
              function(failure) {
                console.error('Something went wrong during Consumer initialization');
              }
            )
            .finally(function(){ 
              $scope.showLoadingIndicator = false;
              isPrePopulating = false;
              getDataToScope(storage.get('familyRatingInfo'));
              console.log('BYF-END CONSUMER FLOW');
            })
          } else {
            // Run this, here, so that init is not run twice when coming from producer (once on page launch, once or return from login)
            _bindHookRegister_login();

            console.log('BYF-BEGIN GUEST FLOW');
            // Not - logged in
            if (storage.get('familyRatingInfo')) {
              console.log('BEGIN sessionStorage retrieval FLOW');
              // Session Data saved
              getDataToScope(storage.get('familyRatingInfo'));
            } else {
              console.log('BEGIN no sessionStorage info FLOW, use defaults');
              // Session Data not saved
              $scope.family = {
                consumerEffectiveDate: null,
                members: []
              };
            }
            $scope.showLoadingIndicator = false;
            console.log('BYF-END GUEST FLOW');
          }

          console.log('end init');
        });
      }

      /****
       * Start Application
       ****/
      _onInit();

      /*************************************
       *************************************
       * Hook, $on, $watch
       *************************************
       *************************************/

      /* On Log-in */
      var _bindHookRegister_login = function() {
        hook.register('ltpaLoginStarted', function() {
          console.log('ltpaLoginStarted handler');
          _onInit();
        });
      };

      /* On Log-out */
      hook.register('btsLogoutEvent', function() {
        _resetFormData();
        _onInit();
      });

      /* Use on the "x" to remove a member from the family */

      /***
       * TODO: see if remove can't be set up better than this
       *
       * Explanation for hokey setup
       *
       * original function was
       *
       * $scope.family.members.splice(index,1);
       * collectSelectedRelationshipsAndBroadcast();
       *
       * This caused an error when the next button was clicked after an applicant in the middle of the list was removed,
       * but I found resetting was possible removing every applicant from the subscriber cleared the issue and adding new members
       * did not cause any problems
       *
       * so then I configured the removeMember function to reset to one applicant, and then simply just add back
       * the applicants that the user didn't remove
       *
       */
      $scope.$on('removeMember', function(event, index, isMiddle) {
        //if we are removing from the end of the list
        //we can just splice safely
        if (!isMiddle) {
          $scope.family.members.splice(index, 1);
          collectSelectedRelationshipsAndBroadcast();
        } else {
          //make a copy of family members array
          var remainingMembers = angular.copy($scope.family.members);
          //clear out the subscriber and index to be removed so we know what to add back to the array
          remainingMembers.splice(index, 1);
          remainingMembers.splice(0, 1);
          //remove everything but the subscriber (unremovable) from family members
          $scope.family.members.splice(1);
          collectSelectedRelationshipsAndBroadcast();
          //wait for angular to update, then
          $timeout(function() {
            //add back the members that weren't removed and update relationships
            angular.forEach(remainingMembers, function(value, key) {
              $scope.family.members.push(value);
            });
            collectSelectedRelationshipsAndBroadcast();
          });
        }
      });
      //function removeAllFamilyCards(){
      //    angular.forEach($scope.family.members, function (value, key) {
      //        $scope.family.members.splice(index, 1);
      //        collectSelectedRelationshipsAndBroadcast();
      //    });
      //}
      /* Watch the county to pull back the effective dates related to that region */
      $scope.$watch(
        'countyInfo',
        function(newVal, oldVal) {
          if (newVal !== undefined && newVal !== oldVal) {
            getConsumerEffectiveDates();
            if (!isPrePopulating) {
              storage.del['familyRatingInfo'];
            } else {
              isPrePopulating = false;
            }
          }
        },
        true
      );

      $scope.$on('brandUpdated', function(event, county) {
        teamsiteManager
          .getContent(['buildYourFamily'], brand)
          .then(function(content) {
            $scope.content = content;
          });
      });

      /* Collects what has been selected for relationships to pass to the directives */
      $scope.$on('relationshipUpdated', function() {
        collectSelectedRelationshipsAndBroadcast();
      });

      // Collects what has been selected for relationships to pass to the directives
      $scope.$on('relationshipUpdated', function() {
        collectSelectedRelationshipsAndBroadcast();
      });

    }
  ]);
};
