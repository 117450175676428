module.exports = function (app) {
    'use strict';
    // TODO fix suffix values
    app.directive('address', ["errorMessages", function (errorMessages) {
        return {
            restrict: 'A',
            replace: true,
            template: require('directives/enrollment/address.html'),
            scope: {
                homeAddressLabel: "@",
                homeAddressTooltip: "@?",
                addressModel: "=",
                required: "=?"
            },
            link: function ($scope, elem, $attrs) {
                $scope.errorMessages = errorMessages;
            }
        };
    }]);
};