/**
 * Created by lid0vs0 on 9/24/2018.
 */
module.exports = function(app) {
    app.factory('sepConfigUtility', [
        'services',
        'sessionData',
        '$q',
        'modal',
        'ddSetup',
        'loginManager',
        function(
            services,
            sessionData,
            $q,
            modal,
            ddSetup,
            loginManager
        ) {
            var service = {
                firstTime:          true,
                isOnSep :           false,
                isOffSep:           false,
                isWindowShop:       false,
                isSepFlow :         false,
                phi:                false,
                newZipCode:         false,
                adoptionDate:       [],
                getSepServiceData:  getSepServiceData,
                isWindowShopPeriod: isWindowShopPeriod,
                setSepFlowFlag:     setSepFlowFlag,
                setAdoptionDate:    setAdoptionDate,
                clearAdoptionDate:  clearAdoptionDate,
                exitSepFlow:        exitSepFlow,
                getAdoptionDate:    getAdoptionDate
            };

            function exitSepFlow(){
                service.clearAdoptionDate();
                service.isSepFlow = false;
                service.firstTime = true;
            }

            function getAdoptionDate(){
                return service.adoptionDate;
            }

            function setAdoptionDate(date){
                clearAdoptionDate();
                service.adoptionDate.push(date);
            }

            function clearAdoptionDate(){
                service.adoptionDate.splice(0,service.adoptionDate.length);
            }

            function setSepFlowFlag(value){
                service.isSepFlow = value;
            }

            function getSepServiceData() {
                
                var sepDataDeferred = $q.defer();
                
                if (service.sepRequestRetrieved && !service.newZipCode) {
                    sepDataDeferred.resolve();
                } else {
                    _resetServiceVariables();

                    if (!loginManager.getUserRole()) {
                        modal.open({
                            "template": require('modals/zip-gate.html'),
                            "controller": require('modals/zip-gate'),
                            onClose: function() {
                                _retrieveValues(sepDataDeferred);
                            }
                        });
                    } else {
                        /** Use the Region from the login data response to determine SEP value */
                        var brand = _getLoggedInUserBrand();
                        ddSetup.setDDTokenValueByRegion(brand);
                        _retrieveValues(sepDataDeferred);

                    }

                }

                return sepDataDeferred.promise;
            }

            function _resetServiceVariables() {
                service.firstTime = true;
                service.isOnSep = false;
                service.isOffSep = false;
                service.isWindowShop = false;
                service.isSepFlow = false;
                service.phi = false;
                service.newZipCode = false;
                service.adoptionDate = [];
            }

            /** Brand is stored in different locations based on ROLE */
            function _getLoggedInUserBrand() {
                if (loginManager.isProducer()) {
                    return (
                        sessionData &&
                        sessionData.loginData &&
                        sessionData.loginData.selectedRegion &&
                        sessionData.loginData.selectedRegion.brand
                    );
                } else if (loginManager.isConsumer()) {
                    return (
                        sessionData &&
                        sessionData.loginData &&
                        sessionData.loginData.shoppingBrand
                    );
                }
            }

            function _retrieveValues(deferred) {
                services.cmcrst.isSepService().get()
                .then(
                    function (success){
                        service.sepRequestRetrieved = true;

                        service.isOffSep = success.data.payload.offSEP;
                        service.isOnSep = success.data.payload.onSEP;

                        if(!service.firstTime){
                            service.isWindowShop = success.data.payload.windowShopping ? true : false;
                        } else {
                            service.firstTime = false;
                        }

                        service.phi = true;

                        deferred.resolve();

                    },
                    function (failure){
                        deferred.reject(failure);
                    }
                )
                .finally(function() {
                    /** ASP-Based DDToken */
                    ddSetup.set();
                    service.newZipCode = false;
                });
            }

            function isWindowShopPeriod(){
                var windowShopAppConfig = (sessionData && sessionData.appConfig && sessionData.appConfig.SALES && sessionData.appConfig.SALES.IS_WINDOWSHOP && sessionData.appConfig.SALES.IS_WINDOWSHOP && sessionData.appConfig.SALES.IS_WINDOWSHOP.booleanValue);
                return windowShopAppConfig || service.isWindowShop;
            }

            return service;
            
        }]);
};