module.exports = function(app) {
    app.controller('findInsurance.special-enrollment-events', ["$state", "$scope", "teamsiteManager", "content", "sepConfigUtility",
        function ($state, $scope, teamsiteManager, content, sepConfigUtility) {

            $scope.content = content;
            sepConfigUtility.getSepServiceData();


            $scope.backToSpecialEnrollment = function () {
                $state.go('findInsurance.special-enrollment');
            };
        }
    ]);
};