module.exports = function(app) {

    app.controller('findInsurance.household-income', ['$scope', '$state', 'services', "teamsiteManager", "brand", "pageContent", "storage", "ratingInfoModel",
        function ($scope, $state, services, teamsiteManager, brand, pageContent, storage, ratingInfoModel) {

            $scope.pageContent                  = null;
            $scope.disableSubsidySubmit         = false;
            $scope.householdSize                = "0";
            $scope.householdIncome              = null;
            
            $scope.customErrorsHouseholdSize    = {
                "customMin": "Household should be at least 1 member.",
                "customMax": "Household can be no more than 12 members."
            };
            $scope.customErrorsHouseholdIncome  = {
                "customMin": "Income must be greater than $0.",
                "customMax": "Income must be less than or equal to $250,000."
            };

            $scope.runSubsidy = function (){
                if ($scope.householdIncomeForm.$valid) {
                    
                    $scope.disableSubsidySubmit         = true;

                    // var subsidyPayload              = angular.copy(storage.get("ratingInfoPayload"));
                    var subsidyPayload              = angular.copy(ratingInfoModel.get());
                    subsidyPayload.houseHoldSize    = $scope.householdSize;
                    subsidyPayload.houseHoldIncome  = Number($scope.householdIncome);

                    storage.set("subsidyPayload", subsidyPayload);

                    services.cmcrst.subsidyService().postSubsidyService(subsidyPayload).then(
                        function(response){
                            if (response.data){
                                storage.set("subsidyResponse", response.data);
                            } else {
                                storage.set("subsidyResponse", {"subsidyAmount" : "0"} );
                            }

                            $state.go('findInsurance.household-results');
                        },
                        function(error){
                            $scope.disableSubsidySubmit = false;
                        }
                    );
                }
            };

            (function onInit(){
                $scope.pageContent = pageContent;
            })();
        }
    ]);
};