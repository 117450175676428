/**
 * Created by lid0vs0 on 8/31/2018.
 */
module.exports = function(bootstrap) {
    bootstrap.run(["Service", "brand", function (Service, brand) {
        return new Service({
            name: 'productAttributeService',
            group: "wcs",

            url: "/wcs/x-services/resources/store/" + brand.current.storeId + "/productservice/byAttributes",
            cache: false,

            postData: function(payload) {
                return this.post(payload);
            }

        });
    }]);
};