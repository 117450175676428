module.exports = function(app) {
    app.controller('pages.brochuresDisplay', ['$state', '$scope', 'brochuresData',
        function($state, $scope, brochuresData) {
        	brochuresData.get().then(function(data){
        		$scope.brochuresData = data;
        		$scope.region = $state.params.region;
        		$scope.marketplaceData = $scope.brochuresData.marketplace;
                $scope.directlyShopData = $scope.brochuresData.directlyShop;
        		$scope.producerData = $scope.brochuresData.producer;
				$scope.genericRxData = $scope.brochuresData.genericrx;
        	});
        }
    ]);
};