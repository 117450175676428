module.exports = function(app) {
  app.service('producerFlowBYFService', [
    'loginManager',
    'services',
    'sharedBYFService',
    '$q',
    'storage',
    'sessionData',
    function(loginManager, services, sharedBYFService, $q, storage, sessionData) {

      var service = {
        initialize: _initialize,
        isCustomerLookup: false,
        canAddDependent: true
      };

      /**
       * This function just takes data we have on SHP side and formats
       * it so that we can restore it on the backend in a format
       * that, when retrieved by HCR, is usable
       * 
       * @param {*} customerLookupData comes from the Producer Login Response
       */
      function _formatFor2dot5Consumption(customerLookupData) {
        // From CUSTOMER LOOKUP w/ UMI (retention)
        if (customerLookupData.customerInfo) {
          angular.forEach(customerLookupData.customerInfo.persons, function(
            person
          ) {
            // Modify DOB
            var dobArray = person.dateOfBirth.split('-');
            person.dob = dobArray[1] + '/' + dobArray[2] + '/' + dobArray[0];

            // Modify Relationship
            if (person.relationship.toUpperCase() === 'SELF') {
              person.relationship = 'SUBSCRIBER';
            }
          });

          return customerLookupData;
        } else if (customerLookupData.hasOwnProperty('prospectBYFInfo')) {
          // Modify DOB

          var dob = new Date(customerLookupData.dob);
          var parts = dob.toISOString().split('-');
          var formattedDOB =
            parts[1] + '/' + parts[2].substring(0, 2) + '/' + parts[0];
          var formattedPerson = {
            fullName: {
              firstName: customerLookupData.firstName,
              lastName: customerLookupData.lastName,
              middleName: '',
              suffix: ''
            },
            relationship: 'SUBSCRIBER',
            relationshipCode: '18',
            ssn: {
              ssnNumber: '',
              noSsnInd: false
            },
            gender: null,
            dateOfBirth: formattedDOB,
            dob: formattedDOB,
            homeAddress: {
              addressLine1: customerLookupData.address1,
              addressLine2: customerLookupData.address2,
              city: customerLookupData.city,
              zipcode: customerLookupData.zipCode,
              address: customerLookupData.address1
            },
            primaryPhoneNumber: customerLookupData.homePhone,
            personId: 0
          };

          return formattedPerson;
        }
      };

      /**
        * If there are multiple family members included in the prospect,
        * format the member list to be in this priority order:
        * 1st: Subscriber
        * 2nd: (if present) Spouse/Domestic Partner
        * 3rd: Dependents
        */
      function _orderMembersAfterLookup(unorderedMembers) {
        var relCodeIndices = [];
        angular.forEach(unorderedMembers, function(value, key) {
          //find the SELF member and add its index to the first slot of relCodeIndices
          if (value.relationship === 'SELF') {
            relCodeIndices[0] = key;
          } else if (
            value.relationship === 'SPOUSE' ||
            value.relationship === 'DOMESTIC PARTNER'
          ) {
            //if there is a spouse/ domestic partner, then add its index to the second slot of relCodeIndices
            relCodeIndices[1] = key;
          }
        });
        var orderedMembers = [];
        //using the found indices of SELF and partner, add them IN ORDER to the ordered members list
        for (var i = 0; i < relCodeIndices.length; i++) {
          orderedMembers.push(unorderedMembers[relCodeIndices[i]]);
        }

        //now that we have found SELF (and optionally partner), we are free to add the dependents to the end of the member list
        angular.forEach(unorderedMembers, function(value, key) {
          if (relCodeIndices.indexOf(key) < 0) {
            orderedMembers.push(value);
          }
        });
        return orderedMembers;
      };

      function _umiFlow(leadInfoUmi) {
        var umiDeferred = $q.defer();

        services.cmcrst
          .customerLookup()
          .postCustomerLookup({ umi: leadInfoUmi })
          .then(
            function(customerLookupResponse) {
              service.isCustomerLookup = true;
              service.canAddDependent = false;

              // Used prior to sending over to 2.5 site
              storage.set('retentionApplication', true);

              services.cmcrst
                .setSessionStorageData()
                .set(
                  'PRODUCER',
                  'CUSTOMERLOOKUP',
                  _formatFor2dot5Consumption(
                    angular.copy(customerLookupResponse.data.payload)
                  )
                );

              // If SalesForce_MemberInfo cookie exists, populate the BYF information
              var persons =
                customerLookupResponse.data.payload.customerInfo.persons;

              var unorderedMembers = [];
              
              angular.forEach(persons, function(person) {
                var dob = person.dateOfBirth.split('-');

                dob = dob[1] + '/' + dob[2] + '/' + dob[0];

                var familyMember = {
                  fullName: {
                    first: person.fullName.firstName
                  },
                  gender: person.gender,
                  dob: dob,
                  relationship: person.relationship,
                  fromLookup: true,
                  tobaccoInd: ''
                };

                unorderedMembers.push(familyMember);
              });

              /** Get the family object (saved or new) */
              var family = sharedBYFService.generateNewFamily();
              
              /** Sort */
              family.members = _orderMembersAfterLookup(unorderedMembers);
              
              sessionData.customerLookup = customerLookupResponse.data;
              
              sharedBYFService.setDataToSessionStorage(
                persons[0].homeAddress.zipcode,
                null,
                null,
                null,
                null,
                family
              );

              umiDeferred.resolve();
            },
            function(error) {
              umiDeferred.reject();
              console.log(error);
            }
          );

        return umiDeferred.promise;
      }

      function _saveOrRetrieveFlow(prospectObj) {
        var saveOrRetrieveDeferred = $q.defer();

        /**
         * Attempt to do customer lookup - retention
         * UMI can either come from the ProspectObj or the BYFInfo
         */
        var umi;
        if (prospectObj.umi) {
          umi = prospectObj.umi;
        } else {
          if (
            prospectObj.prospectBYFInfo &&
            prospectObj.prospectBYFInfo.additionalInfo
          ) {
            var umiObj = JSON.parse(prospectObj.prospectBYFInfo.additionalInfo);
            if (umiObj && umiObj.umi) {
              umi = umiObj.umi;
            }
          }
        }

        if (umi) {
          console.log('initProducerFlow(2a1a):  has UMI');
          _umiFlow(umi).then(
            function(success) {
              saveOrRetrieveDeferred.resolve(success);
            },
            function(failure) {
              saveOrRetrieveDeferred.reject(failure);
            }
          );
        }

        // initProducerFlow(2a2): not post-BYF after new quote - SAVED BUT ONLY IMMEDIATELY???? NO DATA BEYOND NEW QUOTE PAGE
        else if (_isEmpty(prospectObj.prospectBYFInfo)) {
          console.log(
            'initProducerFlow(2a1b): Additional Build-Your-Family info missing (unsure of ramifications, so flow isolated)'
          );

          // Used prior to sending over to 2.5 site
          services.cmcrst
            .setSessionStorageData()
            .set(
              'PRODUCER',
              'PROSPECTDATA',
              _formatFor2dot5Consumption(angular.copy(prospectObj))
            );

          var dob = new Date(prospectObj.dob);
          var parts = dob.toISOString().split('-');
          var formattedDOB =
            parts[1] + '/' + parts[2].substring(0, 2) + '/' + parts[0];

          /** Build a pre-populated MEMBER object */
          var prospectAsMember = {
            fullName: {
              first: prospectObj.firstName
            },
            gender: '',
            dob: formattedDOB,
            relationship: '',
            tobaccoInd: ''
          };

          /** Get the family object (saved or new) */
          var family = sharedBYFService.generateNewFamily();

          /** Sort */
          family.members = _orderMembersAfterLookup([prospectAsMember]);
          
          sharedBYFService.setDataToSessionStorage(
            prospectObj.zipCode,
            null,
            null,
            null,
            null,
            family
          );
          
          saveOrRetrieveDeferred.resolve();
        }

        // DEVON: initProducerFlow(2a3): DEAD END FLOW???
        else {
          console.log(
            'initProducerFlow(2a3): additional prospect information needs rendered'
          );
          saveOrRetrieveDeferred.resolve();
        }

        return saveOrRetrieveDeferred.promise;
      }

      function _initialize() {
        var initDeferred = $q.defer();

        /** Get PRODUCER LOGINDATA */
        loginManager
          .producerAttributeHelper()
          .then(function(sessionStorageResponse) {
            // Lead info === new quote
            var leadInfoUmi =
              sessionStorageResponse &&
              sessionStorageResponse.leadData &&
              sessionStorageResponse.leadData.umi;

            if (leadInfoUmi) {
              _umiFlow(leadInfoUmi).then(
                function(success) {
                  initDeferred.resolve(success);
                },
                function(failure) {
                  initDeferred.reject(failure);
                }
              );
            } else {
              // retention??
              var isSaveOrRetrieve = (
                  sessionStorageResponse &&
                  sessionStorageResponse.prospectData &&
                  (
                    sessionStorageResponse.quoteMode.toUpperCase() === 'SAVE' ||
                    sessionStorageResponse.quoteMode.toUpperCase() === 'RETRIEVE'
                  )
                );

              if (isSaveOrRetrieve) {
                _saveOrRetrieveFlow(sessionStorageResponse.prospectData.payload.prospect).then(
                  function(success) {
                    initDeferred.resolve(success);
                  },
                  function(failure) {
                    initDeferred.reject(failure);
                  }
                );
              }

              // initProducerFlow(2b):  null out rating info for display;
              else if (!storage.get('familyRatingInfo')) {
                console.log(
                  'initProducerFlow(2b - end):  Null out inital values;'
                );
                
                sharedBYFService.setDataToSessionStorage(
                  null,
                  null,
                  null,
                  null,
                  null,
                  sharedBYFService.generateNewFamily()
                );

                initDeferred.resolve();
              }

              // initProducerFlow(2c - end): set saved rating info for display
              else if (storage.get('familyRatingInfo')) {
                /** DEVON: does this matter? */
                // getDataToScope(storage.get('familyRatingInfo'));
                initDeferred.resolve();
              }

            }
          });
          
        return initDeferred.promise;
      }

      //https://coderwall.com/p/_g3x9q/how-to-check-if-javascript-object-is-empty
      function _isEmpty(obj) {
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
            return false;
          }
        }
        return true;
      }

      return service;
    }
  ]);
};
