module.exports = function (app) {
    app.run(["Service", function (Service) {
        new Service({
            name: 'requestEnrollKit',
            group: 'cmcsnr',
            url: '/cmcsnr/x-services/providerDirectory/report',
            transformResponse: angular.identity,
            post: function (payload) {
                return this.post(payload);
            }
        });
    }]);
};
