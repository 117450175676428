module.exports = function (app) {
    app.controller('enrollment.child-information', ["$state", "$scope", "teamsiteService", "$timeout",

        function ($state, $scope, teamsiteService, $timeout) {
            teamsiteService.loadFile("childInformation").then(function (data) {
                $scope.teamsiteContent = data;
                $scope.childInfoContent = data.childInfo;
                $scope.addressInfo = {
                    "addressLine1": "123 Main Street",
                    "city": "Pittsburgh"
                };
                $scope.personInfo = {
                    "fullName": {
                        "firstName": "Chris"
                    },
                    "sameAddressInfo": {
                        "yes": "true"
                    },
                    "ssn": "123456789"
                };
            });
        }
    ]);
};