module.exports = function (app) { "use strict";

    require('./your-information')(app);
    require('./instructions')(app);
    require('./other-insurance')(app);
    require('./review-application')(app);
    require('./authorization')(app);
    require('./dependents-information')(app);
    require('./child-information')(app);
    require('./application-summary')(app);
    require('./payment-information')(app);
    require('./payment-review')(app);
    require('./communication-preferences')(app);
    require('./life-event-document-upload')(app);

    app.controller('enrollment', ["$state", "$scope","$cookies", "$rootScope",
        function ($state, $scope, $cookies, $rootScope) {
            $scope.headings = "Enrollment";

            $scope.sidebar.links = [{
                heading: "New Application",
                label: "New Application",
                state: "enrollment.yourInformation",
                description: "Submit an online application for your selected plan.",
                refineTemplate: "directives/enrollment/planInfo.tpl.html"
            }, {
                heading: "Summary of Benefits &amp; Coverage",
                label: "Summary of Benefits &amp; Coverage",
                href: "#",
                newWindow: true
            }, {
                heading: "View Glossary of Terms",
                label: "View Glossary of Terms",
                href: "#",
                newWindow: true
            }];
            var sidebarCookie = $cookies.get("SHOP_FOUR_DOT_OH_SIDEBAR");
            var onXCookie = $cookies.get("OnExchangeFlag");
            $rootScope.isOnX = (onXCookie == "y");

            if(sidebarCookie){
                var sidebarDecoded = JSON.parse(window.atob(sidebarCookie));

                $scope.planInfo = {
                    plan:           sidebarDecoded.productName,
                    county:         sidebarDecoded.county,
                    coverageStart:  sidebarDecoded.coverageStartDate,
                    premium:        sidebarDecoded.premium
                };
                //$cookies.put("SHOP_FOUR_DOT_OH_SIDEBAR", "",
                //    {
                //        "expires"   : "-1",
                //        "path"      : "/"
                //    });

            } else {
                $scope.planInfo = {
                    plan: "Unknown",
                    county: "Unknown",
                    coverageStart: "Unknown",
                    premium: "Unknown"
                };
            }


            if ($state.current.name === "enrollment") {
                $state.go("enrollment.instructions");
            }
        }]);

    // Setup Stub Form Validation
    app.directive('form', function () {
        return {
            restrict: 'E',
            require: 'form',
            link: function (scope, elem, attrs, formCtrl) {
                scope.$watch(function () {
                    return formCtrl.$submitted;
                }, function (submitted) {
                    submitted && scope.$broadcast('$submitted');
                });
            }
        };
    }).directive('ngForm', function () {
        return {
            restrict: 'EA',
            require: 'form',
            link: function (scope, elem, attrs, formCtrl) {
                scope.$on('$submitted', function () {
                    formCtrl.$setSubmitted();
                });
            }
        };
    });

    return [{
        state: "enrollment",
        url: "/enrollment",
        views: {
            "body@": {
                template: require("pages/enrollment/enrollment.html"),
                controller: "enrollment"
            },
            "content@enrollment": {
                template: require("pages/enrollment/your-information.html"),
                controller: "enrollment.your-information"
            }
        }
    }, {
        state: "enrollment.your-information",
        url: "/your-information",
        views: {
            "body@": {
                template: require("pages/enrollment/enrollment.html"),
                controller: "enrollment"
            },
            "content@enrollment.your-information": {
                template: require("pages/enrollment/your-information.html"),
                controller: "enrollment.your-information"
            }
        }
    }, {
        state: "enrollment.instructions",
        url: "/instructions",
        views: {
            "body@": {
                template: require("pages/enrollment/enrollment.html"),
                controller: "enrollment"
            },
            "content@enrollment.instructions": {
                template: require("pages/enrollment/instructions.html"),
                controller: "enrollment.instructions"
            }
        },
        resolve: {
            contentPromise: function (teamsiteService) {
                return teamsiteService.loadFile("instructions");
            }
        }
    }, {
        state: "enrollment.other-insurance",
        url: "/other-insurance",
        views: {
            "body@": {
                template: require("pages/enrollment/enrollment.html"),
                controller: "enrollment"
            },
            "content@enrollment.other-insurance": {
                template: require("pages/enrollment/other-insurance.html"),
                controller: "enrollment.other-insurance"
            }
        }
    }, {
        state: "enrollment.dependents-information",
        url: "/dependents-information",
        views: {
            "body@": {
                template: require("pages/enrollment/enrollment.html"),
                controller: "enrollment"
            },
            "content@enrollment.dependents-information": {
                template: require("pages/enrollment/dependents-information.html"),
                controller: "enrollment.dependents-information"
            }
        }
    }, {
        state: "enrollment.review-application",
        url: "/review-application",
        views: {
            "body@": {
                template: require("pages/enrollment/enrollment.html"),
                controller: "enrollment"
            },
            "content@enrollment.review-application": {
                template: require("pages/enrollment/review-application.html"),
                controller: "enrollment.review-application"
            }
        }
    }, {
        state: "enrollment.child-information",
        url: "/child-information",
        views: {
            "body@": {
                template: require("pages/enrollment/enrollment.html"),
                controller: "enrollment"
            },
            "content@enrollment.child-information": {
                template: require("pages/enrollment/child-information.html"),
                controller: "enrollment.child-information"
            }
        }
    }, {
        state: "enrollment.authorization",
        url: "/authorization",
        views: {
            "body@": {
                template: require("pages/enrollment/enrollment.html"),
                controller: "enrollment"
            },
            "content@enrollment.authorization": {
                template: require("pages/enrollment/authorization.html"),
                controller: "enrollment.authorization"
            }
        }
    }, {
        state: "enrollment.application-summary",
        url: "/application-summary",
        views: {
            "body@": {
                template: bootstrap.templates["page-templates/categoryCustom.html "],
                controller: "enrollment"
            },
            "content@enrollment.application-summary": {
                template: require("pages/enrollment/application-summary.html"),
                controller: "enrollment.application-summary"
            }
        }
    },{
        state: "enrollment.payment-information",
        url: "/payment-information",
        views: {
            "body@": {
                template: require("pages/enrollment/enrollment.html"),
                controller: "enrollment"
            },
            "content@enrollment.payment-information": {
                template: require("pages/enrollment/payment-information.html"),
                controller: "enrollment.payment-information"
            }
        }
    },{
        state: "enrollment.payment-review",
        url: "/payment-review",
        views: {
            "body@": {
                template: require("pages/enrollment/enrollment.html"),
                controller: "enrollment"
            },
            "content@enrollment.payment-review": {
                template: require("pages/enrollment/payment-review.html"),
                controller:  "enrollment.payment-review"
            }
        }
    }, {
        state: "enrollment.communication-preferences",
        url: "/communication-preferences",
        views: {
            "body@": {
                template: require("pages/enrollment/enrollment.html"),
                controller: "enrollment"
            },
            "content@enrollment.communication-preferences": {
                template: require("pages/enrollment/communication-preferences.html"),
                controller: "enrollment.communication-preferences"
            }
        }
    } ,{
        state: "enrollment.life-event-document-upload",
        url: "/life-event-document-upload",
        views: {
            "body@": {
                template: require("pages/enrollment/enrollment.html"),
                controller: "enrollment"
            },
            "content@enrollment.life-event-document-upload": {
                template: require("pages/enrollment/life-event-document-upload.html"),
                controller: "enrollment.life-event-document-upload"
            }
        }
    }];
};