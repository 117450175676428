module.exports = function(app) {
    app.controller('findInsurance.special-enrollment-qualify', ["$state", "$scope", "teamsiteManager", "content", "modal", "$window", "sepConfigUtility", "sessionData",
        function ($state, $scope, teamsiteManager, content, modal, $window, sepConfigUtility, sessionData) {

            $scope.content = content;
            $scope.question1 = {
                selectedValue: null
            };
            $scope.showInterstitial = false;

            function _onInit() {
                /** Don't show the interstitial for producer flows */
                var producerRoles = ['INTERNAL', 'AGENT', 'AGENCY_ADMIN'];
                if (
                    sessionData &&
                    sessionData.loginData &&
                    sessionData.basicInfo &&
                    sessionData.basicInfo.role &&
                    producerRoles.indexOf(sessionData.basicInfo.role) !== -1
                ) {
                    $scope.showInterstitial = false;
                } else {
                    $scope.showInterstitial = true;
                }
            }

            $scope.showShopForNewPlan = function(selectedFlowIndex){
                var isNewPlanFlow = selectedFlowIndex === 1;
                $scope.shopNewPlan = isNewPlanFlow;
                $scope.updatedAndManageCoverageSelected = !isNewPlanFlow;
            };

            $scope.backToSpecialEnrollment = function () {
                $state.go('findInsurance.special-enrollment');
            };

            $scope.goToBuildYourFamily = function () {
                sepConfigUtility.setSepFlowFlag(true);
                $state.go('findInsurance.build-your-family');
            };

            $scope.goToMarketplace = function(){
                $window.open('https://www.healthcare.gov/', '_self');
            };

            _onInit();
        }
    ]);
};