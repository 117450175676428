module.exports = function(app) {
    'use strict';
    app.directive('relationship', ["errorMessages", function (errorMessages) {
        return {
            restrict: 'EA',
            replace: true,
            template: require('directives/enrollment/relationship.html'),
            scope: {
                relationshipCode: "=",
                relationship: "="
            },
            link: function ($scope, elem, $attrs) {
                $scope.errorMessages = errorMessages;
                $scope.relationships = [{
                    label: "Select One",
                    value: "",
                    code: ""
                }, {
                    label: "Spouse",
                    value: "SPOUSE",
                    code: "01"
                }, {
                    label: "Dependent",
                    value: "DEPENDENT",
                    code: "02"
                }, {
                    label: "Domestic Partner",
                    value: "DOMESTICPARTNER",
                    code: "29"
                }];

                $scope.$watch("relationshipCode", function (relCode) {
                    var i;
                    if (!relCode) {
                        return ;
                    }

                    for (i = 0; i < $scope.relationships.length; i+=1) {
                        if (relCode === $scope.relationships[i].code) {
                            $scope.selectedRelationship = $scope.relationships[i];
                            break;
                        }
                    }
                });

                $scope.setRelationship = function (rel) {
                    $scope.relationship = rel.value;
                    $scope.relationshipCode = rel.code;
                    $scope.selectedRelationship = rel;
                };
            }
        };
    }]);
};