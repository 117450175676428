module.exports = function (app) {
    'use strict';

   // var modalConfirmController = require("./editPlanInfoModal");
    app.directive('planSideInfo', [ 'modal', function (modal) {
        return {
            restrict: 'EA',
            replace: true,
            template: require('directives/enrollment/planSideInfo.html'),
            link: function (scope, elem, $attrs) {
                scope.toggleLinks = function () {
                    scope.linksopen = !scope.linksopen;
                };
                scope.linksopen = true;
                scope.editPlanInfo = function () {
                    modal.open({
                        template: require('directives/enrollment/editPlanInfo.tpl.html'),
                        controller: require("./editPlanInfoModal")
                    });
                };
            }
        };
    }]);
};
