/****
 * Use this, coupled with custom error messages for BTS-FORM-MESSAGES-GENERATOR, to have a different mix/max error validation
 ****/
/****
 * Example
 * 
 * <input type="text" 
          minimum="1"
          maximum="5"
          data-custom-errors="{"min":"too low","max":"too high"}"
          data-include-errors=
          data-limit-to-max="{"min":"too low","max":"too high"}"/>
*/
module.exports = function(app){
    app.directive('limitToMax', function() {
        return {
            require: 'ngModel',
            scope: {
                "limitToMax": "="
            },
            link: function(scope, element, attributes, ngModel) {
                if (attributes.maximum) {
                    ngModel.$validators.customMax = function (value) {
                        return Number(value) <= Number(attributes.maximum)
                    }
                }
                if (attributes.minimum) {
                    ngModel.$validators.customMin = function (value) {
                        return Number(value) >= Number(attributes.minimum);
                    }
                }
            }
        };
    });
};