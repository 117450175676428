module.exports = function (app) { "use strict";
    app.controller('enrollment.dependents-information', ["$state", "$scope", "teamsiteService", "applicationFactory",

        function ($state, $scope, teamsiteService, applicationFactory) {
            teamsiteService.loadFile("yourInformation").then(function (data) {
                $scope.teamsiteContent = data;
                $scope.personInfoContent = data.personalInformationSection;
                $scope.materialContent = data.memberMaterialsSection;
                $scope.sameAddressInfo = {};
                $scope.dependents = applicationFactory.getDependentsData();
            });

            $scope.toChild = function (isValid) {
                if (!isValid) { return; }
                $state.go("enrollment.child-information");
            };
        }]);
};