/**
 * Created by lidu0ho on 10/17/2017.
 */
module.exports = function (app) {
    app.run(["Service", function (Service) {
        new Service({
            name: 'saveScfInfo',
            group: 'cmcsnr',
            url: '/cmcsnr/x-services/scf/saveScfInfo',
            post: function (payload) {
                return this.post(payload);
            }
        });
    }]);
};
