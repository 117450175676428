module.exports = function(app) {
    app.controller('pages.completedApplication.review', ["$state", "$scope", "$http", "$stateParams", "teamsiteService",
        function ($state, $scope, $http, $stateParams, teamsiteService) {

            if (!$stateParams.appData || $stateParams.appData.status !== 200) {
                $state.go("completedApplication.verify");
                return;
            }

            $scope.planData = $stateParams.appData;

            teamsiteService.loadFiles(["reviewApplication", "notificationOff", "entityManager"]).then(function (teamsiteContent) {
                $scope.teamsiteContent = teamsiteContent;
            });
        }
    ]);
};