module.exports = function (app) {
    'use strict';
    app.directive('socialSecurityNumber', ["errorMessages", function (errorMessages) {
        return {
            restrict: 'A',
            replace: true,
            template: require('directives/enrollment/socialSecurityNumber.html'),
            scope: {
                ssn: "="
            },
            link: function ($scope, elem, $attrs) {
                $scope.errorMessages = errorMessages;
                $scope.toggleSsnInd = function () {
                    $scope.disableSsn = $scope.ssn.noSsnInd;
                    elem.find("input[name=ssn]").val("");
                    $scope.ssn.required = !$scope.disableSsn;
                    if ($scope.ssn.noSsnInd) {
                        $scope.ssn.value = "";
                        $scope.ssn.ssnNumber = "";
                    }
                };

            }
        };
    }]);
};