module.exports = function (app) {
    'use strict';
    app.directive('dateOfBirth', ['errorMessages', '$filter', function (errorMessages, $filter) {
        return {
            restrict: 'A',
            replace: true,
            template: require('directives/enrollment/dateOfBirth.html'),
            scope: {
                ttContent: "@",
                dateOfBirth: "="
            },
            link: function ($scope, elem, $attrs) {
                var dateObj,
                    dateConversion = $filter('dateConversion');
                $scope.errorMessages = errorMessages;
                $scope.$watch("dateOfBirth", function (dateOfBirth) {
                    if (dateOfBirth) {
                        dateObj = dateConversion(dateOfBirth, "mm-dd-yyyy");
                        $scope.dobModel = new Date(dateObj);
                    }
                });

                $scope.setDateStr = function (dateObj) {
                    if (!dateObj) {
                        return;
                    }
                    $scope.dateOfBirth = dateConversion(dateObj, 'mm/dd/yyyy');
                };
            }
        };
    }]);
};