module.exports = function(app) {
    app.controller('pages.findInsurance.compare-plans', ["$state", "$scope", "$q","$filter","teamsiteManager",
        function ($state, $scope, $q, $filter, teamsiteManager) {
        	teamsiteManager.getContent("compareGridBase", "DEFAULT").then(function(data) {
        		window.scope = $scope;
        		$scope.content = data;
        		//console.log("content::",data);
	        	$scope.myCompareModel = {};
	        	$scope.myCompareModel.networkType = "in";
	        	$scope.myCompareModel.headerList = [];
	        	$scope.myCompareModel.headerList.push({"name":"Health Savings Blue PPO","subname":"4000","metal":"bronze",
	        		"description":"Product description goes here if we ahve it"});
	        	$scope.myCompareModel.headerList.push({"name":"Balance Blue PPO","subname":"6000","metal":"silver","taxCredit":true});
	        	$scope.myCompareModel.headerList.push({"name":"Shared Cost Blue PPO","subname":"9000","metal":"gold",
	        		"description":"Product description goes here if we ahve it"});

	        	$scope.myCompareModel.rowList = [];
	        	$scope.myCompareModel.rowList.push({"heading":data.premiumLabel,"descript":data.premiumDescription,
	        		"data":[
	        			{in:"<strong>$251.27</strong>",out:"<strong>$251.30</strong>"},
	        			{in:"<strong>$251.30</strong>",out:"<strong>$251.40</strong>"},
	        			{in:"<strong>$251.27</strong>",out:"<strong>$251.40</strong>"}]});
	        	$scope.myCompareModel.rowList.push({"heading":data.deductibleLabel,"descript":data.deductibleDescription,
	        		"data":[
	        		{in:"<strong>$6,450.00</strong>",out:"<strong>$7,450.00</strong>"},
	        		{in:"<strong>$6,450.00</strong>",out:"<strong>$7,450.00</strong>"},
	        		{in:"<strong>$6,450.00</strong>",out:"<strong>$7,450.00</strong>"}]});
	        	$scope.myCompareModel.rowList.push({"heading":data.oopLabel,"descript":data.oopDescription,
	        		"data":[
	        		{in:"<strong>$6,450.00</strong>",out:"<strong>$4,450.00</strong>"},
	        		{in:"<strong>$6,450.00</strong>",out:"<strong>$4,450.00</strong>"},
	        		{in:"<strong>$6,450.00</strong>",out:"<strong>$4,450.00</strong>"}]});
	        	$scope.myCompareModel.rowList.push({"heading":data.coInsuranceLabel,"descript":data.coInsuranceDescription,
	        		"data":[
	        		{in:"<strong>100% after deductible</strong>",out:"<strong>100% after deductible</strong>"},
	        		{in:"<strong>100% after deductible</strong>",out:"<strong>0% after deductible</strong>"},
	        		{in:"<strong>100% after deductible</strong>",out:"<strong>100% after deductible</strong>"}]});
	        	$scope.myCompareModel.rowList.push({"heading":data.doctorVisitLabel,"descript":data.doctorVisitDescription,
	        		"data":[
	        			{in:"<div><p>PCP: <strong>100% after deductible</strong></p><p>Specialist: <strong>100% after deductible</strong></p></div>",
	        			out:"<div><p>PCP: <strong>100% after deductible</strong></p><p>Specialist: <strong>100% after deductible</strong></p></div>"},
	        			{in:"<div><p>PCP: <strong>100% after deductible</strong></p><p>Specialist: <strong>100% after deductible</strong></p></div>",
	        			out:"<div><p>PCP: <strong>100% after deductible</strong></p><p>Specialist: <strong>100% after deductible</strong></p></div>"},
	        			{in:"<div><p>PCP: <strong>100% after deductible</strong></p><p>Specialist: <strong>100% after deductible</strong></p></div>",
	        			out:"<div><p>PCP: <strong>100% after deductible</strong></p><p>Specialist: <strong>100% after deductible</strong></p></div>"}]});
	        	$scope.myCompareModel.rowList.push({"heading":data.emergencyVisitLabel,"descript":"",
	        		"data":[
	        			{in:"<strong>0% after deductible</strong>",out:"<strong>100% after deductible</strong>"},
	        			{in:"<strong>0% after deductible</strong>",out:"<strong>100% after deductible</strong>"},
	        			{in:"<strong>0% after deductible</strong>",out:"<strong>100% after deductible</strong>"}]});
	        	var tmpLabel = "<a class='active-link' href='javascript:'><i>"+data.preventativeLinkText+"</i></a>";
	        	$scope.myCompareModel.rowList.push({"heading":data.preventativeTestingLabel,"descript":tmpLabel,
	        		"data":[
	        			{in:"<strong>100%, no deductible or copay</strong>",out:"<strong>100%, no deductible or copay</strong>"},
	        			{in:"<strong>100%, no deductible or copay</strong>",out:"<strong>100%, no deductible or copay</strong>"},
	        			{in:"<strong>100%, no deductible or copay</strong>",out:"<strong>100%, no deductible or copay</strong>"}]});
	        	$scope.myCompareModel.rowList.push({"heading":data.outpatientSurgeryLabel,"descript":"",
	        		"data":[
	        			{in:"<strong>100% after deductible</strong>",out:"<strong>100% after deductible</strong>"},
	        			{in:"<strong>100% after deductible</strong>",out:"<strong>100% after deductible</strong>"},
	        			{in:"<strong>100% after deductible</strong>",out:"<strong>100% after deductible</strong>"}]});
	        	$scope.myCompareModel.rowList.push({"heading":data.inpatientStayLabel,"descript":"",
	        		"data":[
	        			{in:"<strong>100% after deductible</strong>",out:"<strong>100% after deductible</strong>"},
	        			{in:"<strong>100% after deductible</strong>",out:"<strong>100% after deductible</strong>"},
	        			{in:"<strong>100% after deductible</strong>",out:"<strong>100% after deductible</strong>"}]});
	        	$scope.myCompareModel.rowList.push({"heading":data.pharmacyBenefitsLabel,"descript":"",
	        		"data":[
	        			{in:"<strong>HCR Comprehensive</strong>",out:"<strong>Not Covered</strong>"},
	        			{in:"<strong>HCR Comprehensive</strong>",out:"<strong>Not Covered</strong>"},
	        			{in:"<strong>HCR Comprehensive</strong>",out:"<strong>Not Covered</strong>"}]});
	        	$scope.myCompareModel.rowList.push({"heading":data.prescriptionInfoLabel,"descript":data.prescriptionSupplyLabel+'<br>'+data.prescriptionInfoDescription,
	        		"data":[
	        			{in:"<div>  <p>Formulary Generic:</p><p><strong>$0.00</strong></p><p>Formulary Brand:</p><p><strong>$0.00</strong></p><p>Non Formulary:</p><p><strong>$0.00</strong></p><p>Specialty Formulary:</p><p><strong>N/A</strong></p></div>",
	        			out:"<strong>Not Covered</strong>"},
        				{in:"<div>  <p>Formulary Generic:</p><p><strong>$100.00</strong></p><p>Formulary Brand:</p><p><strong>$100.00</strong></p><p>Non Formulary:</p><p><strong>$100.00</strong></p><p>Specialty Formulary:</p><p><strong>N/A</strong></p></div>",
        				out:"<strong>Not Covered</strong>"},
        				{in:"<div>  <p>Formulary Generic:</p><p><strong>$0.00</strong></p><p>Formulary Brand:</p><p><strong>$0.00</strong></p><p>Non Formulary:</p><p><strong>$0.00</strong></p><p>Specialty Formulary:</p><p><strong>N/A</strong></p></div>",
        				out:"<strong>Not Covered</strong>"}]});
	        	
	        	$scope.application = {
	        				"Primary Applicant": "John Doe",
	                        "Zip Code": 15222,
	                        "County": "Allegheny",
	                        "Effective Date": "04/01/2016",
	                        "Coverage For": "1 Adult, 0 Children"
	                    };
	        });
        }
    ]);
};