module.exports = function(app) {

	return app.factory("enrollmentUtility", ["storage", "$q", "services", "$cookies", "brand", "hmShopDateAndTimeUtility", 
		function(storage, $q, services, $cookies, brand, hmShopDateAndTimeUtility) {

			var enrollmentUtilityExternalFunctions = {
				"routeToOldShop": _routeToOldShop
			}

			function _routeToOldShop(selectedProductInformation, ratingInfo, aptcSliderValue) {

	            localStorage.setItem("ratingInfoFrom2Dot5", JSON.stringify(ratingInfo));
		        var onExchangeObject = storage.get("onExchangeObject");
				var cookieObject = {
					"productAttrs" 				: _getNecessaryProductAttributes(selectedProductInformation),
					"formattedApplicantInfo" 	: _getNecessaryApplicantInfo(ratingInfo, selectedProductInformation),
					"aptcSliderValue" 			: aptcSliderValue,
					"prospectId"				: onExchangeObject && onExchangeObject.prospectId ? onExchangeObject.prospectId : "",
					"ratingInfo" 				: JSON.stringify(ratingInfo)
				};

				console.log("cookieObject", cookieObject);

				// Brand is set on BYF; when coming from CHMHCR we do not land on that page, so it is unset.  
				// Because we are unable to get to view-plans w/o a branding, if it is unset, just retain the value from before.
				var brandCookieName = "brand";
				if (brand.current.sharedBrandCode.toUpperCase() !== "UNBRANDEDHM") {
					document.cookie = brandCookieName + "=" + brand.current.sharedBrandCode + ";domain=.highmark.com;path=/;";
				}

				var commerceActivityTokenName 	= "CommerceActivityToken";
				document.cookie 				= commerceActivityTokenName + "=" + encodeURIComponent(new Date()) + ";domain=.highmark.com;path=/;";

				var enrollDataCookieName 	= "SHOP_FOUR_DOT_OH_ENROLL_PACKAGE";
				var enrollDataCookieValue 	= window.btoa(JSON.stringify(cookieObject));
				document.cookie 			= enrollDataCookieName + "=" + enrollDataCookieValue + ";domain=.highmark.com;path=/;";

				console.log(enrollDataCookieName + "=" + enrollDataCookieValue + ";domain=.highmark.com;path=/;");

				var possibleProducerAttrs = storage.get("producerAttributeHelper");
		        // Go forward to 2.5
		        
		        if (onExchangeObject && onExchangeObject.prospectId) {
		        	window.location.href = "/?prospectId=" + onExchangeObject.prospectId + "#!/marketplace/enroll";
				} else if (possibleProducerAttrs && (possibleProducerAttrs.quoteMode.toUpperCase() === "SAVE" || possibleProducerAttrs.quoteMode.toUpperCase() === "RETRIEVE")) {
		            window.location.href = '/?saveQuote=true#!/enrollment/instructions';
				} else {
		            window.location.href = '/#!/enrollment/instructions';
				}
			};

			function _getNecessaryApplicantInfo(ratingInfo, selectedPlan) {
				return {
					"zipCode"		: ratingInfo.zipCode,
					"county"		: ratingInfo.county,
					"region"		: ratingInfo.region,
					"state"			: ratingInfo.state,
					"groups"		: _formattedApplicants(ratingInfo.applicants, selectedPlan),
					"retention"		: storage.get("retentionApplication") || false, //<-- confirm when set, looks like saved applications - need to find trigger
					"newEnrollment"	: true,
					"effectiveDate"	: storage.get("selectedEffectiveDateISO8601") || (function getProducerSavedQuoteEffectiveDate(){
						
						var formattedEffectiveDate = (function getSlashedDate(){

							if (ratingInfo.effectiveDate.indexOf("/") !== -1) {
								return ratingInfo.effectiveDate;
							} else {
								var dateArray = ratingInfo.effectiveDate.split("-");
								return dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0];
							}
						})();

						return hmShopDateAndTimeUtility.getISO8601CompliantDatetimeString(new Date(formattedEffectiveDate.replace(/[^ -~]|/g,'')))
					})()
				};

				function _formattedApplicants(applicants, selectedPlan) {
					var groups = [{
						"childOnly"		: storage.get("isChildOnly"),
						"persons"		: [],
						"selectedPlan" 	: {
							"medical" 	: {
								"monthlyPremiumDisplay": selectedPlan.monthlyPremium
							}
						}
					}];

					angular.forEach(applicants, function(applicant){

						var applicantDOBArray 	= applicant.dob.split("-");
						var newApplicantObj 	= {
							"dob"			: applicantDOBArray[1] + "/" + applicantDOBArray[2] + "/" + applicantDOBArray[0],
							"tobaccoStatus"	: applicant.tobaccoStatus,
							"relationship"	: applicant.relationship.toUpperCase() === "DOMESTIC PARTNER" ? "Spouse" : applicant.relationship,
							"fullName"		: {
								"firstName"	: applicant.name
							}
						};

						groups[0].persons.push(newApplicantObj);

					});

					return groups;	
				};
			};

			function _getNecessaryProductAttributes(product) {
				return {
					"region" 			: product.region,
					"name" 				: product.name,
					"productId" 		: product.productId,
					"groupNoValue" 		: product.groupNoValue,
					"monthlyPremium" 	: product.monthlyPremium,
					"levelOfCoverage" 	: product.levelOfCoverage
				};
			};

			return enrollmentUtilityExternalFunctions;
		}
	]);
}