module.exports = function (app) {

    app.controller('pages.findInsurance.view-plans', [
        "$state",
        "$scope",
        "$q",
        "$filter",
        "pageContent",
        "brand",
        "sessionData",
        "planDataUtility",
        "services",
        "storage",
        "coveredPrescriptionDrugsUtility",
        "coveredProvidersUtility",
        "discontinuedPlansUtility",
        "sepConfigUtility",
        "brandConfig",
        "hmShopDateAndTimeUtility",
        "modal",
        "prospectUtility",
        "loginManager",
        "appConfig",
        "$rootScope",
        "$cookies",
        "ratingInfoModel",
        "SALES_CONSTANTS",
        function (
            $state,
            $scope,
            $q,
            $filter,
            pageContent,
            brand,
            sessionData,
            planDataUtility,
            services,
            storage,
            coveredPrescriptionDrugsUtility,
            coveredProvidersUtility,
            discontinuedPlansUtility,
            sepConfigUtility,
            brandConfig,
            hmShopDateAndTimeUtility,
            modal,
            prospectUtility,
            loginManager,
            appConfig,
            $rootScope,
            $cookies,
            ratingInfoModel,
            SALES_CONSTANTS
        ) {

            /****
             * Internal Variables
             ****/
            var umiOnProducer;

            /****
             * View Variable Declarations
             ****/
            $scope.brand = brand;
            $scope.pageContent = pageContent;

            $scope.showFooter = true; // determines whether HEALTH_SHERPA changes are needed

            $scope.isSEPFlow = sepConfigUtility.isSepFlow;
            $scope.sidebar.planCount = 0;
            $scope.sidebar.showFindAProviderButton = false;
            $scope.enrollButtonLabel = "Enroll";
            $scope.compareObject = {
                "atOrAboveMinimum": false,
                "atOrBelowMaximum": false,
                "activeCompares": 0,
                "comparableMinimum": 2,
                "comparableLimit": 3
            };

            $scope.dropdownOptions = [
                { "name": "Premium Low to High", "value": "annualPremium", "reverse": false },
                { "name": "Premium High to Low", "value": "annualPremium", "reverse": true },
                { "name": "Plan Name (A to Z)", "value": "formattedName", "reverse": false },
                { "name": "Deductible Low to High ", "value": "individualDeductible", "reverse": false },
                { "name": "Deductible High to Low", "value": "individualDeductible", "reverse": true }
            ];



            /****
             * View Function Initializations
             ****/
            $scope.getMinOrMax = getMinOrMax;
            $scope.order = order;
            $scope.planFilter = planFilter;
            $scope.comparePlans = comparePlans;
            $scope.populateCrosswalk = populateCrosswalk;
            $scope.planYearForCompares = planYearForCompares;
            $scope.atComparableLimit = atComparableLimit;
            $scope.goToPrintSummary = goToPrintSummary;
            $scope.prepareAdjustedPremium = prepareAdjustedPremium;
            $scope.saveQuote = saveQuote;
            $scope.backToHouseholdIncome = backToHouseholdIncome;

            function backToHouseholdIncome() {
                storage.del("ffmObject");
                storage.del("taxCreditInfo");
                storage.del("ratingInfoFrom2Dot5");

                // Populate onExchangeObject
                storage.set("onExchangeObject", {
                    "isOnExchange": false,
                    "subsidyAmount": 0
                });

                $rootScope.isOnX = false;

                $state.go('findInsurance.household-income');

            };

            function saveQuote() {

                var prospectData = storage.get("producerAttributeHelper").prospectData.payload.prospect;
                var newQuoteCookieFlag = $cookies.get("lookupNewQuote") ? true : false;
                var umi;
                //if umi is not stored in prospectData and is instead stored in additoinal info
                //(indicating a resumed save quote on the BYF page), make sure that it is saved back to the prospect
                //so that customers currently enrolled can be saved on view plans and resumed
                if (prospectData.umi) {
                    umi = prospectData.umi;
                    delete prospectData.umi;
                } else {
                    if (prospectData.prospectBYFInfo && prospectData.prospectBYFInfo.additionalInfo) {
                        var umiObj = JSON.parse(prospectData.prospectBYFInfo.additionalInfo);
                        if (umiObj && umiObj.umi) {
                            umi = umiObj.umi;
                        }
                    }
                }

                //accumulate data that needs to be stored when the quote is saved
                var viewPlansReturnData = {
                    "familyRatingInfo": storage.get("familyRatingInfo"),
                    "ratingInfoPayload": ratingInfoModel.get(),
                    // "ratingInfoPayload"    : storage.get("ratingInfoPayload"),
                    "onExchangeObject": storage.get("onExchangeObject"),
                    "subsidyResponse": storage.get("subsidyResponse"),
                    "prospectValues": {
                        "prspAgtLookUpId": prospectData.prspAgtLookUpId,
                        "emailCount": prospectData.emailCount
                    },
                    "umi": umi,
                    "childOnlyApplication": storage.get("isChildOnly"),
                    "newQuoteCookieFlag": newQuoteCookieFlag
                };



                //set appropriate data for producer portal to use
                prospectData.prospectBYFInfo.SEPData = {
                    planYear: viewPlansReturnData.ratingInfoPayload.effectiveDate.split("-")[0],
                    isSEP: $scope.isSepFlow
                };

                prospectData.agent.agentId = sessionData.loginData.agentId;
                prospectData.prospectBYFInfo.comparePlans = [];
                prospectData.prospectBYFInfo.selectedPlan = {};

                //add in storage info in string form for retrieval later in the save quote flow
                prospectData.prospectBYFInfo.additionalInfo = JSON.stringify(viewPlansReturnData);

                $scope.newProspectData = prospectData;
                //update customer prospect, if successful display success modal
                prospectUtility.updateProspect(prospectData).then(function (prospectResponse) {
                    var loginData = storage.get("producerAttributeHelper");
                    loginData.prospectData = $scope.newProspectData;
                    storage.set('producerAttributeHelper', loginData);
                    _displaySaveQuoteSuccess();
                    //if prospect service ran successfully,
                    //display success modal

                }, function (error) {
                    //if prospect service failed, display failure modal for save quote button
                    _displaySaveQuoteFailure();
                });
            }

            function _displaySaveQuoteSuccess() {
                $scope.savedQuotes = {
                    "title": $scope.pageContent.viewPlansFindInsurance.modals.saveQuotes.title,
                    "buttonText": $scope.pageContent.viewPlansFindInsurance.modals.saveQuotes.btnText,
                    "message": $scope.pageContent.viewPlansFindInsurance.modals.saveQuotes.successMessage
                };//display success text on modal
                modal.open({
                    template: require('modals/save-quote.html'),
                    controller: require('modals/save-quote'),
                    scope: $scope
                });
            }

            function _displaySaveQuoteFailure() {
                $scope.savedQuotes = {
                    "title": $scope.pageContent.viewPlansFindInsurance.modals.saveQuotes.title,
                    "buttonText": $scope.pageContent.viewPlansFindInsurance.modals.saveQuotes.btnText,
                    "message": $scope.pageContent.viewPlansFindInsurance.modals.saveQuotes.failureMessage
                };
                //display failure text on modal
                modal.open({
                    template: require('modals/save-quote.html'),
                    controller: require('modals/save-quote'),
                    scope: $scope
                });
            }


            /****
             * Function Declarations
             ****/
            function getMinOrMax(list, attribute, getMax) {
                var tmpArray = [];

                angular.forEach(list, function (value) {
                    var tmp = "" + value[attribute];
                    tmp = tmp.replace("$", "");
                    tmp = tmp.replace(",", "");
                    tmpArray.push(Number(tmp));
                });

                Math.min.apply(Math, tmpArray);
                if (getMax) { return Math.max.apply(Math, tmpArray); }
            }

            // TODO - rewrite all of this, not utilizing planData util, building elements and assigning values via innerHTML shouldn't happen
            //returns true if all elements were successfully added, false otherwise
            function populateCrosswalk(plans, isProducer) {
                var numColumns = 2;
                //verify correct datatypes
                try {
                    for (var plan in plans) {
                        var elementPrefix = plan;
                        //prepare headers and objects for the indicated element
                        var planTable = document.getElementById(elementPrefix + "-plan");
                        var yearHeader = document.createElement("b");
                        yearHeader.innerHTML = "Your " + plans[plan].year + " Plan*";
                        yearHeader.style.fontSize = "20pt";
                        var table = document.createElement("table");
                        table.id = elementPrefix + "-plan-table";
                        table.className = "lookup-table";
                        table.style.textAlign = "center";
                        //add the name header to the table
                        var headerRow = table.createTHead();
                        var header = document.createElement("th");
                        header.colSpan = numColumns;
                        header.innerHTML = plans[plan].name.split("_").join(" ");
                        header.style.textAlign = "center";
                        header.className = "table-header";
                        headerRow.appendChild(header);
                        for (var category in plans[plan].pricing) {
                            if (typeof category !== "undefined") {
                                //if one of the three categories with monetary values attached,
                                var row = table.insertRow(table.rows.length);
                                var key = row.insertCell(0);
                                //then prepare them as key value pairs and format the monetary amount
                                var value = row.insertCell(1);
                                key.innerHTML = category.split("_").join(" ");
                                value.innerHTML = plans[plan].pricing[category];
                                key.classList.add("lookup-table-element");
                                key.classList.add("table-key");
                                value.classList.add("lookup-table-element");
                                value.classList.add("table-value");
                            }
                        }
                        if (isProducer) {
                            if (plans[plan].flags.aptc || plans[plan].flags.csr) {
                                //the data is a single column category, so it gets added in a block.
                                var singleColumnDataRow = table.insertRow(table.rows.length);
                                var singleColumnData = document.createElement("td");
                                singleColumnData.colSpan = numColumns;
                                singleColumnData.className = "single-column-data";
                                if (plans[plan].flags.aptc) {
                                    var aptc = document.createElement("p");
                                    aptc.innerHTML = "&#10004; APTC";
                                    singleColumnData.appendChild(aptc);
                                }
                                if (plans[plan].flags.csr) {
                                    var csr = document.createElement("p");
                                    csr.innerHTML = "&#10004; CSR";
                                    singleColumnData.appendChild(csr);
                                }
                                singleColumnDataRow.appendChild(singleColumnData);
                            }
                        }
                        //add the constructed table and header to the DOM
                        //and return true to signal a success
                        planTable.appendChild(yearHeader);
                        planTable.appendChild(table);
                        console.log(elementPrefix + " data population successful");
                    }
                }
                catch (error) {
                    //if something goes wrong during table construction, output the error and return false
                    console.log("An issue occurred during " + elementPrefix + " table population:");
                    console.log(error.message);
                    return false;
                }
                return true;
            }

            //only used in the case of a member, so can call prepare customer lookup with a constant false flag
            function callCustomerLookup(umi) {

                services
                    .cmcrst
                    .customerLookup()
                    .postCustomerLookup({ "umi": umi })
                    .then(function (customerLookupResponse) {

                        services
                            .cmcrst
                            .setSessionStorageData()
                            .set("PRODUCER", "CUSTOMERLOOKUP", _formatFor2dot5Consumption(angular.copy(customerLookupResponse.data.payload)));

                        sessionData.customerLookup = customerLookupResponse.data;
                        prepareLookupData();
                    });

                var _formatFor2dot5Consumption = function (customerLookupData) {

                    // From CUSTOMER LOOKUP w/ UMI (retention)
                    if (customerLookupData.customerInfo) {
                        angular.forEach(customerLookupData.customerInfo.persons, function (person) {
                            // Modify DOB
                            var dobArray = person.dateOfBirth.split("-");
                            person.dob = dobArray[1] + "/" + dobArray[2] + "/" + dobArray[0];

                            // Modify Relationship
                            if (person.relationship.toUpperCase() === "SELF") {
                                person.relationship = "SUBSCRIBER";
                            }

                        })

                        return customerLookupData;
                    } else if (customerLookupData.hasOwnProperty("prospectBYFInfo")) {
                        // Modify DOB

                        var dob = new Date(customerLookupData.dob);
                        var parts = dob.toISOString().split("-");
                        var formattedDOB = parts[1] + "/" + parts[2].substring(0, 2) + "/" + parts[0];
                        var formattedPerson = {
                            "fullName": {
                                "firstName": customerLookupData.firstName,
                                "lastName": customerLookupData.lastName,
                                "middleName": "",
                                "suffix": ""
                            },
                            "relationship": "SUBSCRIBER",
                            "relationshipCode": "18",
                            "ssn": {
                                "ssnNumber": "",
                                "noSsnInd": false
                            },
                            "gender": null,
                            "dateOfBirth": formattedDOB,
                            "dob": formattedDOB,
                            "homeAddress": {
                                "addressLine1": customerLookupData.address1,
                                "addressLine2": customerLookupData.address2,
                                "city": customerLookupData.city,
                                "zipcode": customerLookupData.zipCode,
                                "address": customerLookupData.address1
                            },
                            "primaryPhoneNumber": customerLookupData.homePhone,
                            "personId": 0
                        };

                        return formattedPerson;
                    }
                };
            }

            function prepareLookupData() {
                var crosswalkDataFromCustomerLookup = sessionData.customerLookup.payload.customerCrosswalk[0];

                var region = crosswalkDataFromCustomerLookup.region;

                var thisYear = crosswalkDataFromCustomerLookup.year;
                var nextYear = (parseInt(thisYear) + 1).toString();

                angular.extend($scope.table, {
                    "thisYear": thisYear,
                    "nextYear": nextYear
                });

                var tempHios = crosswalkDataFromCustomerLookup.currentYearHios16D;
                var currentHios = "";
                if (tempHios) {
                    currentHios = tempHios.slice(0, -2) + "-" + tempHios.slice(-2);
                }
                $scope.table.currentHios = currentHios;


                var tempGroupNo = crosswalkDataFromCustomerLookup.currentYearGroupNumber;
                var currentGroupNo = "";
                if (tempGroupNo) {
                    currentGroupNo = tempGroupNo.slice(0, -2) + "-" + tempGroupNo.slice(-2);
                }

                var nextHios = "";
                tempHios = crosswalkDataFromCustomerLookup.nextYearCWHios16d;
                if (tempHios) {
                    nextHios = tempHios.slice(0, -2) + "-" + tempHios.slice(-2);
                }

                tempGroupNo = crosswalkDataFromCustomerLookup.nextYearCWGroupNumber;
                var nextGroupNo = "";
                if (tempGroupNo) {
                    nextGroupNo = tempGroupNo.slice(0, -2) + "-" + tempGroupNo.slice(-2);
                }

                var productAttrServicePayload = {
                    "PLAN_REGION": region,
                    "PLAN_YEAR": thisYear,
                    "HIOS_ID": currentHios,
                    "GROUP_NO": currentGroupNo
                };

                var futureProductAttrServicePayload = {
                    "PLAN_REGION": region,
                    "PLAN_YEAR": nextYear,
                    "HIOS_ID": nextHios,
                    "GROUP_NO": nextGroupNo
                };

                $scope.table.thisYear = sessionData.customerLookup.payload.customerCrosswalk[0].year;
                $scope.table.nextYear = (parseInt($scope.table.thisYear) + 1).toString();



                services.wcs.productAttributeService().postData(productAttrServicePayload).then(function (response) {
                    if (response.data.products.length > 0) {
                        $scope.table.currentPlan = response.data.products[0];
                        prepareFutureData(futureProductAttrServicePayload);
                    } else {
                        //if current year fials, search with the previous year
                        var lastYear = (parseInt($scope.table.thisYear) - 1).toString();
                        var pastProductAttrServicePayload = productAttrServicePayload;
                        pastProductAttrServicePayload.PLAN_YEAR = lastYear;
                        services.wcs.productAttributeService().postData(pastProductAttrServicePayload).then(function (response) {
                            if (response.data.products.length > 0) {
                                $scope.table.currentPlan = response.data.products[0];
                                prepareFutureData(futureProductAttrServicePayload);
                            }
                            //if previous year fails, stop searching
                        });
                    }
                });
            }

            function prepareFutureData(futurePayload) {
                services.wcs.productAttributeService().postData(futurePayload).then(function (response) {
                    //is there any work to do determining which plan to select if more than one?
                    //i would imagine, match up the HIOS ID
                    var tempHios = sessionData.customerLookup.payload.customerCrosswalk[0].nextYearCWHios16d;
                    var nextHios = tempHios.slice(0, -2) + "-" + tempHios.slice(-2);
                    var nextYearGroupNo = sessionData.customerLookup.payload.customerCrosswalk[0].nextYearCWGroupNumber;
                    nextYearGroupNo = nextYearGroupNo.slice(0, -2) + "-" + nextYearGroupNo.slice(-2);
                    $scope.table.futurePlan = response.data.products[0];

                    //construct object to pass along to table construction function
                    var plans = {};
                    plans.current = {};
                    plans.current.year = $scope.table.thisYear;
                    plans.current.pricing = {};
                    plans.current.name = $scope.table.currentPlan.name;
                    plans.current.pricing.Premium = sessionData.customerLookup.payload.customerCrosswalk[0].currentYearResponsiblePremiumAmt;
                    var isIndv = sessionData.customerLookup.payload.customerInfo.persons.length == 1;
                    //obtain deductible and oop from the plan

                    var searchResults_Current = findOopAndDeductible(isIndv, $scope.table.currentPlan);
                    plans.current.pricing.Deductible = searchResults_Current.Deductible;
                    plans.current.pricing.Out_Of_Pocket_Max = searchResults_Current.Oop;
                    plans.future = {};
                    plans.future.year = $scope.table.nextYear;
                    plans.future.pricing = {};
                    plans.future.name = $scope.table.futurePlan.name;
                    plans.future.pricing.Premium = sessionData.customerLookup.payload.customerCrosswalk[0].nextYearResponiblePremiumAmt;
                    //check number of applicants for the next year plan
                    var searchResults_Future = findOopAndDeductible(isIndv, $scope.table.futurePlan);
                    plans.future.pricing.Deductible = searchResults_Future.Deductible;
                    plans.future.pricing.Out_Of_Pocket_Max = searchResults_Future.Oop;

                    //function to extract deductible/oop max from the specified plan for the specified number of applicants (single vs family)
                    function findOopAndDeductible(isIndv, plan) {
                        var searchResults = {};

                        var args = {
                            teamsiteContent: pageContent.compareGridBase,
                            isOnExchange: false
                        };

                        var planData = planDataUtility.transformSinglePlanData(plan, isIndv, args);

                        if (isIndv) {
                            if (planData.is4TierProduct) {
                                searchResults.Deductible = planData.preferredIndividualDeductible;
                                searchResults.Oop = planData.preferredIndividualOutOfPocketMax;
                            } else if (planData.cpaCommunityBlue) {
                                searchResults.Deductible = planData.enhancedIndividualDeductible;
                                searchResults.Oop = planData.enhancedIndividualOutOfPocketMax;
                            } else {
                                searchResults.Deductible = planData.individualDeductible;
                                searchResults.Oop = planData.individualOutOfPocketMax;
                            }
                        } else {
                            if (planData.is4TierProduct) {
                                searchResults.Deductible = planData.preferredFamilyDeductible;
                                searchResults.Oop = planData.preferredFamilyOutOfPocketMax;
                            } else if (planData.cpaCommunityBlue) {
                                searchResults.Deductible = planData.enhancedFamilyDeductible;
                                searchResults.Oop = planData.enhancedFamilyOutOfPocketMax;
                            } else {
                                searchResults.Deductible = planData.familyDeductible;
                                searchResults.Oop = planData.familyOutOfPocketMax;
                            }
                        }

                        return searchResults;
                    }

                    //check if the last digit in the hios id is NOT a 0 or 1
                    function validateCsr(hios) {
                        return (hios.substr(hios.length - 1) == "4" || hios.substr(hios.length - 1) == "5" || hios.substr(hios.length - 1) == "6");
                    }
                    //make sure that the aptc value is greater than 0
                    function validateAptc(aptc) {
                        if (aptc === "") {
                            return false;
                        }
                        var numAptc = Number(aptc);
                        if (!isNaN(numAptc)) {
                            return numAptc > 0;
                        } else {
                            return false;
                        }
                    }
                    if ($scope.table.isProducer) {
                        //if a producer is viewing the table, determine the aptc and csr flags to add to the plans object for use in constructing the table
                        var csrBool_Current = validateCsr($scope.table.currentHios);
                        var aptcBool_Current = validateAptc(sessionData.customerLookup.payload.customerCrosswalk[0].currentYearAptc);
                        plans.current.flags = {};
                        plans.current.flags = {};
                        plans.current.flags.aptc = aptcBool_Current;
                        plans.current.flags.csr = csrBool_Current;
                        var csrBool_Future = validateCsr(nextHios);
                        var aptcBool_Future = validateAptc(sessionData.customerLookup.payload.customerCrosswalk[0].nextYearAptc);
                        plans.future.flags = {};
                        plans.future.flags.aptc = aptcBool_Future;
                        plans.future.flags.csr = csrBool_Future;
                    }
                    for (var cost in plans.current.pricing) {
                        if (plans.current.pricing[cost] != "N/A") {
                            plans.current.pricing[cost] = "$" + plans.current.pricing[cost];
                        } else {
                            plans.current.pricing[cost] = "$0";
                        }
                    }
                    for (var cost in plans.future.pricing) {
                        if (plans.future.pricing[cost] != "N/A") {
                            plans.future.pricing[cost] = "$" + (plans.future.pricing[cost]);
                        } else {
                            plans.future.pricing[cost] = "$0";
                        }
                    }
                    var success = false;
                    success = populateCrosswalk(plans, $scope.table.isProducer);

                    if (success) {
                        $scope.table.showLookupDesc = true;
                        document.getElementById("tableContainer").style.display = "inline";
                    }

                });
            }

            /**
             *  returns true if user is logged in and a producer, false otherwise
             * @returns {*|boolean}
             * @private
             */
            function _isProducer() {
                var producerRoles = ["INTERNAL", "AGENT", "AGENCY_ADMIN"];
                return sessionData && sessionData.basicInfo && sessionData.basicInfo.role && producerRoles.indexOf(sessionData.basicInfo.role) !== -1;
            };
            function _constructCustomerLookup() {

                if (sessionData.loggedIn) {
                    $scope.table = {
                        "isProducer": _isProducer()
                    };


                    if (sessionData &&
                        sessionData.appConfig &&
                        sessionData.appConfig.SALES &&
                        sessionData.appConfig.SALES.VIEW_CUSTOMER_LOOKUP &&
                        sessionData.appConfig.SALES.VIEW_CUSTOMER_LOOKUP.booleanValue) {

                        if ($scope.table.isProducer) {
                            // Only display table if producer is referencing customer w/ UMI
                            if (sessionData && sessionData.customerLookup) {
                                $scope.umiPresent = true;
                                $scope.table.viewTable = true;
                                prepareLookupData();
                            } else {
                                loginManager.producerAttributeHelper().then(function (producerData) {

                                    if (producerData) {

                                        var prospectData = producerData.prospectData.payload.prospect;

                                        if (prospectData && umiOnProducer) {
                                            var necessaryUmiLength = 13;
                                            if (umiOnProducer.length < necessaryUmiLength) {
                                                var x = umiOnProducer.length;
                                                while (x !== necessaryUmiLength) {
                                                    x++;
                                                    umiOnProducer += "0";
                                                }
                                            }
                                            callCustomerLookup(umiOnProducer);
                                        }
                                    }
                                })
                            }

                            //Show talking points contents based on campaign id / county
                            //only for highmark region
                            if ((window.location.href).lastIndexOf("highmark.com") > 0) {
                                $scope.showTalkingPointSection = false;
                                showTalkingPointContent();
                                if (!($scope.pageContent.talkingPoints.talkingPointContent)) {
                                    $scope.showTalkingPointSection = false;
                                }
                            } else {
                                $scope.showTalkingPointSection = false;
                            }

                        } else {
                            // Only display if consumer has a UMI
                            if (sessionData && sessionData.memberInfo && sessionData.memberInfo.umi) {
                                $scope.umiPresent = true;
                                //call the customer lookup service, and set the response to sessionData to be accessible later
                                $scope.table.viewTable = true;

                                var umiToUse = sessionData.memberInfo.umi;

                                var necessaryUmiLength = 13;
                                if (umiToUse.length < necessaryUmiLength) {
                                    var x = umiToUse.length;
                                    while (x !== necessaryUmiLength) {
                                        x++;
                                        umiToUse += "0";
                                    }
                                }

                                callCustomerLookup(umiToUse);
                            }
                        }
                    }
                }
            }

            function showTalkingPointContent() {
                if (sessionData && sessionData.customerLookup && sessionData.customerLookup.payload && sessionData.customerLookup.payload.customerCrosswalk) {
                    var crosswalkData = sessionData.customerLookup.payload.customerCrosswalk[0];

                    if (crosswalkData.campaign && typeof crosswalkData.campaign !== "undefined") {
                        angular.forEach($scope.pageContent.talkingPoints.UMI.talkingPoint, function (value, key) {
                            if (value.id == crosswalkData.campaign) {
                                $scope.pageContent.talkingPoints.talkingPointContent = value.talkingPointContent;
                            }
                        });
                        if (!($scope.pageContent.talkingPoints.talkingPointContent) && typeof crosswalkData.region !== "undefined" && crosswalkData.region) {
                            bindTalkingPointContent($scope.pageContent.talkingPoints.REGION[crosswalkData.region], crosswalkData.county);
                            if (!($scope.pageContent.talkingPoints.talkingPointContent)) {
                                getApplicantData();
                            }
                        }
                    } else if (typeof crosswalkData.region !== "undefined" && crosswalkData.region) {
                        bindTalkingPointContent($scope.pageContent.talkingPoints.REGION[crosswalkData.region], crosswalkData.county);
                        if (!($scope.pageContent.talkingPoints.talkingPointContent)) {
                            getApplicantData();
                        }
                    } else {
                        console.log("Customer doesn't have crosswalk data");
                        getApplicantData();
                    }
                } else {
                    getApplicantData();
                }
            }

            function getApplicantData() {
                var ratingInfo = ratingInfoModel.get();
                if (typeof ratingInfo.region !== "undefined" && ratingInfo.region) {
                    bindTalkingPointContent($scope.pageContent.talkingPoints.REGION[ratingInfo.region], ratingInfo.county);
                }
            }

            function bindTalkingPointContent(searchContent, toCompare) {
                angular.forEach(searchContent, function (value, key) {
                    if (value.county.toUpperCase() == toCompare.toUpperCase()) {
                        $scope.pageContent.talkingPoints.talkingPointContent = value.talkingPointContent;
                    }
                });
            }

            function order(predicate) {
                $scope.predicate = predicate.value;
                $scope.reverse = predicate.reverse;
                $scope.sortModel = predicate;
            }

            function planFilter(item) {

                var hasMetal = false;
                var inGroup = false;
                var inPriceRange = false;
                var inDeductibleRange = false;
                var inOopRange = false;

                angular.forEach($scope.sidebar.metalCheckModel, function (value, key) {
                    if ((value === true) && !hasMetal) {
                        hasMetal = item.attributes.miscattribute.METAL_LEVEL.value && item.attributes.miscattribute.METAL_LEVEL.value.indexOf(key) > -1;
                    }
                });

                angular.forEach($scope.sidebar.groupCheckModel, function (value, key) {
                    if ((value === true) && !inGroup) {
                        inGroup = item.name && item.name.toUpperCase().indexOf(key.toUpperCase()) > -1;
                    }
                });

                if ((!$scope.sidebar.rangeModel.minCost && !$scope.sidebar.rangeModel.maxCost) ||
                    ($scope.sidebar.rangeModel.minCost <= item.monthlyPremium && item.monthlyPremium <= $scope.sidebar.rangeModel.maxCost)) {
                    inPriceRange = true;
                }

                if (inPriceRange) {
                    if (item.deductible >= $scope.sidebar.rangeModel.minDeductible &&
                        item.deductible <= $scope.sidebar.rangeModel.maxDeductible) {
                        inDeductibleRange = true;
                    } else if (!$scope.sidebar.rangeModel.minDeductible && !$scope.sidebar.rangeModel.maxDeductible) {
                        inDeductibleRange = true;
                    }

                    if (item.oopMax >= $scope.sidebar.rangeModel.minOopMax &&
                        item.oopMax <= $scope.sidebar.rangeModel.maxOopMax) {
                        inOopRange = true;
                    } else if (!$scope.sidebar.rangeModel.minOopMin && !$scope.sidebar.rangeModel.maxOopMax) {
                        inOopRange = true;
                    }
                }

                $scope.sidebar.planCount = $scope.filteredList ? $scope.filteredList.length : 0;

                return hasMetal && inGroup && inPriceRange && inDeductibleRange && inOopRange;
            }

            function comparePlans() {
                if ($scope.compareObject.atOrAboveMinimum && $scope.compareObject.atOrBelowMaximum) {
                    var plansToCompare = [];

                    angular.forEach($scope.planList, function (plan) {
                        if (plan.compareMe) {
                            plansToCompare.push(plan);
                        }
                    });
                    if ($scope.subsidyAmount) {
                        var subsidy;
                        if ($scope.hasReturnedFromFFM) {
                            subsidy = $scope.aptc.aptcSlider;
                        } else {
                            subsidy = $scope.subsidyAmount;
                        }
                        //put in logic here to overwrite premium with adjusted premium w/ a flag to show the disclaimer
                        angular.forEach(plansToCompare, function (value, key) {
                            plansToCompare[key] = $scope.prepareAdjustedPremium(value, parseFloat(subsidy));
                        });
                    }
                    $state.go("findInsurance.compare-plans", {
                        productObjects: plansToCompare
                    });
                }
            }

            function planYearForCompares() {
                /****
                 * Determine the plan year of the first selected plan for comparison
                 * this is used later to prevent comparison between plans of differing years
                 * see high-level-plan-details
                 ****/
                for (var planIndex = 0; planIndex < $scope.planList.length; planIndex++) {
                    if ($scope.planList[planIndex].compareMe) {
                        return $scope.planList[planIndex].planYear;
                    }
                }
                return null;
            }

            function atComparableLimit() {
                // configured true/false/high/low/metric tracker for comparing plans and displaying info
                var activeCompares = 0;

                angular.forEach($scope.planList, function (plan) {
                    if (plan.compareMe) {
                        activeCompares++;
                    }
                });

                // return for check
                if (activeCompares >= $scope.compareObject.comparableMinimum && activeCompares <= $scope.compareObject.comparableLimit) {

                    // OK range

                    $scope.compareObject["atOrBelowMaximum"] = true;
                    $scope.compareObject["atOrAboveMinimum"] = true;
                    $scope.compareObject["activeCompares"] = activeCompares;

                    if (activeCompares === $scope.compareObject.comparableLimit) {
                        // AT limit
                        return true;
                    } else {
                        // BELOW limit
                        return false;
                    }
                } else {
                    // TOO MANY or TOO FEW
                    if (activeCompares >= $scope.compareObject.comparableMinimum) {
                        // TOO MANY
                        $scope.compareObject["atOrAboveMinimum"] = true;
                        $scope.compareObject["atOrBelowMaximum"] = false;
                        $scope.compareObject["activeCompares"] = activeCompares;

                        return true;
                    }
                    if (activeCompares <= $scope.compareObject.comparableLimit) {
                        // TOO FEW
                        $scope.compareObject["atOrBelowMaximum"] = true;
                        $scope.compareObject["atOrAboveMinimum"] = false;
                        $scope.compareObject["activeCompares"] = activeCompares;
                    }


                    return false;
                }
            }

            function prepareAdjustedPremium(plan, subsidyValue) {
                var subsidy;
                if (subsidyValue) {
                    subsidy = parseFloat(subsidyValue);
                }
                // var newPlan = angular.copy(plan);
                if ($scope.hasReturnedFromFFM) {
                    plan.adjustedPremiumDisclaimer = "(*) " + $scope.pageContent.viewPlansFindInsurance.adjustedPremiumDisclaimers.postFFM;
                    if (!subsidyValue) {
                        subsidy = $scope.aptc.aptcSlider;
                    }
                } else {
                    plan.adjustedPremiumDisclaimer = "(*) " + $scope.pageContent.viewPlansFindInsurance.adjustedPremiumDisclaimers.preFFM;
                    if (!subsidyValue) {
                        subsidy = parseFloat($scope.subsidyAmount);
                    }
                }
                var aptc = parseFloat(subsidy.toFixed(2));
                plan.monthlyAdjustedPremium = parseFloat((plan.monthlyPremium - aptc).toFixed(2));
                if (plan.monthlyAdjustedPremium < 0) {
                    plan.monthlyAdjustedPremium = 0;
                }
                plan.monthlyAdjustedPremiumFormatted = "$" + (plan.monthlyAdjustedPremium).toFixed(2);

                plan.annualAdjustedPremium = parseFloat((plan.monthlyAdjustedPremium * 12).toFixed(2));
                plan.annualAdjustedPremiumFormatted = "$" + (plan.annualAdjustedPremium).toFixed(2);

                plan.featuresAdjustedPremium = true;
                return plan;
            }

            function goToPrintSummary() {
                //replace premium with adjusted premium IF AVAILABLE
                if ($scope.subsidyAmount) {
                    var subsidy;
                    if ($scope.hasReturnedFromFFM) {
                        subsidy = $scope.aptc.aptcSlider;
                    } else {
                        subsidy = $scope.subsidyAmount;
                    }
                    var planListCopy = angular.copy($scope.planList);
                    angular.forEach(planListCopy, function (value, key) {
                        planListCopy[key] = $scope.prepareAdjustedPremium(value, parseFloat(subsidy));
                    });
                    $state.go("findInsurance.view-plans-grid", {
                        "products": planListCopy
                    });
                } else {
                    $state.go("findInsurance.view-plans-grid", {
                        "products": $scope.planList
                    });
                }
            }


            /****
             * Utility Functions
             ****/
            var _sideBarNarrowByGroupForRegion = function (brand) {
                return {
                    "header": "Narrow by Product Group",
                    "checkList": filterAvailableOptionsByReturnedProducts([
                        { "name": "Balance Blue" },
                        { "name": "BlueAccess HSA Bronze" },
                        { "name": "Care Guide Blue" },
                        { "name": "Comprehensive Care Blue" },
                        { "name": "Direct Blue Conemaugh" },
                        { "name": "Flex Blue" },
                        { "name": "Health Savings Embedded Blue" },
                        { "name": "Health Savings" },
                        { "name": "Heath Savings Blue" },
                        { "name": "Major Events Blue" },
                        { "name": "Major Events" },
                        { "name": "My Blue Access PPO" },
                        { "name": "My Blue Access WV EPO" },
                        { "name": "my Connect Blue" },
                        { "name": "My Direct Blue" },
                        { "name": "My Lehigh Valley Flex Blue" },
                        { "name": "my Lehigh Valley" },
                        { "name": "My Priority Blue Flex" },
                        { "name": "My Priority Blue" },
                        { "name": "myBlue Access" },
                        { "name": "Patient Centered Medical Home" },
                        { "name": "Shared Cost Blue" },
                        { "name": "Shared Cost" },
                        { "name": "Together Blue" },
                        { "name": "My Blue Access" }

                    ]),
                    "otherCheckList": [
                        { "name": "Health Savings Account Eligible (HSA)" }
                    ],
                    "other": "Other"
                };

                /****
                 * Pass in possible values [{"name":"example"}]
                 * Return only those which were actually going to be associated w/ returned plans
                 ****/
                function filterAvailableOptionsByReturnedProducts(planValueOptions) {
                    var filteredList = [];

                    angular.forEach(planValueOptions, function (possiblePlanValue) {

                        var alreadyMatched = false;

                        angular.forEach($scope.planList, function (plan) {
                            if (!alreadyMatched && plan.name.toUpperCase().indexOf(possiblePlanValue["name"].toUpperCase()) !== -1) {
                                alreadyMatched = true;
                                filteredList.push(possiblePlanValue);
                            }
                        });
                    });

                    return filteredList;
                }

            };

            var _configureSideBar = function () {
                /****
                 * Use Rating Info payload values to structure meta info for application
                 ****/
                // var ratingInfoPayload = storage.get("ratingInfoPayload");
                var ratingInfoPayload = ratingInfoModel.get();
                var effectiveDateYear = ratingInfoPayload.effectiveDate.split("-")[0];

                var viewPlansSideBarConfigObj = {
                    "isOpen": true,
                    "application": {
                        "zip": ratingInfoPayload.zipCode,
                        "county": ratingInfoPayload.county,
                        "effectiveDate": ratingInfoPayload.effectiveDate,
                        "coverageFor": (function getNumberOfAdultsAndChildren() {

                            var numberOfAdults = 0;
                            var numberOfChildren = 0;

                            var adultLabel = "Adult";
                            var childLabel = "Child";

                            // Determine number of adults vs children
                            angular.forEach(ratingInfoPayload.applicants, function (person) {
                                var personAge = new Date(person.dob);

                                if (hmShopDateAndTimeUtility.isDateFromTodayYoungerOrOlder(personAge, 18, "older")) {
                                    numberOfAdults++;
                                    if (numberOfAdults > 1) {
                                        adultLabel = "Adults"
                                    }
                                } else {
                                    numberOfChildren++;
                                    if (numberOfChildren > 1) {
                                        childLabel = "Children"
                                    }
                                }
                            });

                            return (function constructureReturnString() {
                                var adultString = numberOfAdults + " " + adultLabel;
                                var childString = numberOfChildren + " " + childLabel;

                                if (numberOfChildren > 0) {
                                    return adultString + ", " + childString;
                                } else {
                                    return adultString
                                }

                            })();
                        })()
                    },
                    "metalCheckModel": { "Catastrophic": false },
                    "groupCheckModel": {},
                    "rangeModel": {
                        "minDeductible": $scope.minDeductible,
                        "maxDeductible": $scope.maxDeductible,
                        "minOopMax": $scope.minOopMax,
                        "maxOopMax": $scope.maxOopMax
                    },
                    "narrowByGroup": _sideBarNarrowByGroupForRegion(brand.current.sharedBrandCode),
                    "narrowByCoverage": {
                        "header": "Narrow by level of coverage",
                        "subtitle": "Narrow by level of coverage",
                        "checkList": [
                            { "name": "Catastrophic", "img": "../bootstrap/resources/img/icons/16px/16__Catastrophic.png" },
                            { "name": "Bronze", "img": "../bootstrap/resources/img/icons/16px/16__Bronze.png" },
                            { "name": "Silver", "img": "../bootstrap/resources/img/icons/16px/16__Silver.png" },
                            { "name": "Gold", "img": "../bootstrap/resources/img/icons/16px/16__Gold.png" },
                            { "name": "Platinum", "img": "../bootstrap/resources/img/icons/16px/16__Platinum.png" }
                        ]
                    },
                    "links": [
                        {
                            "heading": "",
                            "label": "Find Your Plans",
                            "state": "findInsurance.view-plans",
                            "description": "",
                            "refineTemplate": "pages/findInsurance/view-plans.refine.html"
                        },
                        {
                            "heading": $scope.pageContent.viewPlansFindInsurance.leftNavLinks[1]["text"],
                            "label": $scope.pageContent.viewPlansFindInsurance.leftNavLinks[1]["text"],
                            "href": $scope.pageContent.viewPlansFindInsurance.leftNavLinks[1]["URL"],
                            "newWindow": $scope.pageContent.viewPlansFindInsurance.leftNavLinks[1]["newWindow"]
                        },
                        {
                            "heading": $scope.pageContent.viewPlansFindInsurance.leftNavLinks[2]["text"],
                            "label": $scope.pageContent.viewPlansFindInsurance.leftNavLinks[2]["text"],
                            "href": $scope.pageContent.viewPlansFindInsurance.leftNavLinks[2]["URL"],
                            "newWindow": $scope.pageContent.viewPlansFindInsurance.leftNavLinks[2]["newWindow"]
                        },
                        {
                            "heading": $scope.pageContent.viewPlansFindInsurance.leftNavLinks[3]["text"],
                            "label": $scope.pageContent.viewPlansFindInsurance.leftNavLinks[3]["text"],
                            "href": $scope.pageContent.viewPlansFindInsurance.leftNavLinks[3]["URL"],
                            "newWindow": $scope.pageContent.viewPlansFindInsurance.leftNavLinks[3]["newWindow"]
                        },
                        {
                            "heading": "Plan Brochure",
                            "label": "Plan Brochure",
                            "href": effectiveDateYear === "2019" ? "https://discoverhighmark.com/individuals-families/content/pdfs/brochures/WPA" + effectiveDateYear + "_ProductBrochure.pdf" : $scope.pageContent.viewPlansFindInsurance.leftNavLinks[4].URL.replace('{{effectiveDateYear}}', effectiveDateYear),
                            "description": "You can view information about different plans and their agreements by clicking the region needed!",
                            "newWindow": true,
                            "hide": ["unbrandedhm", "hmbcbs", "hbcbs", "highmarkbcbs"].indexOf(brand.current.sharedBrandCode.toLowerCase()) === -1,
                            "filterData": {
                                "brand": ["unbrandedhm", "hmbcbs", "hbcbs", "highmarkbcbs"]
                            }
                        },
                        {
                            "heading": "Plan Brochure",
                            "label": "Plan Brochure",
                            "href": effectiveDateYear === "2019" ? "https://discoverhighmark.com/individuals-families/content/pdfs/brochures/DE" + effectiveDateYear + "_ProductBrochure.pdf" : $scope.pageContent.viewPlansFindInsurance.leftNavLinks[4].URL.replace('{{effectiveDateYear}}', effectiveDateYear),
                            "description": "You can view information about different plans and their agreements by clicking the region needed!",
                            "newWindow": true,
                            "hide": ["hmbcbsde", "highmarkbcbsde", "de"].indexOf(brand.current.sharedBrandCode.toLowerCase()) === -1,
                            "filterData": {
                                "brand": ["hmbcbsde", "highmarkbcbsde", "de"]
                            }
                        },
                        {
                            "heading": "Plan Brochure",
                            "label": "Plan Brochure",
                            "href": effectiveDateYear === "2019" ? "https://discoverhighmark.com/individuals-families/content/pdfs/brochures/CPA" + effectiveDateYear + "_ProductBrochure.pdf" : $scope.pageContent.viewPlansFindInsurance.leftNavLinks[4].URL.replace('{{effectiveDateYear}}', effectiveDateYear),
                            "description": "You can view information about different plans and their agreements by clicking the region needed!",
                            "newWindow": true,
                            "hide": ["hbs", "pbs", "hmbs"].indexOf(brand.current.sharedBrandCode.toLowerCase()) === -1,
                            "filterData": {
                                "brand": ["hbs", "pbs", "hmbs"]
                            }
                        },
                        {
                            "heading": "Plan Brochure",
                            "label": "Plan Brochure",
                            "href": effectiveDateYear === "2019" ? "https://discoverhighmark.com/individuals-families/content/pdfs/brochures/NEPA" + effectiveDateYear + "_ProductBrochure.pdf" : $scope.pageContent.viewPlansFindInsurance.leftNavLinks[4].URL.replace('{{effectiveDateYear}}', effectiveDateYear),
                            "description": "You can view information about different plans and their agreements by clicking the region needed!",
                            "newWindow": true,
                            "hide": ["nepa"].indexOf(brand.current.sharedBrandCode.toLowerCase()) === -1,
                            "filterData": {
                                "brand": ["nepa"]
                            }
                        },
                        {
                            "heading": "Plan Brochure",
                            "label": "Plan Brochure",
                            "href": effectiveDateYear === "2019" ? "https://discoverhighmark.com/individuals-families/content/pdfs/brochures/WV" + effectiveDateYear + "_ProductBrochure.pdf" : $scope.pageContent.viewPlansFindInsurance.leftNavLinks[4].URL.replace('{{effectiveDateYear}}', effectiveDateYear),
                            "description": "You can view information about different plans and their agreements by clicking the region needed!",
                            "newWindow": true,
                            "hide": ["hmbcbswv", "ms", "highmarkbcbswv"].indexOf(brand.current.sharedBrandCode.toLowerCase()) === -1,
                            "filterData": {
                                "brand": ["hmbcbswv", "ms", "highmarkbcbswv"]
                            }
                        }
                    ],
                    "goToFindAProvider": function () {
                        $state.go("findInsurance.find-a-provider-or-drug");
                    }
                };


                /****
                 * Sidebar already exists, merge in additional data
                 ****/
                console.log("sidebar extended");
                angular.extend($scope.sidebar, viewPlansSideBarConfigObj);


                /****
                 * Check all sidebar CHECKBOXES by....
                 ****/
                // ...PLAN NAME
                angular.forEach($scope.sidebar.narrowByGroup, function (value, key) {
                    angular.forEach($scope.sidebar.narrowByGroup[key], function (planForFilter) {
                        $scope.sidebar.groupCheckModel[planForFilter.name] = true;
                    });
                });

                var areAllApplicantsUnderThirty = (function determineIfAllApplicantsAreYoungerThan30() {
                    var applicantsOnRatingInfo = ratingInfoPayload.applicants;

                    for (var applicantIndex = 0; applicantIndex < applicantsOnRatingInfo.length; applicantIndex++) {

                        var formattedDOB = new Date(applicantsOnRatingInfo[applicantIndex].dob);

                        if (hmShopDateAndTimeUtility.isDateFromTodayYoungerOrOlder(formattedDOB, 30, "older")) {
                            return false;
                        }
                    }
                    console.log("all applicants younger than 30, this will force catastrophic plans to be checked by default")
                    return true;
                })();

                // ...METAL LEVEL
                angular.forEach($scope.sidebar.narrowByCoverage.checkList, function (value) {
                    if (value.name.toUpperCase() !== "CATASTROPHIC" || areAllApplicantsUnderThirty) {
                        $scope.sidebar.metalCheckModel[value.name] = true;
                    } else {
                        $scope.sidebar.metalCheckModel[value.name] = false;
                    }
                });

                $scope.order($scope.dropdownOptions[0]);
            };

            /****
             * Use Plans' planNames and Selected ProviderIds to determined covered status.
             * Append Data to planList objects
             ****/

            var _configureProviderCoverageForTransformedPlans = function (noCache) {
                if (storage.get("selectedProviders") && storage.get("selectedProviders").length !== 0) {
                    // added the param to send isVitalsLive flag into the coveredPlansByProviders method
                    coveredProvidersUtility
                        .coveredPlansByProviders($scope.planList, storage.get("selectedProviders"), noCache, $scope.isVitalsLive)
                        .then(function (allProvidersContent) {
                            storage.set("selectedProvidersDeltaExists", false)
                            console.log("ALL PROVIDERS SEARCHED");
                            angular.forEach($scope.planList, function (plan) {
                                plan.providerCoverage = {};

                                angular.forEach(allProvidersContent, function (providerDetails) {
                                    /***
                                     * Provider details returned
                                     ****/

                                    var isPlanAccepted = false;

                                    if (providerDetails.plansAccepted) {

                                        angular.forEach(providerDetails.plansAccepted, function (acceptedPlan) {
                                            var planName = "",
                                                blnSDPlan = false;
                                            // We needed extra data from the accepted plan than just the name
                                            // so we have to test whether the returned item is a string or an array
                                            blnSDPlan = typeof (acceptedPlan) !== "string";
                                            this.planName = (blnSDPlan) ? acceptedPlan.name.trim().toUpperCase() : acceptedPlan.trim().toUpperCase();
                                            // if this planName matches the plan we're looking over, then that means it's accepted by this provider
                                            // planName is from Sapphire
                                            if (this.planName.toUpperCase() === plan.attributes.miscattribute.PROVIDER_PLAN_NAME.value.trim().toUpperCase()) {

                                                isPlanAccepted = true;

                                                if (blnSDPlan) {
                                                    // and if this is a SD call, we also need the networkId to be attached to the plan
                                                    plan.networkId = acceptedPlan.networkId;
                                                }
                                            }
                                        });

                                        if (isPlanAccepted) {
                                            if (!plan.providerCoverage["providersAvailable"]) {
                                                plan.providerCoverage["providersAvailable"] = [];
                                            }
                                            plan.providerCoverage["providersAvailable"].push(providerDetails);
                                        } else {
                                            if (!plan.providerCoverage["providersUnavailable"]) {
                                                plan.providerCoverage["providersUnavailable"] = [];
                                            }
                                            plan.providerCoverage["providersUnavailable"].push(providerDetails);
                                        }
                                    }
                                    /****
                                     * Provider details not returned
                                     ****/
                                    else {
                                        if (!plan.providerCoverage["PROVIDERS_NODATARETURNED"]) {
                                            plan.providerCoverage["PROVIDERS_NODATARETURNED"] = [];
                                        }
                                        plan.providerCoverage["PROVIDERS_NODATARETURNED"].push(providerDetails);
                                    }

                                });
                            });
                        })
                } else {
                    angular.forEach($scope.planList, function (plan) {
                        plan.prescriptionDrugsCovered = null;
                    });
                }
            };

            /****
             * Use Plans' HIOSids and Selected Prescription Drug RXCUIs to determined covered status.
             * Append Data to planList objects
             ****/
            var _configureDrugCoverageForTransformedPlans = function () {
                if (storage.get("selectedPrescriptionDrugs") && storage.get("selectedPrescriptionDrugs").length !== 0) {
                    coveredPrescriptionDrugsUtility.coveredDrugsByPlanListAndSelectedDrugs($scope.planList, storage.get("selectedPrescriptionDrugs")).then(
                        function (coverageInformation) {
                            if (coverageInformation != null) {
                                angular.forEach($scope.planList, function (planListItem) {
                                    var planListItemHiosIdWithoutCSR = planListItem.hiosId.split("-")[0];
                                    planListItem.prescriptionDrugsCovered = coverageInformation[planListItemHiosIdWithoutCSR];

                                    angular.forEach(planListItem.prescriptionDrugsCovered, function (value, key) {
                                        angular.forEach(value, function (individualDrugCoverageItem) {
                                            angular.forEach(storage.get("selectedPrescriptionDrugs"), function (selectedDrug) {

                                                if (selectedDrug.rxcui === individualDrugCoverageItem.rxcui) {
                                                    individualDrugCoverageItem.name = selectedDrug.name;
                                                    individualDrugCoverageItem.full_name = selectedDrug.full_name;
                                                }
                                            })
                                        })
                                    })
                                });
                            }
                        }, function (failedResponse) {
                            angular.forEach($scope.planList, function (planListItem) {
                                planListItem.prescriptionDrugsCovered = "service error";
                            })

                        });
                } else {
                    angular.forEach($scope.planList, function (plan) {
                        plan.prescriptionDrugsCovered = null;
                    })
                }
            };

            var _configureFindAProviderButtonDisplay = function () {
                $scope.sidebar.showFindAProviderButton = sessionData && sessionData.appConfig && sessionData.appConfig.SALES && sessionData.appConfig.SALES.SHOULDDISPLAY_FINDAPROVIDER_BUTTON && sessionData.appConfig.SALES.SHOULDDISPLAY_FINDAPROVIDER_BUTTON.booleanValue ? sessionData.appConfig.SALES.SHOULDDISPLAY_FINDAPROVIDER_BUTTON.booleanValue : true;
                // I had to set up this call to sessionData here because it doesn't appear to be populated until this point.
                // TODO: There has to be a better way to make sure this happens before anything else. Right now this is a race condition waiting to happen
                $scope.isVitalsLive = (sessionData && sessionData.appConfig && sessionData.appConfig.PROVIDER && sessionData.appConfig.PROVIDER.VITALS_LIVE && sessionData.appConfig.PROVIDER.VITALS_LIVE.booleanValue) &&
                    brand.current.isVitalsActive;
            };
            //same as _formatOnExchangePostData in HCR productsManager.js
            var _formatFfmReturnPostData = function (countyData, onExchangeData) {
                // brand: "HMBCBS"
                // code: 3
                // county: "ALLEGHENY"
                // ffmRatingArea: "PA004"
                // fipsCode: "42003"
                // hhicServiceZone: "C"
                // hmServiceZone: "A"
                // microMarket: "Region1"
                // ratingArea: 4
                // ratingRegion: ""
                // region: "WPA"
                // state: "PA"
                // zipCode: '15222'



                //top level post data
                var effDateArray = onExchangeData.effectiveDate.split('/'); //mm-dd-yyyy
                var effDate = effDateArray[2] + '-' + effDateArray[0] + '-' + effDateArray[1]  //yyyy-mm-dd
                var appArray = [];
                var ellLevel = '1'; //must be single digit format for the service

                //use the CSR value at the root of the data model
                if (onExchangeData.CSR) {
                    ellLevel = String(parseInt(onExchangeData.CSRLevel));
                }

                //first, need to find primary person's index, everything has to be based off the index
                //this is being done in two loops to make maintenance easier. originally was in one loop, but that started to have
                //issues when we encountered different FFM data
                var primaryIndex = 0;
                for (var i = 0; i < onExchangeData.applicants.length; i++) {
                    if (onExchangeData.applicants[i].isEligible && onExchangeData.applicants[i].isPrimary) {
                        primaryIndex = i;
                    }
                }

                //iterate over applicants to format the appArray
                for (var i = 0; i < onExchangeData.applicants.length; i++) {
                    if (onExchangeData.applicants[i].isEligible) {
                        //collect dob for each applicant
                        var dobSplit = onExchangeData.applicants[i].dateOfBirth.split("/"); // mm/dd/yyyy
                        var formattedDob = dobSplit[2] + '-' + dobSplit[0] + '-' + dobSplit[1];

                        var relationshipValue = '';
                        if (onExchangeData.applicants[i].isPrimary) {
                            //this index is the primary applicant, set eligibility level and relationship value
                            console.log('primary applicants csr level', onExchangeData.applicants[i].CSRLevel);
                            //populate top level variable for eligibilty level
                            //if CSR is false, set to '01'
                            // if(onExchangeData.applicants[i].CSR){
                            // 	ellLevel = String(parseInt(onExchangeData.applicants[i].CSRLevel));
                            // }else{
                            // 	ellLevel = '1'; //must be single digit format for the service
                            // }
                            // console.log('ellLevel ', ellLevel);
                            relationshipValue = 'Subscriber';
                        } else {
                            //non primary applicants must use the primary applicant's index to fetch the familyCodes array
                            angular.forEach(onExchangeData.applicants[primaryIndex].familyCodes, function (familyCode) {
                                if (onExchangeData.applicants[i].personID === familyCode.person) {
                                    if (familyCode.codeValue === '01') {
                                        relationshipValue = 'Spouse';
                                    } else {
                                        relationshipValue = 'Dependent';
                                    }
                                }
                            });
                        }

                        appArray.push({
                            name: onExchangeData.applicants[i].fullName.firstName,
                            dob: formattedDob,
                            tobaccoStatus: onExchangeData.applicants[i].tobaccoInd,
                            relationship: relationshipValue
                        });
                    }

                }

                var retVal = {
                    userInfo: [{
                        zipCode: countyData.zipCode,
                        county: countyData.county,
                        region: countyData.region,
                        state: countyData.state,
                        serviceZones: countyData.serviceZones,
                        ratingArea: String(countyData.ratingArea),
                        effectiveDate: effDate,
                        eligibilityLevel: ellLevel,
                        applicants: appArray
                    }]

                };
                return retVal;

            }

            var _getOffExchangePayload = function () {

                /** County Info */
                var modelVal = ratingInfoModel.get();
                var storageVal = storage.get('ratingInfoPayload');
                var ratingInfo = modelVal || storageVal;

                /** Family Info */
                var familyInfo = storage.get('familyRatingInfo');
                var applicantInfo = (function getApplicantInfo() {
                    dateConversion = $filter('dateConversion');
                    var applicants = [];
                    
                    // iterating through family members to shape the array structure for payload
                    angular.forEach(familyInfo.family.members, function (member) {
                        var applicant = {
                            dob: dateConversion(member.dob, "yyyy-mm-dd"), // correcting d.o.b. to match required payload format
                            tobaccoStatus: member.tobaccoInd,
                            relationship: member.relationship.toUpperCase() === "DOMESTIC PARTNER" ? "Spouse" : member.relationship
                        }
                        applicants.push(applicant);
                    })
                    return applicants;
                })();

                // constant values for payload contstruction
                var market = 'Individual';
                var version = 'LEGACY';
                var productGroup = 'Off-Exchange';

                var effectiveDateInfo = storage.get('selectedEffectiveDateISO8601');
                var effectiveDateYear = effectiveDateInfo.substring(0, 4);

                var serviceZoneForEffectiveDateYear = (function configureServiceZones() {
                    var serviceZones = [];

                    // create substring of effectivedate to only grab the effective year
                    var effectiveDateYear = familyInfo.family.consumerEffectiveDate.substring(
                        familyInfo.family.consumerEffectiveDate.length - 4
                    );
                    familyInfo.effectiveDateYear = effectiveDateYear;
                    
                    // logic to only filter service zones by effective year for PA products
                    // non-PA keeps original service zone values
                    if (
                        familyInfo.countyInfo.state === 'PA' &&
                        familyInfo.countyInfo.serviceZones &&
                        familyInfo.countyInfo.serviceZonesByPlanYear[effectiveDateYear]
                    ) {
                        serviceZones = familyInfo.countyInfo.serviceZonesByPlanYear[effectiveDateYear];
                    }


                    // grabbing just the value for the service zone  
                    var parsedServiceZones = [];
                    serviceZones.forEach(function (item) {
                        parsedServiceZones.push(item.value);
                    });

                    return parsedServiceZones;
                })();

                var aspId = (function getASPId() {
                    var aspHostId = window.location.hostname.split('.')[1]
                    if (aspHostId.toUpperCase().indexOf('HIGHMARK') !== -1) {
                        return 'HM';
                    } else if (aspHostId.toUpperCase().indexOf('YOURWYOBLUE') !== -1) {
                        return 'WY'; // or WYOMING?
                    } else if (aspHostId.toUpperCase().indexOf('BLUECROSSMNONLINE') !== -1) {
                        return 'MN'; // or MINCR?
                    } else if (aspHostId.toUpperCase().indexOf('BCBSNDPORTALS') !== -1) {
                        return 'ND'; // or NDBCBS?
                    }
                })();

                var decodedDDToken = (function () {
                    if ($cookies.get('ddToken')) {
                        return window.atob($cookies.get('ddToken'));
                    }
                    return null;
                })();

                var retVal = {
                    version: version,
                    aspId: aspId,
                    market: market,
                    year: effectiveDateYear,
                    region: familyInfo.countyInfo.region,
                    serviceZone: serviceZoneForEffectiveDateYear,
                    ratingArea: familyInfo.countyInfo.ratingArea,
                    effectiveDate: dateConversion(familyInfo.family.consumerEffectiveDate, "yyyy-mm-dd"),
                    ddToken: decodedDDToken,
                    applicants: applicantInfo,
                    attributes: {
                        product_group: productGroup,
                    }

                }

                return retVal;
            }

            var _getUntransformedProducts = function (noCache) {
                var productServiceDeferred = $q.defer();
                var onExchangeObj = storage.get("onExchangeObject");

                $scope.showLoadingIndicator = true;

                if (noCache) {

                    if (onExchangeObj && onExchangeObj.isOnExchange) {

                        if (!onExchangeObj.hasReturnedFromOnExchange) {
                            var requestPayload = {
                                "userInfo": []
                            };

                            localStorage.setItem("taxCreditInfo", JSON.stringify(ratingInfoModel.get()));

                            requestPayload.userInfo.push(ratingInfoModel.get());
                            requestPayload.userInfo[0].eligibilityLevel = onExchangeObj.eligibilityLevel || "1";
                            console.log("init get products")
                            services.wcs.ratingInfoService({ "storeId": brand.current.storeId }).putRatingInfoService(ratingInfoModel.get()).then(function () {
                                services
                                    .wcs
                                    .onExchangeProductService({ "storeId": brand.current.storeId })
                                    .getProducts(requestPayload)
                                    .then(function (productResponse) {
                                        console.log("ON-EXCHANGE service called", productResponse);
                                        productServiceDeferred.resolve(productResponse.data);
                                    });
                            });
                        } else {
                            if (sessionData.loggedIn) {

                                //get ratingInfoPayload and data from FFM to accurately construct payload for onExchange
                                var ffmPayload = _formatFfmReturnPostData(ratingInfoModel.get(), onExchangeObj.data);

                                console.log("init get products")
                                services
                                    .wcs
                                    .onExchangeProductService({ "storeId": brand.current.storeId })
                                    .getProducts(ffmPayload)
                                    .then(function (productResponse) {
                                        console.log("ON-EXCHANGE service called", productResponse)
                                        productServiceDeferred.resolve(productResponse.data);
                                    });
                            } else {
                                productServiceDeferred.resolve([]);
                            }
                        }
                    } else {
                        if (
                            storage.get(SALES_CONSTANTS.commerce.useProductService)
                        ) {
                            services
                                .wcs
                                .ratingInfoService({ "storeId": brand.current.storeId })
                                .putRatingInfoService(ratingInfoModel.get())
                                .then(function () {
                                    services
                                        .wcs
                                        .offExchangeProductService({ "storeId": brand.current.storeId })
                                        .getProducts()
                                        .then(function (productResponse) {
                                            console.log("OFF-EXCHANGE service called", productResponse)
                                            productServiceDeferred.resolve(productResponse.data);
                                        });
                            });
                        } else {
                            services
                                .gcp
                                .offExchangeProductService_GCP()
                                .getProducts(_getOffExchangePayload())
                                .then(function (productResponse) {
                                    console.log("OFF-EXCHANGE service called", productResponse)
                                    productServiceDeferred.resolve(productResponse.data);
                                });
                        }
                    }
                } else {
                    if (planDataUtility.cachedProducts) {
                        productServiceDeferred.resolve(planDataUtility.cachedProducts);
                    } else {
                        _getUntransformedProducts(true)
                            .then(function (productResponse) {
                                productServiceDeferred.resolve(productResponse);
                            });
                    }
                }

                return productServiceDeferred.promise;
            };

            var _configureOnExchangeTaxCreditInfo = function () {
                if (storage.get("onExchangeObject") && storage.get("onExchangeObject").isOnExchange) {
                    // var ratingInfoPayload   = storage.get("ratingInfoPayload");
                    var ratingInfoPayload = ratingInfoModel.get();
                    var subsidyResponse = storage.get("subsidyResponse");

                    if (ratingInfoPayload) {
                        if (subsidyResponse) {
                            $scope.subsidyAmount = subsidyResponse.subsidyAmount;
                            $scope.eligibilityLevel = subsidyResponse.eligibilityLevel;
                            $scope.federalPovertyLevel = Math.round(subsidyResponse.federalPovertyLevel);
                        }
                    }
                }
            };

            var _configureAptcData = function () {
                var onExchangeObject = storage.get("onExchangeObject");

                if (onExchangeObject && onExchangeObject.hasReturnedFromOnExchange && onExchangeObject.data && onExchangeObject.data.APTC) {
                    $scope.viewAptcSlider = true;
                    //pull the maximum aptc value from the onExchangeObject
                    var maxAptc = parseFloat(onExchangeObject.data.APTCMonthly);

                    $scope.aptc = {
                        showSlider: true,
                        towardsMonthlyPremiums: "$" + (maxAptc * 12).toFixed(2),
                        aptcSlider: maxAptc,
                        aptcInput: "$" + maxAptc.toFixed(2),
                        aptcSliderString: maxAptc.toFixed(2),
                        aptcSliderMax: maxAptc,
                        monthlyAptc: "$" + maxAptc.toFixed(2),
                        totalAptc: "$" + (maxAptc * 12).toFixed(2),
                        federalTaxCreditReturn: "+ $0.00",
                        tooltip: "This is an Advanced Premium Tax Credit (APTC) that can be used right away, " +
                            "or saved for later. The tax credit can be used to lower the monthly premium cost of an individual, " +
                            "metal level qualified health plan.  The applicant(s) can choose how much of the credit to app" +
                            "ly toward the monthly premium.",
                    };

                    $scope.subsidyAmount = $scope.aptc.aptcSlider;
                } else {
                    if (onExchangeObject && onExchangeObject.hasReturnedFromOnExchange && onExchangeObject.data && !onExchangeObject.data.APTC) {
                        $scope.forceSuppressAptcTooltip = true;
                    }
                }
            };

            var _configureReturnFromFFM = function () {
                var ffmObject = localStorage.getItem("ffmObject") || storage.get("ffmObject"); // get from local storage, or storage (for page refresh)

                if (ffmObject) {
                    //remove ffmObject from localStorage if it's present on return from 2.5 ffm
                    localStorage.removeItem("ffmObject");
                    storage.set("ffmObject", ffmObject);

                    var onExchangeObject = JSON.parse(ffmObject);
                    onExchangeObject.hasReturnedFromOnExchange = true;
                    $rootScope.isOnX = true;

                    //set it to storage for easier access later
                    storage.set("onExchangeObject", onExchangeObject);


                    var ratingInfoPayload = localStorage.getItem("taxCreditInfo") || storage.get("taxCreditInfo"); // get from local storage, or storage (for page refresh)

                    if (!ratingInfoPayload) {
                        ratingInfoPayload = localStorage.getItem("ratingInfoFrom2Dot5") || storage.get("ratingInfoFrom2Dot5"); // get from local storage, or storage (for page refresh)
                    }

                    if (ratingInfoPayload) {

                        // storage.set("ratingInfoPayload", JSON.parse(ratingInfoPayload));
                        ratingInfoModel.set(JSON.parse(ratingInfoPayload));
                        localStorage.removeItem("taxCreditInfo");
                        localStorage.removeItem("ratingInfoFrom2Dot5");

                        // Get App Config Values
                        services
                            .cmcrst
                            .countyList()
                            // .postData({"zipCode": storage.get("ratingInfoPayload").zipCode})
                            .postData({ "zipCode": ratingInfoModel.get().zipCode })
                            .then(function (countyResponse) {
                                var countyList = countyResponse.data.payload.countyList;
                                angular.forEach(countyList, function (countyObj) {
                                    // if (countyObj.county.toUpperCase() === storage.get("ratingInfoPayload").county) {
                                    if (countyObj.county.toUpperCase() === ratingInfoModel.get().county) {
                                        brand.override = countyObj.brand;
                                        brandConfig.update();
                                        appConfig.getAll().then(function (response) {
                                            sessionData.appConfig = angular.extend({}, response);
                                        });
                                    }
                                });
                            });
                    }
                }
                if (ffmObject) {
                    return true;
                } else {
                    return false;
                }
            };

            var _configureAdjustedPremiumFlags = function (ffmFlag) {
                $scope.hasReturnedFromFFM = ffmFlag;
                //TODO: make choice of year dynamic when more info is available
                // var ratingInfoPayload = storage.get("ratingInfoPayload");
                var ratingInfoPayload = ratingInfoModel.get();
                //right now, hardcoded to show sbc pdf only if it is 2018
                var effectiveDateYear = ratingInfoPayload.effectiveDate.split("-")[0];
                $scope.is2018Plan = (effectiveDateYear == "2018");
            };

            var _configureFooterBranding = function () {
                $scope.pageContent = pageContent;
                switch (brand.current.sharedBrandCode.toUpperCase()) {
                    //case 'hmbcbs':
                    case "NEPA":
                    case "HMBCBS":
                        pageContent.viewPlansFindInsurance.companyName = 'Highmark Blue Cross Blue Shield';
                        break;
                    //case 'pbs':
                    //case 'hmbs':
                    case "PBS":
                    case "HBS":
                    case "HMBS":

                        pageContent.viewPlansFindInsurance.companyName = 'Highmark Select Resources';
                        break;
                    //case 'hmbcbswv':
                    case "HMBCBSWV":
                        pageContent.viewPlansFindInsurance.companyName = 'Highmark Blue Cross Blue Shield West Virginia';
                        break;
                    //case 'hmbcbsde':
                    case "HMBCBSDE":
                        pageContent.viewPlansFindInsurance.companyName = 'Highmark Blue Cross Blue Shield Delaware';
                        break;
                    case "WYBCBS":
                        pageContent.viewPlansFindInsurance.companyName = 'Wyoming Blue Cross Blue Shield';
                        break;
                    case "NDBCBS":
                        pageContent.viewPlansFindInsurance.companyName = 'North Dakota Blue Cross Blue Shield';
                        break;
                    default:
                        pageContent.viewPlansFindInsurance.companyName = 'Highmark Blue Cross Blue Shield';
                        break;
                }
                var companyName = pageContent.viewPlansFindInsurance.companyName;
                $scope.pageContent.compareGridBase.footerAttention = pageContent.compareGridBase.footerAttention.replace("storeName", companyName);
            };

            /****
             * Use teamsite mapping for determining discontinued plans,
             * then apply appropriate message to plan object regarding their discontinued status.
             ****/
            var _configurePlanDataForDiscontinuedPlans = function () {
                // var ratingInfoPayload = storage.get("ratingInfoPayload");
                var ratingInfoPayload = ratingInfoModel.get();
                var currentPlanYearToTrackForDiscontinuedPlans = $scope.pageContent.viewPlansFindInsurance.disclaimerYear;

                if (ratingInfoPayload.county) {
                    angular.forEach($scope.planList, function (plan) {
                        plan.discontinuedStatus = discontinuedPlansUtility.determineIfPlanIsDiscontinued(plan, ratingInfoPayload.county, pageContent.discontinuedPlans, currentPlanYearToTrackForDiscontinuedPlans);
                    })
                } else {
                    console.log("no county data to determine if discontinued");
                }
            };
            /*****
             *
             * @returns {boolean}
             * @private
             * returns false if user is not a producer or if producerAttributeHelper has not already been put into storage
             */
            var _checkForProducerAttributeHelper = function () {
                //only check for producerAttributeHelper if user logged in is a producer
                if (_isProducer()) {
                    var attributeHelper = storage.get("producerAttributeHelper");
                    if (attributeHelper) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }

            };

            /***
             *  If user is a producer, check for a prospect to populate storage objects to restore state of view plans
             *  and use data stored in prospect to populate storage correctly
             */
            var _checkForSaveQuoteProspect = function () {

                var checkForSaveQuoteProspectDeferred = $q.defer();

                loginManager.producerAttributeHelper().then(function (producerData) {

                    var prospectData = producerData && producerData.prospectData && producerData.prospectData.payload && producerData.prospectData.payload.prospect;

                    if (prospectData) {

                        //retrieve storage data that was stringified and placed in the additionalInfo field
                        var saveQuoteData;
                        var retrievalVals;
                        //if additionalInfo is not present, we cannot continue with save quote restoration
                        //so resolve false and skip repopulation attempt
                        if (prospectData.prospectBYFInfo && prospectData.prospectBYFInfo.additionalInfo) {
                            saveQuoteData = JSON.parse(prospectData.prospectBYFInfo.additionalInfo);
                            //if prospect values are not available, then
                            // we cannot continue with save quote flow, so resolve false
                            if (saveQuoteData.prospectValues) {

                                // populate some variables used elsewhere
                                if (saveQuoteData.umi) {
                                    umiOnProducer = saveQuoteData.umi;
                                    delete saveQuoteData.umi;
                                    saveQuoteData.prospectValues.umi = umiOnProducer;
                                }
                                if (saveQuoteData.newQuoteCookieFlag) {
                                    $cookies.put("lookupNewQuote", "y", {
                                        "path": "/"
                                    }
                                    );
                                }
                                delete saveQuoteData.newQuoteCookieFlag;

                                if (saveQuoteData.childOnlyApplication.toString().toUpperCase() === "FALSE" ||
                                    saveQuoteData.childOnlyApplication.toString().toUpperCase() === "TRUE") {
                                    storage.set("isChildOnly", saveQuoteData.childOnlyApplication);
                                } else {
                                    console.log("childOnlyApplication necessary app for enroll");
                                }

                                //restore values to prospect and to storage
                                retrievalVals = saveQuoteData.prospectValues;


                                delete saveQuoteData.prospectValues;


                                //clear out the prospectBYFInfo object so that the prospect can be used to populate the BYF application page
                                prospectData.prospectBYFInfo = {};

                                //need store umi in prospect too!!!

                                // Store values necessary for application flows to be populated, i.e. ratingInfoPayload
                                angular.forEach(saveQuoteData, function (value, key) {
                                    storage.set(key, value);
                                });

                                angular.forEach(retrievalVals, function (value, key) {
                                    prospectData[key] = value;
                                })

                                producerData.prospectData.payload.prospect = prospectData;

                                //save producerAttributeHelper back to storage
                                storage.set("producerAttributeHelper", producerData);

                                checkForSaveQuoteProspectDeferred.resolve(true);
                            } else {
                                checkForSaveQuoteProspectDeferred.resolve(false);
                            }

                        } else {
                            checkForSaveQuoteProspectDeferred.resolve(false);
                        }
                    } else {
                        checkForSaveQuoteProspectDeferred.resolve(false);
                    }
                });


                return checkForSaveQuoteProspectDeferred.promise;
            };

            var _confirmExistenceOfAppConfigKeysOrRegen = function () {
                var confirmExistenceOfAppConfigKeysOrRegen = $q.defer();

                if (!sessionData.appConfig || angular.equals(sessionData.appConfig, {})) {
                    loginManager.producerAttributeHelper().then(function (producerData) {
                        if (producerData && producerData.loginData.selectedRegion.brand) {

                            brand.override = producerData.loginData.selectedRegion.brand;
                            brandConfig.update();

                            appConfig.getAll().then(function (response) {
                                sessionData.appConfig = angular.extend({}, response);
                                confirmExistenceOfAppConfigKeysOrRegen.resolve();
                            });
                        } else {
                            confirmExistenceOfAppConfigKeysOrRegen.resolve();
                        }
                    });
                } else {
                    confirmExistenceOfAppConfigKeysOrRegen.resolve();
                }

                return confirmExistenceOfAppConfigKeysOrRegen.promise;
            };

            var _setBrandByCookie = function () {
                if ($cookies.get("brand")) {
                    brand.override = $cookies.get("brand");
                    brandConfig.update();
                }
            };

            /****
             * INITIALIZE
             ****/
            (function onInit() {
                $scope.sidebar.planCount = 0;
                $scope.showFooter = (function () {
                    /* Don't show if the value is true */
                    if (
                        sessionData &&
                        sessionData.appConfig &&
                        sessionData.appConfig.SALES &&
                        sessionData.appConfig.SALES.HEALTH_SHERPA_ACTIVE &&
                        sessionData.appConfig.SALES.HEALTH_SHERPA_ACTIVE.booleanValue
                    ) {
                        return false;
                    }
                    return true;
                })();

                _setBrandByCookie();

                _confirmExistenceOfAppConfigKeysOrRegen().then(function () {

                    // go back to build-your-family if sessionData not populated
                    _checkForSaveQuoteProspect().then(function (returningFromSavedQuote) {


                        //have to check if returning from ffm before doing anything else
                        var returningFromFfm = _configureReturnFromFFM();

                        // if (!storage.get("ratingInfoPayload")) {
                        if (ratingInfoModel.isNotSet()) {
                            // Clear this out, so new quote will later work
                            // storage.del("producerAttributeHelper");
                            $state.go("findInsurance.build-your-family");
                        } else {
                            _configureAdjustedPremiumFlags(returningFromFfm);
                            _configureFooterBranding();

                            $scope.windowShopContent = pageContent.viewPlansFindInsurance.modals.windowShop;

                            if (returningFromFfm || returningFromSavedQuote) {
                                $state.params.noCacheProductService = true;
                            }

                            _getUntransformedProducts($state.params.noCacheProductService)
                                .then(function (productResponse) {

                                    $scope.sidebar.planCount = 0;

                                    var ratingInfoPayload = ratingInfoModel.get();
                                    var isIndividual = ratingInfoPayload.applicants.length > 1 ? false : false;

                                    if (storage.get("onExchangeObject")) {
                                        var args = {
                                            teamsiteContent: pageContent.compareGridBase,
                                            isOnExchange: storage.get("onExchangeObject").isOnExchange,
                                            csrLevel: "0" + storage.get("onExchangeObject").eligibilityLevel,
                                            csr: true
                                        };
                                    } else {
                                        var args = {
                                            teamsiteContent: pageContent.compareGridBase,
                                            isOnExchange: false
                                        };
                                    }

                                    /****
                                    * Convert all plans into normalized structure for display in HighLevelPlanDetails cards
                                    ****/
                                    if ($state.params.noCacheProductService || !planDataUtility.cachedProducts) {
                                        $scope.planList = planDataUtility.cachedProducts = planDataUtility.transformPlanData(angular.copy(productResponse), isIndividual, args);
                                    } else {
                                        $scope.planList = planDataUtility.cachedProducts;
                                    }
                                    console.log("$scope.planList (post-transform):", $scope.planList);

                                    _configureOnExchangeTaxCreditInfo();
                                    _configureFindAProviderButtonDisplay();

                                    _configurePlanDataForDiscontinuedPlans();
                                    _configureDrugCoverageForTransformedPlans();
                                    _configureProviderCoverageForTransformedPlans($state.params.noCacheProductService);
                                    _configureSideBar();
                                    _constructCustomerLookup();
                                    _configureAptcData();
                                    $scope.showLoadingIndicator = false;
                                });
                        }
                    });
                });
            })();
            /****
             * Watchers and Broadcasts
             ****/
        }
    ]);
};