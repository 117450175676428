module.exports = ["$scope", "brand", "specialEnrollmentPeriodPaperApplicationUtility", "sepConfigUtility", "hmShopDateAndTimeUtility", function($scope, brand, specialEnrollmentPeriodPaperApplicationUtility, sepConfigUtility, hmShopDateAndTimeUtility) {
	/****
	 * Local variable declarations
	 ****/
	var region;
	var currentYearOnly 		= false;
	var nextYearOnly 			= false;
	var adoptionCanHave2Dates 	= false;
	var validAdoptionDateRange 	= false;

	/****
	 * Scope variable declarations
	 ****/
	$scope.zipCode 			= "";
	$scope.county 			= {};
	$scope.canGenerateApp 	= false;

	$scope.selectedYear;
	$scope.planYearOptions = (function() {
		
		// var date = new Date(2018, 11, 16);
		var date = new Date();
		var monthOffset = 1;

		// Before December 15th of this year
		if (date.getTime() > (new Date((date).getFullYear(), 12 - monthOffset, 01)).getTime() &&
			date.getTime() < (new Date((date).getFullYear(), 12 - monthOffset, 15)).getTime()) {
			console.log("between 12/1 and 12/15");
			currentYearOnly 		= true;
			nextYearOnly 			= false;
			adoptionCanHave2Dates 	= false;
		}
		// Between December 15th, this year and January 1st, 2019
		else if (date.getTime() > (new Date((date).getFullYear()	 , 12 - monthOffset, 15)).getTime() &&
				 date.getTime() < (new Date((date).getFullYear() + 1, 01 - monthOffset, 01)).getTime()) {
			console.log("between 12/15 and 1/1");
			currentYearOnly 		= false;
			nextYearOnly 			= true;
			adoptionCanHave2Dates 	= true;
		}
		// Between January 1st, 2019 and March 16th, 2019
		else if (date.getTime() > (new Date((date).getFullYear(), 01 - monthOffset, 01)).getTime() &&
				 date.getTime() < (new Date((date).getFullYear(), 03 - monthOffset, 16)).getTime()) {
			console.log("between 1/1 and 3/16");
			currentYearOnly 		= true;
			nextYearOnly 			= false;
			adoptionCanHave2Dates 	= true;
		}
		// Between March 16th, 2019 and December 1st, 2019
		else {
			console.log("between 3/16 and 12/1");
			currentYearOnly 		= true;
			nextYearOnly 			= false;
			adoptionCanHave2Dates 	= false;
		}


		var thisYear = (date).getFullYear();
		var lastYear = thisYear - 1;
		var nextYear = thisYear + 1;

		var thisYearOptionOnly = [{
			"label" 	: thisYear.toString(),
			"year" 		: thisYear
		}];

		var thisYearAndNextYearOptionsOnly = [{
			"label" 	: thisYear.toString(),
			"year" 		: thisYear
		}, {
			"label" 	: nextYear.toString(),
			"year" 		: nextYear
		}];
		
		var nextYearOptionOnly = [{
			"label" 	: nextYear.toString(),
			"year" 		: nextYear
		}];

		var lastYearAndThisYearOptions = [{
			"label" 	: lastYear.toString(),
			"year" 		: lastYear
		}, {
			"label" 	: thisYear.toString(),
			"year" 		: thisYear
		}];

		// Yes Adoption
		if (sepConfigUtility && !angular.equals(sepConfigUtility.adoptionDate, [])) {
			var daysBetweenAdoptionDateAndToday = hmShopDateAndTimeUtility.getDaysBetween2Dates(new Date(sepConfigUtility.adoptionDate[0].displayEffDate), date);

			// Valid within 60 days
			if (daysBetweenAdoptionDateAndToday <= 60) {
				validAdoptionDateRange = true;
			}
		}

		if (validAdoptionDateRange) {
			if (adoptionCanHave2Dates) {
				if (currentYearOnly && !nextYearOnly){
					return lastYearAndThisYearOptions;
				} else if (!currentYearOnly && nextYearOnly){
					return thisYearAndNextYearOptionsOnly;
				}
			} else {
				if (currentYearOnly && !nextYearOnly){
					return thisYearOptionOnly;
				} else if (!currentYearOnly && nextYearOnly){
					return nextYearOptionOnly;
				}
			}
		} else {
			if (currentYearOnly && !nextYearOnly){
				return thisYearOptionOnly;
			} else if (!currentYearOnly && nextYearOnly){
				return nextYearOptionOnly;
			}
		}
	})();

	/****
	 * Function Declarations
	 ****/
	$scope.allowGenerationOfPaperApp 	= allowGenerationOfPaperApp
	$scope.generatePaperApp 			= generatePaperApp;

	/****
	 * Function Definitions
	 ****/
	function allowGenerationOfPaperApp() {
		$scope.canGenerateApp = true;
	};

	function generatePaperApp() {
		specialEnrollmentPeriodPaperApplicationUtility.getAppByRegionAndPlanYear(region, $scope.selectedYear.year);
	};

	(function onInit(){

	});

	$scope.$on("brandUpdated", function(event, county) {
        region = county.region;
    });
}];