/**
 * Created by LID0VS0 on 10/5/2018.
 */
module.exports = function(app) {
    app.factory("clickToChatUtility", ["services", "sessionData", "hook", function(services, sessionData, hook){
        var utilities = {
            handleChatAvailability: handleChatAvailability,
            registerOnClickEventHook: registerOnClickEventHook
        };

        function registerOnClickEventHook(){
            hook.register("triggerSalesforceChatLink", function(){
                //open the chat to interact with a live agent
                liveagent.startChat(window._sfdcConfig.buttonID);
            });
        }
        function handleChatAvailability(){
            try {
                var onlineNode = angular.element(document.querySelector("#liveagent_button_online_" + window._sfdcConfig.buttonID))[0];
                var offlineNode = angular.element(document.querySelector("#liveagent_button_offline_" + window._sfdcConfig.buttonID))[0];

                var onlineStyle = onlineNode.style.display;
                var offlineStyle = offlineNode.style.display;

                //check the styles to see if they are being 'displayed' or not (buttons never actually visible)
                var onlineIndex = onlineStyle.indexOf("none");
                var offlineIndex = offlineStyle.indexOf("none");
                //if onlineNode contains none as its display style, disable the link
                if(onlineIndex > -1 && offlineIndex < 0) {
                    hook.execute("toggleChatAvailabilityOff");
                //if onlineNode does not contain none as its display style, enable the link
                } else if (onlineIndex < 0 && offlineIndex > -1) {
                    hook.execute("toggleChatAvailabilityOn");
                //if both online and offlineNodes contain none as its display style, disable the link
                } else {
                    hook.execute("toggleChatAvailabilityOff");
                }
            } catch (e) {
                console.log("clickToChatUtility.handleChatAvailability failed, reason: ", e);
            }
        }
        return utilities;
    }]);
}