module.exports = function (app) {

    app.directive('loginRegister', function () {
        return {
            template: require('./loginRegister.html'),
            scope: {},
            controller: ['$scope', '$state', '$q', 'services', 'brand', 'session', function ($scope, $state, $q, services, brand, session) {

                $scope.config = {
                    errorText: "Error Text",
                    loggingIn: false,
                    showInvalidUserPass: false
                };

                $scope.loginInfo = {
                    username: "",
                    password: ""
                };

                $scope.performRegister = function () {
                    $state.go('registration');
                };

                $scope.performLogin = function () {

                    var username = $scope.loginInfo.username;
                    var password = $scope.loginInfo.password;

                    if (username != null && username.length > 0 && password != null && password.length > 0) {
                        $scope.loggingIn = true;
                        $scope.showInvalidUserPass = false;

                        services.rbsmbl.loginversion({'username': username}).fetch().then(
                            function (response) {
                                if (response && response.data && response.data.payload) {
                                    password = response.data.payload.convertCase ? password.toUpperCase() : password;

                                    if (response.data.payload.loginVersion === 'MER_LOGIN_V1') {
                                        services.rbsmbl.login().login(username, password).then(
                                            function (response) {
                                                console.log(response);
                                            },
                                            handleError
                                        );
                                    } else if (response.data.payload.loginVersion === 'BV_LOGIN') {
                                        services.wcs.ltpalogin({"storeId": brand.current.storeId}).postData({},
                                            {
                                                username: username,
                                                password: password
                                            }).then(
                                            function (response) {
                                                console.log(response);
                                                session.start().then(function () {
                                                    var returnDefer = $q.defer();
                                                    returnDefer.resolve(response);
                                                });
                                            },
                                            handleError
                                        );
                                    }

                                }
                            });
                    }
                };

                function handleError(error) {
                    console.log("Error logging in");
                    //$scope.loginInfo.username = "";
                    $scope.loginInfo.password = "";
                    $scope.errorText = error.specificErrorContent;

                    if (error.data && error.data.baseMessage) {
                        switch (error.data.baseMessage.returnCodeDescription) {
                            case "GEN-NORECCHMECS":
                            case "GEN-NOMATCH":
                            case "USER-NOT-ON-FILE":
                                $scope.showInvalidUserPass = true;
                        }
                    }
                }

            }]};
    });
};