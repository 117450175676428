
/**
 * Created by lid0vs0 on 9/26/2018.
 */
module.exports = [
    "$scope", "$state", function ($scope) {

        $scope.closeWindowShoppingModal = function() {
            var x = $scope;
            $scope.closeModal();
        };
    }];