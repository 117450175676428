module.exports = function(app) {
	
	/****
	 * Try to keep track of items added here
	 * 1) isDateFromTodayYoungerOrOlder - used to quickly check age criteria using years
	 ****/
	return app.factory("hmShopDateAndTimeUtility", [function() {

		/****
		 * Factory return - map to functions to be exposed, here
		 ****/
		var utilities = {
			"isDateFromTodayYoungerOrOlder" 		: _isDateFromTodayYoungerOrOlder,
			"getISO8601CompliantDatetimeString" 	: _getISO8601CompliantDatetimeString,
            "getDaysBetween2Dates"                  : _getDaysBetween2Dates
		};

		/****
		 * Function declarations/implementations
		 * - be sure to map in 'utilities' object for external use
		 ****/
		function _isDateFromTodayYoungerOrOlder(
			dateToCheck 	/* new Date() */,
			param 			/* 18, 26, 65 */,
			youngerOrOlder 	/* "younger" or "older" */) {
		  if (!(typeof param).toUpperCase() === "NUMBER") {
                return false
            } else {
                // Get current date...
                var zeroYearsAgo        = new Date();
                // ...use it make a date X years from now...
                var paramYearsAgo   = zeroYearsAgo.getFullYear() - param;
                var comparableDate = new Date();
                    comparableDate.setFullYear(paramYearsAgo);

                // compare if X years from now is greater than the date entered.  If so, they are 
                // subtract X years from today
                // time goes up, so a date older than 65 would be greater than 65 years ago
                if (youngerOrOlder.toUpperCase() === "YOUNGER") {
                    return new Date(dateToCheck) >= comparableDate ;
                } else if (youngerOrOlder.toUpperCase() === "OLDER") {
                    return new Date(dateToCheck) <= comparableDate;
                } else {
                    console.error("Incorrect 'youngerOrOlder' param")
                }
            }
		}

		// Ex: "2019-01-01T05:00:00+00:00"
		/****
		 * If you give it a date object, it'll convert to ISO8601,
		 * If you give it nothing, it'll generate the time now in ISO8601
		 ****/
		function _getISO8601CompliantDatetimeString(
			dateParam /* new Date() */
		) {
	        var date = dateParam || new Date();
            var year = date.getFullYear().toString();

            var month = (date.getMonth() + 1).toString();
            if (month.length === 1) {
                month = "0" + month;
            }

            var dayOfMonth = date.getDate().toString();
            if (dayOfMonth.length === 1) {
                dayOfMonth = "0" + dayOfMonth;
            }

            var hoursInTime = date.getHours().toString();
            if (hoursInTime.length === 1) {
                hoursInTime = "0" + hoursInTime;
            }

            var minutesOfHour = date.getMinutes().toString();
            if (minutesOfHour.length === 1) {
                minutesOfHour = "0" + minutesOfHour;
            }

            var secondsOfMinute = date.getSeconds().toString();
            if (secondsOfMinute.length === 1) {
                secondsOfMinute = "0" + secondsOfMinute;
            }

            var utcTimeZoneOffsetHours = Math.abs(Math.floor(date.getTimezoneOffset()/60)).toString();
            if (utcTimeZoneOffsetHours.length === 1) {
                utcTimeZoneOffsetHours = "0" + utcTimeZoneOffsetHours;
            }
            
            var utcTimeZoneOffsetMinutes = Math.abs((date.getTimezoneOffset()%60)).toString();
            if (utcTimeZoneOffsetMinutes.length === 1) {
                utcTimeZoneOffsetMinutes = "0" + utcTimeZoneOffsetMinutes;
            }

            // Timezone offset is positive(if going east) or negative(going west) from GMT, the offset is the time we must add to get to GMT
            // 2 am EST is 6am GMT (timezone offset would be 240, so we had to add 240 minutes to EST)
            var utcTimeZoneOffsetPositiveOrNegative = date.getTimezoneOffset() > 0 ? "-" : "+";

            var iso8601CompliantDate = year + "-" + month + "-" + dayOfMonth + "T" + hoursInTime + ":" + minutesOfHour + ":" + secondsOfMinute + utcTimeZoneOffsetPositiveOrNegative + utcTimeZoneOffsetHours + ":" + utcTimeZoneOffsetMinutes;

            return iso8601CompliantDate;
		}

        function _getDaysBetween2Dates(
            date1, /* new Date() */
            date2  /* new Date() */
        ) {
            var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
            var firstDate = new Date(date1.getFullYear(),date1.getMonth(),date1.getDate());
            var secondDate = new Date(date2.getFullYear(),date2.getMonth(),date2.getDate());

            var diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime())/(oneDay)));

            return diffDays;
        };

		return utilities;
	}]);
}