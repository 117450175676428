module.exports = function(app) {
    app.controller('pages.agreementDisplay', ["$state", "$scope",
        function($state, $scope) {

            var parsedKeywords;
            $scope.region = $state.params.region;

            $scope.openAgreement = function(agreement) {
                window.open(agreement.link);
            };


            // Refine sidebar
            $scope.sidebar.adjustRefineKeywords = function() {
                $scope.sidebar.emptySet = true;
                parsedKeywords = $scope.sidebar.keywordSearch.replace(/\s+/, ' ').split(' ');

                return parsedKeywords;
            };

            $scope.refineFilter = function(item) {
                var i = parsedKeywords ? parsedKeywords.length : 0;
                var stringItem = JSON.stringify(item);
                var show = true;

                while (i--) {
                    if (stringItem.toLowerCase().indexOf(parsedKeywords[i].toLowerCase()) === -1) {
                        show = false;
                    }
                }

                if ($scope.sidebar && $scope.sidebar.region && item) {  
                    if ($scope.sidebar.region.label != item.Region){
                        show = false;
                    }
                }

                if ($scope.sidebar.emptySet && show) {
                    $scope.sidebar.emptySet = false;
                }

                return show;
            };
        }
    ]);
};