module.exports = function(app) {
    app.controller('enrollment.life-event-document-upload',  ["$state", "$scope", "$timeout","services","$cookies", "loginManager", "prospectUtility", "sepConfigUtility",

        function($state, $scope, $timeout, services, $cookies, loginManager, prospectUtility, sepConfigUtility) {
            $scope.navigateToEditDetails = function(){
                window.location.href = '/#!/find-insurance/build-your-family';
            };
            var wySepLifeEventDetails = $cookies.get("DOCUMENT_UPLOAD_VALUES");
            // We'll use this to save the document data to the prospect object for later retrieval
            $scope.prospectDocumentData = wySepLifeEventDetails;
            wySepLifeEventDetails = JSON.parse(window.atob(wySepLifeEventDetails));
            if(wySepLifeEventDetails) {
                $scope.lifeEventDetails = {
                    "qualifyingLifeEvent": wySepLifeEventDetails.lifeEventName,
                    "dateOfLifeEvent": wySepLifeEventDetails.lifeEventDate,
                    "effectiveDateOfCoverage": wySepLifeEventDetails.effectiveDate,
                    "supportingDocument": wySepLifeEventDetails.supportingDocumentation
                };
            }
            $scope.files = [];
            //watch the file variable, when it changes, update the files array to
            //only contain the new file
            $scope.$watch('file', function(newValue, oldValue){
                if(newValue && newValue !== oldValue){
                    $scope.files.splice(0, $scope.files.length);
                    $scope.files.push(newValue);
                }
            });
            $scope.$watch("fileList", function(newValue) {
                if (newValue) {
                    $scope.files = Array.from(newValue);
                }
            });
            //when the visible upload document button is clicked, activate the hidden and ugly
            //file upload button to get a file from the user
            $scope.uploadFile = function(){
                var x = $scope;
                document.getElementById("sepFileSelection").click();
            };
            $scope.goBack = function(){
                window.location.href = '/#!/enrollment/instructions';
            };
            //when the remove link next to the file is clicked, remove the file from the files list
            //so that it will not appear in the ng-repeat
            $scope.removeFile = function(file){
                var x = $scope;
                $scope.files = [];
                $scope.file = "";
            };
            $scope.submitForm = function(){
                $scope.submitAll();
                //get ready to use the form to submit
            };
            $scope.submitAll = function(){
                var x = $scope;
                //construct a formData object to append the contents of $scope.files
                var fd = new FormData();
                angular.forEach($scope.files, function(file, index){
                    fd.append('file', file);
                });

                services
                    .cmcrst
                    .sepLifeEventDocUpload()
                    .documentUpload(fd)
                    .then(function(data) {
                        console.log("success doc upload",data);

                        var dcn = window.btoa( data.data.dcn);
                        $cookies.put("LIFE_EVENT_DCN", dcn, {
                            "path": "/"
                        });
                        
                        /** Get prospect data from the producer login response... need prospectId */
                        loginManager.producerAttributeHelper().then(function(response) {

                            if (response) {
                                /** Use the UPDATED prospect info and ENHANCE it so that.... */
                                if (!response.prospectData.payload.prospect.prospectBYFInfo.additionalInfo) {
                                    response.prospectData.payload.prospect.prospectBYFInfo.additionalInfo = {};
                                } else {
                                    response.prospectData.payload.prospect.prospectBYFInfo.additionalInfo = JSON.parse(response.prospectData.payload.prospect.prospectBYFInfo.additionalInfo);
                                }

                                /** Save SEP Document Upload Data */
                                response.prospectData.payload.prospect.prospectBYFInfo.additionalInfo.sepCircumstanceData = $scope.prospectDocumentData;
                                response.prospectData.payload.prospect.prospectBYFInfo.additionalInfo.lifeEventDcn = data.data.dcn;

                                response.prospectData.payload.prospect.prospectBYFInfo.additionalInfo = JSON.stringify(response.prospectData.payload.prospect.prospectBYFInfo.additionalInfo);

                                var selectedPlanCookie = $cookies.get("SHOP_FOUR_DOT_OH_SELECTEDPLAN");
                                if(selectedPlanCookie){
                                    response.prospectData.payload.prospect.prospectBYFInfo.selectedPlan = JSON.parse(window.atob(selectedPlanCookie));
                                }
                            
                                // Oddly determine SEP payload
                                if (!response.prospectData.payload.prospect.prospectBYFInfo.SEPData) {

                                    response.prospectData.payload.prospect.prospectBYFInfo.SEPData = {};

                                    if (sepConfigUtility.isOnSEP) {
                                        response.prospectData.payload.prospect.prospectBYFInfo.SEPData = {
                                            "onSEP":sepConfigUtility.isOnSEP,
                                            "offSEP": sepConfigUtility.isOffSEP
                                        }
                                        response.prospectData.payload.prospect.prospectBYFInfo.SEPData.effectiveDate = storage.get('selectedEffectiveDateISO8601');
                                    } else {
                                        response.prospectData.payload.prospect.prospectBYFInfo.SEPData = {
                                            "isSEP": false,
                                            "planYear": "2015"
                                        };
                                    }

                                }

                                delete response.prospectData.payload.prospect.prospectBYFInfo.continueType;
                                delete response.prospectData.payload.prospect.umi;
                                
                                /** Call made to UPDATE PROSPECT service will succeed - values set here show up in the producer saved quotes page */
                                prospectUtility.updateProspect(response.prospectData.payload.prospect)
                                    .then(function(setSessionStorageResponse){
                                        window.location.href = '/#!/enrollment/your-information';
                                    }); 
                            } else {
                                window.location.href = '/#!/enrollment/your-information';
                            }
                            
                        })
                        
                    }, function (error) {
                        console.log("error", error);
                    });
            };
        }

    ]);
    //directive to bind the uploaded file to the scope
    app.directive('ngFileModel', ['$parse','$timeout', function ($parse, $timeout) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var model = $parse(attrs.ngFileModel);
                var modelSetter = model.assign;
                element.bind('change', function () {
                    var values = element[0].files[0];
                    $timeout(function () {
                            modelSetter(scope, values);
                    });
                });
            }
        };
    }]);
};