module.exports = [
    "$scope",
    "$state",
    "storage",
    "$rootScope" ,
    "sessionData",
    "prospectUtility",
    "ffmEligibilityUtility",
    "$cookies",
    function (
        $scope,
        $state,
        storage,
        $rootScope,
        sessionData,
        prospectUtility,
        ffmEligibilityUtility,
        $cookies
    ) {


        $scope.prepareNewQuoteCookie= function(){
            //if in customer lookup flow and
            //user has elected to alter the number of applicants,
            //set a cookie to use as a flag
            var x = $scope;
            if($scope.editFamilyMember){
                $cookies.put("lookupNewQuote", "y", {
                     "path":    "/"
                    }
                );
            } else {
                //otherwise, there should not be a cookie flag set
                //so jic, expire it
                $cookies.put("lookupNewQuote", "", {
                    "path":     "/",
                    "expires":  "-1"
                });
            }

        };
        $scope.goToViewPlans = function() {
            $scope.prepareNewQuoteCookie();
            $state.go('findInsurance.view-plans', {
                "noCacheProductService": true
            });
            $scope.closeModal();
        };
        $scope.sendToFFM = function(){


            $rootScope.isOnX = true;
            storage.set("onExchangeObject", {
                "isOnExchange"              : true,
                "subsidyAmount"             : 0,
                "hasReturnedFromOnExchange" : false,
                "eligibilityLevel"          : ""
            });
            storage.set("subsidyResponse", {
                "subsidyAmount"             : 1,
                "eligibilityLevel"          : 1,
                "federalPovertyLevel"       : 0
            });
            localStorage.setItem("taxCreditInfo",JSON.stringify(storage.get("ratingInfoPayload")));
            $scope.closeModal();

            ffmEligibilityUtility.sendToFFM();

        };
        $scope.goToSubsidy = function() {
            $scope.prepareNewQuoteCookie();
            $state.go('findInsurance.household-income');
            $scope.closeModal();
        };

        (function onInit(){

	        // Populate onExchangeObject
	        storage.set("onExchangeObject", {
	            "isOnExchange"  : false,
	            "subsidyAmount" : 0
	        });

            $rootScope.isOnX = false;

	    })();
}];