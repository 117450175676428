module.exports = function(app){
    app.directive('prescriptionDrugCoverageForPlan', [
        function () {
            return {
                restrict: 'A',
                template: require('./prescriptionDrugCoverageForPlanTemplate.html'),
                scope: {
                    planData: '='
                },

                link: function ($scope) {
                }
            }
        }
	])
}