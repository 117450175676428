/**
 * Created by LID0VS0 on 8/20/2018.
 */
module.exports = function(bootstrap) {
    bootstrap.run(["Service", function (Service) {
        return new Service({
            name: 'smallGroupCountySearch',
            group: "cmcrst",
            url: "/cmcrst/x-services/public/smallgroup",
            cache: false,

            postSmallGroupCountySearch: function(payload){
                return this.post(payload);
            }
        });
    }]);
};