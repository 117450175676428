/**
 * Created by lid4an9 on 08/28/2018.
 */
module.exports = function (app) {
    app.run(["Service", saveProspect]);
    app.run(["Service", doesProspectExist]);
    app.run(["Service", prospectSummary]);
    app.run(["Service", recordUserTransferToFFM]);
    app.run(["Service", updateProspect]);


    function saveProspect(Service) {
        new Service({
            name: "saveProspect",
            group: "cmcrst",
            url: "/cmcrst/x-services/prospect",
            cache: false,

            save: function(prospect) {
                return this.put({"payload":prospect});
            }
        });
    };

    function doesProspectExist(Service) {
        new Service({
            name: "doesProspectExist",
            group: "cmcrst",
            url: "/cmcrst/x-services/prospect",
            cache: false,
            get: function() {
                return this.get();
            }
        });
    };

    function prospectSummary(Service) {
        new Service({
            name: "prospectSummary",
            group: "cmcrst",
            url: "/cmcrst/x-services/applicant/onexchange/summary?prospectId=",
            cache: false,

            getSummary: function(queryParams) {
                this.url += queryParams.prospectId;
                return this.get();
            }

        });
    };

    function recordUserTransferToFFM(Service) {
        new Service({
            name: "recordUserTransferToFFM",
            group: "cmcrst",
            url: "/cmcrst/x-services/prospect/prospectupdate",
            cache: false,

            update: function(params) {
                return this.post(params);
            }

        });  
    };

    function updateProspect(Service){

        new Service({
            name:   "updateProspect",
            group:  "cmcrst",
            url:    "/cmcrst/x-services/prospect",
            headers:{
                FromCSR : undefined,
                FromDP : null
            },
            isCritical: true,
            handleAs: "json",
            includeBaseMessage: false,
            postUpdate: function(data, name){
                this.headers.FromCSR = name;
                return this.post({
                    "payload" : {
                        "prospect" : data
                    }
                });
            }

        })
    }


};