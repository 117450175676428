module.exports = function(app){
    'use strict';
    app.directive('emailAddress', ["errorMessages", function (errorMessages) {
        return {
            restrict: 'A',
            replace: true,
            template: require('directives/enrollment/emailAddress.html'),
            scope: {
                fieldLabel: "@",
                emailAddress: "="
            },
            link: function ($scope, elem, $attrs) {
                $scope.errorMessages = errorMessages;
            }
        };
    }]);
};