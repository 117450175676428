/**
 * Created by lid0vs0 on 9/24/2018.
 */
module.exports = function(bootstrap) {
    bootstrap.run(["Service", function (Service) {
        var sepService =  new Service({
            name: 'isSepService',
            group: "cmcrst",
            url: "/cmcrst/x-services/consumer/sep",
            cache: false,

            get: function(){
                return this.get();
            }
        });
        return sepService;
    }]);
};