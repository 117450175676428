module.exports = function (app) { "use strict";
    app.controller('pages.marketplace.applicationStatus', ["$state", "$scope", "$http", "$stateParams", "teamsiteService", "$timeout", "appStatusService",
        function ($state, $scope, $http, $stateParams, teamsiteService, $timeout, appStatusService) {

            teamsiteService.loadFile("applicationStatus").then(function (teamsiteContent) {
                $scope.teamsiteContent = teamsiteContent;

                appStatusService.getAppStatus().then(function (data) {
                    $scope.appStatusResponse = data;
                });
            });
        }
    ]);
};