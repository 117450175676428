module.exports = function(app) { "use strict";

    require('./build-your-family.js')(app);
    require('./view-plans.js')(app);
    require('./view-plans-grid.js')(app);
    require('./compare-plans.js')(app);
    require('./plan-details.js')(app);
    require('./view-plans.js')(app);
    require('./special-enrollment.js')(app);
    require('./special-enrollment-qualify.js')(app);
    require('./special-enrollment-events.js')(app);
    require("./household-results")(app);
    require("./household-income")(app);
    require("./find-a-provider-or-drug")(app);
    require("./find-a-provider-or-drug/provider")(app);
    require("./find-a-provider-or-drug/drug")(app);
    require("./find-a-provider-or-drug/landing")(app);

    app.controller('pages.findInsurance', ["$state", "$scope", "brand", "sidebarPageContent", "sepConfigUtility", "modal",
        function($state, $scope, brand, sidebarPageContent, sepConfigUtility, modal) {
            $scope.backAction = function() {
                $state.go('findInsurance.view-plans');
            };

            var brandList = ["pbs","unbrandedhm","nepa","hmbcbs","hmbcbswv","hmbcbsde"];

            function _onInit() {
                var discoverHighmarkLink;
                if(brandList.indexOf(brand.current.sharedBrandCode) !== -1){
                    discoverHighmarkLink = "https://www.discoverhighmark.com/";
                } else {
                    discoverHighmarkLink = "https://www.discoverhighmark.com/individuals-families/insurance/insurance-plans";
                }

                $scope.sidebar.links = [];
                $scope.headings = "SHOP";
                $scope.backText = "Back to Plan List";
    
                // Add Initial Links
                angular.forEach(sidebarPageContent.viewPlansFindInsurance.leftNavLinks, function(link){
                    $scope.sidebar.links.push({
                        "heading"   : link["text"],
                        "label"     : link["text"],
                        "href"      : link["URL"],
                        "newWindow" : link["newWindow"]
                    })
                });
                
                if (sepConfigUtility.isSepFlow || $state.current.name === "findInsurance.special-enrollment" || $state.current.name === "findInsurance.special-enrollment-qualify") {
                    // Add Custom Links
                    $scope.sidebar.links.push({
                        "heading"   : "Paper Forms for Direct Enrollment With Highmark",
                        "label"     : "Paper Forms for Direct Enrollment With Highmark",
                        "onClick"      : function(){
                            modal.open({
                                template: require("modals/paper-app-by-zip.html"),
                                controller: require("modals/paper-app-by-zip"),
                                scope: $scope
                            })
                        }
                    });
                }
            }

            _onInit();

            $scope.$on('brandUpdated', function() {
                _onInit();
            })
            
        }
    ]);

    return [
        {
            state: "findInsurance",
            url: "/find-insurance",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.findInsurance"
                }
            },
            resolve: {
                "sidebarPageContent": function(teamsiteManager, brand) {
                    return teamsiteManager.getMultiContent(["viewPlansFindInsurance"], brand);
                }
            }
        },
        {
            state: "findInsurance.build-your-family",
            url: "/build-your-family",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.findInsurance"
                },
                "content@findInsurance.build-your-family": {
                    template: require("pages/findInsurance/build-your-family.html"),
                    controller: "pages.findInsurance.build-your-family as ctrl"
                }
            },
            resolve: {
                "pageContent": function(teamsiteManager, brand) {
                    return teamsiteManager.getMultiContent(
                        [
                            "buildYourFamily"
                        ], brand);
                }
            }
        },
        {
            state: "findInsurance.view-plans",
            url: "/view-plans",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/categoryCustomFullWidth.html"],
                    controller: "pages.findInsurance"
                },
                "content@findInsurance.view-plans": {
                    template: require("pages/findInsurance/view-plans.html"),
                    controller: "pages.findInsurance.view-plans"
                }
            },
            params: {
                ratingInfo: {},
                noCacheProductService: false
            },
            resolve: {
                "pageContent": function(teamsiteManager, brand) {
                    return teamsiteManager.getMultiContent(
                        [
                            "entityManager",
                            "viewPlansFindInsurance",
                            "productListContent",
                            "viewPlansMarketplace",
                            "compareGridBase",
                            "discontinuedPlans",
                            "talkingPoints"
                        ], brand);
                }
            }
        },
        {
            state: "findInsurance.view-plans-grid",
            url: "/view-plans-grid",
            views: {
                "body@": {
                    template: require("pages/findInsurance/category-detail-12.html"),
                    controller: "pages.findInsurance"
                },
                "content@findInsurance.view-plans-grid": {
                    template: require("pages/findInsurance/view-plans-grid.html"),
                    controller: "pages.findInsurance.view-plans-grid"
                }
            },
            params: {
                products: null
            },
            resolve: {
                "pageContent": function(teamsiteManager, brand) {
                    return teamsiteManager.getMultiContent(
                        [
                            "entityManager",
                            "viewPlansFindInsurance",
                            "productListContent",
                            "viewPlansMarketplace",
                            "compareGridBase",
                            "previewPlans"
                        ], brand);
                }
            }
        },
        {
            state: "findInsurance.compare-plans",
            url: "/compare-plans",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/contentCustom.html"],
                    controller: "pages.findInsurance"
                },
                "content@findInsurance.compare-plans": {
                    template: require("pages/findInsurance/plan-details.html"),
                    controller: "pages.findInsurance.plan-details"
                }
            },
            params: {
                isComparePlans: true,
                productObjects: null
            },
            resolve: {
                pageContent: function(teamsiteManager, brand){
                    return teamsiteManager.getMultiContent(["productListContent", "compareGridBase", "viewPlansFindInsurance", "planDetailsTableConfiguration", "planDetailsAdditionalLinks"], brand)
                }
            }
        },
        {
            state: "findInsurance.plan-details",
            url: "/plan-details",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/contentCustom.html"],
                    controller: "pages.findInsurance"
                },
                "content@findInsurance.plan-details": {
                    template: require("pages/findInsurance/plan-details.html"),
                    controller: "pages.findInsurance.plan-details"
                }
            },
            params: {
                isComparePlans: false,
                productObjects: null
            },
            resolve: {
                pageContent: function(teamsiteManager, brand){
                    return teamsiteManager.getMultiContent(["productListContent", "compareGridBase", "viewPlansFindInsurance", "planDetailsTableConfiguration", "planDetailsAdditionalLinks"], brand)
                }
            }
        },
        {
            state: "findInsurance.special-enrollment",
            url: "/special-enrollment",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.findInsurance"
                },
                "content@findInsurance.special-enrollment": {
                   template: require("pages/findInsurance/special-enrollment.html"),
                    controller: "findInsurance.special-enrollment"
                }
            },
            resolve: {
                content: function($q, teamsiteManager, brand){
                    return $q.all({
                            specialEnrollmentV2: teamsiteManager.getContent("specialEnrollmentV2", brand),
                            specialEnrollment: teamsiteManager.getContent("specialEnrollment", brand)
                    });
                }
            }
        },
        {
            state: "findInsurance.special-enrollment-qualify",
            url: "/special-enrollment-qualify",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.findInsurance"
                },
                "content@findInsurance.special-enrollment-qualify": {
                    template: require("pages/findInsurance/special-enrollment-qualify.html"),
                    controller: "findInsurance.special-enrollment-qualify"
                }
            },
            resolve: {
                content: function (teamsiteManager, brand) {
                    return teamsiteManager.getContent("specialEnrollmentQualifyV2", brand);
                }
            }
        },
        {
            state: "findInsurance.special-enrollment-events",
            url: "/special-enrollment-events",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.findInsurance"
                },
                "content@findInsurance.special-enrollment-events": {
                    template: require("pages/findInsurance/special-enrollment-events.html"),
                    controller: "findInsurance.special-enrollment-events"
                }
            },
            resolve: {
                content: function (teamsiteManager, brand) {
                    return teamsiteManager.getContent("specialEnrollmentEvents", brand);
                }
            }
        },
        {
            state: "findInsurance.household-income",
            url: "/household-income",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.findInsurance"
                },
                "content@findInsurance.household-income": {
                    template: require("pages/findInsurance/household-income.html"),
                    controller: "findInsurance.household-income"
                }
            },
            params: {
                ratingInfo: {}
            },
            resolve: {
                pageContent: function(teamsiteManager, brand) {
                    return teamsiteManager.getContent("householdIncome", brand);
                }
            }
        },
        {
            state: "findInsurance.household-results",
            url: "/household-results",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.findInsurance"
                },
                "content@findInsurance.household-results": {
                    template: require("pages/findInsurance/household-results.html"),
                    controller: "findInsurance.household-results"
                }
            },
            params: {
                ratingInfo: {},
                subsidy: {}
            }, 
            resolve: { 
                pageContent: function(teamsiteManager, brand) {
                    return teamsiteManager.getContent("householdResults", brand)
                }
            }
        },
        {
            state: "findInsurance.find-a-provider-or-drug",
            url: "/find-a-provider-or-drug",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/contentCustom.html"],
                    controller: "pages.findInsurance"
                },
                "content@findInsurance.find-a-provider-or-drug": {
                    template: require("pages/findInsurance/find-a-provider-or-drug.html"),
                    controller: "pages.findInsurance.find-a-provider-or-drug"
                }
            },
            resolve: { 
                pageContent: function(teamsiteManager, brand) {
                    return teamsiteManager.getContent("viewPlansFindInsurance", brand)
                }
            }
        },
        {
            state: "findInsurance.find-a-provider-or-drug.provider",
            url: "/provider",
            views: {
                "findAProviderContent@findInsurance.find-a-provider-or-drug": {
                    template: require("pages/findInsurance/find-a-provider-or-drug/provider.html"),
                    controller: "pages.findInsurance.find-a-provider-or-drug.provider"
                }
            }
        },
        {
            state: "findInsurance.find-a-provider-or-drug.drug",
            url: "/drug",
            views: {
                "findAProviderContent@findInsurance.find-a-provider-or-drug": {
                    template: require("pages/findInsurance/find-a-provider-or-drug/drug.html"),
                    controller: "pages.findInsurance.find-a-provider-or-drug.drug"
                }
            }
        },
        {
            state: "findInsurance.find-a-provider-or-drug.landing",
            url: "/landing",
            views: {
                "findAProviderContent@findInsurance.find-a-provider-or-drug": {
                    template: require("pages/findInsurance/find-a-provider-or-drug/landing.html"),
                    controller: "pages.findInsurance.find-a-provider-or-drug.landing"
                }
            }
        },
    ];
};