/****
 * Takes plan list and all providers saved from the provider.js page.
 * Providers have been manipulated to flatten results, merging practionerIds of unique value of same npn, into array
 * Make service calls for each, merge results of acceptedPlans
 * Revision: 22 Apr 2019 - lid3fd4 (J. Batchelor) - Added plansAcceptedService ref, split out Watson and Sapphire
 *      Digital data sources,
 ****/
module.exports = function(app) {

	return app.factory("coveredProvidersUtility", ["storage", "$q", "plansAcceptedService", "services",
        "appConfig", function(storage, $q, plansAcceptedService, services) {

		var allProvidersContentCache = null;

		function _getPlansFromWatsonServices(practitionerId,provider,allProvidersContent){
            var getProviderDetailsDeferred = $q.defer();
			var requestParams = {
                "locationSequenceNumber" 	: provider.location.locationSequenceNo, // think this needs to be from professional info
                "practitionerId" 			: practitionerId, // this seems different, even if locations are the same
                "vendorId" 					: provider.location.vendorId,
                "vendorType" 				: "D",
                "brandName" 				: "Blue"
            }

            // Get accepted plans from provider search
            services
                .rbsmbl
                .searchDetails({"type":"public"})
                .fetch(requestParams)
                .then(function(response){
                    getProviderDetailsDeferred.resolve(response.data.payload.detailsResult);
                }, function(failure){
                    allProvidersContent.push(provider);
                    getProviderDetailsDeferred.resolve(response.data.payload.detailsResult);
                });
            return getProviderDetailsDeferred.promise;
		}
		function _getPlansFromSapphireDigital(practitionerId,provider,allProvidersContent){
            var getProviderDetailsDeferred = $q.defer();
            getProviderDetailsDeferred.provider = provider;
			var providerId = provider.id;
            var locationId = provider.customHMHS.locationSelected.locationId;
            plansAcceptedService.getPlansForProvider(providerId,locationId)
				.then(function(response){
				    var providerDetails = getProviderDetailsDeferred.provider;
				    providerDetails.plansAccepted = response;
				    //TODO: Find business logic reasons for selectedLocation
				    if (providerDetails.selectedLocation == undefined){
				        providerDetails.selectedLocation = getProviderDetailsDeferred.provider.location;
                    }
				    getProviderDetailsDeferred.resolve(providerDetails);
				}, function(fail){
				    allProvidersContent.push(getProviderDetailsDeferred.provider);
                    getProviderDetailsDeferred.resolve(response);
				});
            return getProviderDetailsDeferred.promise;
        }
            var utilities = {
                coveredPlansByProviders: _coveredPlansByProviders,
            }

            function _coveredPlansByProviders(planList, providers, noCache, vitalsLive) {

		        var coveredPlansByProvidersDeferred 	= $q.defer();
                var allProvidersContent 				= [];
                var mergeProviderDetailsDeferredArray 	= [];

                // If no change
                console.log("changes from selectedProviders?", storage.get("selectedProvidersDeltaExists"));
                console.log("Force no cache", noCache);
                if (storage.get("selectedProvidersDeltaExists") || noCache) {

                    angular.forEach(providers, function(provider){
                        mergeProviderDetailsDeferredArray.push(_getPlansFromSapphireDigital(null,provider,allProvidersContent));
                    });

                    $q.all(mergeProviderDetailsDeferredArray).then(function(providers){

                        angular.forEach(providers, function(p) {
                            allProvidersContent.push(p);
                        });
                        allProvidersContentCache = allProvidersContent;
                        coveredPlansByProvidersDeferred.resolve(allProvidersContent);
                    });

                    /****
                     * After all provider detail calls and
                     * After all merges have occured
                     * Return the final providers array
                     ****/
                    // $q.all(mergeProviderDetailsDeferredArray).then(function(){
                    console.log("return NO cache of provider results");
                } else {
                    console.log("returning cache of provider results");
                    coveredPlansByProvidersDeferred.resolve(allProvidersContentCache);
                }

                return coveredPlansByProvidersDeferred.promise;
            }

	    return utilities;

	}])
}