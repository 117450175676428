/**
 * Created by lid4an9 on 08/28/2018.
 */
module.exports = function (app) {
    app.run(["Service", findADrugAutoComplete]);
    app.run(["Service", findADrug]);
    app.run(["Service", coveredDrugs]);
    app.run(["Service", searchByRXCUI]);

    function findADrugAutoComplete(Service) {
         new Service({
            name: 'findADrugAutoComplete',
            group: "cmcrst",
            url: "/cmcrst/x-services/public/druglookup/autocomplete/",
            postData: function (searchText, year) {
                var params = {
                    "year"      : year,
                    "searchtext": searchText,
                    "planids"   : ""
                }
                return this.post(params);
            }
        });
    }


    function findADrug(Service) {
         new Service({
            name: 'findADrug',
            group: "cmcrst",
            url: "/cmcrst/x-services/public/druglookup/",
            postData: function (searchText, year) {
                var params = {
                    "year"      : year,
                    "searchtext": searchText,
                    "planids"   : ""
                }
                return this.post(params);
            }
        });
    }


    function coveredDrugs(Service) {
         new Service({
            name: 'coveredDrugs',
            group: "cmcrst",
            url: "/cmcrst/x-services/public/druglookup/covered",
            postData: function (commaSeparatedHIOS, commaSeparatedRXCUI, year) {
                var params = {
                    "year"      : year,
                    "searchtext": commaSeparatedRXCUI,
                    "planids"   : commaSeparatedHIOS
                }
                return this.post(params);
            }
        });
    }

    function searchByRXCUI(Service) {
         new Service({
            name: 'searchByRXCUI',
            group: "cmcrst",
            url: "/cmcrst/x-services/public/druglookup/plandrug",
            postData: function (searchText, year) {
                var params = {
                    "year"      : year,
                    "searchtext": searchText,
                    "planids"   : ""
                }
                return this.post(params);
            }
        });
    }
};