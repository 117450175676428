module.exports = function(app) {
  return app.factory('loginManager', [
    'storage',
    '$q',
    'services',
    '$cookies',
    'sessionData',
    function(storage, $q, services, $cookies, sessionData) {
      var utilities = {
        producerAttributeHelper: _producerAttributeHelper,
        getUserRole: _getUserRole,
        isProducer: _isProducer,
        isConsumer: _isConsumer
      };

      var producerRoles = ['INTERNAL', 'AGENT', 'AGENCY_ADMIN'];

      /**
       * @description - will return logged in producer object including prospect data if logged in.
       * otherwise, returns null
       */
      function _producerAttributeHelper() {
        var deferred = $q.defer();
        console.log(sessionData);
        var retVal = {
          isProducer: false,
          hasTelesales: false,
          loginData: null,
          teleTraining: false
        };

        var dataStorageCache = storage.get('producerAttributeHelper');
        
        if (dataStorageCache) {
          console.log(
            "producerAttributeHelper from 'storage': ",
            dataStorageCache
          );
          deferred.resolve(dataStorageCache);
        } else {
          if ($cookies.get('LtpaToken2')) {
            
            /****
             * After coming from Producer portal, grab the data and store it for caching
             ****/
            services.cmcrst
              .getSessionStorageData({
                userType: 'PRODUCER',
                customerId: 'LOGINDATA'
              })
              .get()
              .then(
                function(sessionStorageResponse) {
                  sessionData.loginData.personalizationID =
                    sessionStorageResponse.data.payload.sessionStorage.data.loginData.personalizationID;
                  sessionData.loginData.WCTrustedToken =
                    sessionStorageResponse.data.payload.sessionStorage.data.loginData.WCTrustedToken;
                  sessionData.loginData.WCToken =
                    sessionStorageResponse.data.payload.sessionStorage.data.loginData.WCToken;
                  sessionData.loginData.userId =
                    sessionStorageResponse.data.payload.sessionStorage.data.loginData.userId;

                  var values = {
                    personalizationID: sessionData.loginData.personalizationID,
                    WCTrustedToken: sessionData.loginData.WCTrustedToken,
                    WCToken: sessionData.loginData.WCToken,
                    userId: sessionData.loginData.userId
                  };
                  var stringTokens = JSON.stringify(values);

                  document.cookie =
                    'CommerceTokens=' +
                    encodeURI(stringTokens) +
                    ';domain=.highmark.com;path=/;';

                  var dataObject =
                    sessionStorageResponse.data.payload.sessionStorage.data;

                  /****
                   * Re-set the value in sessionStorage in case of navigation to 2.5 site
                   ****/
                  services.cmcrst
                    .setSessionStorageData()
                    .set('PRODUCER', 'LOGINDATA', dataObject)
                    .then(function(setSessionStorageResponse) {
                      console.log(
                        'setSessionStorageResponse',
                        setSessionStorageResponse
                      );
                    });

                  console.log('sessionStorageResponse from XHR:', dataObject);

                  var constructedDataObject = {
                    familyData: dataObject.familyData,
                    groupData: dataObject.groupData,
                    hasRetailStore: false,
                    hasTelesales: false,
                    isProducer: false,
                    leadData: dataObject.leadData,
                    prospectData: dataObject.prospectData,
                    quoteMode: dataObject.quoteMode,
                    ratingInfoData: dataObject.ratingInfoData,
                    teleTraining: false,
                    loginData: dataObject.loginData
                  };

                  /****
                   * Determine if Producer
                   ****/
                  if (constructedDataObject.loginData.agencies) {
                    constructedDataObject.isProducer = true;

                    /****
                     * Determine if has Telesales and/or Retail Store entitlements
                     ****/
                    if (
                      constructedDataObject.loginData &&
                      constructedDataObject.loginData.selectedAgency
                    ) {
                      for (
                        var entitlementIndex = 0;
                        entitlementIndex <
                        constructedDataObject.loginData.selectedAgency
                          .entitlements.length;
                        entitlementIndex++
                      ) {
                        var curEntitlement =
                          constructedDataObject.loginData.selectedAgency
                            .entitlements[entitlementIndex];
                        if (
                          curEntitlement.descriptionKey === 'TelesalesAgentDP'
                        ) {
                          constructedDataObject.hasTelesales = true;
                        } else if (
                          curEntitlement.descriptionKey === 'RetailStore'
                        ) {
                          constructedDataObject.hasRetailStore = true;
                        }
                      }
                    }
                  } else if (
                    constructedDataObject.loginData.type.actorType ===
                      'AGENT' ||
                    constructedDataObject.loginData.type.actorType ===
                      'AGENCY_ADMIN' ||
                    constructedDataObject.loginData.type.actorType ===
                      'INTERNAL'
                  ) {
                    constructedDataObject.isProducer = true;
                  }

                  /****
                   * Determine if Telesales Training active
                   ****/
                  if (dataObject.teleTraining) {
                    constructedDataObject.teleTraining =
                      dataObject.teleTraining.toString().toUpperCase() ===
                      'TRUE';
                  }

                  console.log(
                    'producerAttributeHelper data set to storage:',
                    constructedDataObject
                  );
                  storage.set('producerAttributeHelper', constructedDataObject);
                  deferred.resolve(constructedDataObject);
                },
                function() {
                  /****
                   * No Producer Data
                   ****/
                  deferred.resolve(null);
                }
              );
          } else {
            
            /****
             * No LTPA2 Cookie
             ****/
            deferred.resolve(null);
          }
        }

        return deferred.promise;
      }

      function _getUserRole() {
        return (
          sessionData &&
          sessionData.basicInfo &&
          sessionData.basicInfo.role &&
          sessionData.basicInfo.role.toUpperCase()
        );
      }

      function _isProducer() {
        return (
          sessionData &&
          sessionData.basicInfo &&
          sessionData.basicInfo.role &&
          producerRoles.indexOf(sessionData.basicInfo.role) !== -1
        );
      }

      function _isConsumer() {
        return (
          sessionData &&
          sessionData.basicInfo &&
          sessionData.basicInfo.role &&
          producerRoles.indexOf(sessionData.basicInfo.role) === -1
        );
      }
      return utilities;
    }
  ]);
};
