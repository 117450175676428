/**
 * Created by lidoj8r on 02/17/2016.
 */
/**
 * Created by lidoj8r on 02/16/2016.
 */
module.exports = function(app) {
    app.controller('pages.enrollment.authorization', ["$state", "$scope", "$sce", "$q", "teamsiteManager",
        function ($state, $scope, $sce, $q, teamsiteManager) {

            teamsiteManager.getContent("notificationOffV2", "DEFAULT").then(
                function(data){
                    $scope.content = data;
                }
            );
            $scope.toNext = function () {
                $state.go("enrollment.authorization");
            };
            $scope.goBack = function () {
                $state.go("enrollment.reviewApplication");
            };
            var childOnly = false;
            $scope.changeContext = function(){
              if (!childOnly){
                  childOnly = true;
                  $("child").load();
              } else {
                  childOnly = false;
              }
            };
        }
    ]);
};