module.exports = function(app) {
    app.controller('findInsurance.household-results', ["$state", "$scope", "$filter", "services","teamsiteManager","brand", "storage", "pageContent", "$rootScope", "ratingInfoModel",
        function ($state, $scope, $filter, services, teamsiteManager, brand, storage, pageContent, $rootScope, ratingInfoModel) {
                
            $scope.content          = null;
            $scope.subsidyAmount    = null;
            $scope.familySize       = null;
            $scope.zip              = null;
            $scope.county           = null;
            $scope.federalPovertyLevel=0;

            $scope.goToStartOfHouseHoldIncome = function() {
                $state.go("findInsurance.household-income");
            };

            $scope.goToViewPlans = function() {
                $scope.disableSubsidySubmit = true;
                $state.go("findInsurance.view-plans", {
                    "noCacheProductService": true
                });
            };

            (function onInit(){
                $scope.content = pageContent;

                var subsidyResponse     = storage.get("subsidyResponse");
                // var ratingInfoPayload   = storage.get("ratingInfoPayload");
                var ratingInfoPayload   = ratingInfoModel.get();

                // Subsidy Data
                if (subsidyResponse) {
                    $scope.subsidyAmount        = subsidyResponse.subsidyAmount;
                    $scope.eligibilityLevel     = subsidyResponse.eligibilityLevel;
                    $scope.federalPovertyLevel  = Math.round(subsidyResponse.federalPovertyLevel);
                }

                // Non-Subsidy Data
                if (ratingInfoPayload) {
                    if (ratingInfoPayload.applicants) {
                        $scope.familySize = ratingInfoPayload.applicants.length;
                    } else {
                        $scope.familySize = 0;
                    }
                    $scope.zip      = ratingInfoPayload.zipCode;
                    $scope.county   = ratingInfoPayload.county;
                }

                // Convert string to float
                var parsedSubsidyValue = parseFloat($scope.subsidyAmount);

                // defaults
                var isOnEx          = false;
                var subsidyAmount   = 0;
                var forceOnExchange = false;

                // On-Exchange because subsidy is not 0
                if (forceOnExchange || parsedSubsidyValue !== 0) {
                    isOnEx          = true;
                    subsidyAmount   = parsedSubsidyValue;
                }
                // Off-Exchange because subsidy is 0
                else {
                    isOnEx          = false;
                    subsidyAmount   = 0;
                }

                // Populate onExchagneObject
                storage.set("onExchangeObject", {
                    "isOnExchange"              : isOnEx,
                    "subsidyAmount"             : subsidyAmount,
                    "hasReturnedFromOnExchange" : false,
                    "eligibilityLevel"          : $scope.eligibilityLevel
                });
                $rootScope.isOnX = isOnEx;

            })();
        }
    ]);
};