module.exports = function(app){
    'use strict';
    app.directive('languageOptions', function () {
        return {
            restrict: 'A',
            replace: true,
            template: require('directives/enrollment/languageOptions.html'),
            scope: {
                spokenOptions: '=',
                readingOptions: "=",
                spokenOptionsLabel: "@",
                readingOptionsLabel: "@",
                selectedSpoken: "=",
                selectedSpokenCode: "=",
                selectedReading: "=",
                selectedReadingCode: "="
            },
            link: function ($scope, elem, $attrs) {
                $scope.setReadingLanguage = function (language) {
                    $scope.selectedReading = language.label;
                    $scope.selectedReadingCode = language.value;
                };

                $scope.setSpokenLanguage = function(language) {
                    $scope.selectedSpoken = language.label;
                    $scope.selectedSpokenCode = language.value;
                };
            }
        };
    });
};