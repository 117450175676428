/**
 * Created by lid4an9 on 08/28/2018.
 */
module.exports = function (app) {
    app.run(["Service", "storage", onExchangeProductService]);
    app.run(["Service", "storage", offExchangeProductService]);
    app.run(["Service", "storage", offExchangeProductService_GCP]);
    function onExchangeProductService(Service, storage) {
        new Service({
            name: 'onExchangeProductService',
            group: "wcs",
            url: "/wcs/x-services/resources/store/:storeId/productservice/onexchange",
            cache: false,

            getProducts: function (params) {
                storage.del("selectedProviders");
                return this.post(params);
            }

        });
    }

    function offExchangeProductService(Service, storage) {

        new Service({
            name: 'offExchangeProductService',
            group: "wcs",
            url: "/wcs/x-services/resources/store/:storeId/productservice",
            cache: false,

            getProducts: function () {
                storage.del("selectedProviders");
                return this.get();
            }
        });

    }

    function offExchangeProductService_GCP(Service, storage) {
        new Service({
            name: 'offExchangeProductService_GCP',
            group: "gcp",
            url: "/retrieveIndividualPlans/individualplans",
            useCorsGCP: true,
            cache: false,
            withCredentials: false,
            attachAppIdHeader: false,

            getProducts: function (payload) {
                storage.del("selectedProviders");
                return this.post(payload);
            }

        });
    }
};