module.exports = function(app) {

    require('./brochuresDisplay.js')(app);
    require('./brochuresData.js')(app);

    app.controller('pages.brochures', ['$state', '$scope', 'brochuresData', 'brand', 'brandConfig', 'navigation',
        function($state, $scope, brochuresData, brand, brandConfig, navigation) {

            $scope.switchRegion = function(){
                // Update the branding
                if($scope.sidebar.region){
                    brand.override = $scope.sidebar.region.referenceId;
                    brandConfig.update();
                    navigation.update();
                    $state.go( $state.current.name, {region: $scope.sidebar.region.regionParam });
                } else {
                    $state.go('brochures.marketplace');
                }
            };

            var updateSidebarRegion = function() {
                var temp;

                for(var i = $scope.sidebar.regionFilter && $scope.sidebar.regionFilter.length -1; i >= 0; i--) {
                    // Sync sidebar region with state param
                    if($scope.sidebar.regionFilter[i].regionParam === $state.params.region) {
                        temp = $scope.sidebar.regionFilter[i];
                        break;
                    }
                } $scope.sidebar.region = temp;
            };

            $scope.$on('$stateChangeSuccess', function(){
                updateSidebarRegion();
            });

            brochuresData.get().then(function(data){
                $scope.headings = data.headings;
                $scope.sidebar.regionFilter = data.regionFilter;
                $scope.showFilter = data.sidebars[$state.current.name.split('.')[1]].showFilter;

                $scope.sidebar.links = [
                    {
                        label: data.sidebars[$state.current.name.split('.')[1]].label,
                        state: $state.current.name,
                        refineTemplate: 'pages/brochures/brochures.filter.html'
                    }
                ];

                updateSidebarRegion();
                $scope.switchRegion();
            });
        }
    ]);
    return [
        {
            state: 'brochures',
            url: '/brochures',
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/category.html'],
                    controller: 'pages.brochures'
                },
                'content@brochures': {
                    template: require('pages/brochures/brochures.html')
                }
            }
        },
        {
            state: 'brochures.marketplace',
            url: '/marketplace/:region',
            views: {
                'content@brochures': {
                    template: require('pages/brochures/brochures.marketplace.html'),
                    controller: 'pages.brochuresDisplay'
                }
            }
        },
        {
            state: 'brochures.producer',
            url: '/smallgroup/:region',
            views: {
                'content@brochures': {
                    template: require('pages/brochures/brochures.producer.html'),
                    controller: 'pages.brochuresDisplay'
                }
            }
        },
        {
            state: 'brochures.genericrx',
            url: '/genericdruglist/:region',
            views: {
                'content@brochures': {
                    template: require('pages/brochures/brochures.genericrx.html'),
                    controller: 'pages.brochuresDisplay'
                }
            }
        }
    ];
};