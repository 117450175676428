module.exports = function (app) {
    'use strict';
    app.directive('numberFormatter', [function () {

        //  See BTS2.5 phone number phone number format
        var autoformat = function (value) {
            if (value) {
                value = String(value);
                value = value.replace(/[^0-9]/g, "");
                // if (value.length === 3 || value.length < 6) {
                //     value = value.replace(/(\d{3})(\d{0,})/, "($1) $2");
                // } else if (value.length === 6 || value.length <= 10) {
                //     value = value.replace(/(\d{3})(\d{3})(\d{0,})/, "($1) $2-$3");
                // }
                if (value.length === 10) {
                    value = value.replace(/[^0-9]/g, "");
                    value = value.replace(/(\d{3})(\d{3})(\d{0,})/, "($1) $2-$3");
                }
            }
            return value;
        };
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                ngModel: "="
            },
            link: function ($scope, elem, $attrs, ctrl) {
                // elem.on('blur', function (e) {
                //     elem.val(autoformat(e.target.value));
                // });

                ctrl.$formatters.push(function (viewValue) {
                    return autoformat(viewValue);
                });

                return ctrl.$parsers.push (function (inputVal) {
                    var displayValue = autoformat(inputVal);
                    ctrl.$setViewValue(displayValue);
                    ctrl.$render();
                    $scope.ngModel = inputVal;
                    return inputVal;
                });
            }
        };
    }]);
};