module.exports = function(app) {
  'use strict';
  app.directive('onAndOffExchangeInterstitial', [
    'teamsiteManager',
    'brand',
    function(teamsiteManager, brand) {
      return {
        restrict: 'A',
        replace: true,
        scope: {
            continueWithPortal: '&'
        },
        template: require('directives/onAndOffExchangeInterstitial.html'),
        link: function($scope, elem, $attrs) {
          teamsiteManager
            .getContent(['exchangeInterstitial'], brand)
            .then(function(content) {
              $scope.content = content;
            });

          
          $scope.externalLink = function() {
              window.location.href = $scope.content.exchangeInterstitial.on.externalUrl;
              // 'https://highmark.healthsherpa.com/?_agent_id=highmark';
          };
          
          $scope.$watch(function() { return brand }, function(newVal, oldVal) {
            if (newVal !== oldVal) {
              teamsiteManager
                .getContent(['exchangeInterstitial'], newVal)
                .then(function(content) {
                  $scope.content = content;
                });
            }
          }, true);
        }
      };
    }
  ]);
};
