/****
 * This is currently used to intercept links of the following format:
 * https://shop{env}.{current domain}{pathname}/#/ -> https://shop{env}.{current domain}{pathname}/#!/
 * 
 * This is due to inconsistency of content and CHMSHP's setup with the #! hashbang as of 2018/10/09
 ****/
module.exports = function(app) {
    app.directive('a', function(){
        return {
            restrict: 'E', // only Elements (<a>),
            link: function(scope, elm, attr) {
                // every time you click on the link
                elm.on('click', function($event) {
                    console.log('event', $event.currentTarget.href)
                    var siteName = (window.location.origin + window.location.pathname + "#/").toUpperCase();
                    if ($event.currentTarget.href.toUpperCase().indexOf(siteName) !== -1) {
                        console.log("your link has been intercepted and modified to handle different hashbang (# -> #!)")
                        window.location = $event.currentTarget.href.replace("#/", "#!/");
                        $event.preventDefault(); // prevents the original link from being contacted
                    }

                    // don't return anything

                })
            }
        }
    })
}