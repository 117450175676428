module.exports = function (app) {
    'use strict';
    app.directive('enrollmentZipCounty', ["errorMessages", 'services', function (errorMessages, services) {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                zipCounty: "=enrollmentZipCounty",
                readOnly: "@?"
            },
            template: require('directives/enrollment/zipCounty.html'),
            link: function ($scope, elem, $attrs) {
                $scope.errorMessages = errorMessages;
                $scope.countyList = [];
                $scope.territories = [
                    {label: "AS", value: "AS"},
                    {label: "AE", value: "AE"},
                    {label: "AP", value: "AP"},
                    {label: "AA", value: "AA"},
                    {label: "DC", value: "DC"},
                    {label: "FM", value: "FM"},
                    {label: "GU", value: "GU"},
                    {label: "MH", value: "MH"},
                    {label: "MP", value: "MP"},
                    {label: "PR", value: "PR"},
                    {label: "VI", value: "VI"}
                ];
                $scope.states = [
                    {label: "AL", value: "AL"},
                    {label: "AK", value: "AK"},
                    {label: "AZ", value: "AZ"},
                    {label: "AR", value: "AR"},
                    {label: "CA", value: "CA"},
                    {label: "CO", value: "CO"},
                    {label: "CT", value: "CT"},
                    {label: "DE", value: "DE"},
                    {label: "FL", value: "FL"},
                    {label: "GA", value: "GA"},
                    {label: "HI", value: "HI"},
                    {label: "ID", value: "ID"},
                    {label: "IL", value: "IL"},
                    {label: "IN", value: "IN"},
                    {label: "IA", value: "IA"},
                    {label: "KS", value: "KS"},
                    {label: "KY", value: "KY"},
                    {label: "LA", value: "LA"},
                    {label: "ME", value: "ME"},
                    {label: "MD", value: "MD"},
                    {label: "MA", value: "MA"},
                    {label: "MI", value: "MI"},
                    {label: "MN", value: "MN"},
                    {label: "MS", value: "MS"},
                    {label: "MO", value: "MO"},
                    {label: "MT", value: "MT"},
                    {label: "NE", value: "NE"},
                    {label: "NV", value: "NV"},
                    {label: "NH", value: "NH"},
                    {label: "NJ", value: "NJ"},
                    {label: "NM", value: "NM"},
                    {label: "NY", value: "NY"},
                    {label: "NC", value: "NC"},
                    {label: "ND", value: "ND"},
                    {label: "OH", value: "OH"},
                    {label: "OK", value: "OK"},
                    {label: "OR", value: "OR"},
                    {label: "PA", value: "PA"},
                    {label: "RI", value: "RI"},
                    {label: "SC", value: "SC"},
                    {label: "SD", value: "SD"},
                    {label: "TN", value: "TN"},
                    {label: "TX", value: "TX"},
                    {label: "UT", value: "UT"},
                    {label: "VT", value: "VT"},
                    {label: "VA", value: "VA"},
                    {label: "WA", value: "WA"},
                    {label: "WV", value: "WV"},
                    {label: "WI", value: "WI"},
                    {label: "WY", value: "WY"}
                ];

                $scope.getCounty = function (zipCode) {
                    var payload = {
                        "payload": {
                            "zipCode": zipCode
                        }
                    };
                    services.cmcrst.countyList().postData(payload).then(
                        function (response) {
                            var i;
                            $scope.countyList = [];
                            for (i = 0; i < response.data.payload.countyList.length; i+=1) {
                                 $scope.countyList.push({
                                    county: response.data.payload.countyList[i].county,
                                    countyCode: response.data.payload.countyList[i].code
                                 });
                            }
                            $scope.selectedCounty = $scope.countyList[0];
                            $scope.zipCounty.county = $scope.countyList[0].county;
                            $scope.zipCounty.countycode = $scope.countyList[0].code;
                        },
                        function (err) {
                        }
                    );
                };

                $scope.$watch("zipCounty", function(zipCounty) {
                    // Initially populate countyList if available
                    if (zipCounty && zipCounty.county) {
                        $scope.countyList = [];
                        $scope.countyList.push({
                            county: zipCounty.county,
                            countyCode: zipCounty.countycode
                        });
                        $scope.selectedCounty = $scope.countyList[0];
                    }

                });

                $scope.setCounty = function (county) {
                    $scope.zipCounty = county;
                };
            }
        };
    }]);
};