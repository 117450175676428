module.exports = function(bootstrap) {
    bootstrap.run(["Service", function (Service) {
        return new Service({
            name: 'customerLookup',
            group: "cmcrst",
            url: "/cmcrst/x-services/secure/customerlookup",
            cache: false,

            postCustomerLookup: function(payload){
                return this.post(payload);
            }
        });
    }]);
};