/**
 * Summary: This directive exists as a wrapper for form submission of FFM redirect
 */

module.exports = function(app){
    'use strict';

    app.directive('ffmRedirectForm', function () {
        return {
            restrict    : 'A',
            replace     : true,
            template    : require('./ffmRedirectFormTemplate.html'),
            scope       : {
                "ffmRedirect": "="
            },
            link        : function (scope, elem) {
                scope.$on("FFMSAMLUpdated", function(evt, saml){
                   
                    // from view plans
                    var samlForm    = document.getElementById("samlResponseForm");
                    var samlInput   = document.getElementById("samlResponse");

                    var nDotPos     = window.location.hostname.indexOf('.');
                    var subDomain   = (window.location.hostname.substring( nDotPos - 5, nDotPos));
                    if( subDomain.indexOf('denv') === -1 && subDomain.indexOf('tenv') === -1 ){
                        subDomain = 'Production';
                    }

                    if (subDomain !== "Production") {
                        samlForm.action = scope.ffmRedirect.samlFormactionQA;
                    }else{
                        samlForm.action = scope.ffmRedirect.samlFormactionProd;
                    }

                    samlInput.value = saml;
                    samlForm.submit();
                });
            }
        };
    });
};