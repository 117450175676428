module.exports = function(app) {
	// Federally Facilitated Marketplace configuration key utility
	return app.factory("configurationUtility", ["storage", "$q", "services", "sessionData", function(storage, $q, services, sessionData) {

		var utilities = {
			getAppConfigValues: _getAppConfigValues
		};

        function _getAppConfigValues() {
            var getAppConfigValuesDeferred = $q.defer();

            services.cmcrst.getAllKeys().get().then(function(data){
                
                var appConfigValues = {};

                if (data && data.data && data.data.payload && Object.prototype.toString.call(data.data.payload.values) === "[object Array]") {
                    angular.forEach(data.data.payload.values, function(item, index) {
                        appConfigValues[item.key] = item.value;
                    });
                }

                getAppConfigValuesDeferred.resolve(appConfigValues);

            });

            return getAppConfigValuesDeferred.promise;
        };

		return utilities;
	}]);
}