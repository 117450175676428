module.exports = function(bootstrap) {
    bootstrap.run(["Service", function (Service) {
        return new Service({
            name: 'ratingInfoService',
            group: "wcs",
            //todo: ask shiva about where this store number is coming from
            url: "/wcs/x-services/resources/store/:storeId/ratingInfo",
            cache: false,

            payload: {},

            putRatingInfoService: function(payload){
                this.payload = payload;
                return this.put({ratingInfo:[payload]});
            },

            getPayload: function(){
                return this.payload;
            }
        });
    }]);
};