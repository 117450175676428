module.exports = function (app) {
    'use strict';
    return app.service('navigationBuilder', ['teamsiteService', 'link', 'navigation', '$q', 'sessionData', 'brand', 'modal',

    function (teamsiteService, link, navigation, $q, sessionData, brand, modal) {

            var mSiteNav = function(id) {
                return "javascript:try {window.opener.nav.goSite('"+ id +"');window.close();}catch(err){alert('The mobile window has unexpectedly closed!');}";
            };
            var findDoctorModal = function() {
                modal.open({
                    classes: "findDoctorModal",
                    template: require('directives/findProviderModal.html'),
                    controller: ["$scope", "brand","sessionData", function($scope, brand, sessionData) {
                        switch (brand.current.sharedBrandCode) {
                            case "hmbcbs":
                                $scope.content = {
                                    "modalTitle": "Highmark has health care providers that are right for you.",
                                    "findDoctor" : {
                                        "url": (sessionData && sessionData.appConfig && sessionData.appConfig.SPLASH && sessionData.appConfig.SPLASH.NEW_PVD_HREF && sessionData.appConfig.SPLASH.NEW_PVD_HREF.booleanValue && sessionData.appConfig.SPLASH.NEW_PVD_HREF.overrideValue) ? sessionData.appConfig.SPLASH.NEW_PVD_HREF.overrideValue : "/chmptl/chm/jsp/providerSearchLink.do",
                                        "target":"_blank"
                                    },
                                    "findPharmacy" : {
                                        "premierPharmPrevHref" : "http://www.medcohealth.com/medco/consumer/mybenefits/pl.jsp?memtype=hmkgold",
                                        "premierPharmCurrentHref" : "https://host1.medcohealth.com/medco/consumer/mybenefits/pl.jsp?memtype=hmkgold3",
                                        "focusedPharmHref" : "http://www.medco.com/medco/consumer/mybenefits/clientPl.jsp?globNav=0&memberNo=OEFOCUSPPOLKUP&memtype=openenrollment",
                                        "medicarePharmHref" : "https://host1.medcohealth.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=hmk",
                                        "nationalPlusNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPREMIEROB",
                                        "nationalNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPremier",
                                        "advantageNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBFocus",
                                        "medicareNetworkHref" : "https://host1.medcohealth.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=hmk"
                                    },
                                    "findEyeCare": "/chmptl/chm/jsp/visionhealth/visionProviderSearch.jsp",
                                    "findDentist" : {
                                        "advantageHref" : "https://secure.ucci.com/tp2fad/?network=070&lang=en&brandid=HM1-",
                                        "advantagePlusHref" : "https://secure.ucci.com/tp2fad/?network=074&lang=en&brandid=HM1-",
                                        "allianceHref" : "https://secure.ucci.com/tp2fad/?network=2&lang=en&brandid=HM1-"
                                    },
                                    "findDrug" : {
                                        "preReform" : {
                                            "progressiveDrugHref" : "http://client.formularynavigator.com/clients/highmark/commercial_20111001.html",
                                            "comprehensiveDrugHref" : "http://client.formularynavigator.com/clients/highmark/commercial.html",
                                            "medicareDrugHref" : "http://client.formularynavigator.com/clients/hm/default.html"
                                        },
                                        "postReform" : {
                                            "progressiveDrugPostReformHref" : "http://client.formularynavigator.com/Search.aspx?siteCode=1791332925",
                                            "comprehensiveDrugPostReformHref" : "http://client.formularynavigator.com/Search.aspx?siteCode=1788724309"
                                        }
                                    }
                                };
                                break;
                            case "hmbcbswv":
                                $scope.content = {
                                    "modalTitle": "Highmark has health care providers that are right for you.",
                                    "findDoctor" : {
                                        "url": (sessionData && sessionData.appConfig && sessionData.appConfig.SPLASH && sessionData.appConfig.SPLASH.NEW_PVD_HREF && sessionData.appConfig.SPLASH.NEW_PVD_HREF.booleanValue && sessionData.appConfig.SPLASH.NEW_PVD_HREF.overrideValue) ? sessionData.appConfig.SPLASH.NEW_PVD_HREF.overrideValue : "https://provdir.highmarkbcbswv.com/?brand=HWV",
                                        "target":"_blank"
                                    },
                                    "findPharmacy" : {
                                        "premierPharmPrevHref" : "http://www.medcohealth.com/medco/consumer/mybenefits/pl.jsp?memtype=hmkgold",
                                        "premierPharmCurrentHref" : "https://host1.medcohealth.com/medco/consumer/mybenefits/pl.jsp?memtype=hmkgold3",
                                        "nationalPlusNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPREMIEROB",
                                        "nationalNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPremier",
                                        "advantageNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBFocus",
                                        "medicareNetworkHref" : "https://host1.medcohealth.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=hmk"


                                    },
                                    "findEyeCare": "http://www.davisvision.com/",
                                    "findDentist" : {
                                        "sectionHref" : "https://secure.ucci.com/ducdws/home.xhtml"
                                    },
                                    "findDrug" : {
                                        "postReform" : {
                                            "progressiveDrugPostReformHref" : "http://client.formularynavigator.com/Search.aspx?siteCode=4909431197",
                                            "comprehensiveDrugPostReformHref" : "http://client.formularynavigator.com/Search.aspx?siteCode=4906449921"
                                        }
                                    }
                                };
                                break;
                            case "hmbcbsde":
                                $scope.content = {
                                    "modalTitle": "Highmark has health care providers that are right for you.",
                                    "findDoctor" : {
                                        "url": (sessionData && sessionData.appConfig && sessionData.appConfig.SPLASH && sessionData.appConfig.SPLASH.NEW_PVD_HREF && sessionData.appConfig.SPLASH.NEW_PVD_HREF.booleanValue && sessionData.appConfig.SPLASH.NEW_PVD_HREF.overrideValue) ? sessionData.appConfig.SPLASH.NEW_PVD_HREF.overrideValue : "/chmptl/chm/jsp/providerSearchLink.do",
                                        "target":"_blank"
                                    },
                                    "findPharmacy" : {
                                        "premierPharmPrevHref" : "http://www.medcohealth.com/medco/consumer/mybenefits/pl.jsp?memtype=hmkgold",
                                        "premierPharmCurrentHref" : "https://host1.medcohealth.com/medco/consumer/mybenefits/pl.jsp?memtype=hmkgold3",
                                        "focusedPharmHref" : "http://www.medco.com/medco/consumer/mybenefits/clientPl.jsp?globNav=0&memberNo=OEFOCUSPPOLKUP&memtype=openenrollment",
                                        "medicarePharmHref" : "https://host1.medcohealth.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=hmk",
                                        "nationalPlusNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPREMIEROB",
                                        "nationalNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPremier",
                                        "advantageNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBFocus",
                                        "medicareNetworkHref" : "https://host1.medcohealth.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=hmk"
                                    },
                                    "findEyeCare": "/chmptl/chm/jsp/visionhealth/visionProviderSearch.jsp",
                                    "findDrug" : {
                                        "preReform" : {
                                            "comprehensiveDrugHref" : "https://client.formularynavigator.com/Search.aspx?siteCode=1546534148"
                                        },
                                        "postReform" : {
                                            "comprehensiveDrugPostReformHref" : "http://client.formularynavigator.com/Search.aspx?siteCode=7597050967"
                                        }
                                    }
                                };
                                break;
                            case "hbs":
                                $scope.content = {
                                    "modalTitle": "Highmark has health care providers that are right for you.",
                                    "findDoctor" : {
                                        "url": (sessionData && sessionData.appConfig && sessionData.appConfig.SPLASH && sessionData.appConfig.SPLASH.NEW_PVD_HREF && sessionData.appConfig.SPLASH.NEW_PVD_HREF.booleanValue && sessionData.appConfig.SPLASH.NEW_PVD_HREF.overrideValue) ? sessionData.appConfig.SPLASH.NEW_PVD_HREF.overrideValue : "/chmptl/chm/jsp/providerSearchLink.do",
                                        "target":"_blank"
                                    },
                                    "findPharmacy" : {
                                        "premierPharmPrevHref" : "http://www.medcohealth.com/medco/consumer/mybenefits/pl.jsp?memtype=hmkgold",
                                        "premierPharmCurrentHref" : "https://host1.medcohealth.com/medco/consumer/mybenefits/pl.jsp?memtype=hmkgold3",
                                        "focusedPharmHref" : "http://www.medco.com/medco/consumer/mybenefits/clientPl.jsp?globNav=0&memberNo=OEFOCUSPPOLKUP&memtype=openenrollment",
                                        "medicarePharmHref" : "https://host1.medcohealth.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=hmk",
                                        "nationalPlusNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPREMIEROB",
                                        "nationalNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPremier",
                                        "advantageNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBFocus",
                                        "medicareNetworkHref" : "https://host1.medcohealth.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=hmk"
                                    },
                                    "findEyeCare": "/chmptl/chm/jsp/visionhealth/visionProviderSearch.jsp",
                                    "findDentist" : {
                                        "advantageHref" : "https://secure.ucci.com/tp2fad/?network=070&lang=en&brandid=HM2-",
                                        "advantagePlusHref" : "https://secure.ucci.com/tp2fad/?network=074&lang=en&brandid=HM2-",
                                        "allianceHref" : "https://secure.ucci.com/tp2fad/?network=2&lang=en&brandid=HM2-"
                                    },
                                    "findDrug" : {
                                        "preReform" : {
                                            "progressiveDrugHref" : "http://client.formularynavigator.com/clients/highmark/commercial_20111001.html",
                                            "comprehensiveDrugHref" : "http://client.formularynavigator.com/clients/highmark/commercial.html",
                                            "medicareDrugHref" : "http://client.formularynavigator.com/clients/hm/default.html"
                                        },
                                        "postReform" : {
                                            "progressiveDrugPostReformHref" : "http://client.formularynavigator.com/Search.aspx?siteCode=1791332925",
                                            "comprehensiveDrugPostReformHref" : "http://client.formularynavigator.com/Search.aspx?siteCode=1788724309"
                                        }
                                    }
                                };
                                break;
                            case "mincr":
                                $scope.content = {
                                    "modalTitle": "Blue Cross and Blue Shield of Minnesota and Blue Plus have health care providers that are right for you.",
                                    "findDoctor": {
                                        "url": (sessionData && sessionData.appConfig && sessionData.appConfig.SPLASH && sessionData.appConfig.SPLASH.NEW_PVD_HREF && sessionData.appConfig.SPLASH.NEW_PVD_HREF.booleanValue && sessionData.appConfig.SPLASH.NEW_PVD_HREF.overrideValue) ? sessionData.appConfig.SPLASH.NEW_PVD_HREF.overrideValue : "https://www.bluecrossmn.com/healthy/public/personal/home/findadoctor/finddoctor",
                                        "target":"_blank"
                                    },
                                    // "findPharmacy" : {
                                    //     "pharmacyHeader":"Want to find a pharmacy near where you live or work? See your benefit booklet for your pharmacy network.",
                                    //     "findAPharmacyHref" : "https://www.myprime.com/v/BCBSMN/COMMERCIAL/en/find-pharmacy.html",
                                    //     "pharmacyFooter":"Please note: These pharmacies participate in our standard pharmacy networks; pharmacy participation can vary depending on the pharmacy network selected for you plan design."
                                    // },
                                    "findDentist" : {
                                        "dentalOverrideContent":"<p>Dental health is important to well-being. All Blue Cross dental plans, use the <a class='find-a-dentist-link-href' href='https://mn.ourdentalcoverage.com/find-a-dentist/' target='_blank'>Advantage Plus 2.0</a> network.</p>",
                                        "dentalOverrideFooter":"You will be leaving our website and entering the United Concordia website to view the providers directory. United Concordia is a separate company contracted to provide the dental network of providers for Blue Cross Blue Shield of Minnesota."
                                    }
                                    // "findDrug" : {
                                    //     "findDrugHeader":"A drug formulary is a preferred list of drugs that cost you less out-of-pocket. Drugs are listed by brand and generic names. See your benefit booklet for your formulary.",
                                    //     "findADrugHref":"https://www.myprime.com/v/BCBSMN/COMMERCIAL/en/find-medicine.html"
                                    // }
                                };
                                break;
                            case "hhic":
                            case "fl":
                            case "lou":
                            case "az":
                            case "neb":
                            case "exc":
                            case "regbcbs":
                            case "regbs":
                            case "wi":
                            case "hz":
                            case "inac":
                            case "mn":
                            case "pp":
                            case "wybcbs":
                            case "bcbsnd":
                                $scope.content = {
                                    "findDoctor" : {
                                        "url": (sessionData && sessionData.appConfig && sessionData.appConfig.SPLASH && sessionData.appConfig.SPLASH.NEW_PVD_HREF && sessionData.appConfig.SPLASH.NEW_PVD_HREF.booleanValue && sessionData.appConfig.SPLASH.NEW_PVD_HREF.overrideValue) ? sessionData.appConfig.SPLASH.NEW_PVD_HREF.overrideValue : "https://provdir.mybenefitshome.com",
                                        "target":"_blank"
                                    },
                                    "findPharmacy" : {
                                        "premierPharmPrevHref" : "http://www.medcohealth.com/medco/consumer/mybenefits/clientPl.jsp?globNav=0&memberNo=OEP2012PPOLKUP&memtype=openenrollment",
                                        "premierPharmCurrentHref" : "http://www.medcohealth.com/medco/consumer/mybenefits/clientPl.jsp?globNav=0&memberNo=1234567899&memtype=openenrollment",
                                        "futureScriptsPharmHref" : "https://www.futurescripts.com/FutureScripts/",
                                        "nationalPlusNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPREMIEROB",
                                        "nationalNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPremier",
                                        "advantageNetworkHref" : "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBFocus"
                                    },
                                    "findDrug" : {
                                        "sectionHref" : "http://client.formularynavigator.com/Search.aspx?siteCode=1158840676"
                                    }
                                };
                                break;
                        }
                        // hide 2015 networks after December, 2016
                        $scope.showFifteenNetworks=new Date().getFullYear() < 2017 ? true : false;
                    }]
                });
            };

            this.buildHeaderFooterTabs = function (jsonfile, filterData) {
                var deferred,
                    self = this;

                deferred = $q.defer();

                teamsiteService.loadFile(jsonfile).then(function (data) {
                    self.createNavItemLinks('headerTabs', data.headerNavTabs, 'shopHome', link, filterData);
                    self.createNavItemLinks('postLogoutUrl', data.postLogoutUrl, 'postLogoutUrl', link, filterData);
                    self.createNavItemLinks('footerLinks', data.footerLinks, 'shopPrePasswordAdditional', link, filterData);
                    self.createNavItemLinks('headerExtraLinks', data.headerExtraLinks, 'shopPostPwdExtraLinks', link, filterData);
                    deferred.resolve(true);
                });

                return deferred.promise;
            };


            this.createNavItemLinks  = function (navigationName, data, category, link, filter) {
                var navItems = navigation.set(navigationName),
                    navLink,
                    linkObj,
                    subLink;

                navItems.links = [];

                angular.forEach(data.links, function (value) {

                    linkObj = {};
                    linkObj.label = value.label;
                    linkObj.filterData = value.filterData;
                    linkObj.icon = !!value.icon;
                    linkObj.rootState = value.rootState;
                    linkObj.state = value.state;

                    if (value.url === 'func') {
                        linkObj.onClick = findDoctorModal;
                    } else {
                        linkObj.url = value.url;
                    }

                    navLink = link.set(category, linkObj);


                    if (value.links) {
                        angular.forEach(value.links, function (value, key) {
                            subLink = link.set(key, value);
                            navLink.links.push(subLink);
                        });
                    }
                    navItems.links.push(navLink);
                });

                if (data.linkFilters) {
                    navItems.linkFilters = data.linkFilters;
                }

                return navItems;
            };

            this.buildShopHeaderFooterTabs = function (filterData) {
                var self = this;
                this.buildHeaderFooterTabs('headerNavTabs', filterData).then(function () {
                    self.updateNavigation();
                });
            };

            this.updateNavigation  = function () {
                navigation.update();
            };

            this.buildPreLoginSideBar = function (viewState) {
                var navLink,
                    navLinks = [],
                    deferred = $q.defer();

                teamsiteService.loadFile('sidebarNavItems').then(function (contentData) {
                    angular.forEach(contentData.preLoginSection.links, function (link) {
                        navLink = {};
                        navLink.heading = link.linkTitle;
                        navLink.label = link.linkText;
                        navLink.href = link.linkHash;
                        if (link.state && link.state === viewState) {
                            navLink.description = link.description;
                            navLink.state = viewState;
                        }
                        navLinks.push(navLink);
                    });
                    deferred.resolve(navLinks);
                });

                return deferred.promise;
            };
    }]);
};
