/**
 * Summary: this directive creates an eligible drawer for the eligibility results page
 *  Params:
 *      plan: plan to be displayed in the drawer
 *
 */

module.exports = function(app){
    'use strict';
    app.controller('Controller',['$scope', function($scope) {

    }]);
    app.directive('eligibleDrawer',['$document','$state', function($document, $state) {
        return {
            template: require('./eligibleDrawer.html'),
            scope: {
                eligibleDrawer: '='
            },
            link: function (scope, elem) {
                //console.log("linker:::",scope);
                scope.isDrawerOpen = false;
                scope.toggleDrawer = function() {
                    scope.isDrawerOpen = !scope.isDrawerOpen;
                };

                scope.comparePlans = function () {
                    $state.go('findInsurance.compare-plans');
                }

                scope.enrollInPlan = function() {
                    /*TODO
                    * SETUP PLAN ENROLLMENT LOGIC
                    */
                    $state.go('enrollment.instructions');
                }
            }
        };
    }]);
};