/**
 * Summary: this directive creates a plan drawer for the view-plans page
 *  Params:
 *      plan: plan to be displayed in the drawer
 *
 */

module.exports = function(app){
    'use strict';
    app.controller('Controller',['$scope', function($scope) {

    }]);
    app.directive('planDrawer',['$document','$state', 'sessionData', '$window', function($document, $state, sessionData, $window) {
        return {
            template: require('./planDrawer.html'),
            scope: {
                planDrawer: '=',
                myCompareModel: '=',
                canCompareModel: '=',
                isLast: "=",
                isFirst: "="
            },
            link: function (scope, elem) {
                console.log("scope.planDrawer",scope.planDrawer)
               // console.log("linker:::",scope);
                scope.isDrawerOpen = false;
                scope.toggleDrawer = function() {
                    scope.isDrawerOpen = !scope.isDrawerOpen;
                };

                scope.comparePlans = function () {
                    $state.go('findInsurance.compare-plans');
                };

                scope.enrollInPlan = function() {
                    /*TODO
                    * SETUP PLAN ENROLLMENT LOGIC
                    */
                    $state.go('enrollment.instructions');
                }

                scope.moreBenefitDetails = function (productId) {
                    console.log("sessionData", sessionData);

                    if (sessionData && sessionData.appConfig && sessionData.appConfig.SOME_FAKE_KEY_THAT_DETERMINES_WHETHER_WE_WANT_TO_GO_TO_4dot0_or_2dot5) {
                        $state.go("findInsurance.plan-details", {
                            "pId": productId
                        });
                    } else {

                        $state.go("findInsurance.plan-details", {
                            "pId": productId
                        });
                        // $window.location = "this is 2.5";
                    }
                }
            }
        };
    }]);
};