/**
 * Created by lida1fh on 12/28/2015.
 * Section 1.0 - Page Layout and general text holders
 * Section 2.0 - Service Calls for page controls
 * Section 3.0 - Document Upload Controls
 */
module.exports = function(app) {
    "use strict";
    app.controller('pages.sep', ["$state", "$scope", "sepDocUpload", "brand", "brandConfig", "navigation", function($state, $scope, sepDocUpload, brand, brandConfig, navigation) {

        brand.override = 'unbrandedhm';
        brandConfig.update();
        navigation.update();

        /** SECTION 1.0 */
        $scope.headings = "Special Enrollment";
        $scope.sidebar = {
            heading: "Special Enrollment Documentation Upload",
            description: "User our document upload application to provide details around the reason for your Special Enrollment.  Once you attach a document to your application, a customer service rep will reach out to you within a few business days."
        };
        $scope.data = sepDocUpload.getDataService();
        $scope.backAction = function() {
            window.history.back();
        };

        /** SECTION 2.0 */
        $scope.findApplication = function() {
            sepDocUpload.getApps($scope.data.appId, $scope.data.dob).then(
                function (application) {
                    $scope.data.application = application.data.payload;
                    sepDocUpload.setDataService($scope.data);
                    if($scope.data.appId ==="12345") {
                        $state.go('sep.results');
                    } else {
                        $state.go('sep.noResults');
                    }
                }
            )
        };
        /** SECTION 3.0 */
        $scope.data.files = {
            list: [],
            properties: {}
        };
        $scope.upload = function(){
            sepDocUpload.setDataService($scope.data);
            sepDocUpload.upload($scope.files.list);
        };

    }]);
    return [
        {
            state: "sep",
            url: "/special-enrollment",
            views: {
                'body@': {
                    template: bootstrap.templates["page-templates/categoryCustom.html"],
                    controller: "pages.sep"
                },
                "content@sep": {
                    template: require("pages/sep/home.html")
                }
            }
        },
        {
            state: "sep.results",
            url: "/results",
            views: {
                "content@sep": {
                    template: require("pages/sep/results.html")
                }
            }
        },
        {
            state: "sep.noResults",
            url: "/results",
            views: {
                "content@sep": {
                    template: require("pages/sep/noResults.html")
                }
            }
        },
        {
            state: "sep.success",
            url: "/success",
            views: {
                "content@sep": {
                    template: require("pages/sep/success.html")
                }
            }
        }
    ];
};