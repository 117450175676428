module.exports = function(app) { 

	"use strict";
    return app.factory("blankEmailApplicationUtility", ["services","storage","$q",function(services,storage,$q) {

        var utilityFunctions = {
        	"getToken" 		: _getToken,
        	"getBlankEmailData"	: _getBlankEmailData
        };

        function _getToken() {
        	// https://shopdenv7.highmark.com/sales/?asdf=1234&fdsa=2838#!/find-insurance/blank-email-application
        	var currentUrl = window.location.href;

			// shopdenv7.highmark.com/sales/?asdf=1234&fdsa=2838#!/find-insurance/blank-email-application
        	var currentUrlWithoutProtocol = currentUrl.replace(/https?:\/\//i, "");

        	// ?asdf=1234&fdsa=2838#!/find-insurance/blank-email-application
        	var currentUrlWithoutHostAndPath = currentUrlWithoutProtocol.replace(window.location.hostname + window.location.pathname, "");

        	// 25, for example
        	var indexOfHashbang 	= currentUrlWithoutHostAndPath.indexOf("#!");

        	// ?asdf=1234&fdsa=2838
        	var queryParamsString 	= currentUrlWithoutHostAndPath.substr(0,indexOfHashbang);
        		queryParamsString	= queryParamsString.replace("?", "");

        	// ["asdf=1234","fdsa=2838"]
        	var queryParamsArray = queryParamsString.split("&");

        	// {"asdf":1234,"fdsa":2838}
        	var queryParamsObject = {};
        	angular.forEach(queryParamsArray, function(queryParamString){
        		var kvp = queryParamString.split("=");
        		queryParamsObject[kvp[0]] = kvp[1];
        	});

        	if (queryParamsObject.token) {
        		return queryParamsObject.token;
        	} else {
        		return false;
        	}
        };

        function _getBlankEmailData(token) {
            // Reset Data
            storage.del("blankEmailApplication_ProducerAttrs");

            var getBlankEmailDataDeferred = $q.defer();
            if (token) {

            	var urlKey = token;

            	services
            		.cmcrst
            		.blankEmailApplicationAttributes()
            		.getAttributes(urlKey)
                	.then(function (response) {
    	                var producerData = {
    	                    "agencyID" 	: null,
    	                    "agentID" 	: null,
    	                    "subject" 	: null,
    	                    "urlKey" 	: urlKey
    	                };

    	                var attributes = response.data.payload.attributes;
    	                angular.forEach(attributes, function (item) {
    	                    switch (item.name) {
    	                        case "AGENCY_ID":
    	                            producerData.agencyID = item.value;
    	                            break;
    	                        case "AGENT_ID":
    	                            producerData.agentID = item.value;
    	                            break;
    	                        case "SUBJECT":
    	                            producerData.subject = item.value;
    	                            break;
    	                    }
    	                });

                        storage.set("blankEmailApplication_ProducerAttrs", producerData);
                        getBlankEmailDataDeferred.resolve(storage.get("blankEmailApplication_ProducerAttrs"));

                        var shopFourDotOhEmailProducerDataCookieName = "SHOP_FOUR_DOT_OH_EMAIL_PRODUCER_DATA";
                        document.cookie = shopFourDotOhEmailProducerDataCookieName + "=" + window.btoa(JSON.stringify(storage.get("blankEmailApplication_ProducerAttrs"))) + ";domain=.highmark.com;path=/;";

    	            }, function(error){
                        getBlankEmailDataDeferred.reject();
                    });
            } else {
                getBlankEmailDataDeferred.resolve({});
            }

            return getBlankEmailDataDeferred;
        };

        return utilityFunctions;
    }]);
};