module.exports = function (app) {
    'use strict';

    app.directive('benefitGrid', ["errorMessages", "$timeout", function (errorMessages, $timeout) {
        return {
            restrict: 'A',
            replace: true,
            template: require('directives/benefitGridTemplate.html'),
            scope: {
                planData: "=",
                pageContent: "=",
                isComparePlans: "=",
                planIndex: "=?"
            },
            link: function ($scope, elem, $attrs) {
                $scope.errorMessages = errorMessages;
            },
            controller: function($scope) {
                $scope.planDetailsConfig = [];

                $scope.consoleLogPlanData = function(){
                    console.log("$scope.planData",$scope.planData);
                };

                $scope.printWindow = function(){
                    window.print();
                };

                $scope.getRowClass = function(order, isLimit) {
                    if ((order + 2) % 2 === 0) {
                        if (isLimit) {
                            return 'odd-row';
                        }
                        return 'even-row';
                    } else {
                        if (isLimit) {
                            return 'even-row';
                        }
                        return 'odd-row';
                    }
                }
                /****
                 * Uses values present on the planData object to determine column sizes for the table,
                 * based on whether they are PPO, preferred, enhanced, regular, etc
                 ****/
                var _normalizeTable = function(){
                    var nonPrescriptionDrugColSize  = 0;
                    var prescriptionDrugColSize     = 0;
                    var inNetworkColumns            = 0;
                    var outOfNetworkColumns         = $scope.planData.lineOfBusiness === "PPO" ? 1 : 0;
                    var numberOfDrugTiers           = 0;
                    var benefitNameDisplayedFactor  = $scope.isComparePlans ? 1 : 0;

                    if ($scope.planData.formatted.preferred) {
                        inNetworkColumns    = 3;
                        numberOfDrugTiers   = 4;
                    } else if ($scope.planData.formatted.enhanced) {
                        inNetworkColumns    = 2;
                        numberOfDrugTiers   = 4;
                    } else {
                        inNetworkColumns    = 1;
                        numberOfDrugTiers   = 4;
                    }

                    var columnNormalizationFactor = 1;

                    var totalBenefitColumns;
                    var lowestCommonDenominator;
                    var labelSize;

                    while (labelSize % 2 !== 0) {
                        columnNormalizationFactor++;
                        totalBenefitColumns         = inNetworkColumns + outOfNetworkColumns;
                        lowestCommonDenominator     = totalBenefitColumns * numberOfDrugTiers * columnNormalizationFactor;
                        labelSize                   = $scope.isComparePlans && $scope.planIndex !== 0 ? 0 : .2*(lowestCommonDenominator/.8);
                        nonPrescriptionDrugColSize  = lowestCommonDenominator / totalBenefitColumns;
                        prescriptionDrugColSize     = lowestCommonDenominator / numberOfDrugTiers;
                    }

                    $scope.displayConfig = {
                        "maxColumns"                    : (totalBenefitColumns * nonPrescriptionDrugColSize) + labelSize,
                        "totalBenefitColumns"           : totalBenefitColumns,
                        "totalBenefitsColSize"          : (totalBenefitColumns * nonPrescriptionDrugColSize),
                        "lowestCommonDenominator"       : lowestCommonDenominator,
                        "labelSize"                     : labelSize,
                        "nonPrescriptionDrugColSize"    : nonPrescriptionDrugColSize,
                        "inNetworkOverallSize"          : nonPrescriptionDrugColSize * inNetworkColumns,
                        "outOfNetworkOverallSize"       : outOfNetworkColumns !== 0 ? (nonPrescriptionDrugColSize * outOfNetworkColumns) : nonPrescriptionDrugColSize,
                        "prescriptionDrugColSize"       : prescriptionDrugColSize,
                        "displayingOutOfNetwork"        : outOfNetworkColumns === 0 ? false : true
                    };

                    console.log ("displayConfig", $scope.displayConfig);
                };


                /****
                 * Loops through sectional items, and sets up individual benefit items
                 ****/
                var _setup_sections_of_table = function(sectionConfigItems, nonPrescriptionDrugAttrConfigItems, prescriptionDrugAttrConfigItems) {
                    angular.forEach(sectionConfigItems, function(sectionConfig, index) {
                        if (!$scope.isComparePlans && sectionConfig.sectionLabel === "<strong>Premium</strong>") {
                                    //do nothing if we are not in view plans and we encounter the premium section
                        } else {
                            var newSectionObj = {
                                "order": sectionConfig.order,
                                "id": sectionConfig.order,
                                "colSpan": sectionConfig.sectionLabel2 ?
                                    $scope.displayConfig.labelSize === 0 ?
                                        $scope.displayConfig.maxColumns : $scope.planIndex === 0 ?
                                        $scope.displayConfig.maxColumns :
                                        $scope.displayConfig.labelSize :
                                    $scope.displayConfig.maxColumns,
                                "htmlLabel": (sectionConfig.sectionLabel.indexOf("Prescription Drugs") > 0 && $scope.displayConfig.displayingOutOfNetwork) ? sectionConfig.sectionLabel.replace("</strong>", " (In-Network)</strong>") : sectionConfig.sectionLabel,
                                "sectionLabel2ColSpan": $scope.displayConfig.totalBenefitsColSize,
                                "sectionLabel2": sectionConfig.sectionLabel2,
                                "benefits": [],
                                "prescriptionDrugBenefits": []
                            };

                            /****
                             * Non-Prescription Drugs
                             ****/
                            for (var attrConfigItem in nonPrescriptionDrugAttrConfigItems) {

                                var newSectionObjBenefitItem = null;

                                newSectionObjBenefitItem = _setup_non_prescriptionDrug_benefits(newSectionObj, "inNetwork", newSectionObjBenefitItem, attrConfigItem, nonPrescriptionDrugAttrConfigItems);
                                newSectionObjBenefitItem = _setup_non_prescriptionDrug_benefits(newSectionObj, "enhanced", newSectionObjBenefitItem, attrConfigItem, nonPrescriptionDrugAttrConfigItems);
                                newSectionObjBenefitItem = _setup_non_prescriptionDrug_benefits(newSectionObj, "preferred", newSectionObjBenefitItem, attrConfigItem, nonPrescriptionDrugAttrConfigItems);
                                newSectionObjBenefitItem = _setup_non_prescriptionDrug_benefits(newSectionObj, "outOfNetwork", newSectionObjBenefitItem, attrConfigItem, nonPrescriptionDrugAttrConfigItems);
                                newSectionObjBenefitItem = _setup_non_prescriptionDrug_benefits(newSectionObj, "limit", newSectionObjBenefitItem, attrConfigItem, nonPrescriptionDrugAttrConfigItems);

                                if (newSectionObjBenefitItem) {
                                    newSectionObj.benefits.push(newSectionObjBenefitItem);
                                }
                            }

                            /****
                             * Prescription Drugs
                             ****/
                            var headerAdded = false;

                            for (attrConfigItem in prescriptionDrugAttrConfigItems) {

                                var newSectionObjBenefitItem = null;

                                newSectionObjBenefitItem = _setup_prescriptionDrug_benefits(newSectionObj, "prescriptionDrug", newSectionObjBenefitItem, attrConfigItem, prescriptionDrugAttrConfigItems, headerAdded, function () {headerAdded = true});
                                if (newSectionObjBenefitItem) {
                                    newSectionObj.prescriptionDrugBenefits.push(newSectionObjBenefitItem);
                                }
                            }
                            $scope.planDetailsConfig.push(newSectionObj);

                            /****
                             * Check for missing benefits, add "N / A"s
                             ****/
                            var anyPreferredAttrs = false;
                            var anyEnhancedAttrs = false;

                            // any missing?
                            angular.forEach($scope.planDetailsConfig, function (section) {
                                angular.forEach(section.benefits, function (benefit) {
                                    if (benefit.preferred) {
                                        anyPreferredAttrs = true;
                                    }
                                    if (benefit.enhanced) {
                                        anyEnhancedAttrs = true;
                                    }
                                });
                            });

                            // add them (this fills out the table in the case of missing attrs)
                            angular.forEach($scope.planDetailsConfig, function (section) {
                                angular.forEach(section.benefits, function (benefit) {

                                    if (!benefit.isLimit) {
                                        if (anyPreferredAttrs) {
                                            if (!benefit["preferred"]) { benefit["preferred"] = "N / A"; }
                                        }
                                        if (anyEnhancedAttrs) {
                                            if (!benefit["enhanced"]) { benefit["enhanced"] = "N / A"; }
                                        }

                                        if (!benefit["inNetwork"]) { benefit["inNetwork"] = "N / A"; }
                                        if (!benefit["outOfNetwork"]) { benefit["outOfNetwork"] = "N / A"; }
                                    }
                                });
                            });
                        }
                    });

                    console.log("planDetailsConfig", $scope.planDetailsConfig);
                };

                /****
                 * Used inside of _setup_sections of table
                 ****/
                var _setup_non_prescriptionDrug_benefits = function(newSectionObj, attributeName, benefitItem, attrConfigItem, nonPrescriptionDrugAttrConfigItems) {
                    if ($scope.planData.formatted[attributeName]) {
                        angular.forEach($scope.planData.formatted[attributeName], function(benefitValue, benefitAttributeName){
                            if (nonPrescriptionDrugAttrConfigItems[attrConfigItem].sectionFK        === newSectionObj.id &&
                                nonPrescriptionDrugAttrConfigItems[attrConfigItem].attributeName    === benefitAttributeName) {

                                if (!benefitItem) {
                                    benefitItem = {
                                        "order"                     : nonPrescriptionDrugAttrConfigItems[attrConfigItem].order,
                                        "htmlLabel"                 : nonPrescriptionDrugAttrConfigItems[attrConfigItem].htmlLabel,
                                        "stretchAcrossBenefitName"  : nonPrescriptionDrugAttrConfigItems[attrConfigItem].stretchAcrossBenefitName.toUpperCase() === "TRUE",
                                        "hasLimit"                  : nonPrescriptionDrugAttrConfigItems[attrConfigItem].hasLimit.toUpperCase() === "TRUE",
                                        "isLimit"                   : nonPrescriptionDrugAttrConfigItems[attrConfigItem].isLimit.toUpperCase() === "TRUE",
                                        "benefitCellColSpan"        : $scope.displayConfig.nonPrescriptionDrugColSize,
                                        "benefitLabelColSpan"       : ($scope.isComparePlans && $scope.planIndex === 0) ? $scope.displayConfig.maxColumns : $scope.displayConfig.labelSize,
                                        "limitCellColSpan"          : $scope.displayConfig.displayingOutOfNetwork ? $scope.displayConfig.inNetworkOverallSize + $scope.displayConfig.outOfNetworkOverallSize : $scope.displayConfig.inNetworkOverallSize,
                                        "stretchedBenefitColSpan"   : $scope.displayConfig.maxColumns
                                    };
                                }
                                if(benefitAttributeName == "monthlyPremiumFormatted"){
                                    if($scope.planData.featuresAdjustedPremium){
                                        benefitItem[attributeName] = "*" + $scope.planData.monthlyAdjustedPremiumFormatted;
                                    } else {
                                        benefitItem[attributeName] = benefitValue;
                                    }

                                } else {
                                    benefitItem[attributeName] = benefitValue;
                                }

                            }
                        });   
                    }

                    return benefitItem;
                };

                /****
                 * Used inside of _setup_sections of table
                 ****/
                var _setup_prescriptionDrug_benefits = function(newSectionObj, attributeName, benefitItem, attrConfigItem, prescriptionDrugAttrConfigItems, headerAdded, updateHeaderCallbackFn) {
                    if ($scope.planData[attributeName]) {

                        angular.forEach($scope.planData[attributeName], function(value, prescriptionDrugType) {

                            if (prescriptionDrugAttrConfigItems[attrConfigItem].sectionFK === newSectionObj.id &&
                                prescriptionDrugAttrConfigItems[attrConfigItem].attributeName === prescriptionDrugType) {

                                if (!headerAdded) {
                                    var prescriptionDrugHeader = {
                                        "order": 0,
                                        "htmlLabel": "<strong>Tiers</strong>",
                                        "hasLimit": false,
                                        "isLimit": false,
                                        "benefitCellColSpan": $scope.displayConfig.prescriptionDrugColSize,
                                        "benefitLabelColSpan": $scope.displayConfig.labelSize,
                                        "limitCellColSpan": $scope.displayConfig.inNetworkOverallSize,
                                        "maxColumns": $scope.displayConfig.totalBenefitsColSize + $scope.displayConfig.labelSize,
                                        "tier1": "<strong>Tier 1</strong>",
                                        "tier2": "<strong>Tier 2</strong>",
                                        "tier3": "<strong>Tier 3</strong>",
                                        "tier4": "<strong>Tier 4</strong>"
                                    };

                                    newSectionObj.prescriptionDrugBenefits.push(prescriptionDrugHeader);

                                    updateHeaderCallbackFn();
                                }

                                angular.forEach($scope.planData[attributeName][prescriptionDrugType], function(prescriptionDrugBenefit, key){

                                        if (!benefitItem) {
                                            benefitItem = {
                                                "order": prescriptionDrugAttrConfigItems[attrConfigItem].order,
                                                "htmlLabel": prescriptionDrugAttrConfigItems[attrConfigItem].htmlLabel,
                                                "hasLimit": prescriptionDrugAttrConfigItems[attrConfigItem].hasLimit.toUpperCase() === "TRUE",
                                                "benefitCellColSpan": $scope.displayConfig.prescriptionDrugColSize,
                                                "benefitLabelColSpan": $scope.displayConfig.labelSize,
                                                "limitCellColSpan": $scope.displayConfig.inNetworkOverallSize,
                                                "maxColumns": $scope.displayConfig.totalBenefitsColSize
                                            };
                                        }

                                        benefitItem[key] = prescriptionDrugBenefit;
                                });
                            }
                        });
                    }

                    return benefitItem;
                };

                (function onInit(){



                    var nonPrescriptionDrugAttrConfigItems  = $scope.pageContent.planDetailsTableConfiguration.nonPrescriptionDrugAttrConfigItems;
                    var prescriptionDrugAttrConfigItems     = $scope.pageContent.planDetailsTableConfiguration.prescriptionDrugAttrConfigItems;
                    var sectionConfigItems                  = $scope.pageContent.planDetailsTableConfiguration.sectionConfigItems;


                    _normalizeTable();
                    _setup_sections_of_table(sectionConfigItems, nonPrescriptionDrugAttrConfigItems, prescriptionDrugAttrConfigItems);

                })();
            }
        };
    }]);
};