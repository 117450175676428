module.exports = function(app) {
    app.controller('pages.findInsurance.find-a-provider-or-drug.drug', ["$scope", "$state", "$http", "$q", "services", "storage", "modal", "ratingInfoModel",
    	function($scope, $state, $http, $q, services, storage, modal, ratingInfoModel) {

            /*********
             * Functionality:
             *  1) Allows user to search for drugs
             *  2) Drugs are displayed in a format which allows the user to see and select one/many
             *********/


            /****
             * Pagination
             ****/
            $scope.resultPagination = {
                "page": 1,
                "itemsPerPage": "11"
            };
            $scope.selectedPagination = {
                "page": 1,
                "itemsPerPage": "11"
            };

            /****
             * VARIABLES - view
             ****/
            $scope.searchConductedAtLeastOnce   = false;
            $scope.showLoadingIndicator         = false;
            $scope.drugSearchResults            = null;
            $scope.selectedDrugs                = null;
            $scope.currentPatientIndicator      = null;
            

            /****
             * VARIABLES - utility
             ****/
            var ratingInfoPayload       = null;
            var drugFormattedForSave    = [];
            var cachedResponses         = [];
            var lastSearchedBy          = null;


            /****
             * VIEW FUNCTIONS
             ****/
            $scope.addSelectedDrug = function(drug) {
                _removeDrugFromArray(drug, $scope.drugSearchResults, false);
                _addDrugToSelectedDrugs(drug);
                $scope.saveSelectedDrug();
            };

            $scope.removeSelectedDrug = function(drug) {
                _removeDrugFromArray(drug, $scope.selectedDrugs, true);
                _removeDrugFromArray(drug, $scope.selectedDrugs);
                $scope.saveSelectedDrug();
            };

            $scope.searchDrugs = function(searchBy) {
                if (searchBy.length >= 3) {
                	searchBy = searchBy.toUpperCase();
                    lastSearchedBy = searchBy;

                    $scope.showLoadingIndicator = true;

                    services
                    	.cmcrst
                    	.findADrugAutoComplete()
                    	.postData(searchBy, parseInt(ratingInfoPayload.effectiveDate.substr(0,4)))
                    	.then(function success(successResp){
                    		$scope.displayDropdown = true;
                            $scope.autoCompleteResults = angular.copy(successResp.data);
                            $scope.showLoadingIndicator = false;
                    	});
                } else {
                    $scope.autoCompleteResults = null;
                }
            };

            $scope.searchDrugs_final = function(searchBy) {
                $scope.displayDropdown      = false;
                $scope.drugSearchName       = searchBy;
                $scope.autoCompleteResults  = null;
                searchBy                    = searchBy.toUpperCase();
                lastSearchedBy              = searchBy;

                $scope.showLoadingIndicator = true;

                var deferred = $q.defer();

                _getDrugBrandName();

                var queryItem = searchBy;

                services
                	.cmcrst
                	.findADrug()
                	.postData(queryItem, parseInt(ratingInfoPayload.effectiveDate.substr(0,4)))
                	.then(function success(successResp){

                        angular.forEach(successResp.data.drugs, function(drug){
                        	angular.forEach($scope.selectedDrugs, function(selectedDrug) {
                        		if (angular.equals(selectedDrug.rxcui, drug.rxcui)) {
                        			drug.unselectable = true;
                        		}
                        	});
                            drug.searchedVia = searchBy;
                        })

                        $scope.drugSearchResults = angular.copy(successResp.data.drugs);

                        $scope.showLoadingIndicator = false;
                        $scope.searchConductedAtLeastOnce = true;

                        deferred.resolve($scope.drugSearchResults);
                	}, function error(errResp) {
                        $scope.searchConductedAtLeastOnce = true;
                        $scope.showLoadingIndicator = false;
                        $scope.drugSearchResults = angular.copy(cachedResponses[searchBy]);    
                        deferred.reject($scope.drugSearchResults);
                   	})
                
                return deferred.promise;
            };

            $scope.saveSelectedDrug = function() {
                storage.set('selectedPrescriptionDrugs', angular.copy($scope.selectedDrugs));
                storage.save();
                // modal.open({
                //     template: '<div style="width: 100%; display: table; height: 100px; vertical-align: middle; text-align: center; font-weight: bolder;"><span style="display: table-cell; vertical-align: middle; font-size: 2em;">Drugs Saved</span></div>',
                //     onClose: function () {
                //     }
                // });
                console.log(JSON.stringify(storage.get('selectedPrescriptionDrugs')));
            };

            $scope.backToViewPlans = function(){
                $state.go('findInsurance.view-plans');
            };

            /****
             * UTILITY FUNCTIONS
             ****/
            var _getDrugBrandName = function() {
                var brand = brand && brand.current && brand.current.sharedBrandCode ? brand.current.sharedBrandCode : "hmbcbs";
                if(brand === "hmbcbswv"){
                    brand = "ms";
                } else if (brand === "nepa") {
                    brand = "hmbcbs"
                }
                // var queryString = "?brand=" + brand + "&zip=" + zip;

                switch(brand) {
                    case "mincr":
                    case "bcbsmn":
                        brandName = "Minnesota Blue Cross and Blue Shield";
                        break;
                    case "hmbcbs":
                    case "nepa":
                        brandName = "Highmark Blue Cross and Blue Shield";
                        break;
                    case "hmbcbsde":
                        "Highmark Delaware";
                        break;
                    case "ms":
                    case "hmbcbswv":
                        brandName = "HM West Virginia Blue Cross and Blue Shield";
                        break;
                    case "hmbs":
                    case "pbs":
                    default:
                        brandName = "Highmark Blue Shield";
                }

                return brandName;
            };

            /****
             * @actuallyRemove - we don't want to remove from search results, just to disable the button
             ****/
            var _removeDrugFromArray = function(drug, arrayToRemoveFrom, actuallyRemove) {

                // before removing the item, compare against other items
                if ($scope.drugSearchResults && $scope.drugSearchResults.length > 0) {
                    for (var index = 0; index < $scope.drugSearchResults.length; index++) {
                        if ($scope.drugSearchResults[index].rxcui === drug.rxcui) {
                            $scope.drugSearchResults[index].unselectable = false;
                            break;
                        }
                    }
                }

                // loop through current selecteds
                for (var selectedIndex = arrayToRemoveFrom.length - 1; selectedIndex >= 0; selectedIndex--) {
                    var allAttrsMatch_soFar = true;

                    // attempt to match all attribute values
                    for (attr in drug) {

                        // Ignore AngularJS internal attributes - ng-repeat adds the hashKey
                        if (attr.indexOf("$$") === -1) { 

                            // if no match at any point, this is not our drug
                            if (arrayToRemoveFrom[selectedIndex][attr] !== drug[attr]) {
                                allAttrsMatch_soFar = false;
                                break;
                            }

                        }

                    }

                    if (allAttrsMatch_soFar && actuallyRemove) {
                        arrayToRemoveFrom.splice(selectedIndex, 1);
                    }
                }
            };

            var _addDrugToSelectedDrugs = function(drug) {
                if (!$scope.selectedDrugs) {
                    $scope.selectedDrugs = [];
                }
                $scope.selectedDrugs.push(drug);

                angular.forEach($scope.drugSearchResults, function(searchDrug){
                    if (angular.equals(searchDrug.rxcui, drug.rxcui)) {
                        searchDrug.unselectable = true;
                    }
                })

            };

            var _formatItemsForSave = function(drug) {
                
            };

            var _setupTestData = function(){
                $scope.searchDrugs_final("smith", "15323", "10").then(function(scopeDrugs){
                    $scope.addSelectedDrug($scope.drugSearchResults[0]);
                    $scope.addSelectedDrug($scope.drugSearchResults[1]);
                });
            };

            /****
             * INITIALIZATION
             ****/
            (function onInit(){
                var selectedPrescriptionDrugs = storage.get("selectedPrescriptionDrugs");
                if (selectedPrescriptionDrugs) {
                    $scope.selectedDrugs = angular.copy(selectedPrescriptionDrugs);
                }

                // ratingInfoPayload = storage.get("ratingInfoPayload");
                ratingInfoPayload = ratingInfoModel.get();
            })();

            /****
             * Watch Handlers
             ****/
            // $scope.$on("initiateSave", $scope.saveSelectedDrug);

    	}
    ]);
};