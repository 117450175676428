module.exports = function(app) {
    app.controller('enrollment.communication-preferences', ["$state", "$scope","$cookies",
        function ($state, $scope, $cookies) {

            $scope.goBack = function() {
                // Go back to 2.5 other-insurance
               window.location.href = '/#!/enrollment/other-insurance';
            };
            $scope.setCommunicationOption = function() {
                var cookiePreference = "";
                if ($scope.communicationOption) {
                    // If true, set the preference to email (E)
                    cookiePreference = "communicationPreferenceIndicator=E";
                    

                } else {
                    // If false, set the preference to paper (P)
                    cookiePreference = "communicationPreferenceIndicator=P";
                }
                // Need to add the domain so the cookie lives on highmark.com
                document.cookie = cookiePreference + ";domain=.highmark.com;path=/;"
            };

            $scope.toNext = function() {
                // Go forward to 2.5 review-application
                window.location.href = '/#!/enrollment/review-application';
                // Also need to pass along the email that was sent in the modal
            };
            (function onInit(){
               // debugger;
            })();
        }
    ]);
};