/**
 * Created by lid4an9 on 09/26/2018.
 */
module.exports = function (app) {
    app.run(["Service", getAllKeys]);

    function getAllKeys(Service) {
        new Service({
            name: "getAllKeys",
            group: "cmcrst",
            url: "/cmcrst/x-services/applicationconfigurationvalues/list/ONEXCHANGE",
            cache: false,

            get: function(prospect) {
                return this.get();
            }

        });
    };
};