module.exports = function (app) {
    'use strict';
    app.directive('fullName', ["errorMessages", function (errorMessages) {
        return {
            restrict: 'A',
            replace: true,
            template: require('directives/enrollment/fullName.html'),
            scope: {
                fullNameLabel: '@',
                model: "="
            },
            link: function ($scope, elem, $attrs) {
                $scope.errorMessages = errorMessages;
            }
        };
    }]);
};