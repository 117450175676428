module.exports = function(app){
    app.service('agreementsData', ["$http",

        function($http){
              this.get = function(year){
                return deferred = $http.get('/content/agreements/'+year+'/agreementsData.json').then (
                    function(response){
                        return response.data;
                    },function(errorData) {
                        return null;
                    }
                );

            };
        }
    ]);
};