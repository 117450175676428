module.exports = function(app) {
  app.service('consumerFlowBYFService', [
    '$q',
    'sessionData',
    'sharedBYFService',
    'loginManager',
    function($q, sessionData, sharedBYFService, loginManager) {
      var service = {
        initialize: _initialize
      };

      function _getConsumerObj() {
        return sessionData.loginData;
      }

      function _getBirthDate() {
        var consumerObj = _getConsumerObj();
        var dateOfBirthFormatted = null;

        if (consumerObj && consumerObj.dateOfBirth !== '') {
          /** VISITOR */
          dateOfBirthArray = consumerObj.dateOfBirth.split('-');
          dateOfBirthFormatted =
            dateOfBirthArray[1] +
            '/' +
            dateOfBirthArray[2] +
            '/' +
            dateOfBirthArray[0];
        } else if (
          sessionData &&
          sessionData.memberInfo &&
          sessionData.memberInfo.dob
        ) {
          /** MEMBER */
          dateOfBirthFormatted = sessionData.memberInfo.dob;
        }

        return dateOfBirthFormatted;
      }

      function _getFirstName() {
        var consumerObj = _getConsumerObj();
        return consumerObj.firstName;
      }

      function _isLoggedInUserMember() {
        return loginManager.getUserRole() === 'MEMBER';
      }

      function _getZipCode() {
        var consumerObj = _getConsumerObj();

        if (_isLoggedInUserMember()) {
          return consumerObj.zipCode;
        }

        return consumerObj.shoppingZipCode;
      }

      function _initialize() {
        var initializationDeferred = $q.defer();

        var member = {
          fullName: {
            first: _getFirstName()
          },
          gender: '',
          dob: _getBirthDate(),
          relationship: '',
          tobaccoInd: ''
        };

        var family = sharedBYFService.generateNewFamily();

        family.members.push(member);

        sharedBYFService.setDataToSessionStorage(
          _getZipCode(),
          null,
          null,
          null,
          null,
          family
        );

        initializationDeferred.resolve();
        return initializationDeferred.promise;
      }

      return service;
    }
  ]);
};
