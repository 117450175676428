/**
 * Created by lid4an9 on 09/26/2018.
 */
module.exports = function (app) {
    app.run(["Service", getSAML]);

    function getSAML(Service) {
        new Service({
            name: "getSAML",
            group: "dpsext",
            url: "/dpsext/x-services/hcr/v1/saml",
            cache: false,

            post: function(payload) {
                return this.post(payload);
            }

        });
    };
};