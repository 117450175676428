module.exports = function(app){

    app.directive('highLevelPlanDetail', ['$state', '$http', 'sessionData', '$q', 'storage', 'prospectUtility', 'ffmEligibilityUtility', 'hook', 'modal', 'services', 'sepConfigUtility', 'brand', "enrollmentUtility", "$cookies", "ratingInfoModel", "specialEnrollmentPeriodPaperApplicationUtility",
        function ($state, $http, sessionData, $q, storage, prospectUtility, ffmEligibilityUtility, hook, modal, services, sepConfigUtility, brand, enrollmentUtility, $cookies, ratingInfoModel, specialEnrollmentPeriodPaperApplicationUtility) {

            return {
                restrict: 'A',
                replace: true,
                template: require('./highLevelPlanDetail.html'),
                scope: {
                    "highLevelPlanDetail"       : '=',
                    "atComparableLimit"         : '&',
                    "pageContent"               : "=",
                    "windowShopContent"         : "=?",
                    "planYearForCompares"       : '&?',
                    "subsidyValue"              : "=?",
                    "hasReturnedFromFfm"        : "=?",
                    "aptcSliderValue"           : "=",
                    "prepareAdjustedPremium"    : "&",
                    "is2018Plan"                : "=",
                    "forceSuppressAptcTooltip"  : "=?"
                },

                link: function ($scope) {


                    $scope.showAptcPremiumToolTip   = false;
                    $scope.showAdditionalDetails    = true;
                    $scope.showDrugData             = false;
                    $scope.showProviderData         = false;
                    $scope.eligibleForCsr           = false;

                    if($scope.hasReturnedFromFfm){
                        if($scope.highLevelPlanDetail.attributes.miscattribute.COST_SHARING_REDUCTION){
                            $scope.eligibleForCsr = ($scope.highLevelPlanDetail.attributes.miscattribute.COST_SHARING_REDUCTION.value) > 1;
                        }
                    }
                    
                    $scope.toggleAdditionalDetailsDisplay = function() {
                        $scope.showAdditionalDetails = !$scope.showAdditionalDetails;
                        if (!$scope.showAdditionalDetails) {
                            $scope.showDrugData = false;
                            $scope.showProviderData = false;
                        }
                    };

                    $scope.toggleDrugDisplay = function() {
                        $scope.showDrugData = !$scope.showDrugData;
                        $scope.showProviderData = false;
                    };
                    
                    $scope.toggleProviderDisplay = function(productId) {
                        // var brandName = _getProviderBrandName(), productId;
                        // brandName = brandName.replace(/ /g,"+");
                        // var url = "/rbsmbl/x-services/public/provider/v1/data/plan/info?brandName="+brandName;
                        // $http({ method : "GET",url : url}).then(function success(response) {
                        //     console.log("success", response);
                        //     angular.forEach(response.data.payload.providerDataList.providerDataRecord, function (product) {
                        //         if(product.productName.toUpperCase() == highLevelPlanDetail.name.toUpperCase()){
                        //             productId = product.productId;
                        //         }
                        //     });
                        //     storage.set("productId", productId);
                        // }, function errorHandling(response) {
                        //     console.log("error",response);
                        // });
                        $scope.showProviderData = !$scope.showProviderData;
                        $scope.showDrugData = false;
                    };
                    var _getProviderBrandName = function() {
                        var brand = brand && brand.current && brand.current.sharedBrandCode ? brand.current.sharedBrandCode : "hmbcbs";
                        if(brand === "hmbcbswv"){
                            brand = "ms";
                        } else if (brand === "nepa") {
                            brand = "hmbcbs"
                        }
                        // var queryString = "?brand=" + brand + "&zip=" + zip;

                        switch(brand) {
                            case "mincr":
                            case "bcbsmn":
                                brandName = "Minnesota Blue Cross and Blue Shield";
                                break;
                            case "hmbcbs":
                            case "nepa":
                                brandName = "Highmark Blue Cross and Blue Shield";
                                break;
                            case "hmbcbsde":
                                "Highmark Delaware";
                                break;
                            case "ms":
                            case "hmbcbswv":
                                brandName = "HM West Virginia Blue Cross and Blue Shield";
                                break;
                            case "hmbs":
                            case "pbs":
                            default:
                                brandName = "Highmark Blue Shield";
                        }

                        return brandName;
                    };
                    $scope.openAdditionalDetails = function (product) {

                        if($scope.is2018Plan) {
                            // var ratingInfoPayload = storage.get("ratingInfoPayload")
                            var ratingInfoPayload = ratingInfoModel.get();
                            var mergeChar = "/";
                            var fileNameMergeChar = "_";

                            var baseURL = "https://" + window.location.host + "/content/sbcs";

                            var region = $scope.highLevelPlanDetail.region;

                            var individualOrOtherwise = "Individual";

                            var individualOrOtherwiseIndicator = "I";

                            var hios = $scope.highLevelPlanDetail.hiosId;

                            var effectiveDateYYYYMMDD = ratingInfoPayload.effectiveDate.replace(/-/g, "");
                            var effectiveDateYYYY = effectiveDateYYYYMMDD.substr(0, 4);
                            var effectiveDateForSBCFilename = effectiveDateYYYY + "0101";

                            var fileEnding = "SBC.pdf";

                            var fileNameArray = [individualOrOtherwiseIndicator, hios, effectiveDateForSBCFilename, fileEnding];
                            var fileNameString = fileNameArray.join(fileNameMergeChar);

                            // SBC for next year
                            // var filePathArray = [baseURL, nextYear, region, individualOrOtherwise, fileNameString];
                            // SBC for this year
                            // var filePathArray = [baseURL, curYear, region, individualOrOtherwise, fileNameString];
                            // SBC for consumerEffectiveDate
                            var filePathArray = [baseURL, effectiveDateYYYY, region, individualOrOtherwise, fileNameString];
                            var filePathString = filePathArray.join(mergeChar);
                            window.open(filePathString, "_blank");
                        } else {
                            //if it is not a 2018 effective date, go to plan details page
                            if($scope.subsidyValue){
                                product = $scope.prepareAdjustedPremium(product, parseFloat($scope.subsidyValue));
                            }
                            $state.go("findInsurance.plan-details", {
                                "productObjects": [product]
                            });
                        }
                    };

                    $scope.toggleCompareStatus = function(status) {
                        if (!$scope.atComparableLimit()) {
                            if ($scope.highLevelPlanDetail.compareMe) {
                                $scope.highLevelPlanDetail.compareMe = status;
                            } else {
                                $scope.highLevelPlanDetail.compareMe = true;
                            }
                        } else {
                            $scope.highLevelPlanDetail.compareMe = false;
                        }
                    };
                    $scope.showWindowShopModal = function(){
                        modal.open({
                            template: require('modals/window-shopping.html'),
                            controller: require('modals/window-shopping'),
                            scope: $scope
                        });
                    };
                    $scope.showCatastrophicHardStopModal = function(){
                        modal.open({
                            template: require('modals/catastrophic-hard-stop-modal.html'),
                            controller: require('modals/catastrophic-hard-stop-modal'),
                            scope: $scope
                        });
                    };
                    $scope.showCsrSoftStopModal = function(){
                        modal.open({
                            template: require('modals/csr-soft-stop-modal.html'),
                            controller: require('modals/csr-soft-stop-modal'),
                            scope: $scope
                        });

                    };
                    var _getAge = function(dateString)
                    {
                        var today = new Date();
                        var birthDate = new Date(dateString);
                        var age = today.getFullYear() - birthDate.getFullYear();
                        var m = today.getMonth() - birthDate.getMonth();
                        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
                        {
                            age--;
                        }
                        return age;
                    };
                    //returns false only if Catastrophic plan and at least one applicant from the list
                    //is 30 or over
                    $scope.catastrophicEnrollCheck = function(familyInfo, coverageLevel){
                        if(coverageLevel.toLowerCase() !== "catastrophic"){
                            return true;
                        }
                        var applicants = familyInfo.family.members;
                        var canEnroll = true;
                        angular.forEach(applicants, function(value, key){
                            if(_getAge(value.dob) >= 30){
                                canEnroll = false;
                            }
                        })
                        return canEnroll;
                    };




                    $scope.enrollButtonAction = function() {

                        var producerRoles   = ["INTERNAL", "AGENT", "AGENCY_ADMIN"];
                        var isProducer = sessionData && sessionData.basicInfo && sessionData.basicInfo.role && producerRoles.indexOf(sessionData.basicInfo.role) !== -1;
                        var isConsumer = sessionData && sessionData.basicInfo && sessionData.basicInfo.role && producerRoles.indexOf(sessionData.basicInfo.role) === -1;

                        var onExchangeObject = storage.get("onExchangeObject");
                        var familyInfo = storage.get("familyRatingInfo");

                        if (sepConfigUtility.isSepFlow) {
                            /****
                             * Special Enrollment Flow
                             ****/
                            if (onExchangeObject && onExchangeObject.isOnExchange) {
                                /****
                                 * onX SEP Flow
                                 ****/
                                $scope.sendToFFM();
                            } else {
                                /****
                                 * offX SEP Flow
                                 ****/
                                var planYear = familyInfo.family.consumerEffectiveDate.split("/")[2];
                                //remove special character introduced in IE
                                planYear = planYear.replace(/[^ -~]/g,'')
                                var region = familyInfo.countyInfo.region;
                                if (region !== "WY") {
                                    specialEnrollmentPeriodPaperApplicationUtility.getAppByRegionAndPlanYear(region, planYear);
                                } else {
                                    $scope.goToEnrollFlow();   
                                }
                                // var brandCode = brand.current.sharedBrandCode.toUpperCase();
                                // var sepPdfKey = brandCode;
                                // if($scope.pageContent.entityManager.coverLetterPDFLinkHash[brandCode].indexOf(planYear) === -1){
                                //     sepPdfKey += "-lastYear";
                                // }
                                // var pathToPdf = $scope.pageContent.entityManager.coverLetterPDFLinkHash[sepPdfKey];
                                // var pdfUrl;
                                // //do the proper thing depending on the region (mostly opening pdfs, WY will continue to the SEP flow in 2.5)
                                // switch (region) {
                                //     case "WPA":
                                //         //some code
                                //         pdfUrl = "https://shop.highmark.com/" + pathToPdf;
                                //         window.open(pdfUrl, '_blank');
                                //         break;
                                //     case "NEPA":
                                //         pdfUrl = "https://shop.highmark.com/" + pathToPdf;
                                //         window.open(pdfUrl, '_blank');
                                //         //some code
                                //         break;
                                //     case "CPA":
                                //         pdfUrl = "https://shop.highmark.com/" + pathToPdf;
                                //         window.open(pdfUrl, '_blank');
                                //         //some code
                                //         break;
                                //     case "DE":
                                //         pdfUrl = "https://shop.highmark.com/" + pathToPdf;
                                //         window.open(pdfUrl, '_blank');
                                //         //some code
                                //         break;
                                //     case "WV":
                                //         pdfUrl = "https://shop.highmark.com/" + pathToPdf;
                                //         window.open(pdfUrl, '_blank');
                                //         //some code
                                //         break;
                                //     case "MN":
                                //         pdfUrl = "https://shop.bluecrossmnonline.com/" + pathToPdf;
                                //         window.open(pdfUrl, '_blank');
                                //         //some code
                                //         break;
                                //     case "ND":
                                //         pdfUrl = "https://shop.bcbsndportals.com/" + pathToPdf;
                                //         window.open(pdfUrl, '_blank');
                                //         //some code
                                //         break;
                                //     case "WY":
                                //         //TODO: goes to WY 2.5 enrollment flow
                                //         $scope.goToEnrollFlow();
                                //         break;
                                //     default:
                                //         break;
                                // }
                            }
                        } else {
                            if (onExchangeObject && onExchangeObject.isOnExchange) {
                                /****
                                 * On-Exchange, eligible
                                 ****/
                                if (onExchangeObject.hasReturnedFromOnExchange) {

                                    //onX cannot accept enrollment in catastrophic plans
                                    if($scope.highLevelPlanDetail.levelOfCoverage.toLowerCase() !== "catastrophic"){
                                        //check if silver level plan if csr eligible
                                        //if not both, then display soft stop modal before redirect
                                        //var isUserCSREligibleObj = storage.get("isUserCSREligible");
                                        //var isUserCSREligible = isUserCSREligibleObj && isUserCSREligibleObj.isEligible;
                                        if($scope.hasReturnedFromFfm && $scope.highLevelPlanDetail.levelOfCoverage.toLowerCase() !== "silver" && $scope.eligibleForCsr){
                                            $scope.showCsrSoftStopModal();
                                        } else {
                                            /****
                                             * Go to 2.5
                                             ****/
                                            $scope.goToEnrollFlow();
                                        }
                                        // go to 2.5?
                                    } else {
                                        $scope.showCatastrophicHardStopModal();
                                    }

                                }
                                /****
                                 * On-Exchange, confirm eligibility or window shop
                                 ****/
                                else {
                                    var hmRegions = ['WV', 'DE', 'NEPA', 'CPA', 'WPA'];
                                    var curRegion = familyInfo.countyInfo.region;
                                    if (hmRegions.indexOf(curRegion) !== -1) {
                                        if (sepConfigUtility.isWindowShopPeriod()) {
                                            /****
                                             * Window Shopping
                                             ****/
                                            $scope.showWindowShopModal();
                                        } else {

                                            // go to ffm
                                            $scope.sendToFFM();
                                        }
                                    } else {
                                        // go to ffm
                                        $scope.sendToFFM();
                                    }
                                }
                            } else {
                                /****
                                 * Off-Exchange
                                 ****/
                                //window shopping modal should only pop up for HM
                                    //if the appconfig IS_WINDOWSHOP is switched on OR it is the actual window shopping period
                                var hmRegions = ['WV', 'DE', 'NEPA', 'CPA', 'WPA'];
                                var curRegion = familyInfo.countyInfo.region;
                                if (hmRegions.indexOf(curRegion) !== -1) {
                                    if (sepConfigUtility.isWindowShopPeriod()) {
                                        /****
                                         * Window Shopping
                                         ****/
                                        $scope.showWindowShopModal();
                                    } else {
                                        /****
                                         * Send to 2.5
                                         ****/
                                        if($scope.catastrophicEnrollCheck(familyInfo, $scope.highLevelPlanDetail.levelOfCoverage)){
                                            $scope.goToEnrollFlow();
                                            // go to 2.5?
                                        } else {
                                            $scope.showCatastrophicHardStopModal();
                                        }
                                    }
                                } else {

                                    if($scope.catastrophicEnrollCheck(familyInfo, $scope.highLevelPlanDetail.levelOfCoverage)){
                                        $scope.goToEnrollFlow();
                                        /****
                                         * Send to 2.5
                                         ****/
                                        $scope.goToEnrollFlow();
                                    } else {
                                        $scope.showCatastrophicHardStopModal();
                                    }
                                    
                                }
                            }

                        }

                    };
                    
                    $scope.goToFindAProvider = function() {
                        storage.set('catalogueConfiguredShowDrugTab', $scope.showDrugInformation);
                        $state.go("findInsurance.find-a-provider-or-drug.provider");
                    };

                    var _configureEnrollButtonLabel = function() {
                        var onExchangeObject = storage.get("onExchangeObject");

                        if(sepConfigUtility.isOnSep){
                            /****
                             * Special Enrollment Flow, OnX
                             ****/
                            $scope.enrollButtonLabel = "Apply for Tax Credit";
                        } else if (sepConfigUtility.isOffSep){
                            /****
                             * Special Enrollment Flow, OffX
                             ****/
                            $scope.enrollButtonLabel = "Select Plan";
                        } else  {
                            if (onExchangeObject && onExchangeObject.isOnExchange) {
                            /****
                             * On-Exchange, eligible
                             ****/
                                if (onExchangeObject.hasReturnedFromOnExchange) {
                                    $scope.enrollButtonLabel = "Enroll";
                                }
                            /****
                             * On-Exchange, confirm eligibility
                             ****/
                                else {
                                    $scope.enrollButtonLabel = "Apply for Tax Credit";
                                }
                            } else {
                            /****
                             * Off-Exchange, window shopping
                             ****/
                                if (sepConfigUtility.isWindowShopPeriod()) {
                                    $scope.enrollButtonLabel = "Select Plan";
                                } 
                            /****
                             * Off-Exchange, not window shopping
                             ****/
                                else {
                                    $scope.enrollButtonLabel = "Enroll";
                                }
                            }
                        }
                    };

                    $scope.goToEnrollFlow = function(){

                        var onExchangeObject = storage.get("onExchangeObject");
                        if(onExchangeObject && onExchangeObject.isOnExchange){
                            $cookies.put("OnExchangeFlag", "y", {
                                path: "/"
                            });
                        } else {
                            $cookies.put("OnExchangeFlag", "n", {
                                path: "/"
                            });
                        }



                        // var ratingInfoPayload = storage.get("ratingInfoPayload");
                        var ratingInfoPayload = ratingInfoModel.get();
                        console.log("ratingInfoPayload", ratingInfoPayload);

                        var selectedProductInformation = angular.copy($scope.highLevelPlanDetail);

                        var aptcSliderValue = angular.copy($scope.subsidyValue);
                        
                        enrollmentUtility.routeToOldShop(selectedProductInformation, ratingInfoPayload, aptcSliderValue);
                    };

                    $scope.sendToFFM = function(){
                        ffmEligibilityUtility.sendToFFM();
                    };

                    $scope.planYearDifferentFromOtherChecks = function(){
                        /***
                         * Return true if plan year is not the same as existing comparables
                         ****/
                        var activeCheckedYears = $scope.planYearForCompares();

                        var doNotComparePlansOfDifferentYears = sessionData && sessionData.appConfig && sessionData.appConfig.SALES && sessionData.appConfig.SALES.MAY_NOT_COMPARE_PLANS_OF_DIFFERENT_YEARS && sessionData.appConfig.SALES.MAY_NOT_COMPARE_PLANS_OF_DIFFERENT_YEARS.booleanValue;
                        if (doNotComparePlansOfDifferentYears && activeCheckedYears) {
                            return $scope.highLevelPlanDetail.planYear.toUpperCase() !== activeCheckedYears.toUpperCase();
                        }

                        return false;
                    };

                    (function onInit(){
                        _configureEnrollButtonLabel();

                        $scope.showDrugInformation = (function() {
                            return !(
                                $scope.highLevelPlanDetail &&
                                $scope.highLevelPlanDetail.attributes &&
                                $scope.highLevelPlanDetail.attributes.miscattribute &&
                                $scope.highLevelPlanDetail.attributes.miscattribute.HIDE_DRUG_COMPONENT &&
                                $scope.highLevelPlanDetail.attributes.miscattribute.HIDE_DRUG_COMPONENT.value === 'TRUE'
                            );
                        })();

                        if ($scope.subsidyValue && (typeof $scope.subsidyValue).toUpperCase() === "STRING") {
                            $scope.subsidyValue = parseFloat($scope.subsidyValue);
                        }
                    })();
                }
            };
        }
    ]);
};