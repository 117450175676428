module.exports = function(app){
    app.controller("enrollment.payment-review",["$scope",
        function($scope) {

            $scope.data = {
                "paymentReview":{
                    "heading":"Please review your information before submitting",
                    "subText":"Press SUBMIT to process your payment.",
                    "bankAccount":{
                        "accountNumber":"Bank Account Number",
                        "routingNumber":"Routing Number"
                    },
                    "creditCard":{
                        "cardType":"Card Type",
                        "cardNumber":"Card Number",
                        "expirationDate":"Expiration Date"
                    },
                    smb:{
                        "heading":"SEND ME A BILL",
                        "subText":"We will send you a bill for the amount of your first month's premium. You can pay by paper check, cashier's check or money order.",
                        "monthlyPremium":"Monthly Premium:",
                        "paymentDue":"Payment Due Date:",
                        "effectiveDate":"Effective Date:"
                    },
                    "payByPhone": {
                        "heading":"PAY BY PHONE",
                        "subText":"",
                        "monthlyPremium":"Monthly Premium:",
                        "paymentDue": "Payment Due Date:",
                        "effectiveDate": "Effective Date:",
                        "paymentId": "Payment ID #:",
                        "policyNumber": "Marketplace Policy Number:"
                    },
                    "primaryName":"Primary Name on Account",
                    "billingAddress":"Billing Address",
                    "paymentAmount":"Payment Amount:",
                    "paymentDate": "Payment Date:"
                }
            };

            var paymentModel ={
                "primaryName":"John Doe",
                "billingAddress": {
                    "address":{
                        "addressLine1": "Address Line1",
                        "addressLine2": "Address Line2",
                        "city": "Pittsburgh"
                    },
                    zipCounty: {
                        "state": "PA",
                        "zip": "15220",
                        "county": "Allegheny"
                    },
                    "homeAddressTooltip":"Home address"
                },
                "bankAccount":{
                    "routingNumber":"1234567890",
                    "accountNumber":"9999999999",
                    "nameOnAccount":"",
                    "bankDisplay":""
                },
                "creditCard":{
                    "cardType":"Visa",
                    "cardNumber":"40001111122222",
                    "expDate":"09/2090"

                },
                "smb":{
                    "monthlyPremium":"99.99",
                    "paymentDueDate":"09/09/1990",
                    "effectiveDate":"09/09/2090"

                },
                "payByPhone": {
                    "paymentId":"PA000000351235",
                    "policyNumber":"81235182512508"
                },
                "paymentAmount":"999.99",
                "paymentDate": "09/09/9999"
            };

            $scope.paymentModel = paymentModel;
            $scope.reviewCreditCard = false;
            $scope.reviewAch = false;
            $scope.reviewSmb = false;
            $scope.reviewPayByPhone = true;
        }
    ]);
};