module.exports = function(app) {
    app.controller('pages.findInsurance.view-plans-grid', ["$state", "$scope", "$q", "$filter", "$window", "pageContent", "storage", "hmShopDateAndTimeUtility",
        function ($state, $scope, $q, $filter, $window, pageContent, storage, hmShopDateAndTimeUtility) {

            $scope.planData     = null;
            $scope.pageContent  = null;



            $scope.options = [];
            if($scope.featuresAdjustedPremium){
                $scope.options.push({name:'Estimated Monthly Premium',value:'monthlyAdjustedPremium', reverse:false});
            } else {
                $scope.options.push({name:'Estimated Monthly Premium',value:'monthlyPremium', reverse:false});
            }

            $scope.options.push({name:'Plan Out-of-Pocket Max',value:'individualOutOfPocketMax', reverse: false});
            $scope.options.push({name:'Maximum Yearly Spend',value:'oopAndPremium', reverse:false});

            $scope.orderByField = 'cost';
            $scope.reverseSort = false;

            $scope.$parent.backText = "Back to Plan List";
            $scope.viewPlansDisclaimer = "Rates are subject to change. Results are based on the information you supplied. To the extent that such supplied information is not comprehensive or correct, the results may not be accurate.";

            $scope.order = function(predicate) {
                $scope.predicate    = predicate.value;
                $scope.reverse      = predicate.reverse;
                $scope.sortModel    = predicate;
            };

            $scope.maxYearlySpend=function(oopMax,cost){
                return ((parseInt(cost*12))+parseInt(oopMax));
            };
            $scope.yearlyPremium=function(cost){
                return (parseInt(cost*12));
            };
            $scope.printSummary = function() {
                $window.print();
            };

            (function onInit(){

                // Teamsite Content
                $scope.pageContent = pageContent;

                // Product(s) info
                if ($state.params.products) {
                    $scope.planData = angular.copy($state.params.products);

                    angular.forEach($scope.planData, function(plan){
                        if(plan.featuresAdjustedPremium){
                            plan.oopAndPremium = $scope.maxYearlySpend(plan.individualOutOfPocketMax, plan.monthlyAdjustedPremium);
                        } else {
                            plan.oopAndPremium = $scope.maxYearlySpend(plan.individualOutOfPocketMax, plan.monthlyPremium);
                        }

                        if(plan.featuresAdjustedPremium && !$scope.featuresAdjustedPremium){
                            $scope.featuresAdjustedPremium = true;
                            $scope.adjustedPremiumDisclaimer = plan.adjustedPremiumDisclaimer;
                        }
                    });
                }

                // Applicant Info
                var currentFamilyInfo = storage.get("familyRatingInfo");

                if (currentFamilyInfo && currentFamilyInfo.family && currentFamilyInfo.family.members && currentFamilyInfo.family.members.length > 0) {
                    $scope.applicant = {
                        "adultLabel"          : "Adult",
                        "childLabel"          : "Child",
                        "county"              : currentFamilyInfo.countyInfo.county,
                        "effectiveDate"       : currentFamilyInfo.family.consumerEffectiveDate,
                        "name"                : currentFamilyInfo.family.members[0].fullName.first,
                        "numberOfAdults"      : 0,
                        "numberOfChildren"    : 0,
                        "zipCode"             : currentFamilyInfo.zipCode
                    };

                    // Determine number of adults vs children
                    angular.forEach(currentFamilyInfo.family.members, function(person) {
                        var personAge = new Date(person.dob);

                        if (hmShopDateAndTimeUtility.isDateFromTodayYoungerOrOlder(personAge, 18, "older")) {
                            $scope.applicant.numberOfAdults++;
                            if ($scope.applicant.numberOfAdults > 1) {
                                $scope.applicant.adultLabel = "Adults"
                            }
                        } else {
                            $scope.applicant.numberOfChildren++;
                            if ($scope.applicant.numberOfChildren > 1) {
                                $scope.applicant.childLabel = "Children"
                            }
                        }
                    });
                }

                // Initial Ordering
                $scope.order($scope.options[0]);

            })();
        }
    ]);
};
