module.exports = function(app){ 'use strict';
    app.directive('familyMemberInput', ["hmShopDateAndTimeUtility","$rootScope",
        function (hmShopDateAndTimeUtility, $rootScope) {
        return {
            restrict: 'A',
            replace: true,
            template: require('./familyMemberInput.html'),
            scope: {
                member: '=',
                familyDemographics: '=',
                memberIndex: '=',
                isChildOnly: '=',
                isMiddleMember: "=",
                editFamilyMember: "=",
                isCustomerLookup: "="
            },

            link: function ($scope) {

                // RelationshipOptions is effectively "final" and should never be modified, only copied
                var relationshipOptions = ['Subscriber', 'Spouse', 'Domestic Partner', 'Dependent'];
                $scope.relationshipOptions = angular.copy(relationshipOptions);
                $scope.isOfSmokingAge = true;
                $scope.isOfChildAge = false;
                $scope.enableAddDependent = $scope.member.fromLookup == true ? true : false;
                $scope.validationErrors = {
                    firstName: "First name is required",
                    overChildAge: "Over allowed child age",
                    badDate: "Invalid Date.<br/>Expected: MM/DD/YYYY",
                    bornInTheFuture: "Applicant's birth has not yet occurred",
                    isDependentYounger: "Dependent can not be more than 26 years",
                    isSubscriberYounger:"Subscriber can not be less than 18 years",
                    isPartnerYounger: "Spouse/Domestic Partner can not be less than 18 years"
                };
                if ($scope.memberIndex === 0) {
                    $scope.member.relationship = $scope.relationshipOptions[0];
                    $scope.relationshipDisabled = true;
                    updateRelationships();
                } else {

                    $scope.relationshipDisabled = false;

                    //if the member information is coming from the customer lookup service
                    if($scope.member.fromLookup) {
                        //check the relationship coming in with the relationshipOptions array to prepopulate alongside
                        //the rest of the data
                        var memberRelationshipArray = [];
                        angular.forEach(relationshipOptions, function (value, key) {
                            memberRelationshipArray[key] = value.toUpperCase();
                        });
                        //if the relationship is NOT Subscriber, spouse, or domestic partner, then it must be dependent
                        var relationshipIndex = memberRelationshipArray.indexOf($scope.member.relationship.toUpperCase());
                        if (relationshipIndex < 0) {
                            $scope.member.relationship = relationshipOptions[3];
                        } else {
                            $scope.member.relationship = relationshipOptions[relationshipIndex];
                        }
                        //update relationships
                        updateRelationships();
                    }
                }

                $scope.updateRelationships = updateRelationships;

                // These are key value pairings
                $scope.genderOptions = {'F': 'Female', 'M': 'Male'};
                $scope.tobaccoOptions = {'N': 'No', 'Y': 'Yes'};

                // Called when we click the 'X' for a member
                $scope.remove = function() {
                    $scope.$emit('removeMember', $scope.memberIndex, $scope.isMiddleMember);
                };

                // Watch birthday to see if the date makes the applicant under 18
                $scope.$watch("isChildOnly", function(newValue, oldValue) {
                    if ($scope.isChildOnly === true){
                        $scope.member.relationship = $scope.relationshipOptions[3];
                        $scope.relationshipDisabled = true;
                    }else {
                        if ($scope.memberIndex === 0) {
                            $scope.member.relationship = $scope.relationshipOptions[0];
                            $scope.relationshipDisabled = true;
                        } else {
                            $scope.relationshipDisabled = false;
                        }
                    }
                    updateRelationships();
                    $scope.formatDate();
                });


                //Make readonly if value getting populated with producer flow when customer lookup involve
                makeFamilyDetailsReadonly();
                function makeFamilyDetailsReadonly() {
                    if ($scope.member.fromLookup) {
                        $scope.firstNameDisabled = ($scope.member.fullName.first) ? true : false;
                        $scope.dobDisabled = ($scope.member.dob) ? true : false;
                        $scope.genderDisabled = ($scope.member.gender) ? true : false;
                        $scope.relationshipDisabled = $scope.member.relationship ? true : false;
                        $scope.enableEditFamilyMember = false;
                    }  else {
                        $scope.firstNameDisabled = false;
                        $scope.dobDisabled = false;
                        $scope.genderDisabled = false;
                        $scope.relationshipDisabled = false;

                        $scope.enableEditFamilyMember = $scope.memberIndex !== 0 ? true : false;
                    }
                    if ($scope.member.fromLookup && $scope.editFamilyMember) {
                        $scope.firstNameDisabled = false;
                        $scope.dobDisabled = false;
                        $scope.genderDisabled = false;
                        $scope.relationshipDisabled = false;
                        $scope.enableEditFamilyMember = $scope.memberIndex !== 0 ? true : false;
                    }
                }

                function _runDobBirthdayBusinessLogic() {
                    var dobEle = $scope.ctrl['memberForm_' + $scope.memberIndex]['dobInput_' + $scope.memberIndex];

                    // Date is of valid values
                    dobEle.$setValidity("badDate", true);

                    var applicantBirthDate = angular.copy($scope.member.dob);

                    /****
                     * Validate Date on criterion
                     ****/
                    var isUnderSmokingAge           = hmShopDateAndTimeUtility.isDateFromTodayYoungerOrOlder(applicantBirthDate, 18, "younger");
                    var isUnderChildAge             = hmShopDateAndTimeUtility.isDateFromTodayYoungerOrOlder(applicantBirthDate, 21, "younger");
                    var isLegalAdult                = hmShopDateAndTimeUtility.isDateFromTodayYoungerOrOlder(applicantBirthDate, 18, "older");

                    var isBirthDateFuturistic       = hmShopDateAndTimeUtility.isDateFromTodayYoungerOrOlder(applicantBirthDate, 0,  "younger");
                    $scope.isDependentYoungerCheck  = hmShopDateAndTimeUtility.isDateFromTodayYoungerOrOlder(applicantBirthDate, 26, "older");

                    // Determine display/defaults of Tobacco Indicator
                    // if age is under 21 we have to disable tobacco option with No in HM region
                    if (isUnderSmokingAge || (window.location.hostname.indexOf('highmark.com') && isUnderChildAge)){
                        $scope.member.tobaccoInd    = "N";
                        $scope.isOfSmokingAge       = false;
                    } else {
                        $scope.member.tobaccoInd    = null;
                        $scope.isOfSmokingAge       = true;
                    }


                    // Child birthdate logic
                    if ($scope.isChildOnly === true && dobEle.$modelValue && isUnderChildAge !== null){
                        // Younger than 21 (pass)
                        if (isUnderChildAge){
                            dobEle.$setValidity("overChildAge", true);
                            $scope.isOfChildAge = true;
                        }
                        // Older than 21 (fail)
                        else {
                            // Continue as planned
                            dobEle.$setValidity("overChildAge", false);
                            $scope.isOfChildAge = false;
                        }
                    } else {
                        dobEle.$setValidity("overChildAge", true);
                    }


                    // Future Birthdate
                    if (isBirthDateFuturistic && dobEle.$modelValue){
                        dobEle.$setValidity("bornInTheFuture", false);
                    } else {
                        dobEle.$setValidity("bornInTheFuture", true);
                    }

                    if(typeof $scope.member.relationship !== 'undefined'){
                        //Cannot have dependents > 26 year old
                        if($scope.isDependentYoungerCheck && dobEle.$modelValue && ($scope.member.relationship.toUpperCase()) === 'DEPENDENT' && ($scope.member.relationship.toUpperCase()) != 'SPOUSE' && ($scope.member.relationship.toUpperCase()) != 'DOMESTIC PARTNER'){
                            dobEle.$setValidity("isDependentYounger", false);
                        } else{
                            dobEle.$setValidity("isDependentYounger", true);
                        }

                        //Subscriber's age has to be >18 year old
                        if(!isLegalAdult && dobEle.$modelValue && $scope.memberIndex === 0 && ($scope.member.relationship.toUpperCase()) === 'SUBSCRIBER'){
                            dobEle.$setValidity("isSubscriberYounger", false);
                        } else{
                            dobEle.$setValidity("isSubscriberYounger", true);
                        }

                        //Subscriber's age has to be >18 year old
                        if(!isLegalAdult && dobEle.$modelValue && ($scope.member.relationship.toUpperCase()) != 'DEPENDENT' && (($scope.member.relationship.toUpperCase()) === 'SPOUSE' || ($scope.member.relationship.toUpperCase()) === 'DOMESTIC PARTNER')){
                            dobEle.$setValidity("isPartnerYounger", false);
                        } else{
                            dobEle.$setValidity("isPartnerYounger", true);
                        }
                    }
                };

                // Update the relationship options accordingly
                $scope.$on('updateRelationshipOptions', function(event, selectedSingleRelationships){
                    $scope.enableEditFamilyMember = true;
                    // Get original selected value to set the drop-down to at the end
                    var selectedValue = $scope.member.relationship;
                    // Copy the "final" relationship options above
                    var updatedRelationships = angular.copy(relationshipOptions);

                    // For every single selected relationship passed in, splice it from the list
                    angular.forEach(selectedSingleRelationships, function(selectedSingleRelationship){
                        // If this value does not equal the one passed in
                        if(selectedValue != selectedSingleRelationship){
                            // If it's subscriber, remove it from the options
                            if(selectedSingleRelationship == 'Subscriber'){
                                spliceSubscriber(updatedRelationships);
                            // If it's spouse or significant other, remove both of them
                            } else if(selectedSingleRelationship == 'Spouse' || selectedSingleRelationship == 'Domestic Partner'){
                                spliceSignificantOther(updatedRelationships);
                            }
                        }
                    });
                    // Set the updated list to the drop-down options scope variable
                    $scope.relationshipOptions = updatedRelationships;

                    // Make sure the right option is picked
                    var selectedIndex = $scope.relationshipOptions.indexOf(selectedValue);
                    $scope.member.relationship = $scope.relationshipOptions[selectedIndex];
                });

                // Update the relationships
                function updateRelationships(){
                    $scope.$emit('relationshipUpdated');
                    //check the birthday logic on both spouses and dependents
                    _runDobBirthdayBusinessLogic();
                }

                // Splice subscriber from a relationship array
                function spliceSubscriber(updatedRelationships){
                    // Get the index and splice it
                    var index = updatedRelationships.indexOf('Subscriber');
                    updatedRelationships.splice(index, 1);
                };

                // Splice both spouse and significant other from a relationship array
                function spliceSignificantOther(updatedRelationships){
                    var index = updatedRelationships.indexOf('Spouse');
                    updatedRelationships.splice(index, 1);
                    index = updatedRelationships.indexOf('Domestic Partner');
                    updatedRelationships.splice(index, 1);
                };

                function isValidDate(year, month, day) {
                    var d = new Date(year, month, day);
                    if (d.getFullYear() == year && d.getMonth() == month && d.getDate() == day) {
                        return true;
                    }
                    return false;
                };

                $scope.member.isValid = function(){
                    var formController = $scope.ctrl["memberForm_"+$scope.memberIndex];
                    formController.$submitted = true;
                    return formController.$valid;
                };

                $scope.formatDate = function() {
                    var dobEle = $scope.ctrl['memberForm_' + $scope.memberIndex]['dobInput_' + $scope.memberIndex];

                    if ($scope.member.dob) {
                        console.log("$scope.member.dob:"+$scope.member.dob)
                        
                        /****
                         * 1/1/7        = 01/01/2007
                         * 1/01/7       = 01/01/2007
                         * 01/1/7       = 01/01/2007
                         * 01/01/87     = 01/01/1987
                         * 01/01/1987   = 01/01/1987
                         ****/
                        var isValidDateRegex = /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{1}|[0-9]{2}|[0-9]{4})$/;
                        if($scope.member.dob.match(isValidDateRegex) ){
                            var parsedDate = new Date(Date.parse($scope.member.dob));

                            var parsedMonth = (parsedDate.getMonth() + 1).toString();
                            if (parsedMonth.length === 1) {
                                parsedMonth = 0 + parsedMonth;
                            }

                            var parsedDay = (parsedDate.getDate()).toString();
                            if (parsedDay.length === 1) {
                                parsedDay = 0 + parsedDay;
                            }

                            var parsedYear = parsedDate.getFullYear().toString();


                            // Ensures values like 13/03/1923 don't fool regex 
                            // (13 would cause invalidation due to being "next" month)                            
                            if (isValidDate(parsedYear,parsedDate.getMonth(),parsedDay)) {
                                $scope.member.dob = parsedMonth + "/" + parsedDay + "/" + parsedYear;
                                _runDobBirthdayBusinessLogic();
                            } else {
                                dobEle.$setValidity("badDate", false);
                                dobEle.$setValidity("overChildAge", true);

                                dobEle.$setValidity("bornInTheFuture", true);
                                dobEle.$setValidity("isDependentYounger", true);
                                dobEle.$setValidity("isSubscriberYounger", true);
                                dobEle.$setValidity("isPartnerYounger", true);
                            }

                        } 
                        // Doesn't match valid regex
                        else {
                            dobEle.$setValidity("badDate", false);
                            dobEle.$setValidity("overChildAge", true);

                            dobEle.$setValidity("bornInTheFuture", true);
                            dobEle.$setValidity("isDependentYounger", true);
                            dobEle.$setValidity("isSubscriberYounger", true);
                            dobEle.$setValidity("isPartnerYounger", true);
                        }
                    }

                };
             //   });
            }
        };}
    ]);

};