module.exports = function(app){
    'use strict';

    app.controller('prototypes.fileUploadCtrl', ['$scope', 'SEPDocument',
        function($scope, SEPDocument){
            $scope.sidebar = {
                heading: "Upload Documents",
                description: "This is a proof of concept for document upload."
            };

            $scope.files = {
                list: [],
                properties: {}
            };

            $scope.upload = function(){
                SEPDocument.upload($scope.files.list).then(
                    function(response){
                    }
                );
            };
        }
    ]);

    return [
        {
            "state": "prototypeFileUpload",
            "url": "/prototypes/fileUpload",
            "views": {
                "body@": {
                    "template": "require('prototypes/fileUpload.html')",
                    "controller": "prototypes.fileUploadCtrl"
                }
            }
        }
    ];
};