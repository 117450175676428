module.exports = function(app) {
	// Prospect Creation
	return app.factory("prospectUtility", ["sessionData", "$q", "services", function(sessionData, $q, services) {

		var utilities = {
			"createProspect" 		: createProspect,
			"saveProspect" 			: saveProspect,
			"getProspectSummary" 	: getProspectSummary,
			"doesProspectExist" 	: doesProspectExist,
			"updateProspect"		: updateProspect
		}

		function _isLoggedIn() {
			return sessionData && sessionData.loggedIn;
		};

		function getLoginData() {
			console.log("sessionData.loginData", sessionData.loginData);
			return sessionData.loginData;
		};

		function createProspect(isOnX) {
			var createProspectDeferred = $q.defer();

			var userData = getLoginData();

			var prospectPayload = {
				"prospect": {
				"firstName" 		: userData.firstName,
				"lastName" 			: userData.lastName,
				"dob" 				: new Date(userData.dateOfBirth).getTime(),
				"address1" 			: userData.addressLine[0],
				"address2" 			: userData.addressLine[1],
				"shoppingInfo" 		: {
					"brand" 	: userData.shoppingBrand,
					"zipCode" 	: userData.shoppingZipCode ? userData.shoppingZipCode : userData.zipCode
				},
				"city" 				: userData.city,
				"state" 			: userData.state,
				"zipCode" 			: userData.zipCode,
				"email" 			: userData.email1,
				"applicationType" 	: isOnX ? "ONEX" : "OFFEX"
				}
			};

			createProspectDeferred.resolve(prospectPayload);

			return createProspectDeferred.promise;
		};

		function saveProspect(prospect) {
			var savedProspectDeferred = $q.defer();
			services
				.cmcrst
				.saveProspect()
				.save(prospect)
				.then(function(success){
					console.log("success", success);
					savedProspectDeferred.resolve(success.data.payload);
				},function(error){
					console.log("error", error);
					savedProspectDeferred.reject("failed");
				});

			return savedProspectDeferred.promise;
		};

		function getProspectSummary(prospect) {
			var getProspectSummaryDeferred = $q.defer();
			services
				.cmcrst
				.prospectSummary()
				.get({"prospectId": prospect.prospectId})
				.then(function(success){
					console.log("success", success);
					getProspectSummaryDeferred.resolve(success.data.payload);
				},function(error){
					console.log("error", error);
					getProspectSummaryDeferred.reject("failed");
				});

			return getProspectSummaryDeferred.promise;
		};

		function doesProspectExist() {
			var doesProspectExistDeferred = $q.defer();
			services
				.cmcrst
				.doesProspectExist()
				.get()
				.then(function(success){
					console.log("success", success);
					doesProspectExistDeferred.resolve(success.data.payload);
				},function(error){
					console.log("error", error);
					doesProspectExistDeferred.reject("failed");
				});

			return doesProspectExistDeferred.promise;
		};
		function updateProspect(prospect){
			var isProspectUpdated = $q.defer();

			services.cmcrst.updateProspect().postUpdate(prospect, sessionData.loginData.nickName).then(
				function(success){
					console.log("updated prospect");
					isProspectUpdated.resolve(success);
				},
				function(error){
					console.log("error:\t" + error);
					isProspectUpdated.reject("failure");
				}
			)
			return isProspectUpdated.promise;
		}

		return utilities;
	}]);
}