module.exports = function(app) {

	app.directive("modifiedAptcPremium", [function() {
	
        return {
            restrict: 'A',
            replace: true,
            template: require('./modifiedAptcPremiumTemplate.html'),
            scope: {
                monthlyPremium 	: "=",
                aptcValue		: "="
            },
            link: function ($scope) {
                $scope.differenceBetweenMonthlyPremiumAndAPTCValue = 0;

            	if (typeof $scope.aptcValue === "STRING") {
            		aptcValue = parseFloat($scope.aptcValue);
            	}

                $scope.differenceBetweenMonthlyPremiumAndAPTCValue = $scope.monthlyPremium - $scope.aptcValue;
                if ($scope.differenceBetweenMonthlyPremiumAndAPTCValue < 0) {
                    $scope.differenceBetweenMonthlyPremiumAndAPTCValue = 0;
                }
            }
        }

	}]);
}