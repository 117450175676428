module.exports = function (app) {
    app.controller('pages.consumer.savedApplications', ['$scope', 'teamsiteService', 'prospectService',

        function ($scope, teamsiteService, prospectService) {
            $scope.savedResults = [];

            teamsiteService.loadFile("savedProgress").then(function (teamsiteContent) {
                $scope.$parent.headings = teamsiteContent.headings;
                $scope.sidebar.links = teamsiteContent.sidebar.links;
                $scope.pageContent = teamsiteContent.pageContent;
            });

            prospectService.getConsumerProspect().then(function (data) {
                $scope.savedResults = data;
                $scope.showLoading = false;

            });

            $scope.pagination = {
                itemsPerPage: '10'
            };
            $scope.pagination.page = 1;
            $scope.showLoading = true;
        }]);
    return [{
        state: "savedApplications",
        url: "/saved-applications",
        views: {
            "body@": {
               template: bootstrap.templates["page-templates/category.html"],
            },
            "content@savedApplications": {
                template: require("pages/consumer/savedApplications.html"),
                controller: "pages.consumer.savedApplications"
            }
        },
        postPassword: true
    }];
};