module.exports = function(app) {
    app.controller('pages.findInsurance.find-a-provider-or-drug.provider', ["$scope", "$state", "$q", "mapServices", "services", "brand", "storage", "modal", "ratingInfoModel","appConfig",
        function($scope, $state, $q, mapServices, services, brand, storage, modal, ratingInfoModel,appConfig) {

            /*********
             * Functionality:
             *  1) Allows user to search for providers
             *  2) Providers are displayed in a format which allows the user to see and select one/many
             *********/
            /****
             * Pagination
             ****/
            $scope.resultPagination = {
                "page": 1,
                "itemsPerPage": "11"
            };
            $scope.selectedPagination = {
                "page": 1,
                "itemsPerPage": "11"
            };

            /****
             * VARIABLES - view
             ****/
            $scope.searchConductedAtLeastOnce   = false;
            $scope.showLoadingIndicator         = false;
            $scope.providerSearchResults        = null;
            $scope.selectedProviders            = null;
            $scope.currentPatientIndicator      = null;

            /** Used to store successful search params - used later for provider details */
            $scope.recentSearchParams = null;

            $scope.customErrorValues            = {
                "zipCodeInvalid"    : "Enter a valid zip code",
                "providerInvalid"   : "Enter a valid provider last name",
                "minimumCharacters" : "Please enter at least 3 characters."
            };

            $scope.radiusOptions            = [
                { "value": 5,    "label": "5 Miles",   "isDefault": false },
                { "value": 10,   "label": "10 Miles",  "isDefault": false },
                { "value": 25,   "label": "25 Miles",  "isDefault": true  },
                { "value": 50,   "label": "50 Miles",  "isDefault": false },
                { "value": 100,  "label": "100 Miles", "isDefault": false }
            ];
            $scope.searchField_radius = $scope.radiusOptions[1].value;


            /****
             * VIEW FUNCTIONS
             ****/
            $scope.addSelectedProvider = function(provider) {
                if (!provider.unselectable) {
                    _removeProviderFromArray(provider, $scope.providerSearchResults, false);
                    _addProviderToSelectedProviders(provider);
                }
            };

            $scope.newAddSelectedProvider = function(newProvider) {
                if (!$scope.selectedProviders) {
                    $scope.selectedProviders = [];
                } 
                $scope.selectedProviders.push(newProvider);
                $scope.saveSelectedProviders();
            };

            $scope.newRemoveSelectedProvider = function(index) {
                $scope.selectedProviders.splice(index, 1);
                $scope.saveSelectedProviders();
            };

            $scope.newSelectProvider = function(provider) {
                var modalScope = $scope.$new();
                modalScope.provider = JSON.parse(JSON.stringify(provider));
                
                modal.open({
                    template: require('pages/findInsurance/find-a-provider-or-drug/modals/provider-address.html'),
                    controller: require("pages/findInsurance/find-a-provider-or-drug/modals/provider-address"),
                    scope: modalScope,
                    onClose: function() {
                        /** `this` is the modal */
                        var locationSelectedFromModal = JSON.parse(JSON.stringify(this.$scope.model.locationSelected));
                        var newProvider = JSON.parse(JSON.stringify(provider));
                        newProvider.customHMHS = {
                            locationSelected: locationSelectedFromModal,
                            searchedVia: {
                                searchText: $scope.recentSearchParams.searchBy,
                                searchZip: $scope.recentSearchParams.zip.toString(),
                                searchRadius: $scope.recentSearchParams.radius.toString()
                            }
                        };


                        /**
                         * No way to pass data from modal up, without enhancement, this just
                         * directly access the modal's created scope
                         */
                        if (
                            this.$scope &&
                            this.$scope.model &&
                            this.$scope.model.locationSelected &&
                            this.$scope.noWayToEmitButLocationIsGood
                        ) {

                            var noMatches = true;
                            
                            angular.forEach( $scope.selectedProviders, function(p) {
                                if (
                                    p.id === newProvider.id &&
                                    p.customHMHS.locationSelected.locationId === newProvider.customHMHS.locationSelected.locationId
                                ) {
                                    noMatches = false;
                                }
                            })
                            if (noMatches) {
                                $scope.newAddSelectedProvider(newProvider);
                            }
                        }
                    }
                });
            };

            $scope.removeSelectedProvider = function(provider) {
                _removeProviderFromArray(provider, $scope.selectedProviders, true);
            };

            $scope.validateProviderNameInput = function(providerNameValue) {
                var isValidRegex = /^(.{3,})$/;
                if (providerNameValue.match(isValidRegex)){
                    $scope.searchProvidersForm.searchField_name.$setValidity("minimumCharacters", true);
                } else {
                    $scope.searchProvidersForm.searchField_name.$setValidity("minimumCharacters", false);
                }
            };

            $scope.newSearchProviders = function(searchBy, zip, radius) {

                var newSearch = $q.defer();

                mapServices.getLatLngFromFormattedAddress(zip)
                .then(function(results, status) {
                    var latitude = results.latLng.lat;
                    var longitude = results.latLng.lng;
                    
                    var params = {
                        lat: latitude,
                        lng: longitude,
                        limit: 200,
                        query: searchBy,
                        radius: radius,
                        searchPCPOnly: (
                            brand &&
                            brand.current &&
                            brand.current.sharedBrandCode &&
                            brand.current.sharedBrandCode !== "mincr"
                        )
                    };

                    services.dpsext.sapphireDigitalProviderSearch().autoCompleteSearch(params)
                    .then(function(providerResults){
                        console.log(providerResults);
                        
                        /** Store search params */
                        $scope.recentSearchParams = {
                            searchBy: searchBy,
                            zip: zip,
                            radius: radius
                        };

                        /** Store search results */
                        $scope.providerSearchResults = providerResults.data.providers;
                        $scope.searchConductedAtLeastOnce = true;
                        newSearch.resolve();
                    });

                });

                return newSearch.promise;

            };

            $scope.saveSelectedProviders = function() {
                if (angular.equals(storage.get("selectedProviders"), $scope.selectedProviders)){
                    storage.set("selectedProvidersDeltaExists", false);
                    console.log("no change has occurred");
                } else {
                    storage.set("selectedProvidersDeltaExists", true);
                    console.log("change has occurred");
                }

                storage.set('selectedProviders', angular.copy($scope.selectedProviders));
                storage.save();
            };

            $scope.backToViewPlans = function(){
                $state.go('findInsurance.view-plans');
            };

            
            /****
             * INITIALIZATION
             ****/
            (function onInit(){
                // _setupTestData();

                storage.set("selectedProvidersDeltaExists", false);
                storage.save();
                var selectedProviders = storage.get("selectedProviders");
                if (selectedProviders) {
                    $scope.selectedProviders = angular.copy(selectedProviders);
                }

                // var ratingInfoPayload = storage.get("ratingInfoPayload");
                var ratingInfoPayload = ratingInfoModel.get();
                $scope.prePopulatedZipCode = ratingInfoPayload && ratingInfoPayload.zipCode;
            })();


        }
    ]);
};