/*implementation for teamsite service call
* merge with other implementation implementation
*/

module.exports = function (app) {
    return app.factory('teamsiteService', ['$q', '$http', function ($q, $http) {

        var teamsiteService = {},
            endPoint = '/content/HCR/Minnesota/jsonContent/';

        teamsiteService.cachedContent = {};
        teamsiteService.brand = 'DEFAULT';

        teamsiteService.loadFile  = function (fileName) {
            var self = this,
                deferred = $q.defer();

            if (self.checkCached(fileName)) {
                deferred.resolve(self.cachedContent[fileName]);
            } else {
                $http.get(endPoint + fileName + '.json').then(
                    function (response) {
                        self.cachedContent[fileName] = response.data[self.brand];
                        deferred.resolve(response.data[self.brand]);
                    }
                );
            }
            return deferred.promise;
        };


        teamsiteService.loadFiles = function (files) {
            var deferred = $q.defer(),
                promises = {},
                i = files.length;
            while (i--) {
                promises[files[i]] = this.loadFile(files[i]);
            }

            $q.all(promises).then(function (data) {
                deferred.resolve(data);
            });
            return deferred.promise;
        };

        teamsiteService.checkCached = function (fileName) {
            return teamsiteService.cachedContent[fileName];
        };

        return teamsiteService;
    }]);
};