/**
 * Created by LID0VS0 on 10/19/2018.
 */
module.exports = [
    "$scope", "$state", function ($scope) {

        $scope.closeCatastrophicHardStopModal = function() {
            var x = $scope;
            $scope.closeModal();
        };
    }];