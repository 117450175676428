module.exports = function(app) {
    app.controller('enrollment.yourInformation', ["$state", "$scope", "teamsiteService", "$timeout", "applicationFactory",

        function($state, $scope, teamsiteService, $timeout, applicationFactory) {
            teamsiteService.loadFile("yourInformation").then(function(data) {
                $scope.teamsiteContent = data;
                $scope.personInfoContent = data.personalInformationSection;
                $scope.materialContent = data.memberMaterialsSection;
                var application = applicationFactory.createBlankApplication();

                $scope.application = {};
                $scope.personData = application.personData;

                // todo: Configuration
                $scope.personData.ssn.optional = true;
                $scope.personData.ssn.required = true;
                $scope.personData.ssn.mask = true;
                $scope.personData.ssn.value = null;
            });

            $scope.goDependents = function(isValid) {

                if (!isValid) { return; }

                $scope.application.personData = $scope.personData;
                applicationFactory.save($scope.application);
                $state.go("enrollment.dependents-information");
            };
        }
    ]);
};