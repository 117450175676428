
/**
 * Created by LID0VS0 on 11/19/2018.
 */
module.exports = [
    "$scope",
    "$state",
    "storage",
    "$rootScope" ,
    "sessionData",
    "prospectUtility",
    "ffmEligibilityUtility",
    function (
        $scope,
        $state,
        storage,
        $rootScope,
        sessionData,
        prospectUtility,
        ffmEligibilityUtility) {

    $scope.goToViewPlans = function() {
        $state.go('findInsurance.view-plans', {
            "noCacheProductService": true
        });
        $scope.closeModal();
    };

        $scope.sendToFFM = function(){


            $rootScope.isOnX = true;
            storage.set("onExchangeObject", {
                "isOnExchange"              : true,
                "subsidyAmount"             : 0,
                "hasReturnedFromOnExchange" : false,
                "eligibilityLevel"          : ""
            });
            storage.set("subsidyResponse", {
                "subsidyAmount"             : 1,
                "eligibilityLevel"          : 1,
                "federalPovertyLevel"       : 0
            });
            localStorage.setItem("taxCreditInfo",JSON.stringify(storage.get("ratingInfoPayload")));
            $scope.closeModal();

            ffmEligibilityUtility.sendToFFM();

        };
    $scope.goToChip = function() {
        window.open("https://discoverhighmark.com/individuals-families/insurance/children","_blank");
        $scope.closeModal();
    };
    $scope.goToSubsidy = function() {
        $state.go('findInsurance.household-income');
        $scope.closeModal();
    };


    (function onInit(){

        // Populate onExchangeObject
        storage.set("onExchangeObject", {
            "isOnExchange"  : false,
            "subsidyAmount" : 0
        });
        $rootScope.isOnX = false;

    })();
}];
