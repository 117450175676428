module.exports = function(app) {
    'use strict';
    return app.factory('appStatusService', ['$q', '$http', '$location',
        function ($q, $http, $location) {

            var appStatusService = {
                url: "/cmcrst/x-services/prospect",
                stub : "stubs/appStatus.json",
                getAppStatus: function () {
                    var deferred = $q.defer();
                    $http.get(this.stub).then(function (response) {
                        deferred.resolve(response.data.payload);
                    }, function (error) {
                        deferred.resolve(error);
                    });
                    return deferred.promise;
                }
            };

            return appStatusService;
        }
    ]);
};