module.exports = function (app) {
    'use strict';
    app.directive('phoneNumbers', function () {


        return {
            restrict: 'A',
            replace: true,
            template: require('directives/enrollment/phoneNumbers.html'),
            scope: {
                homePhoneLabel: "@",
                workPhoneLabel: "@",
                mobilePhoneLabel: "@",
                primaryPhone: "=",
                workPhone: "=?",
                mobilePhone: "=?"
            },
            link: function ($scope, elem, $attrs) {


            }
        };
    });
};