module.exports = ["$scope", "$state", "storage", "sessionData", "brand", "$timeout", "teamsiteManager", function ($scope, $state, storage, sessionData, brand, $timeout, teamsiteManager) {

	$scope.pageContent = null;

	$scope.brandSearchDone = false;

	$scope.zipCode = "";
	$scope.county = {};

	$scope.isMinnesota = false;
	$scope.isWestVirginia = false;
	$scope.isPennsylvania = false;	
	$scope.isDelaware = false;

    $scope.option1 = function() {
        $scope.closeModal();
    };

    $scope.option2 = function() {
        $scope.closeModal();
    };

    $scope.configWithBrand = function(){
    	console.log("checking....");
    	if (!angular.equals(sessionData.appConfig, {})) {
    		teamsiteManager
    			.getMultiContent(["findProvider"], brand)
    			.then(function(teamsiteContent) {
					// var teamsiteContent = {
				 //        "highmarkWPA": {
				 //            "regionState": "WPA",
				 //            "isWPA": true,
				 //            "isInPA": true,
				 //            "isMN": false,
				 //            "findDoctorLink": "https://www.highmarkbcbs.com/pvd/#/home",
				 //            "findEyeCareLink": "https://idoc.davisvision.com/DavisVision.Member/Highmark/Authentication/MemberHandoff/?group=XU7&groupmember=3408&destinationurl=Highmark/FindAProvider/Index",
				 //            "advantageLink": "https://secure.ucci.com/tp2fad/?network=070&lang=en&brandid=HM1-",
				 //            "advantagePlusLink": "https://secure.ucci.com/tp2fad/?network=074&lang=en&brandid=HM1-",
				 //            "preReformProgressiveLink": "http://client.formularynavigator.com/clients/highmark/commercial_20111001.html",
				 //            "preReformComprehensiveLink": "http://client.formularynavigator.com/clients/highmark/commercial.html",
				 //            "preReformMedicareRxLink": "http://highmark.medicare-approvedformularies.com/",
				 //            "postReformProgressiveLink": "http://client.formularynavigator.com/Search.aspx?siteCode=1791332925",
				 //            "postReformComprehensiveLink": "http://client.formularynavigator.com/Search.aspx?siteCode=1788724309",
				 //            "postReformEssentialLink": "http://client.formularynavigator.com/Search.aspx?siteCode=6571849149",
				 //            "pharmacyLink": {
				 //                "description1": "Want to find a pharmacy near where you live or work? Use our network pharmacy locator to find your most convenient options.",
				 //                "description2": "Your plan information details which network -- Premier, Focused or Medicare -- is part of your benefit.",
				 //                "note": "Please note: These pharmacies participate in our standard pharmacy network; pharmacy participation can vary depending on the pharmacy network selected for your plan design.",
				 //                "currentYearLabel": "For Plans effective January 01, {currentYear}",
				 //                "linkCurrentYear": {
				 //                    "premier2012": {
				 //                        "label": "National Plus Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPREMIEROB"
				 //                    },
				 //                    "premier": {
				 //                        "label": "National Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPremier"
				 //                    },
				 //                    "focused": {
				 //                        "label": "Advantage Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBFocus"
				 //                    },
				 //                    "medicare": {
				 //                        "label": "Medicare Preferred Value Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKMedDPref"
				 //                    },
				 //                    "medicarePref": {
				 //                        "label": "Medicare Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKPlPartnr"
				 //                    }
				 //                },
				 //                "nextYearLabel": "For Plans effective January 01, {nextYear}",
				 //                "linkNextYear": {
				 //                    "nationalPlus": {
				 //                        "label": "National Plus Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPREMIEROB"
				 //                    },
				 //                    "national": {
				 //                        "label": "National Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPremier"
				 //                    },
				 //                    "advantage": {
				 //                        "label": "Advantage Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBFocus"
				 //                    },
				 //                    "medicare": {
				 //                        "label": "Medicare Standard Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKPlPartnr"
				 //                    },
				 //                    "medicarePref": {
				 //                        "label": "Medicare Preferred Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKMedDPref"
				 //                    }
				 //                }
				 //            }
				 //        },
				 //        "highmarkCPA": {
				 //            "regionState": "CPA",
				 //            "isCPA": true,
				 //            "isInPA": true,
				 //            "isMN": false,
				 //            "findDoctorLink": "https://www.highmarkblueshield.com/pvd/#/home",
				 //            "findEyeCareLink": "https://idoc.davisvision.com/DavisVision.Member/Highmark/Authentication/MemberHandoff/?group=XU7&groupmember=3408&destinationurl=Highmark/FindAProvider/Index",
				 //            "advantageLink": "https://secure.ucci.com/tp2fad/?network=070&lang=en&brandid=HM2-",
				 //            "advantagePlusLink": "https://secure.ucci.com/tp2fad/?network=074&lang=en&brandid=HM2-",
				 //            "preReformProgressiveLink": "http://client.formularynavigator.com/clients/highmark/commercial_20111001.html",
				 //            "preReformComprehensiveLink": "http://client.formularynavigator.com/clients/highmark/commercial.html",
				 //            "preReformMedicareRxLink": "http://highmark.medicare-approvedformularies.com/",
				 //            "postReformProgressiveLink": "http://client.formularynavigator.com/Search.aspx?siteCode=1791332925",
				 //            "postReformComprehensiveLink": "http://client.formularynavigator.com/Search.aspx?siteCode=1788724309",
				 //            "postReformEssentialLink": "http://client.formularynavigator.com/Search.aspx?siteCode=6571849149",
				 //            "pharmacyLink": {
				 //                "description1": "Want to find a pharmacy near where you live or work? Use our network pharmacy locator to find your most convenient options.",
				 //                "description2": "Your plan information details which network -- Premier, Focused or Medicare -- is part of your benefit.",
				 //                "note": "Please note: These pharmacies participate in our standard pharmacy network; pharmacy participation can vary depending on the pharmacy network selected for your plan design.",
				 //                "currentYearLabel": "For Plans effective January 01, {currentYear}",
				 //                "linkCurrentYear": {
				 //                    "premier2012": {
				 //                        "label": "National Plus Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPREMIEROB"
				 //                    },
				 //                    "premier": {
				 //                        "label": "National Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPremier"
				 //                    },
				 //                    "focused": {
				 //                        "label": "Advantage Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBFocus"
				 //                    },
				 //                    "medicare": {
				 //                        "label": "Medicare Preferred Value Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKMedDPref"
				 //                    },
				 //                    "medicarePref": {
				 //                        "label": "Medicare Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKPlPartnr"
				 //                    }
				 //                },
				 //                "nextYearlabel": "For Plans effective January 01, {nextYear}",
				 //                "linkNextYear": {
				 //                    "nationalPlus": {
				 //                        "label": "National Plus Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPREMIEROB"
				 //                    },
				 //                    "national": {
				 //                        "label": "National Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPremier"
				 //                    },
				 //                    "advantage": {
				 //                        "label": "Advantage Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBFocus"
				 //                    },
				 //                    "medicare": {
				 //                        "label": "Medicare Standard Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKPlPartnr"
				 //                    },
				 //                    "medicarePref": {
				 //                        "label": "Medicare Preferred Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKMedDPref"
				 //                    }
				 //                }
				 //            }
				 //        },
				 //        "highmarkWV": {
				 //            "regionState": "WV",
				 //            "isWV": true,
				 //            "isInPA": false,
				 //            "isMN": false,
				 //            "findDoctorLink": "https://www.highmarkbcbswv.com/pvd/#/home",
				 //            "findEyeCareLink": "https://idoc.davisvision.com/DavisVision.Member/Highmark/Authentication/MemberHandoff/?group=XU7&groupmember=3408&destinationurl=Highmark/FindAProvider/Index",
				 //            "postReformProgressiveLink": "http://client.formularynavigator.com/Search.aspx?siteCode=4815439887",
				 //            "postReformComprehensiveLink": "http://client.formularynavigator.com/Search.aspx?siteCode=1158840676",
				 //            "postReformEssentialLink": "http://client.formularynavigator.com/Search.aspx?siteCode=6571849149",
				 //            "pharmacyLink": {
				 //                "description1": "Want to find a pharmacy near where you live or work? Use our network pharmacy locator to find your most convenient options.",
				 //                "description2": "Your plan information details which network -- Premier, Focused or Medicare -- is part of your benefit.",
				 //                "note": "Please note: These pharmacies participate in our standard pharmacy network; pharmacy participation can vary depending on the pharmacy network selected for your plan design.",
				 //                "currentYearLabel": "For Plans effective January 01, {currentYear}",
				 //                "linkCurrentYear": {
				 //                    "premier2012": {
				 //                        "label": "National Plus Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPREMIEROB"
				 //                    },
				 //                    "premier": {
				 //                        "label": "National Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPremier"
				 //                    },
				 //                    "focused": {
				 //                        "label": "Advantage Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBFocus"
				 //                    },
				 //                    "medicare": {
				 //                        "label": "Medicare Preferred Value Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKMedDPref"
				 //                    },
				 //                    "medicarePref": {
				 //                        "label": "Medicare Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKPlPartnr"
				 //                    }
				 //                },
				 //                "nextYearLabel": "For Plans effective January 01, {nextYear}",
				 //                "linkNextYear": {
				 //                    "nationalPlus": {
				 //                        "label": "National Plus Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPREMIEROB"
				 //                    },
				 //                    "national": {
				 //                        "label": "National Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPremier"
				 //                    },
				 //                    "advantage": {
				 //                        "label": "Advantage Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBFocus"
				 //                    },
				 //                    "medicare": {
				 //                        "label": "Medicare Standard Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKPlPartnr"
				 //                    },
				 //                    "medicarePref": {
				 //                        "label": "Medicare Preferred Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKMedDPref"
				 //                    }
				 //                }
				 //            }        
				 //        },
				 //        "highmarkDE": {
				 //            "regionState": "DE",
				 //            "isDE": true,
				 //            "isInPA": false,
				 //            "isMN": false,
				 //            "findDoctorLink": "https://www.highmarkbcbsde.com/pvd/#/home",
				 //            "findEyeCareLink": "https://idoc.davisvision.com/DavisVision.Member/Highmark/Authentication/MemberHandoff/?group=XU7&groupmember=3408&destinationurl=Highmark/FindAProvider/Index",
				 //            "preReformComprehensiveLink": "https://client.formularynavigator.com/Search.aspx?siteCode=1546534148",
				 //            "postReformComprehensiveLink": "http://client.formularynavigator.com/Search.aspx?siteCode=7597050967",
				 //            "postReformProgressiveLink": "https://client.formularynavigator.com/Search.aspx?siteCode=3597426829",
				 //            "pharmacyLink": {
				 //                "description1": "Want to find a pharmacy near where you live or work? Use our network pharmacy locator to find your most convenient options.",
				 //                "description2": "Your plan information details which network -- Premier, Focused or Medicare -- is part of your benefit.",
				 //                "note": "Please note: These pharmacies participate in our standard pharmacy network; pharmacy participation can vary depending on the pharmacy network selected for your plan design.",
				 //                "currentYearLabel": "For Plans effective January 01, {currentYear}",
				 //                "linkCurrentYear": {
				 //                    "premier2012": {
				 //                        "label": "National Plus Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPREMIEROB"
				 //                    },
				 //                    "premier": {
				 //                        "label": "National Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPremier"
				 //                    },
				 //                    "focused": {
				 //                        "label": "Advantage Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBFocus"
				 //                    },
				 //                    "medicare": {
				 //                        "label": "Medicare Preferred Value Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKMedDPref"
				 //                    }
				 //                },
				 //                "nextYearLabel": "For Plans effective January 01, {nextYear}",
				 //                "linkNextYear": {
				 //                    "nationalPlus": {
				 //                        "label": "National Plus Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPREMIEROB"
				 //                    },
				 //                    "national": {
				 //                        "label": "National Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBPremier"
				 //                    },
				 //                    "advantage": {
				 //                        "label": "Advantage Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKBFocus"
				 //                    },
				 //                    "medicarePref": {
				 //                        "label": "Medicare Preferred Network Pharmacy",
				 //                        "link": "https://www.express-scripts.com/medco/consumer/mybenefits/medPPOEntry.jsp?memtype=HMKMedDPref"
				 //                    }
				 //                }
				 //            }
				 //        },
				 //        "mincrMN": {
				 //            "isMN": true,
				 //            "regionState": "MINCR",
				 //            "isWPA": false,
				 //            "isInPA": false,
				 //            "isDE": false,
				 //            "isWV": false,
				 //            "modalDescript": "Blue Cross and Blue Shield of Minnesota and Blue Plus have health care providers that are right for you.",
				 //            "findDoctorLink": "https://www.bluecrossmnonline.com/find-a-doctor/",
				 //            "findPharmacy": {
				 //                "pharmPrevHref": "",
				 //                "pharmacyHeader": "Want to find a pharmacy near where you live or work? See your benefit booklet for your pharmacy network.",
				 //                "findAPharmacyHref": "https://myprime.com/v/BCBSMN/COMMERCIAL/en/find-pharmacy.html",
				 //                "pharmacyFooter": "Please note: These pharmacies participate in our standard pharmacy networks; pharmacy participation can vary depending on the pharmacy network selected for you plan design."
				 //            },
				 //            "findDrug": {
				 //                "findDrugHeader": "A drug formulary is a preferred list of drugs that cost you less out-of-pocket. Drugs are listed by brand and generic names. See your benefit booklet for your formulary.",
				 //                "findADrugHref": "https://myprime.com/v/BCBSMN/COMMERCIAL/en/find-medicine.html"
				 //            }
				 //        }
				 //    };

			        var brandConfig = {
			            // Highmark WPA brand IDs
			            "HMBCBS": teamsiteContent["findProvider"]["highmarkWPA"],
			            "HIGHMARKBCBS": teamsiteContent["findProvider"]["highmarkWPA"],
			            "HBCBS": teamsiteContent["findProvider"]["highmarkWPA"],

			            // Highmark CPA brand IDs
			            "HBS": teamsiteContent["findProvider"]["highmarkCPA"],
			            "PBS": teamsiteContent["findProvider"]["highmarkCPA"],
			            "HIGHMARKBLUESHIELD": teamsiteContent["findProvider"]["highmarkCPA"],

			            // Highmark WV brand IDs
			            "MS": teamsiteContent["findProvider"]["highmarkWV"],
			            "WV": teamsiteContent["findProvider"]["highmarkWV"],
			            "HIGHMARKBCBSWV": teamsiteContent["findProvider"]["highmarkWV"],
			            "HMBCBSWV": teamsiteContent["findProvider"]["highmarkWV"],

			            // Highmark DE brand IDs
			            "DE": teamsiteContent["findProvider"]["highmarkDE"],
			            "HMBCBSDE": teamsiteContent["findProvider"]["highmarkDE"],
			            "HIGHMARKBCBSDE": teamsiteContent["findProvider"]["highmarkDE"],

			            //MINNESOTA
			            "MINCR": teamsiteContent["findProvider"]["mincrMN"],

			            //NEPA - matches Highmark WPA
			            "NEPA": teamsiteContent["findProvider"]["highmarkWPA"],

			            //WY
			            "WYBCBS": teamsiteContent["findProvider"]["wybcbs"],

			            //ND
			            "NDBCBS": teamsiteContent["findProvider"]["ndbcbs"]
			        };

					$scope.pageContent = brandConfig[brand.current.sharedBrandCode.toUpperCase()];

					if ($scope.pageContent["isInPA"] || $scope.pageContent["isWPA"] || $scope.pageContent["isCPA"]) {
						$scope.isPennsylvania = true;
					}

					if ($scope.pageContent["isDE"]) {
						$scope.isDelaware = true;	
					}

					if ($scope.pageContent["isMN"]) {
						$scope.isMinnesota = true;
					}

					if ($scope.pageContent["isWV"]) {
						$scope.isWestVirginia = true;
					}

			        $scope.pageContent.pharmacyLink.currentYearLabel = $scope.pageContent.pharmacyLink.currentYearLabel.replace("{currentYear}", new Date().getFullYear());
			        $scope.pageContent.pharmacyLink.nextYearLabel = $scope.pageContent.pharmacyLink.nextYearLabel.replace("{nextYear}", new Date().getFullYear() + 1);

			        $scope.pageContent.showPharmacyNextYear      = sessionData.appConfig.SALES.CONSUMER_PHARMACY_2016 && sessionData.appConfig.SALES.CONSUMER_PHARMACY_2016.booleanValue;
			        $scope.pageContent.showPharmacyCurrentYear   = sessionData.appConfig.SALES.CONSUMER_PHARMACY_2015 && sessionData.appConfig.SALES.CONSUMER_PHARMACY_2015.booleanValue;

		    		$scope.brandSearchDone = true;
			        console.log("success!");

    			});
	    } else {
	    	$timeout(function(){
	    		$scope.configWithBrand();
	    	},100);
	    }
    };


    (function onInit(){
    	if (brand.override !== "unbrandedhm") {
    		$scope.configWithBrand();
    	}
    })();
}];