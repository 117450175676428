/**
 * Created by lid4an9 on 10/29/2018.
 */
module.exports = function (app) {
    app.run(["Service", blankEmailApplicationAttributes]);


    function blankEmailApplicationAttributes(Service) {
        new Service({
            "name"  : "blankEmailApplicationAttributes",
            "group" : "cmcrst",
            "url"   : "/cmcrst/x-services/linkSharing/attribute",
            "cache" : false,
            getAttributes: function(attributeKey) {
                this.url += "?key="+attributeKey;
                return this.get();
            }
        });
    };
};