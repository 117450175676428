module.exports = function (app) {
    'use strict';
    app.directive('sex', ["errorMessages", function (errorMessages) {
        return {
            restrict: 'EA',
            replace: true,
            template: require('directives/enrollment/sex.html'),
            scope: {
                sex: "=",
                indexItem: "=?"
            },
            link: function ($scope, elem, $attrs) {
                $scope.errorMessages = errorMessages;

                $scope.$watch("indexItem", function(index){
                    if (index) {
                        $scope.indexItem = index;
                    }
                });
            }
        };
    }]);
};