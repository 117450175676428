module.exports = function(app) { 'use strict';
    return app.factory('appRetrieveService', ['$q', '$http', '$location',
        function ($q, $http, $location) {
            var appRetrieveService = {},
                formatPhoneNumber = function (digits) {
                    if (digits) {
                        digits = String(digits);
                        digits = digits.replace(/^[0-9]/g, '');
                        digits = digits.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                    }
                    return digits;
                };

            appRetrieveService.parseToken = function () {
                var queryObj = $location.search();
                return queryObj;
            };

            appRetrieveService.retrieveApp = function (lastName, dob, zipCode, appType, key) {
                var postUrl,
                    postData,
                    isOnx = false,
                    dataObj = {},
                    deferred = $q.defer();
                switch (appType) {
                    case "ONX_COMPLETE":
                        postUrl = "/cmcrst/x-services/applicant/onexchange/sharedSummary";
                        isOnx = true;
                        break;
                    case "OFFX_COMPLETE":
                        postUrl = "/cmcrst/x-services/offExchange/application/shared";
                        break;
                    case "OFFX_COMPLETE_SEP":
                    case "OFFX_COMPLETE_SEP_LINK2":
                        postUrl = "/cmcrst/x-services/offExchange/application/shared";
                        break;
                }

                postData = {
                    "payload": {
                        "key": key,
                        "lastName": lastName,
                        "dob": "09/01/1990",
                        "zipCode": zipCode
                    }
                };

                $http.post(postUrl, JSON.stringify(postData)).then(
                    function (response) {
                        if (isOnx) {
                            dataObj = {
                                appData: JSON.parse(response.data.payload.appData),
                                ffmResponse: JSON.parse(response.data.payload.ffmResponse),
                                type: appType
                            };
                        } else {
                            dataObj = {
                                appData: JSON.parse(response.data.payload.appData),
                                type: appType
                            };
                        }
                        dataObj.status = response.status;
                        deferred.resolve(dataObj);
                    },
                    function (error) {
                        dataObj.status = error.status;
                        deferred.resolve(dataObj);
                    }
                );

                return deferred.promise;
            };


            appRetrieveService.processPersonData = function (person) {
                var retVal = {
                    fullNameValue: null,
                    relationshipToPolicyHolder: null,
                    ssnValue: person.ssn.ssnNumber,
                    ssnMaskedValue: person.ssn.ssnNumber.replace(/(\d{5})(\d{4})/, "*****$2"),
                    sexValue: null,
                    dobValue: person.dateOfBirth,
                    homeAddressValue: null,
                    mailingAddressValue: null,
                    dependentSameAddressAsSubscriber: false,
                    pcpValue: person.pcp,
                    /*primaryPhoneValue: formatPhoneNumber(person.primaryPhoneNumber),
                    otherPhoneValue: formatPhoneNumber(person.otherPhoneNumber),*/
                    homePhone: formatPhoneNumber(person.primaryPhoneNumber),
                    workPhone: formatPhoneNumber(person.otherPhoneNumber),
                    mobilePhone: formatPhoneNumber(person.cellNumber),
                    email: person.email,
                    tobaccoValue: null,
                    specialAssistanceValue: person.specialAssistance ? "Yes" : "No",
                    receiptMemberMaterialsValue: null,
                    contactToCss: "",
                    contactToValue: null,
                    spokenLanguageCss: person.spokenLanguage === "" ? "hidden" : "",
                    spokenLanguageValue: person.spokenLanguage,
                    readingLanguageCss: person.readingLanguage === "" ? "hidden" : "",
                    readingLanguageValue: person.readingLanguage
                };

                //policy holder full name
                var personName = person.fullName.firstName;
                if (person.fullName.middleName) {
                    personName += " " + person.fullName.middleName;
                }
                personName += " " + person.fullName.lastName;

                if (person.fullName.suffix) {
                    personName += " " + person.fullName.suffix;
                }

                retVal.fullNameValue = personName;

                //set relationship value for dependents
                if (person.relationship.toUpperCase() !== "SUBSCRIBER") {
                    if (person.relationship.toUpperCase() === "DOMESTICPARTNER") {
                        retVal.relationshipToPolicyHolder = "Domestic Partner";
                    } else {
                        var relationship = person.relationship.toLowerCase();
                        retVal.relationshipToPolicyHolder = relationship.substr(0, 1).toUpperCase() + relationship.substr(1);
                    }
                }

                //relationshipToPolicyHolder

                //policy holder sex
                if (person.gender.toUpperCase() === "M") {
                    retVal.sexValue = "Male";
                } else {
                    retVal.sexValue = "Female";
                }

                //home address
                var homeAddress = null;

                if (person.relationship.toUpperCase() === "SUBSCRIBER") {
                    //for subscriber

                    homeAddress = person.homeAddress.addressLine1;
                    if (person.homeAddress.addressLine2) {
                        homeAddress += "<br>" + person.homeAddress.addressLine2;
                    }

                    homeAddress += "<br>" + person.homeAddress.city;
                    homeAddress += ", " + person.homeAddress.state;
                    homeAddress += " " + person.homeAddress.zipcode;

                    retVal.homeAddressValue = homeAddress;
                } else {

                    //for dependent
                    if (person.sameAddressAsSubscriber) {
                        //due to scoping we don't know what the subscribers home address
                        //object is. Handlebars in the html will use this flag
                        retVal.dependentSameAddressAsSubscriber = true;
                    } else {
                        retVal.dependentSameAddressAsSubscriber = false;

                        //populate home address value
                        homeAddress = person.homeAddress.addressLine1;
                        if (person.homeAddress.addressLine2) {
                            homeAddress += "<br>" + person.homeAddress.addressLine2;
                        }

                        homeAddress += "<br>" + person.homeAddress.city;
                        homeAddress += ", " + person.homeAddress.state;
                        homeAddress += " " + person.homeAddress.zipcode;

                        retVal.homeAddressValue = homeAddress;
                    }
                }


                //mailing address
                //only policy holder has a mailing address value
                if (person.relationship.toUpperCase() === "SUBSCRIBER" && person.sameMailingAddress) {
                    retVal.mailingAddressValue = homeAddress;
                } else {
                    var mailingAddress = person.mailingAddress.addressLine1;
                    if (person.mailingAddress.addressLine2) {
                        mailingAddress += "<br>" + person.mailingAddress.addressLine2;
                    }

                    mailingAddress += "<br>" + person.mailingAddress.city;
                    mailingAddress += ", " + person.mailingAddress.state;
                    mailingAddress += " " + person.mailingAddress.zipcode;

                    retVal.mailingAddressValue = mailingAddress;
                }

                //adjust pcp display value
                //blank if not checked
                retVal.pcpValue.pcpInd = person.pcp.pcpInd ? "Yes" : null;
                retVal.pcpValue.name = person.pcp.name;
                retVal.pcpValue.number = person.pcp.number;

                //policy holder tobacco use
                if (person.tobaccoInd) {
                    retVal.tobaccoValue = "Yes<br>" + person.tobaccoLastUsed;
                } else {
                    retVal.tobaccoValue = "No";
                }

                //policy holder receipt member materials
                // var region = EnrollManager.getRegion();
                // var regionUrl = EntityManager.toWebsiteURL(region);
                // var receiptText = [];

                var reigon = 'MINCR';
                var regionUrl = "https://www.shop.highmark.com";
                var receiptText = [];
                if (person.receiveVia.electronically) {
                    receiptText.push("Electronically by logging into " + regionUrl);
                }

                if (person.receiveVia.mail) {
                    receiptText.push("In the mail at the address noted above");
                }

                if (person.receiveVia.email) {
                    receiptText.push("Via email the email address noted above");
                }

                if (receiptText.length > 0) {
                    retVal.receiptMemberMaterialsValue = receiptText.join("<br>");
                }

                //policy holder contact to
                var contactTo = [];
                if (person.emailInd) {
                    contactTo.push(person.email);
                }

                if (person.cellNumberInd) {
                    contactTo.push(formatPhoneNumber(person.cellNumber));
                }

                if (contactTo.length === 0) {
                    retVal.contactToCss = "hidden";
                } else {
                    retVal.contactToValue = contactTo.join("<br>");
                }

                //console.log("retval: ", retVal);

                return retVal;
            };
            return appRetrieveService;
        }
    ]);
};