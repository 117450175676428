module.exports = function (app) {
    'use strict';
    app.directive('tobaccoUsage', ["errorMessages", function (errorMessages) {
        return {
            restrict: 'EA',
            replace: true,
           template: require('directives/enrollment/tobaccoUsage.html'),
            scope: {
                questionText: "@",
                tobaccoLastUsed: "=",
                tooltipContent: "@",
                index: "@?"
            },
            link: function ($scope, elem, $attrs) {
                $scope.errorMessages = errorMessages;
            }
        };
    }]);
};