module.exports = function(app) {
	app.service("specialEnrollmentPeriodPaperApplicationUtility", ["teamsiteManager", "brand", "$q", "storage", function(teamsiteManager, brand, $q, storage) {

		var entityManagerContent = null;

		var serviceProperties = {
			"getAppByRegionAndPlanYear": _getAppByRegionAndPlanYear
		};

		function _getAppByRegionAndPlanYear(region, planYear) {
			_getTeamsiteContent().then(function(tsContent){

		        var familyInfo 	= storage.get("familyRatingInfo");
		        var brandCode 	= brand.current.sharedBrandCode.toUpperCase();
		        var sepPdfKey 	= brandCode;

		        if(tsContent.coverLetterPDFLinkHash[brandCode].indexOf(planYear) === -1){
		            sepPdfKey += "-lastYear";
		        }

		        var pathToPdf = tsContent.coverLetterPDFLinkHash[sepPdfKey];
		        var pdfUrl;
		        //do the proper thing depending on the region (mostly opening pdfs, WY will continue to the SEP flow in 2.5)
		        switch (region) {
		            case "WPA":
		                //some code
		                pdfUrl = "https://shop.highmark.com/" + pathToPdf;
		                window.open(pdfUrl, '_blank');
		                break;
		            case "NEPA":
		                pdfUrl = "https://shop.highmark.com/" + pathToPdf;
		                window.open(pdfUrl, '_blank');
		                //some code
		                break;
		            case "CPA":
		                pdfUrl = "https://shop.highmark.com/" + pathToPdf;
		                window.open(pdfUrl, '_blank');
		                //some code
		                break;
		            case "DE":
		                pdfUrl = "https://shop.highmark.com/" + pathToPdf;
		                window.open(pdfUrl, '_blank');
		                //some code
		                break;
		            case "WV":
		                pdfUrl = "https://shop.highmark.com/" + pathToPdf;
		                window.open(pdfUrl, '_blank');
		                //some code
		                break;
		            case "MN":
		                pdfUrl = "https://shop.bluecrossmnonline.com/" + pathToPdf;
		                window.open(pdfUrl, '_blank');
		                //some code
		                break;
		            case "ND":
		                pdfUrl = "https://shop.bcbsndportals.com/" + pathToPdf;
		                window.open(pdfUrl, '_blank');
		                //some code
		                break;
		            case "WY":
		                //TODO: goes to WY 2.5 enrollment flow
		                $scope.goToEnrollFlow();
		                break;
		            default:
		                break;
		        }		
			})
		};

		function _getTeamsiteContent() {
			var tsContentDeferred = $q.defer();

			if (entityManagerContent) {
				tsContentDeferred.resolve(entityManagerContent);
			} else {
				teamsiteManager.getContent("entityManager", brand).then(function(results){
					entityManagerContent = results;
					tsContentDeferred.resolve(entityManagerContent);
				})
			}

			return tsContentDeferred.promise;
		};

		return serviceProperties;
	}])	
};