module.exports = function (app) {
    "use strict";
    app.controller('enrollment.instructions', ["$state", "$scope", "$q", "$timeout", "contentPromise",
        function ($state, $scope, $q, $timeout, contentPromise) {

            $scope.teamsiteContent = contentPromise;
            $scope.goNext = function () {
                $state.go("enrollment.your-information");
            };
        }
    ]);
};