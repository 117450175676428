module.exports = function(app){
    app.service('brochuresData', ['$http',
        function($http){
            var deferred = $http.get('/content/brochures/brochuresData.json').then (
                function(response){
                    return response.data;
                }
            );
            this.get = function(){
                return deferred;
            };
        }
    ]);
};