module.exports = function (app) {
    'use strict';
    return app.service('prospectService', ['$q', '$http', 'brand', 'services', '$filter', function ($q, $http, brand, services, $filter) {

        var parseData = function (data) {
            var appData,
                savedResults = [];

            if (!data.appdata) {
                return savedResults;
            }

            appData = angular.fromJson(data.appdata);

            if (appData.selectedPlan) {
                savedResults.push({
                    prospectId: appData.prospectId,
                    selectedPlan: appData.selectedPlan,
                    timestamp: data.status.timestamp,
                    displayText: "Specific Plan Details",
                    appData: appData
                });
            }

            if (appData.comparePlans) {
                savedResults.push({
                    prospectId: appData.prospectId,
                    comparePlans: appData.comparePlans,
                    timestamp: data.status.timestamp,
                    displayText: "Compare Plans",
                    appData: appData
                });
            }

            if (appData.planDetails) {
                savedResults.push({
                    prospectId: appData.prospectId,
                    timestamp: data.status.timestamp,
                    planDetails: appData.planDetails,
                    displayText: "View Plans Results",
                    appData: appData
                });
            }
            return savedResults;
        };

        this.getConsumerProspect = function () {
            var deferred = $q.defer();

            $http.get("/cmcrst/x-services/prospect").then(
                function (response) {
                    deferred.resolve(parseData(response.data.payload.prospects[0]));
                }
            );
            return deferred.promise;
        };

        this.getRatingInfo = function (savedResult) {
            var ratingInfoRequest = this.buildRatingInfoRequest(savedResult.appData);
            return services.wcs.ratingInfo({"storeId": brand.current.storeId}).putData(ratingInfoRequest);
        };

        this.buildRatingInfoRequest = function (appData) {
            var applicants = [];
            angular.forEach(appData.applicants, function (applicant) {
                applicant.dob = $filter('date')(applicant.dob, 'yyyy-MM-dd');
                applicants.push(applicant);
            });
            return {
                zipCode: appData.zipCode,
                county: appData.county,
                region: appData.region,
                effectiveDate: $filter('date')(appData.effectiveDate, 'yyyy-MM-dd'),
                state: appData.state,
                serviceZones: appData.serviceZones,
                ratingArea: appData.ratingArea,
                applicants: applicants
            };
        };

        // Save Rating Information and ProspectInfo
        this.saveConsumerUserData = function (args) {
            var ratingInfoRequest = this.buildRatingInfoRequest(args.appData);
            return $http.put('/cmcrst/x-services/sessionstorage', {
                baseMessage: {
                    headerVersion: "1.0",
                    payloadVersion: "2.0",
                    additionalInfo: null
                },
                payload: {
                    sessionStorage: {
                        userType: 'CONSUMER',
                        customerId: args.prospectId,
                        data: ratingInfoRequest
                    }
                }
            });
        };
    }]);
};
