module.exports = function(bootstrap) {
    bootstrap.run(["Service", function (Service) {
        return new Service({
            name: 'consumerEffectiveDate',
            group: "cmcrst",
            url: "/cmcrst/x-services/consumer/consumereffectivedate",
            cache: false,

            postConsumerEffectiveDate: function(payload){
                var fullPayload = {
                    "baseMessage":{
                        "headerVersion":"1.0",
                        "payloadVersion":"2.0",
                        "additionalInfo":null
                    },
                    "payload": payload
                };
                return this.post(fullPayload);
            }
        });
    }]);
};