module.exports = function(app){

    app.controller("pages.enrollment.application-summary",["$scope","teamsiteService",
        function($scope,teamsiteService){

            teamsiteService.loadFile("reviewApplication").then(function(data) {
                $scope.content = {
                    paymentPreference: {
                        "title": "PAYMENT PREFERENCES",
                        "paymentOptionDescription": "CHOOSE HOW YOU WANT TO PAY",
                        "paymentOptionSubText": "Please Select a payment option from the list below",
                        "paymentOptions": [
                            {
                                "label": "PAY DIRECTLY FROM MY BANK ACCOUNT",
                                "display": true,
                                "description": "One month's premium will be charged to your bank account when we receive and process your application.Please note that coverage does not begin until you effective date. For future payments, you will receive a statement each month that will contain options for continued paymenets.",
                                "description_unauthorized": "Because no social security number was provided with the application, this option is unavailable for your first payment."

                            }, {
                                "label": "CHARGE MY CREDIT/DEBIT CARD(Including Pre-Paid Debit Cards)",
                                "display": true,
                                "description": "One Month's premium will be charged to your credit/debit card when we receive and process your application. Please note that coverage does not begin until your effective date. For future payments, you will receive a statement each month that will contain options for continued payments.",
                                "description_unauthorized": "Because no social security number was provided with the application, this option is unavailable for your first payment."
                            }, {
                                "label": "SEND ME A BILL",
                                "display": true,
                                "description": "A bill will be sent to you for the amount of your first month's premium, payable via paper check, cashier's check, or money order. If you select SEND ME A BILL, you must pay the full amount of your bill. Your coverage will not begin unless we receive a payment in full by the date listed on your bill. Please note that coverage does not begin until the effective date listed on your bill."
                            }, {

                                "label": "PAY BY PHONE",
                                "display": true,
                                "description": "Before transferring your customer into the Payment IVR you must click the Next; button to ensure the premium amount is sent back to salesforce.com"
                            }
                        ]
                    },
                    "printApplication": {
                        "description": "You have successfully submitted you enrollment application for Care Guide Blue HMO 500 for the 2015 year. Please keep a copy of this completed application for your records.",
                        "title": "Print Application",
                        "application" :{
                            "titleSubtext": "Your Enrollment Application is Complete!",
                            "submittedTime": "Application Submitted on:",
                            "premium": "Monthly Premium Amount",
                            "paymentConfirmationNumber": "Payment Confirmation Number"
                        }

                    },
                    "planInformation": {
                        "heading": "PLAN INFORMATION",
                        "plan":{
                            "productLabel": "Product",
                            "effectiveDateLabel": "Effective Date",
                            "monthlyPremiumLabel": "Monthly Premium"
                        }

                    },
                    "policyHolderInfo": {
                        "heading": "Policy Holder/Subscriber Information",
                        "fullNameLabel": "Full Name",
                        "ssnLabel": "Social Security Number",
                        "sexLabel": "Sex",
                        "dobLabel": "Date of Birth",
                        "homeAddressLabel": "Home Address",
                        "mailingAddressLabel": "Mailing Address",
                        "primaryPhoneNumberLabel": " Primary Phone Number",
                        "otherPhoneNumberLabel": "Other Phone Number",
                        "primaryCareProviderLabel": "Primary Care Provider Information",
                        "emailAddress": "Email Address",
                        "pcpNameLabel": "Primary Care Physician Name",
                        "pcpNumberLabel": "PCP Number",
                        "pcpPatientQuestionLabel": "Are you currently a patient of this physician?",
                        "tobaccoUseQuestion": "Have you smoked or used any form of tobacco regularly (4 or more times per week on average excluding religious or ceremonial use) within the last six months?",
                        "receiptOfMaterialsLabel": "Receipt of member materials",
                        "contactHealthProductsServicesLabel": "Contact of health-related products and services to",
                        "preferredSpokenLanguageLabel": "Preferred Spoken Language",
                        "preferredReadingLanguageLabel": "Preferred Reading Language",
                        "specialAssistanceQuestion": "Requires special assistance, including accommodations for disabilities or limited English proficiency."
                    },
                    "familyInfo":{
                        "title"    : "Family Information",
                        "fullName" : "Person Full Name",
                        "relation" : "Relationship to contract holder",
                        "ssn"      : "Social Security Number",
                        "gender"   : "Gender",
                        "dob"      : "Date of Birth",
                        "homeAddress" : "Home Address",
                        "tobaccoQuestions" : "Have you smoked or used any form of tobacco regularly (4 or more times per week on average excluding religious or ceremonial use) within the last six months?",
                        "assistance" : "Requires special assistance, including accommodations for disabilities or limited English proficiency."
                    },
                    "otherInsurance": {
                        "heading": "Other Health Insurance Information",
                        "otherPersonApplyQuestion": "Is any person applying for this coverage entitled to benefits under Medicare Part A or enrolled in Medicare Part B?",
                        "enrollOtherGroupQuestion": "Are you or any of your family members who are applying for this coverage enrolled in any private or governmental group or individual health plan or program at the time of this Application?",
                        "privateInsuranceInfo": {
                            "insuranceCarrierLabel": "Insurance Carrier",
                            "groupNumberLabel": "Group Number",
                            "policyHolderLabel": "Policy Holder",
                            "effectiveDateLabel": "Effective Date",
                            "policyNumberLabel": "Policy Number",
                            "relToApplicantLabel": "Relationship to applicant",
                            "policyHolderDobLabel": "Policy Holder's Birth Date",
                            "policyHolderEmploymentStatusLabel": "Policy Holder's Employment Status",
                            "replaceIntentionQuestion": "Is this coverage for which you are applying intended to replace any other accident or health insurance you or any family members applying currently have? This includes any current {companyName} policy."

                        },
                        "replaceOtherInsurance": {

                            "insuranceCarrierLabel": "Insurance Carrier",
                            "groupNumberLabel": "Group Number",
                            "policyHolderLabel": "Policy Holder",
                            "effectiveDateLabel": "Effective Date",
                            "policyNumberLabel": "Policy Number",
                            "relToApplicantLabel": "Relationship to applicant",
                            "policyHolderDobLabel": "Policy Holder's Birth Date",
                            "policyHolderEmploymentStatusLabel": "Policy Holder's Employment Status"
                        }
                    },
                    "producerInfo": {
                        "heading": "Producer Information",
                        "isProducerQuestion": "Are you a producer filling out this application on behalf of a client?",
                        "agencyNumberLabel": "Agency Number",
                        "agencyNameLabel": "Agency Name",
                        "producerNumberLabel": "Producer Number",
                        "producerNameLabel": "Producer Name",
                        "businessPhoneNumberLabel": "Business Phone Number",
                        "provideMaterialsQuestion": "Have you provided the applicant with all relevant marketing materials?",
                        "advisedFeatureQuestion": " Have you advised the applicant of the features of the product that they have selected, including satisfying their deductible(s)?",
                        "currentCustomerQuestion": "Is the applicant a current customer of Highmark Blue Cross Blue Shield?",
                        "signRecordsQuestion": "Have you retained a signed copy of this for your records?",
                        "workWithProducerQuestion": "Are you working with a producer to fill out this application?",
                        "producerCollaborationInfo": {
                            "producerNumberLabel": "Producer Number",
                            "agencyNumberLabel": "Agency Number"

                        }
                    },
                    "notificationAndAuthorization": {
                        "title": "NOTIFICATION AND AUTHORIZATION",
                        "subtitle": "By completing this enrollment application I understand that I will be submitting an actual enrollment and I agree to the following:",
                        "contentSection1": {
                            "para1": "I/we hereby apply for health care plan coverage for myself and/or my eligible dependents listed on this Application. I/we understand and agree that the terms and conditions of our coverage will be controlled by the written Agreement with Highmark Blue Cross Blue Shield and that they may adopt reasonable policies, procedures, rules and interpretations, consistent with the language of that Agreement, to administer the program. I/we recognize that our coverage will only apply to admissions that occur and services that are provided on or after the effective date of our coverage.",
                            "para2": "I acknowledge and agree that any personally identifiable health information about me or my enrolled dependents (''Protected Health Information'') is protected by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and other privacy laws, and that, in accordance with those laws, Highmark Inc. may use and disclose Protected Health Information for payment, treatment and health care operations.",
                            "para3": "A copy of Highmark Inc.'s Notice of Privacy Practices is available on the Highmark Inc. Web site or from the Highmark Inc. Privacy Office.",
                            "para4": "I/we understand that the Agreement is available only to residents of the geographic area in which the product for which this Application is completed is available, and that this Application is subject to the provisions of the Agreement.",
                            "para5": "I/we understand that the receipt of the benefits under this program is subject to Highmark Inc.'s determination of medical necessity and appropriateness. Except for emergencies or delivery-related admissions, all inpatient admissions are subject to review by Highmark Inc. prior to the proposed admission.",
                            "para6": "I can confirm that no one applying for health insurance on this Application is incarcerated (detained or jailed).",
                            "para7": "I know that I must tell Highmark Blue Cross Blue Shield if any information I supplied on this Application changes. I can call 1-800-544-6679 to report any changes."
                        },
                        "contentSection2": {
                            "headline": "EFFECTIVE DATE OF COVERAGE",
                            "para1": "I/we understand/agree that, subject to the conditions of enrollment on this Application, coverage will be effective for individuals listed on this Application following receipt of a completed Application and payment of the first premium in full:",
                            "para2": "If you are applying during:<br><u>Open Enrollment Period:</u><br>a) on January 1, 2016 if the Application is received on or before December 15, 2015.<br /><br />- OR - <br /><br />b) On February 1, 2016 if the Application is received from December 16, 2015 through January 15, 2016.<br /><br />- OR - <br /><br />c) On March 1, 2016 if the Application is received from January 16, 2016 through January 31, 2016.<br /><br />OR<br /><br /><u>Special Enrollment Period/Limited Open Enrollent Period:</u><br />Coverage will be effective based on the applicable laws defined for each Special Enrollment Period or Limitied Open Enrollment Period.",
                            "para3": "To the best of my/our knowledge and belief, the information provided on this Application is true and correct.",
                            "para4": "I also understand that any attempts to qualify for the program chosen through fraud or other intentional misrepresentation of a material fact will result in termination of such contract.",
                            "para5": "<Strong>If a Producer is submitting an application on behalf of You/Us:</strong> I/We authorize the Producer to sign (written or electronically) this application on My/Our behalf and understand such authorization and Producer signature carries the same legal obligation as a written signature and I/We are agreeing to all of the terms and conditions set forth within this application, including the Notification and Authorization.",
                            "para6": "I/We understand and I/We am/are creating an ''Electronic Signature'' that carries the same legal obligation as a written signature and I/We am/are agreeing to all of the terms and conditions set forth within this application.",
                            "para7": "<strong>Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties.</strong>"
                        },
                        "date": "Date: ",
                        "acceptCheckbox": "I ACCEPT. By checking this box, I understand I am creating an electronic signature that carries the same legal obligations as a written signature and am AGREEING to all of the terms and conditions set forth within this application, including the Notification and Authorization. If I do NOT check this box my application will NOT be processed and I will NOT be considered for health plan coverage.",
                        "buttons": {
                            "backBtn": "BACK",
                            "submitBtn": "SUBMIT"
                        },
                        "modals": {
                            "serviceError": "<div id='bad-request-modal'><p class='padding-all'>There has been a problem accessing this service.</br><br>To report a technical issue, please contact technical support: 1-800-294-9568.<br><br> OR <br><br> To apply via phone:<br><br>In Western Pennsylvania: 1-855-329-1760<br>In Northeastern Pennsylvania: 1-855-856-0317<br>In Central Pennsylvania: 1-855-329-1761<br>In Delaware: 1-855-329-1764<br>In West Virigina: 1-855-329-1763<br>TTY: 711</div>"
                        },
                        "producerAuth": {
                            "label": "Producer Name",
                            "date": "Date:",
                            "accept": "I ACCEPT. By checking this box, I understand I am creating an electronic signature that carries the same legal obligations as a written signature and am AGREEING to all of the terms and conditions set forth below. Further, I am AGREEING to the terms and conditions set forth in the application, including the Notification and Authorization, on behalf of each applicant required to sign the application pursuant to applicable law. If I do NOT check this box the application will NOT be processed and the applicant will NOT be considered for health plan coverage. Each applicant required to sign the application:",
                            "acceptList": {
                                "item1": "received a copy of the blank application, including the Notification and Authorization",
                                "item2": "reviewed the completed application",
                                "item3": "read the Notification and Authorization",
                                "item4": "ACCEPTS the terms and conditions set forth in the application, including the Notification and Authorization",
                                "item5": "provided me with their written consent to sign this application on their behalf, pursuant to applicable laws",
                                "item6": "will receive from me a copy of the completed and submitted application, including the Notice and Authorization, immediately in a secure manner pursuant to all applicable laws",
                                "item7": " &middot; No Producer may: (i) accept risk or pass on any eligibility requirements; (ii) make or alter the terms of the application or policy; or (iii) waive any of <span data-dojo-attach-point='entityHTML'></span> rights or requirements"
                            },
                            "strong": "",
                            "acceptCheckbox": "I am not aware, based on the applicant's responses to my inquiries, of any factors impacting the eligibility of the applicant and each of his/her dependents applying for this coverage.",
                            "acceptCheckboxList": {},
                            "additional": ""
                        }
                    }



                },
                    $scope.applicationSummaryModel = {

                        "printApplication": {
                            "application": {

                                "submittedTime": "2016-03-04 19:52:17 EST",
                                "premium": "$300.74",
                                "paymentConfirmationNumber": "No payment made yet."
                            }
                        },
                        "familyInfo":[
                            {
                                "fullName" : "John Doe",
                                "relation" : "Spouse",
                                "ssn"      : "1231661661",
                                "gender"   : "Male",
                                "dob"      : "09/09/1900",
                                "homeAddress" : "2nd Main St, Pittsburgh,Pa,15220",
                                "tobaccoQuestions" : "Yes",
                                "assistance" : "No"
                            },{
                                "fullName" : "Brad Smith",
                                "relation" : "child",
                                "ssn"      : "342626262662",
                                "gender"   : "Male",
                                "dob"      : "09/09/1990",
                                "homeAddress" : "2nd Main St, Pittsburgh,Pa,15220",
                                "tobaccoQuestions" : "Yes",
                                "assistance" : "No"

                            }
                        ],
                        "planInformation": {
                            "plan": {
                                "productLabel": "Shared Cost Blue PPO 6000 a Community Blue Flex Plan",
                                "effectiveDateLabel": "04/01/2016",
                                "monthlyPremiumLabel": "$300.74"
                            },

                            "policyHolderInfo": {
                                "heading": "Policy Holder/Subscriber Information",
                                "fullNameLabel": "Full Name",
                                "ssnLabel": "Social Security Number",
                                "sexLabel": "Sex",
                                "dobLabel": "Date of Birth",
                                "homeAddressLabel": "Home Address",
                                "mailingAddressLabel": "Mailing Address",
                                "primaryPhoneNumberLabel": " Primary Phone Number",
                                "otherPhoneNumberLabel": "Other Phone Number",
                                "primaryCareProviderLabel": "Primary Care Provider Information",
                                "emailAddress": "Email Address",
                                "memberMaterials": "Receipt of member materials",
                                "contactHealthProductsServicesLabel": "Contact of health-related products and services to",
                                "tobaccoUseQuestion": "Have you smoked or used any form of tobacco regularly (4 or more times per week on average excluding religious or ceremonial use) within the last six months?",
                                "preferredSpokenLanguageLabel": "Preferred Spoken Language",
                                "preferredReadingLanguageLabel": "Preferred Reading Language",
                                "specialAssistanceQuestion": "Requires special assistance, including accommodations for disabilities or limited English proficiency.",
                                "pcpNameLabel": "Primary Care Physician Name",
                                "pcpNumberLabel": "PCP Number",
                                "pcpPatientQuestionLabel": "Are you currently a patient of this physician?"
                            },
                            "otherInsurance": {
                                "heading": "Other Health Insurance Information",
                                "otherPersonApplyQuestion": "Is any person applying for this coverage entitled to benefits under Medicare Part A or enrolled in Medicare Part B?",
                                "enrollOtherGroupQuestion": "Are you or any of your family members who are applying for this coverage enrolled in any private or governmental group or individual health plan or program at the time of this Application?",
                                "privateInsuranceInfo": {
                                    "insuranceCarrierLabel": "Insurance Carrier",
                                    "groupNumberLabel": "Group Number",
                                    "policyHolderLabel": "Policy Holder",
                                    "effectiveDateLabel": "Effective Date",
                                    "policyNumberLabel": "Policy Number",
                                    "relToApplicantLabel": "Relationship to applicant",
                                    "policyHolderDobLabel": "Policy Holder's Birth Date",
                                    "policyHolderEmploymentStatusLabel": "Policy Holder's Employment Status",
                                    "replaceIntentionQuestion": "Is this coverage for which you are applying intended to replace any other accident or health insurance you or any family members applying currently have? This includes any current {companyName} policy."

                                },
                                "replaceOtherInsurance": {
                                    "insuranceCarrierLabel": "Insurance Carrier",
                                    "groupNumberLabel": "Group Number",
                                    "policyHolderLabel": "Policy Holder",
                                    "effectiveDateLabel": "Effective Date",
                                    "policyNumberLabel": "Policy Number",
                                    "relToApplicantLabel": "Relationship to applicant",
                                    "policyHolderDobLabel": "Policy Holder's Birth Date",
                                    "policyHolderEmploymentStatusLabel": "Policy Holder's Employment Status"
                                },
                                "notificationAndAuthorization": {
                                    "title": "NOTIFICATION AND AUTHORIZATION",
                                    "subtitle": "By completing this enrollment application I understand that I will be submitting an actual enrollment and I agree to the following:",
                                    "contentSection1": {
                                        "para1": "I/we hereby apply for health care plan coverage for myself and/or my eligible dependents listed on this Application. I/we understand and agree that the terms and conditions of our coverage will be controlled by the written Agreement with Highmark Blue Cross Blue Shield and that they may adopt reasonable policies, procedures, rules and interpretations, consistent with the language of that Agreement, to administer the program. I/we recognize that our coverage will only apply to admissions that occur and services that are provided on or after the effective date of our coverage.",
                                        "para2": "I acknowledge and agree that any personally identifiable health information about me or my enrolled dependents (�Protected Health Information�) is protected by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and other privacy laws, and that, in accordance with those laws, Highmark Inc. may use and disclose Protected Health Information for payment, treatment and health care operations.",
                                        "para3": "A copy of Highmark Inc.�s Notice of Privacy Practices is available on the Highmark Inc. Web site or from the Highmark Inc. Privacy Office.",
                                        "para4": "I/we understand that the Agreement is available only to residents of the geographic area in which the product for which this Application is completed is available, and that this Application is subject to the provisions of the Agreement.",
                                        "para5": "I/we understand that the receipt of the benefits under this program is subject to Highmark Inc.�s determination of medical necessity and appropriateness. Except for emergencies or delivery-related admissions, all inpatient admissions are subject to review by Highmark Inc. prior to the proposed admission.",
                                        "para6": "I can confirm that no one applying for health insurance on this Application is incarcerated (detained or jailed).",
                                        "para7": "I know that I must tell Highmark Blue Cross Blue Shield if any information I supplied on this Application changes. I can call 1-800-544-6679 to report any changes."
                                    },
                                    "contentSection2": {
                                        "headline": "EFFECTIVE DATE OF COVERAGE",
                                        "para1": "I/we understand/agree that, subject to the conditions of enrollment on this Application, coverage will be effective for individuals listed on this Application following receipt of a completed Application and payment of the first premium in full:",
                                        "para2": "If you are applying during:<br><u>Open Enrollment Period:</u><br>a) on January 1, 2016 if the Application is received on or before December 15, 2015.<br /><br />- OR - <br /><br />b) On February 1, 2016 if the Application is received from December 16, 2015 through January 15, 2016.<br /><br />- OR - <br /><br />c) On March 1, 2016 if the Application is received from January 16, 2016 through January 31, 2016.<br /><br />OR<br /><br /><u>Special Enrollment Period/Limited Open Enrollent Period:</u><br />Coverage will be effective based on the applicable laws defined for each Special Enrollment Period or Limitied Open Enrollment Period.",
                                        "para3": "To the best of my/our knowledge and belief, the information provided on this Application is true and correct.",
                                        "para4": "I also understand that any attempts to qualify for the program chosen through fraud or other intentional misrepresentation of a material fact will result in termination of such contract.",
                                        "para5": "<Strong>If a Producer is submitting an application on behalf of You/Us:</strong> �I/We authorize the Producer to sign (written or electronically) this application on My/Our behalf and understand such authorization and Producer signature carries the same legal obligation as a written signature and I/We are agreeing to all of the terms and conditions set forth within this application, including the Notification and Authorization.",
                                        "para6": "I/We understand and I/We am/are creating an �Electronic Signature� that carries the same legal obligation as a written signature and I/We am/are agreeing to all of the terms and conditions set forth within this application.",
                                        "para7": "Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties."
                                    },
                                    "date": "Date: ",
                                    "acceptCheckbox": "I ACCEPT. By checking this box, I understand I am creating an electronic signature that carries the same legal obligations as a written signature and am AGREEING to all of the terms and conditions set forth within this application, including the Notification and Authorization. If I do NOT check this box my application will NOT be processed and I will NOT be considered for health plan coverage.",
                                    "buttons": {
                                        "backBtn": "BACK",
                                        "submitBtn": "SUBMIT"
                                    },
                                    "modals": {
                                        "serviceError": "<div id='bad-request-modal'><p class='padding-all'>There has been a problem accessing this service.</br><br>To report a technical issue, please contact technical support: 1-800-294-9568.<br><br> OR <br><br> To apply via phone:<br><br>In Western Pennsylvania: 1-855-329-1760<br>In Northeastern Pennsylvania: 1-855-856-0317<br>In Central Pennsylvania: 1-855-329-1761<br>In Delaware: 1-855-329-1764<br>In West Virigina: 1-855-329-1763<br>TTY: 711</div>"
                                    },
                                    "producerAuth": {
                                        "label": "Producer Name",
                                        "date": "Date:",
                                        "accept": "I ACCEPT. By checking this box, I understand I am creating an electronic signature that carries the same legal obligations as a written signature and am AGREEING to all of the terms and conditions set forth below. Further, I am AGREEING to the terms and conditions set forth in the application, including the Notification and Authorization, on behalf of each applicant required to sign the application pursuant to applicable law. If I do NOT check this box the application will NOT be processed and the applicant will NOT be considered for health plan coverage. Each applicant required to sign the application:",
                                        "acceptList": {
                                            "item1": "received a copy of the blank application, including the Notification and Authorization",
                                            "item2": "reviewed the completed application",
                                            "item3": "read the Notification and Authorization",
                                            "item4": "ACCEPTS the terms and conditions set forth in the application, including the Notification and Authorization",
                                            "item5": "provided me with their written consent to sign this application on their behalf, pursuant to applicable laws",
                                            "item6": "will receive from me a copy of the completed and submitted application, including the Notice and Authorization, immediately in a secure manner pursuant to all applicable laws",
                                            "item7": "No Producer may: (i) accept risk or pass on any eligibility requirements; (ii) make or alter the terms of the application or policy; or (iii) waive any of <span data-dojo-attach-point='entityHTML'></span> rights or requirements"
                                        },
                                        "strong": "",
                                        "acceptCheckbox": "I am not aware, based on the applicant's responses to my inquiries, of any factors impacting the eligibility of the applicant and each of his/her dependents applying for this coverage.",
                                        "acceptCheckboxList": {},
                                        "additional": ""
                                    }
                                }

                            }
                        },

                        "producerInfo": {
                            "isProducerQuestion": "Yes",
                            "agencyNumber": "0505303",
                            "agencyName": "Insurance Agency",
                            "producerNumber": "11111111",
                            "producerName": "Joe Smith",
                            "businessPhoneNumber": "1234567890",
                            "provideMaterialsQuestion": "Yes",
                            "advisedFeatureQuestion": "Yes",
                            "signRecordsQuestion": "Yes",
                            "workWithProducerQuestion": "Yes",
                            "producerCollaborationInfo": {
                                "producerNumber": "1244444",
                                "agencyNumber": "23433333"

                            }
                        },
                        "accept1":true,
                        "accept2":true
                    },
                    $scope.printSummary = function(printSectionId){
                        var innerContents = document.getElementById(printSectionId).innerHTML;
                        var popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
                        popupWinindow.document.open();
                        popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + innerContents + '</html>');
                        popupWinindow.document.close();
                    }

            });


        },

    ]);

};
// $scope.teamsiteContent = data;
