module.exports = function (app) {
    'use strict';

    app.directive('planDetailsAdditionalLinks', ["brand", "storage", "ratingInfoModel", function (brand, storage, ratingInfoModel) {
        return {
            restrict: 'A',
            replace: true,
            template: require('directives/enrollment/planDetailsAdditionalLinksTemplate.html'),
            scope: {
                planDetailsAdditionalLinksContent: "=",
                planData: "="
            },
            link: function ($scope, elem, $attrs) {},
            controller: function($scope) {
                (function onInit(){
                    $scope.generatedSBClink = (function(){
                        /****
                         *    https://shopdenv7.highmark.com/content/sbcs/2019/WPA/Individual/I_38949PA0080008-00_20190101_SBC.pdf
                         ****/
                        // var ratingInfoPayload = storage.get("ratingInfoPayload");
                        var ratingInfoPayload = ratingInfoModel.get();

                        var mergeChar = "/";
                        var fileNameMergeChar = "_";

                        var baseURL                         = "https://" + window.location.host + "/content/sbcs";

                        var region                          = $scope.planData.region;

                        var individualOrOtherwise           = "Individual";

                        var individualOrOtherwiseIndicator  = "I";

                        var hios                            = $scope.planData.hiosId;

                        var effectiveDateYYYYMMDD           = ratingInfoPayload.effectiveDate.replace(/-/g,"");
                        var effectiveDateYYYY               = effectiveDateYYYYMMDD.substr(0,4);
                        var effectiveDateForSBCFilename     = effectiveDateYYYY + "0101";

                        var fileEnding                      = "SBC.pdf";

                        var fileNameArray = [individualOrOtherwiseIndicator, hios, effectiveDateForSBCFilename, fileEnding];
                        var fileNameString = fileNameArray.join(fileNameMergeChar);

                        // SBC for next year
                        // var filePathArray = [baseURL, nextYear, region, individualOrOtherwise, fileNameString];
                        // SBC for this year
                        // var filePathArray = [baseURL, curYear, region, individualOrOtherwise, fileNameString];
                        // SBC for consumerEffectiveDate
                        var filePathArray = [baseURL, effectiveDateYYYY, region, individualOrOtherwise, fileNameString];
                        var filePathString = filePathArray.join(mergeChar);

                        return filePathString;
                    })();

                    $scope.generatedPlanBrochurelink = function(inputLink){
                        /****
                         *    http://discoverhighmark.com/individuals-families/content/pdfs/brochures/WPA2019_ProductBrochure.pdf
                         ****/
                        // var ratingInfoPayload = storage.get("ratingInfoPayload");
                        var ratingInfoPayload = ratingInfoModel.get();
                        
                        var effectiveDateYYYYMMDD           = ratingInfoPayload.effectiveDate.replace(/-/g,"");
                        var effectiveDateYYYY               = effectiveDateYYYYMMDD.substr(0,4);

                        // var filePathString = "http://discoverhighmark.com/individuals-families/content/pdfs/brochures/WPA2019_ProductBrochure.pdf";
                        inputLink = inputLink.replace("{region_year}",ratingInfoPayload.region+effectiveDateYYYY);

                        return inputLink;
                    };
                })();
            }
        }
    }])
}