module.exports = function(app) {
    app.controller('pages.findInsurance.find-a-provider-or-drug.landing', ["$scope", "$state", "$q", "mapServices", "services", "brand", "storage", "modal", "ratingInfoModel","appConfig",
        function($scope, $state, $q, mapServices, services, brand, storage, modal, ratingInfoModel,appConfig) {

            $scope.backToViewPlans = function(){
                $state.go('findInsurance.view-plans');
            };

        }
    ]);
};