module.exports = function (app) {
    require('./verify.js')(app);
    require('./review.js')(app);

    app.controller('pages.completedApplication', ["$state", "$scope", "teamsiteService", "$timeout",
        function($state, $scope, teamsiteService, $timeout) {
        }
    ]);
    return [{
        state: "completedApplication",
        url: "/completedApplication",
        views: {
            "body@": {
                template: require("pages/completedApplication/completedApplication.tpl.html"),
                controller: "pages.completedApplication"
            }
        }
    }, {
        state: "completedApplication.verify",
        url: "/verify",
        views: {
            "body@": {
                template: require("pages/completedApplication/completedApplication.tpl.html"),
                controller: "pages.completedApplication"
            },
            "content@completedApplication.verify": {
                template: require("pages/completedApplication/verify.html"),
                controller: "pages.completedApplication.verify"
            }
        }
    }, {
        state: "completedApplication.review",
        url: "/review",
        views: {
            "body@": {
                template: require("pages/completedApplication/completedApplication.tpl.html"),
                controller: "pages.completedApplication"
            },
            "content@completedApplication.review": {
                template: require("pages/completedApplication/review.html"),
                controller: "pages.completedApplication.review"
            }
        },
        params: {
            appData: null
        }
    }];
};