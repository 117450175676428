module.exports = function (app) {
    app.controller('pages.completedApplication.verify', ["$state", "$scope", "teamsiteService", "$timeout", "errorMessages", "appRetrieveService",
        function($state, $scope, teamsiteService, $timeout, errorMessages, appRetrieveService) {

            teamsiteService.loadFile("confirmInformation").then(function(data) {
                $scope.teamsiteContent = data;
                $scope.errorMessages = errorMessages;

                $scope.retrieveCompletedApp = function () {
                    if (!$scope.appRetrieveForm.$valid) {
                        return;
                    }

                    var tokenData = appRetrieveService.parseToken();

                    appRetrieveService.retrieveApp($scope.retrieveAppData.lastName,
                        $scope.retrieveAppData.dob,
                        $scope.retrieveAppData.zipCode,
                        tokenData.type, tokenData.token).then(function (response) {
                        $scope.responseData = response;

                        $state.go("completedApplication.review", {
                            'appData': response
                        });
                    });
                };
            });
        }
    ]);
};