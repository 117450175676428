module.exports = function(app) {

	require('./eligibility.js')(app);
    require('./applicationStatus.js')(app);

	app.controller('pages.marketplace', ["$state", "$scope", "teamsiteManager",
		function($state, $scope, teamsiteManager) {
			teamsiteManager.getMultiContent(["enrollMarketplace","entityManager","pageTitles"],"DEFAULT").then(function(data) {
				$scope.content = data;
				$scope.backText = "Back to Plan List";

	            $scope.backAction = function() {
	                $state.go('marketplace.view-plans');
	            };

			    $scope.headings = $scope.content.pageTitles.marketplace.applicationStatus;

			    $scope.sidebar.links = [
			        {
			          heading: "Application Status",
			          label: "Marketplace Application Status",
			          state: "marketplace",
			          description: "Track the status of your application to the Health Insurance Marketplace"
			        }
			    ];
			});
		} 
	]);
    return [
    {
        state: "marketplace",
        url: "/marketplace",
        views: {
            "body@": {
                template: bootstrap.templates["page-templates/category.html"],
                controller: "pages.marketplace"
            }
        }
    },
    {
        state: "marketplace.view-plans",
        url: "/view-plans",
        views: {
            "body@": {
                template: bootstrap.templates["page-templates/category.html"],
                controller: "pages.marketplace"
            },
            "content@marketplace.view-plans": {
                template: require("pages/findInsurance/view-plans.html"),
                controller: "pages.findInsurance.view-plans"
            }
        }
    },
    {
        state: "marketplace.compare-plans",
        url: "/compare-plans",
        views: {
            "body@": {
                template: require("pages/findInsurance/category-detail-12.html"),
                controller: "pages.marketplace"
            },
            "content@marketplace.compare-plans": {
                template: require("pages/findInsurance/compare-plans.html"),
                controller: "pages.findInsurance.compare-plans"
            }
        }
    },
    {
        state: "marketplace.eligibility",
        url: "/eligibility",
        views: {
            "body@": {
                template: bootstrap.templates["page-templates/category.html"],
                controller: "pages.marketplace"
            },
            "content@marketplace.eligibility": {
                template: require("pages/marketplace/eligibility.html"),
                controller: "pages.marketplace.eligibility"
            }
        }
    },
    {
        state: "marketplace.applicationStatus",
        url: "/application-status",
        views: {
            "body@": {
                template: bootstrap.templates["page-templates/category.html"],
                controller: "pages.marketplace"
            },
            "content@marketplace.applicationStatus": {
                template: require("pages/marketplace/applicationStatus.html"),
                controller: "pages.marketplace.applicationStatus"
            }
        }
    }];
};
