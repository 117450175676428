module.exports = function(app) {

    require('./agreementDisplay.js')(app);
    require('./agreementsData.js')(app);

    app.controller('pages.agreements', ["$state", "$scope", "agreementsData", "brand",
        function($state, $scope, agreementsData, brand) {

            $scope.sidebar.isWY = (brand &&
            brand.current &&
            brand.current.sharedBrandCode &&
            (brand.current.sharedBrandCode == "wybcbs"));
            if($scope.sidebar.isWY){
                $scope.headings = "Benefit Booklets";
            } else {
                $scope.headings = "Agreements & Benefit Booklets";
            }


            $scope.sidebar.year = "";

            // When region is switched, reset the Filter
            $scope.sidebar.switchRegion = function(){
                
                $scope.sidebar.emptySet = true;
                
                if ($scope.sidebar.region) {
                    $state.go('agreements.region', {
                        region: $scope.sidebar.region.referenceId.toLowerCase()
                    });

                    $scope.sidebar.regionSet = true;
                    $scope.sidebar.displayFilter = ($scope.sidebar.yearSet && $scope.sidebar.regionSet);

                    if ($scope.sidebar.keywordSearch) { $scope.sidebar.keywordSearch = ""; }

                } else {
                    $state.go('agreements');
                    $scope.sidebar.regionSet = false;
                    $scope.sidebar.displayFilter = ($scope.sidebar.yearSet && $scope.sidebar.regionSet);
                }
            };

            $scope.sidebar.getYearJsonData = function(){
                
                agreementsData.get($scope.sidebar.year).then(function(regions){
                        
                    // If function call is successful, set flags and create the arrays
                    if (regions != null && regions.labelList) {
                        $scope.sidebar.emptySet = true;
                        $scope.sidebar.yearSet = true;
                        $scope.sidebar.yearError = false;
                        $scope.sidebar.regionSet = false;

                        if ($scope.sidebar.region) { $scope.sidebar.region = ""; }

                        $scope.agreementsData = regions;
                        $scope.sidebar.regionsCollection = [];

                        for (var i = 0; i < regions.labelList.length; i++ ) {
                            if (regions.labelList[i].requiredBrand === brand.current.sharedBrandCode) {
                                $scope.sidebar.regionsCollection.push(regions.labelList[i]);
                            }
                        }

                        $state.go('agreements');

                    } else {
                    // If call fails or no data in json set to error mode
                        $scope.sidebar.regionsCollection = [];
                        $scope.sidebar.yearError = true;
                        $scope.sidebar.yearSet = false;
                        $scope.sidebar.regionSet = false;

                        $state.go('agreements');
                    }

                    // Hide or show the filter based on if year json is return and we are in a region
                    $scope.sidebar.displayFilter = ($scope.sidebar.yearSet && $scope.sidebar.regionSet);
                });
            };

            // Set the initial region on url location request
            if ($state.params.region) {
                $scope.sidebar.region = $state.params.region;
            }

            $scope.sidebar.resetEmptySet =  function(){
                $scope.sidebar.emptySet = true;
            };

            var updateSidebarRegion = function() {
                var tmp;
                for(var i = $scope.sidebar.regionsCollection && $scope.sidebar.regionsCollection.length -1; i >= 0; i--) {
                    var value = $scope.sidebar.regionsCollection[i];
                    if($state.params.region === value.referenceId) {
                        tmp = value;
                        break;
                    }
                } $scope.sidebar.region = tmp;
            };

            $scope.$on("$stateChangeSuccess", function(){
                updateSidebarRegion();
            });

            $scope.sidebar.links = [
                {
                    label: "Home",
                    state: "agreements",
                    refineTemplate: "pages/agreements/agreements.refine.html"
                }
            ];
            updateSidebarRegion();
            $scope.sidebar.isOpenSearch = true;
        }
    ]);
    return [
        {
            state: "agreements",
            url: "/sbc-agreements",
            views: {
                'body@': {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.agreements"
                },
                "content@agreements": {
                    template: require("pages/agreements/agreements.html")
                }
            }
        },
        {
            state: "agreements.region",
            url: "/:region",
            views: {
                "content@agreements": {
                    template: require("pages/agreements/agreementsTemplate.html"),
                    controller: "pages.agreementDisplay"
                }
            }
        }
    ];
};