/**
 * Created by lida1fh on 12/28/2015.
 */
module.exports = function(app) {
    return app.service("sepDocUpload", ["$q", "$http", "services", "handleError", "$state", function($q, $http, services, handleError, $state){

        var appData ={};
        var application = $http.get("./stubs/application.json").error().then(
            function(response){
                return response;
            }
        );

        this.getApps = function(appId){
           return application;
        };

        this.getDataService = function() {
                return appData;
        };

        this.setDataService = function(data) {
            appData = data;
        };

        var callBackFunction = function() {
            $state.go('sep.success');
        };

        var url = "/cmcrst/x-services/application/upload"; //files are saved to NAS: V:\CORPDATA05\CSS and EMARKETING\NasTempDrive\SEP_UPLOAD

        this.upload = function(fileList){
            var
                formData = new FormData(),
                i = fileList.length;

            var errorContent ={};
                errorContent.title = "Service Error",
                errorContent.content = "Please Call XXX-XXX-XXX";

            //add all of the assigned files
            while(i--){
                formData.append('uploadedFile' + i, fileList[i]);
            }
            return services.cmcrst.docUpload().postData(formData).then(
                function(response){
                    handleError.serviceResponseModal(response, errorContent, callBackFunction);
                },
                function(err) {
                    handleError.serviceResponseModal(err, errorContent, null);
//                    handleError.serviceResponse(err, errorContent, callBackFunction);

                }
            );
        };
    }]);
};