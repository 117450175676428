module.exports = function (app) {
    'use strict';
    app.directive('maskAccNo', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, elem, $attrs, ctrl) {
                var formatAccNo = function(){
                    var temp;
                    $(elem).focusin(function(){
                        $(elem).val(temp);
                    });
                    $(elem).on('blur',function(){
                        temp = $(elem).val();                    
                        $(elem).val("***-***" + temp.slice(6));                   
                    });
                }
                $scope.$watch("paymentModel", function (paymentModel) {
                    if (paymentModel && paymentModel.bankAccount && paymentModel.bankAccount.accountNumber) {
                        formatAccNo();
                    }
                });               
                
                elem.bind('click', function () {
                    elem.val(ctrl.$viewValue);
                });
            }
        };
    });
};