module.exports = function (app) {
    app.controller('pages.registration.selectUserType', ["$state", "$scope", "$stateParams", "stateService", "preserveUrl", "storage", "prospectUtility",
        function ($state, $scope, $stateParams, stateService, preserveUrl, storage, prospectUtility) {
           
            $scope.goToRegister = function (userType) {
                $state.go("register", {userType: userType, originalUrl: preserveUrl.getUrl()});
            };

            (function onInit(){
	            // ppreserveUrl.setUrl("/#!/marketplace/application-status");
	            if (storage.get("onExchangeObject")) {
	            	preserveUrl.setUrl("/sales/#!/find-insurance/view-plans");
	            } else {
	            	preserveUrl.setUrl("/sales/#!/find-insurance/build-your-family");
	            }
            })();
        }]
    );
};