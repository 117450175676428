module.exports = function(app) {

	return app.factory("discontinuedPlansUtility", [function() {

		var utilities = {
			determineIfPlanIsDiscontinued: _determineIfPlanIsDiscontinued,
		};

		function _determineIfPlanIsDiscontinued(plan, planCounty, discontinuedPlansJsonContent, yearDisclaimerApplies) {
			var discontinuedPlansArray 	= _generateDiscontinuedPlansByCountyObjectArray(discontinuedPlansJsonContent);
			var formattedHios = plan.hiosId.replace("-","");

			for (var arrayIndex = 0; arrayIndex < discontinuedPlansArray.length; arrayIndex++) {
				if (discontinuedPlansArray[arrayIndex].county.toUpperCase() === planCounty.toUpperCase()) {
					if (discontinuedPlansArray[arrayIndex].hiosId === formattedHios) {
						if ((new Date()).getFullYear().toString() === yearDisclaimerApplies) {
							return true;
						}
					}
				}
			}

			return false;
	    };

	    function _generateDiscontinuedPlansByCountyObjectArray(discontinuedPlansJsonContent) {
	    	var returnableArray = [];

	    	angular.forEach(discontinuedPlansJsonContent["IDS"], function(stringValue) {
	    		var stringSplit = stringValue.split("_");

		    	var templateObj 	= {
		    		"county": stringSplit[1],
		    		"hiosId": stringSplit[0]
		    	};

	    		returnableArray.push(templateObj);
	    	});

	    	return returnableArray;
	    };

	    return utilities;

	}])
}