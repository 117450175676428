module.exports = function(app) {
    app.controller('pages.approvalDashboard', ["$state", "$scope", "sepDocUpload", "brand", "brandConfig", "navigation",
        function($state, $scope, sepDocUpload, brand, brandConfig, navigation) {

        brand.override = 'unbrandedhm';
        brandConfig.update();
        navigation.update();

        $scope.headings = "SEP Applications";
        $scope.sidebar = {
            heading: "Special Enrollment Documentation Upload",
            description: "User our document upload application to provide details around the reason for your Special Enrollment.  Once you attach a document to your application, a customer service rep will reach out to you within a few business days."
        };

        $scope.sidebar = {
            links: [
                {
                    heading: "SEP Applications",
                    label: "SEP Applications",
                    state: 'approvalDashboard',
                    description: "View applications",
                    refineTemplate: "pages/approvals/approvals.refine.html"
                }
            ]
        };

        $scope.sidebar.displayStatus = [
            { "name": "Pending" },
            { "name": "Awaiting Documentation" },
            { "name": "Requested More Info" },
            { "name": "Declined" }
        ];

        $scope.data = {};

        $scope.data.applications = [
            {
               "fullName": {
                   "first": "Neil",
                   "last": "Kinnett"
               },
                applicationId: 123423423,
                "agent" :{
                    "first" : "Matt",
                    "last" : "Houser"
                },
                "statusCode": 14,
                "status": "Pending",
                "lastActivity" : "11/12/2014"
            },
            {
                "fullName": {
                    "first": "John",
                    "last": "Jacob"
                },
                applicationId: 555555,
                "agent" :{
                    "first" : "Larry",
                    "last" : "Smith"
                },
                "statusCode": 13,
                "status": "Awaiting Documentation",
                "lastActivity" : "12/12/2015"
            }
        ];

        $scope.sidebar.searchOpen = true;

        $scope.goTo = function(page) { $state.go(page) }
    }]);
    return [
        {
            state: "approvalDashboard",
            url: "/approval-dashboard",
            views: {
                'body@': {
                    template: bootstrap.templates["page-templates/categoryCustom.html"],
                    controller: "pages.approvalDashboard"
                },
                "content@approvalDashboard": {
                    template: require("pages/approvals/approvals.html")
                }
            }
        }
    ];
};