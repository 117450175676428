module.exports = function(app){
    app.directive('providerCoverageForPlan', ['$state', 'storage',"services", "mapServices", "sessionData",
        function ($scope, storage, services, mapServices, sessionData) {
            return {
                restrict: 'A',
                template: require('./providerCoverageForPlanTemplate.html'),
                scope: {
                    planData: '='
                },

                link: function ($scope) {
                    $scope.isHM = window.location.href.indexOf("highmark.com") > 0 ? true : false;
                    
                    console.log($scope.planData, $scope.planData.providerCoverage.length)

                    var findPlan = function(planName, planList){

                        //first step in locating the correct plan is to filter out every plan except for those that
                        //the planData plan name contains as a substring
                        var getPlanSubstrings = function(plan){
                            return planName.indexOf(plan.productName) != -1;
                        };
                        var substringPlans = planList.filter(getPlanSubstrings);
                        var locatedPlan;
                        //next, check the length of the substring plans array.
                        //if length is only 1, then the plan has been found (b/c it is not contained as a substring elsewhere in teh plan list.
                        if(substringPlans.length == 1){
                            locatedPlan = substringPlans.pop();
                        } else {
                            //more than one plan were found as a substring to the planData plan name
                            //so some refining needs to be done

                            //the list of substrings will contain every plan name that is a substring of
                            //the planData plan name

                            //so, the most accurate substring would be the longest substring, so find the plan
                            //with the longest name and pull it out
                            substringPlans.sort(function(a, b){
                                return a.productName.length - b.productName.length;
                            });
                            locatedPlan = substringPlans.pop();

                            //the list contains all plans from the plan list that are a substring of the selected plan's name
                            //so, if the list contains more than one item, they must be substrings of each other.
                            //therefore, the one item in the list that is not a substring of anything else must be the plan
                            //we are searching for
                        }
                        return locatedPlan;
                    };

                    //if the name is found to be a substring of any other of the plan names,
                    //return true
                    //return false if name is not a substring of anything else from the plan list

                    $scope.providerDetails = function(provider){

                        if ($scope.isHM){
                            // if vitals app config key is true then use service related to Sapphire
                            if ($scope.$root.brandData.isVitalsActive && (sessionData.appConfig && sessionData.appConfig.PROVIDER && sessionData.appConfig.PROVIDER.VITALS_LIVE) ? sessionData.appConfig.PROVIDER.VITALS_LIVE.booleanValue : false){
                                var networkId = $scope.planData.networkId;
                                storage.session.currPlanNetworkId = networkId;
                                mapServices.getLatLngFromFormattedAddress(provider.customHMHS.searchedVia.searchZip).then(function(results, status) {
                                    var domain = location.hostname;
                                    var urlPrefix = (domain.search(/env\d?\./gi) > -1) ? "uat." : "";
                                    var urlPattern = 'https://' + urlPrefix + 'highmark.sapphirecareselect.com/profile/{providerId}/{locationId}/%7B%22radius%22:%22{radius}%22,%22name%22:%22{provName}%22%7D?ci={ciValue}&network_id={networkId}&geo_location={latLon}&locale={locale}';
                                    var networkId = storage.session.currPlanNetworkId;
                                    var svcUrl = urlPattern.split("{env}").join(env)
                                        // .split("{providerId}").join(provider.professionalInfo.providerId)
                                        .split("{providerId}").join(provider.id)
                                        .split("{locationId}").join(provider.customHMHS.locationSelected.locationId)
                                        .split("{networkId}").join(networkId)
                                        .split("{radius}").join(provider.customHMHS.searchedVia.searchRadius || 10)
                                        .split("{provName}").join(provider.customHMHS.searchedVia.searchText)
                                        .split("{ciValue}").join("home")
                                        .split("{latLon}").join(results.latLng.lat + "," + results.latLng.lng)
                                        .split("{locale}").join("en_us");

                                    window.open(svcUrl);
                                });
                            } else {
                                //$scope.isEnabled = true;
                                var familyInfo              = storage.get("familyRatingInfo"),
                                    region                  = familyInfo.countyInfo.region,
                                    practitionerId          = provider.professionalInfo.practitionerId,
                                    vendorId                = provider.selectedLocation.vendorId,
                                    //locationSequenceNumber  = provider.selectedLocation.fivePartKey.locationSequenceNumber,
                                    locationSequenceNumber  = provider.selectedLocation.locationSequenceNo,
                                    RegionSpecificDomain,
                                    providerDetailsPath,
                                    regionBrand;
                                switch (region) {
                                    case "WPA":
                                    case "NEPA":
                                        //some code
                                        RegionSpecificDomain = "highmarkbcbs";
                                        regionBrand = "hmbcbs";
                                        break;
                                    case "CPA":
                                        RegionSpecificDomain = "highmarkblueshield";
                                        regionBrand = "hmbs";
                                        break;
                                    case "DE":
                                        RegionSpecificDomain = "highmarkbcbsde";
                                        regionBrand = "hmbcbsde";
                                        break;
                                    case "WV":
                                        RegionSpecificDomain = "highmarkbcbswv";
                                        regionBrand = "hmbcbswv";
                                        break;
                                    default:
                                        break;
                                }
                                var brandName;

                                switch(regionBrand) {
                                    case "hmbcbs":
                                    case "nepa":
                                        brandName = "Highmark Blue Cross and Blue Shield";
                                        break;
                                    case "hmbcbsde":
                                        "Highmark Delaware";
                                        break;
                                    case "ms":
                                    case "hmbcbswv":
                                        brandName = "HM West Virginia Blue Cross and Blue Shield";
                                        break;
                                    case "hmbs":
                                    case "pbs":
                                    default:
                                        brandName = "Highmark Blue Shield";
                                }

                                var planInfoParams = {};
                                planInfoParams.brandName = RegionSpecificDomain;
                                services.rbsmbl.planInfo().fetchAll(brandName).then(
                                    function(fetchAllResponse){
                                        //var x = fetchAllResponse;
                                        //var y = $scope;
                                        var planList = fetchAllResponse.data.payload.providerDataList.providerDataRecord;
                                        var plan = findPlan($scope.planData.name, planList);
                                        providerDetailsPath = "https://www."+RegionSpecificDomain+".com/find-a-doctor/#/details/";
                                        providerDetailsPath += practitionerId + "/";
                                        providerDetailsPath += vendorId + "/";
                                        providerDetailsPath += locationSequenceNumber + "/";
                                        providerDetailsPath += plan.productId ? plan.productId : '';
                                        providerDetailsPath += "?vendorType=D";

                                        window.open(providerDetailsPath, "_blank");
                                    }, function(error){
                                        console.log("could not redirect to provider details: " + error);
                                    }
                                );
                            }
                        }
                    };
                }
            };
        }
	]);
}