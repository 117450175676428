module.exports = function (app) {
    'use strict';
    app.directive('toggleQuestion', ["errorMessages", function (errorMessages) {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                questionText: "@",
                required: "@",
                index: "@",
                toggleItem: "=",
                toggleId: "@"
            },
            template: require('directives/enrollment/toggleQuestion.html'),
            link: function ($scope, elem) {
                $scope.errorMessages = errorMessages;
            }
        };
    }]);
};