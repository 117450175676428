/**
 * Created by LIDA1FH on 06/21/2015.
 */
module.exports = function(app) {
    app.filter("refineStatus", function(){
        return function(items, status) {
            var listOfItems = [],
                index = 0;
            var allFalse = function(data) {
                for (var i in data) {
                    if (data.hasOwnProperty(i) && data[i] === true) return false;
                }
                return true;
            };

            // no filters, show all
            if(!status || allFalse(status)) {
                return items;
            }

            // use filters
            while(index < items.length) {
                for (var key in status) {
                    //TODO: need to find out how to dynamically pull ites[index]**** so that we don't have to add here

                    if(status.hasOwnProperty(key) && status[key] && items[index].status === key) {
                        listOfItems.push(items[index]);
                    }
                }
                index++;
            }
            return listOfItems;
        };
    });
};