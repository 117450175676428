module.exports = function(app) {
  app.service('sharedBYFService', [
    'storage',
    function(storage) {
      var service = {
        setDataToSessionStorage: _setDataToSessionStorage,
        getFamilyValues: _getFamilyValues,
        generateNewFamily: _generateNewFamily
      };

      var family = {
        consumerEffectiveDate: null,
        members: []
      };

      function _generateNewFamily() {
          return angular.copy(family);
      }

      function _getFamilyValues() {
        var familyRatingInfo = storage.get('familyRatingInfo');

        if (storage.get('familyRatingInfo')) {
            return familyRatingInfo.family;
        } else {
            return angular.copy(family);
        }
      }

      // This will set the data into the session
      function _setDataToSessionStorage(
        zipCode,
        countyInfo,
        childOnlyPolicy,
        currentMember,
        medicareEligible,
        familyData
      ) {

        if (!childOnlyPolicy) { childOnlyPolicy = false; }
        if (!currentMember) { currentMember = false; }
        if (!medicareEligible) { medicareEligible = {}; }
        if (!familyData) {
            familyData = {
                consumerEffectiveDate: null,
                members: []
            };
        }

        storage.set('familyRatingInfo', {
          zipCode: zipCode,
          countyInfo: countyInfo,
          childOnlyPolicy: childOnlyPolicy,
          currentMember: currentMember,
          medicareEligible: medicareEligible,
          family: familyData
        });

        return storage.get('familyRatingInfo');
      }

      return service;
    }
  ]);
};
