module.exports = function(app) {

	app.factory("ratingInfoModel", [function(){
		var defaultModel = {
            "zipCode"         : null,
            "county"          : null,
            "region"          : null,
            "effectiveDate"   : null,
            "state"           : null,
            "serviceZones"    : null,
            "ratingArea"      : "",
            "applicants"      : null,
            "ffmRatingArea"   : null
		};

		var activeModel = {};

		var serviceProperties = {
			"get"		: _get,
			"set"		: _set,
			"isNotSet" 	: _isNotSet
		};

		function _set(params) {
			// empty object
			if (_isNotSet()) {
				activeModel = angular.copy(defaultModel);
			}

			angular.extend(activeModel, params);
		};

		function _get() {
			return angular.copy(activeModel);
		};

		function _isNotSet() {
			return angular.equals(activeModel, {});
		};

		return serviceProperties;
	}]);
};