module.exports = function(app) {
  app.directive('zipCounty', [
    'services',
    'brand',
    'brandConfig',
    'modal',
    'appConfig',
    'sessionData',
    'sepConfigUtility',
    'loginManager',
    function(
      services,
      brand,
      brandConfig,
      modal,
      appConfig,
      sessionData,
      sepConfigUtility,
      loginManager
    ) {
      return {
        restrict: 'A',
        replace: true,
        template: require('./zipCounty.html'),
        scope: {
          county: '=',
          zipCode: '=',
          countySelected: '&',
          editZipTrigger: '&',
          loggedInUserRole: '=?',
          enableInput: '=',
          emitZipFailed: '&'
        },

        link: function($scope) {
          $scope.countyList = [];

          $scope.selectedCounty = function() {
            
            brand.override = $scope.county.brand;
            brandConfig.update();
            $scope.$emit('brandUpdated', angular.copy($scope.county));
            appConfig.getAll().then(function(response) {
              sessionData.appConfig = angular.extend({}, response);
            });

            if ($scope.enableInput) {
              sepConfigUtility.newZipCode = true;
            }

            $scope.countySelected({ response: $scope.county });
          };

          $scope.isLoggedIn = loginManager.getUserRole();

          $scope.editZipCode = function() {
            sepConfigUtility.newZipCode = true;
            $scope.editZipTrigger();
          };

          $scope.$watch('zipCode', function(zipCode) {
            if (!zipCode || (zipCode && zipCode.length != 5)) {
              return;
            }

            $scope.countyList = [];
            $scope.zipCode = zipCode;

            var payload = {
              zipCode: zipCode
            };

            return services.cmcrst
              .countyList()
              .postData(payload)
              .then(
                function(countyResponse) {
                  $scope.countyList = countyResponse.data.payload.countyList;
                  if ($scope.countyList.length === 1) {
                    $scope.county = $scope.countyList[0];
                    $scope.selectedCounty();
                  }
                },
                function(error) {
                  if ($scope.zipCode && $scope.zipCode.length === 5) {
                    modal.open({
                      template: require('modals/zipcode-error-modal.html'),
                      controller: require('modals/zipcode-error-modal'),
                      onClose: function() {
                        $scope.zipCode = '';
                      }
                    });
                    $scope.emitZipFailed()
                  }
                  // todo: Figure out how to display service error modal when 500 error occurs
                  // if (response.data === '500') {
                  //    modal.open({
                  //        template: require('modals/zipcode-service-error-modal.html'),
                  //        controller: require('modals/zipcode-service-error-modal'),
                  //        onClose: function (){
                  //            $scope.zipCode = "";
                  //        }
                  //    });
                  //}
                }
              );
          });

          $scope.$watch('county', function(county, county2) {
            if ($scope.county && !angular.equals($scope.county, {})) {
              $scope.selectedCounty();
            }
          });
        }
      };
    }
  ]);
};
