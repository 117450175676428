var app = angular.module("app", ["bootstrap.main", "bootstrap.larg"]);
app.value("appId", "CHMSHP");

require("./directives/hrefInterceptor")(app);
require("./directives/compareGrid")(app);
require("./directives/consumer/applicationDrawer")(app);
require("./directives/currencyInput")(app);
require("./directives/eligibleDrawer")(app);
require("./directives/onAndOffExchangeInterstitial")(app);
require("./directives/enrollment/suffix")(app);
require("./directives/enrollment/fullName")(app);
require("./directives/enrollment/socialSecurityNumber")(app);
require("./directives/enrollment/maskSsn")(app);
require("./directives/enrollment/maskAccNo")(app);
require("./directives/enrollment/dateOfBirth")(app);
require("./directives/enrollment/sex")(app);
require("./directives/enrollment/address")(app);
require("./directives/enrollment/zipCounty")(app);
require("./directives/enrollment/toggleQuestion")(app);
require("./directives/enrollment/phoneNumbers")(app);
require("./directives/enrollment/emailAddress")(app);
require("./directives/enrollment/contactPreference")(app);
require("./directives/enrollment/tobaccoUsage")(app);
require("./directives/enrollment/languageOptions")(app);
require("./directives/enrollment/numberFormatter")(app);
require("./directives/enrollment/planSideInfo")(app);
require("./directives/enrollment/pcpInfo")(app);
require("./directives/enrollment/relationship")(app);
require("./directives/enrollment/paymentPreference")(app);
require("./directives/enrollment/appCompleteReview")(app);
require("./directives/familyMemberInput")(app);
require("./directives/fileUploadDirective")(app);
require("./directives/highLevelPlanDetail")(app);
require("./directives/limitToMax")(app);
require("./directives/loginRegister")(app);
require("./directives/narrowBy")(app);
require("./directives/modifiedAptcPremium")(app);
require("./directives/aptcSlider")(app);
require("./directives/benefitGrid")(app);
require("./directives/planDrawer")(app);
require("./directives/zipCounty")(app);
require("./directives/enrollment/planDetailsAdditionalLinks")(app);
require("./directives/prescriptionDrugCoverageForPlan")(app);
require("./directives/providerCoverageForPlan")(app);
require("./directives/ffmRedirectForm")(app);

require("./filters/filters")(app);

require("./prototypes/sepDocumentSrvc")(app);

require("./resources/filters")(app);

require("./services/buildYourFamily.producerFlowService.js")(app);
require("./services/buildYourFamily.consumerFlowService.js")(app);
require("./services/buildYourFamily.sharedService.js")(app);
require("./services/appRetrieveService")(app);
require("./services/appStatusService")(app);
require("./services/applicationFactory")(app);
require("./services/customerLookup")(app);
require("./services/ddSetup")(app);
require("./services/effectiveDateService")(app);
require("./services/errorMessages")(app);
require("./services/navigationBuilder")(app);
require("./services/preserveUrl")(app);
require("./services/productService")(app);
require("./services/productAttributeService")(app);
require("./services/providerDirectory")(app);
require("./services/prospectService")(app);
require("./services/planDataUtility")(app);
require("./services/plansAcceptedService")(app);
require("./services/sepConfigUtility")(app);
require("./services/ratingInfoService")(app);
require("./services/saveScfInfo")(app);
require("./services/isSepService")(app);
require("./services/sepDocUpload")(app);
require("./services/stateService")(app);
require("./services/subsidyService")(app);
require("./services/teamsiteManager")(app);
require("./services/teamsiteService")(app);
require("./services/groupNumberSearch")(app);
require("./services/smallGroupCountySearch")(app);
require("./services/marketplaceDrugSearch")(app);
require("./services/sessionStorageService")(app);
require("./services/prospectHTTPService")(app);
require("./services/configurationUtilityService")(app);
require("./services/samlResponseforFFMService")(app);
require("./services/sepLifeEventDocUpload")(app);
require("./services/blankEmailApplicationService")(app);

require("./utilities/coveredPrescriptionDrugsUtility")(app);
require("./utilities/clickToChatUtility")(app);
require("./utilities/updateFooterUtility")(app);
require("./utilities/coveredProvidersUtility")(app);
require("./utilities/loginManager")(app);
require("./utilities/ffmEligibilityUtility")(app);
require("./utilities/prospectUtility")(app);
require("./utilities/configurationUtility")(app);
require("./utilities/discontinuedPlansUtility")(app);
require("./utilities/hmShopDateAndTimeUtility")(app);
require("./utilities/enrollmentUtility")(app);
require("./utilities/blankEmailApplicationUtility")(app);
require("./utilities/specialEnrollmentPeriodPaperApplicationUtility")(app);

require("./models/ratingInfoModel")(app);

var routes = [];

routes = routes.concat(require("./pages/sep/home")(app));
routes = routes.concat(require("./pages/approvals/approvalDashboard")(app));
routes = routes.concat(require("./pages/approvals/reviewApplication")(app));
routes = routes.concat(require("./pages/approvals/modifyApplication")(app));
routes = routes.concat(require("./pages/agreements/agreements")(app));
routes = routes.concat(require("./pages/sbcs/sbcs")(app));
routes = routes.concat(require("./pages/sbcs/sbcDisplay")(app));
routes = routes.concat(require("./pages/sbcs/sbcsData")(app));
routes = routes.concat(require("./pages/brochures/brochures")(app));
routes = routes.concat(require("./pages/findInsurance/find-insurance")(app));
routes = routes.concat(require("./pages/login/login")(app));
routes = routes.concat(require("./pages/enrollment/enrollment")(app));
routes = routes.concat(require("./pages/marketplace/marketplace")(app));
routes = routes.concat(require("./pages/completedApplication/completedApplication")(app));
routes = routes.concat(require("./pages/consumer/savedApplications")(app));
routes = routes.concat(require("./pages/findInsurance/household-income")(app));
routes = routes.concat(require("./pages/findInsurance/household-results")(app));
routes = routes.concat(require("./pages/registration/registration")(app));
routes = routes.concat(require("./pages/medicare/orderForms")(app));
routes = routes.concat(require("./prototypes/fileUpload")(app));

app.service('routes', function () {
    return routes;
});

app.config(["$stateProvider", "$locationProvider", "$compileProvider", "$urlRouterProvider",
    function ($stateProvider, $locationProvider, $compileProvider, $urlRouterProvider) {

        $urlRouterProvider.otherwise("/find-insurance/build-your-family");
        $locationProvider.html5Mode(false).hashPrefix('!');
        /****
        * ^^^
        * Using this is starting to get us 
        * in trouble with other projects not using this (AEM shift should resolve)
        ****/

        // If using AEM, use below routes
        //$urlRouterProvider.otherwise("/");
        //$locationProvider.html5Mode(true);
    }]);

var exceptionServiceLoopBreaker = {};
app.config(['$httpProvider', 'SALES_CONSTANTS', function ($httpProvider, SALES_CONST) {

    $httpProvider.interceptors.push(function (services, brand, $q) {
        return {
            'responseError': function (response) {
                if ((exceptionServiceLoopBreaker.config && exceptionServiceLoopBreaker.config.url)
                    !== response.config.url && response.config.url
                    !== "/cmcsnr/x-services/scf/saveScfInfo") {
                    exceptionServiceLoopBreaker = response;

                    var payload = {
                        "baseMessage": {},
                        "payload": {
                            "applicationName": "CHMSHP",
                            "serviceName": 'Endpoint',
                            "asp": "HM",
                            "url": window.location.href,
                            "brand": brand.current.sharedBrandCode,
                            "actorType": "member",
                            "ubk": brand.current.ubk,
                            "origin": "backend",
                            "serviceInput": response.config || {},
                            "serviceOutput": response || {}
                        }
                    };
                }
                var defer = $q.defer();
                defer.reject(response);
                return defer.promise;
            }
        };
    });
}]);

// Use decorate property through $provide rather than override
var exceptionUiLoopBreaker = {};
app.factory('$exceptionHandler', ['$log', 'services', 'brand', function ($log, services, brand) {
    return function errorCatcherHandler(exception, cause) {

        if (exceptionUiLoopBreaker.message !== exception.message) {
            exceptionUiLoopBreaker = exception;

            var payload = {
                "baseMessage": {},
                "payload": {
                    "applicationName": "CHMSHP",
                    "serviceName": "UI",
                    "asp": "HM",
                    "url": window.location.href,
                    "brand": brand.current.sharedBrandCode,
                    "actorType": "member",
                    "ubk": brand.current.ubk,
                    "origin": "frontend",
                    "serviceInput": cause || {},
                    "serviceOutput": exception || {}
                }
            };
        }
        $log.error(exception, cause);
    };
}]);

app.run([
    '$state',
    'navigation',
    'brand',
    'link',
    'brandConfig',
    'navigationBuilder',
    'coremetrics',
    'ddSetup',
    'hook',
    'modal',
    'clickToChatUtility',
    '$rootScope',
    'updateFooterUtility',
    'storage',
    'teamsiteManager',
    'SALES_CONSTANTS',
    'brandValues',
    function (
        $state,
        navigation,
        brand,
        link,
        brandConfig,
        navigationBuilder,
        coremetrics,
        ddSetup,
        hook,
        modal,
        clickToChatUtility,
        $rootScope,
        updateFooterUtility,
        storage,
        teamsiteManager,
        SALES_CONSTANTS,
        brandValues
    ) {
        /** used to override header and footer logos to display dual HM logo (brand simplification) */
        brandValues.brandInfo.forEach(function(x) {
            x.logoImg = "highmark_bcbsbs.png";
            return x.logoImg;
        });
        /** Build HOSTNAME for HEADER/FOOTER usage */
        var host = window.location.hostname;
        var firstPart = host.split('.')[0];
        var hasTestEnv = firstPart.search(/[0-9]/);
        var whichTest;
        if (hasTestEnv !== -1) {
            whichTest = firstPart.substr(firstPart.length -1, 1);
        }
        
        var hasPenv = firstPart.search('penv');
        
        var hostForNav;
        if (hasPenv !== -1) {
            hostForNav = "https://shoppenv";
        } else if (whichTest) {
            hostForNav = "https://shoptenv" + whichTest;
        } else {
            hostForNav = "https://shop";
        }

        /** Set DDToken again, manually, to ensure we have the appropriate ASP-based value (not region based) */
        ddSetup.set();

        coremetrics.postPasswordProducerEnd();
        $rootScope.isOnX = false;
        updateFooterUtility.swapToOffxFooter();

        //set up watcher
        $rootScope.$watch("isOnX", function (newValue, oldValue) {
            if (newValue != oldValue) {
                if (newValue) {
                    updateFooterUtility.swapToOnxFooter();
                } else {
                    updateFooterUtility.swapToOffxFooter();
                }
                brandConfig.update();
                //call service to update brandValues based on onX/offX flag
            }
        });


        navigation.setFilter('portal', function (item) {
            return 'sales' === item.filterData.portal;
        });

        // Links to include on specific states, sbcs and agreements
        navigation.setFilter('includeState', function (item) {
            if (item.filterData && item.filterData.includeState) {
                //if state contains includeStates return true, else return false
                var shouldFilter = false;
                angular.forEach(item.filterData.includeState, function (state) {
                    shouldFilter = shouldFilter || $state.includes(state);
                });
                return shouldFilter;
            } return true;
        });

        // Links to show that exclude sbcs and agreements
        navigation.setFilter('excludeState', function (item) {
            if (item.filterData && item.filterData.excludeState) {
                //if state does not contain any of the ExcludeStates return true, else return false
                var shouldFilter = true;
                angular.forEach(item.filterData.excludeState, function (state) {
                    shouldFilter = shouldFilter && !$state.includes(state);
                });
                return shouldFilter;
            } return true;
        });

        var sbcsLink = link.set("sbcsLink", {
            "label": "SBCS",
            "url": "#!/sbcs",
            "newWindow": false,
            filterData: {
                "includeState": ['sbcs', 'agreements'],
                "brand": ["unbrandedhm", "hmbcbs", "wybcbs"]
            }
        });

        var isWY = (
            brand &&
            brand.current &&
            brand.current.sharedBrandCode &&
            (brand.current.sharedBrandCode == "wybcbs")
        );

        var agreementsLink;
        var homeLink;
        if (isWY) {
            /** override the other override for wyoming logos */
            brandValues.brandInfo.forEach(function(x) {
                x.logoImg = "bcbs-wyoming-logo.png";
                return x.logoImg;
            });
            
            agreementsLink = link.set("agreementsLink", {
                "label": "Benefit Booklets",
                "url": "#!/sbc-agreements",
                "newWindow": false,
                filterData: {
                    "includeState": ['sbcs', 'agreements'],
                    "brand": ["unbrandedhm", "hmbcbs", "wybcbs"]
                }
            });

            homeLink = link.set("homeLink", {
                "icon": "icon-home",
                "url": hostForNav + ".yourwyoblue.com",
                "newWindow": false,
                filterData: {
                    "excludeState": ['sbcs', 'agreements'],
                    "brand": ["unbrandedhm", "hmbcbs", "hbs", "hmbs", "hmbcbsde", "hmbcbswv", "nepa", "ndbcbs", "wybcbs", "mincr"]
                  
                }
            });
    
        } else {
            agreementsLink = link.set("agreementsLink", {
                "label": "Agreements",
                "url": "#!/sbc-agreements",
                "newWindow": false,
                filterData: {
                    "includeState": ['sbcs', 'agreements'],
                    "brand": ["unbrandedhm", "hmbcbs", "wybcbs"]
                }
            });

            homeLink = link.set("homeLink", {
                "icon": "icon-home",
                "url": "#!/find-insurance/build-your-family",
                "newWindow": false,
                filterData: {
                    "excludeState": ['sbcs', 'agreements'],
                    "brand": ["unbrandedhm", "hmbcbs", "hbs", "hmbs", "hmbcbsde", "hmbcbswv", "nepa", "ndbcbs", "wybcbs", "mincr"]
                  
                }
            });
    
        }

        var headerTabs = navigation.set("headerTabs");
        headerTabs.links.push(homeLink, sbcsLink, agreementsLink);
        headerTabs.linkFilters.push("includeState", "excludeState", "brand");

        if (window.location.hostname.indexOf('bluecrossmnonline') > -1) {
            brand.override = 'mincr';
            brandConfig.update();
            navigationBuilder.buildShopHeaderFooterTabs();
        }
        else if (window.location.hostname.indexOf('yourwyoblue') > -1) {
            brand.override = 'wybcbs';
            brandConfig.update();

            var WYshopLink = link.set("shopLink", {
                "label": "Shop",
                "url": hostForNav + ".yourwyoblue.com",
                "newWindow": false,
                filterData: {
                    "excludeState": ['sbcs', 'agreements'],
                    "brand": ["wybcbs"]
                }
            });
            var WYfindADoctorLink = link.set("findADoctorLink", {
                "label": "Find A Doctor or RX",
                "url": "#!/sbcs",
                "newWindow": false,
                filterData: {
                    "excludeState": ['sbcs', 'agreements'],
                    "brand": ["wybcbs"]
                }
            });
            var WYmedicareLink = link.set("medicareLink", {
                "label": "Medicare",
                "url": "https://medicare.yourwyoblue.com/",
                "newWindow": false,
                filterData: {
                    "excludeState": ['sbcs', 'agreements'],
                    "brand": ["wybcbs"]
                }
            });
            var WYadditionalLinks = link.set("customerServiceLink", {
                "label": "Additional Links",
                "url": "#",
                "newWindow": false,
                "filterData": {
                    "portal": "sales",
                    "brand": ["wybcbs"]
                },
                "linkFilters": ['brand'],
                links: [
                    link.set("agentLink", {
                        "label": "Find an Agent",
                        "url": "https://www.bcbswy.com/insurance/",
                        "newWindow": true,
                        filterData: {
                            "brand": ["wybcbs"]
                        }
                    }),
                    link.set("informationLink", {
                        "label": "Important Info",
                        "url": "https://www.bcbswy.com/information/",
                        "newWindow": true,
                        filterData: {
                            "brand": ["wybcbs"]
                        }
                    }),
                    link.set("WYLink", {
                        "label": "BCBSWY.com",
                        "url": "https://www.bcbswy.com/",
                        "newWindow": true,
                        filterData: {
                            "brand": ["wybcbs"]
                        }
                    })
                ]
            });
            var footerLinks = navigation.set('footerLinks');
            footerLinks.links = [];
            footerLinks.links.push(WYshopLink);
            footerLinks.links.push(WYfindADoctorLink);
            footerLinks.links.push(WYmedicareLink);
            footerLinks.links.push(WYadditionalLinks);
        }
        else {
            brand.override = 'unbrandedhm';
            brandConfig.update();
            //only for highmark, set up click to chat
            document.onclick = function () {
                //update click to chat on mouse clicks
                clickToChatUtility.handleChatAvailability();
            };
            //perform initial chat availability check
            clickToChatUtility.handleChatAvailability();
            //register hook to catch onclick trigger from BTS
            clickToChatUtility.registerOnClickEventHook();


            var discoverLink = link.set("discoverLink", {
                "label": "Discover",
                "url": "http://discoverhighmark.com/",
                "newWindow": false,
                filterData: {
                    "excludeState": ['sbcs', 'agreements'],
                    "brand": ["*"]
                }
            });
            var shopLink = link.set("shopLink", {
                "label": "Shop",
                "url": "#!/find-insurance/build-your-family",
                "newWindow": false,
                filterData: {
                    "excludeState": ['sbcs', 'agreements'],
                    "brand": ["*"]
                }
            });
            var findADoctorLink = link.set("findADoctorLink", {
                "label": "Find A Doctor or RX",
                "url": null,
                "onClick": function () {
                    modal.open({
                        "template": require('modals/find-a-provider-header-template.html'),
                        "controller": require('modals/find-a-provider-header'),
                        "classes": "wider-modal",
                    });
                },
                "newWindow": false,
                filterData: {
                    "excludeState": ['sbcs', 'agreements'],
                    "brand": ["*"]
                }
            });

            var medicareLink = link.set("medicareLink", {
                "label": "Medicare",
                "url": "https://medicare.highmark.com/",
                "newWindow": false,
                filterData: {
                    "excludeState": ['sbcs', 'agreements'],
                    "brand": ["*"]
                }
            });

            // Set up the additional links for the footer
            var additionalLinks = link.set("customerServiceLink", {
                "label": "Additional Links",
                "url": "#",
                "newWindow": false,
                "filterData": {
                    "portal": "sales",
                    "brand": ["*"]
                },
                "linkFilters": ['brand'],
                links: [
                    link.set("customerServiceLink", {
                        "label": "Customer Service",
                        "url": "https://www.highmarkbcbs.com/chmptl/chm/jsp/contactus.do?oid=-13447&submitbutton=custserv",
                        newWindow: false,
                        filterData: {
                            "brand": ["unbrandedhm", "hmbcbs"]
                        }
                    }),
                    link.set("customerServiceLink", {
                        "label": "Customer Service",
                        "url": "https://www.highmarkblueshield.com/chmptl/chm/jsp/contactus.do?oid=-13447&submitbutton=custserv",
                        newWindow: false,
                        filterData: {
                            "brand": ["pbs"]
                        }
                    }),
                    link.set("customerServiceLink", {
                        "label": "Customer Service",
                        "url": "https://www.highmarkbcbsde.com/chmptl/chm/jsp/contactus.do?oid=-13447&submitbutton=custserv",
                        newWindow: false,
                        filterData: {
                            "brand": ["hmbcbsde"]
                        }
                    }),
                    link.set("customerServiceLink", {
                        "label": "Customer Service",
                        "url": "https://www.highmark.com/hmk2/contact/index.shtm",
                        newWindow: false,
                        filterData: {
                            "brand": ["hmbcbswv"]
                        }
                    }),
                    link.set("highmarkDirectLink", {
                        "label": "Find a Direct Store",
                        "url": "http://www.highmarkdirect.com",
                        "newWindow": false,
                        filterData: {
                            "brand": ["hmbcbs", "pbs", "unbrandedhm"]
                        }
                    }),
                    link.set("aboutHighmarkLink", {
                        "label": "About Highmark",
                        "url": "https://www.highmark.com/hmk2/about/vision/index.shtml",
                        "newWindow": false,
                        filterData: {
                            "brand": ["*"]
                        }
                    }),
                    link.set("privacyPolicyLink", {
                        "url": "https://www.highmark.com/hmk2/privacy.shtml",
                        "label": "Privacy Policy",
                        newWindow: false,
                        filterData: {
                            "brand": ["unbrandedhm", "hmbcbs"]
                        }
                    }),
                    link.set("privacyPolicyLink", {
                        "url": "https://www.highmark.com/hmk2/privacy.shtml",
                        "label": "Privacy Policy",
                        newWindow: false,
                        filterData: {
                            "brand": ["pbs"]
                        }
                    }),
                    link.set("privacyPolicyLink", {
                        "url": "http://www.athighmark.com/privacy/de/index.shtml",
                        "label": "Privacy Policy",
                        newWindow: false,
                        filterData: {
                            "brand": ["hmbcbsde"]
                        }
                    }),
                    link.set("privacyPolicyLink", {
                        "url": "http://www.athighmark.com/privacy/wv/index.shtml",
                        "label": "Privacy Policy",
                        newWindow: false,
                        filterData: {
                            "brand": ["hmbcbswv"]
                        }
                    }),
                    link.set("medicalPolicyLink", {
                        "url": "https://www.highmark.com/health/professionals/medical-policy/medical-policy-acceptance.html",
                        "label": "Medical Policy",
                        newWindow: false,
                        filterData: {
                            "brand": ["unbrandedhm", "hmbcbs"]
                        }
                    }),
                    link.set("medicalPolicyLink", {
                        "url": "https://www.highmarkblueshield.com/health/pbs-professionals/medical-policy/hbs-medical-policy-acceptance.html",
                        "label": "Medical Policy",
                        newWindow: false,
                        filterData: {
                            "brand": ["pbs"]
                        }
                    }),
                    link.set("medicalPolicyLink", {
                        "url": "https://www.highmark.com/health/professionals/delaware-commercial-medical-policy/medical-policy-acceptance.html",
                        "label": "Medical Policy",
                        newWindow: false,
                        filterData: {
                            "brand": ["hmbcbsde"]
                        }
                    }),
                    link.set("accessibilityLink", {
                        "url": "https://www.highmarkbcbs.com/accessibility/accessibility_wpa.html",
                        "label": "Accessibility",
                        newWindow: false,
                        filterData: {
                            "brand": ["unbrandedhm", "hmbcbs"]
                        }
                    }),
                    link.set("accessibilityLink", {
                        "url": "https://www.highmarkblueshield.com/accessibility/accessibility_cre.html",
                        "label": "Accessibility",
                        newWindow: false,
                        filterData: {
                            "brand": ["pbs"]
                        }
                    }),
                    link.set("accessibilityLink", {
                        "url": "https://www.highmarkbcbsde.com/accessibility/accessibility_de.html",
                        "label": "Accessibility",
                        newWindow: false,
                        filterData: {
                            "brand": ["hmbcbsde"]
                        }
                    }),
                    link.set("accessibilityLink", {
                        "url": "https://www.mybenefitshome.com/accessibility/accessibility_mbh.html",
                        "label": "Accessibility",
                        newWindow: false,
                        filterData: {
                            "brand": ["hmbcbswv"]
                        }
                    }),
                    link.set("smsTextingLink", {
                        "label": "SMS Texting",
                        "url": "https://www.highmark.com/hmk2/texting.shtml",
                        "newWindow": false,
                        filterData: {
                            "brand": ["*"]
                        }
                    }),
                    link.set("termsOfServiceLink", {
                        "label": "Terms of Service",
                        "url": "https://www.highmark.com/hmk2/terms.shtml",
                        "newWindow": false,
                        filterData: {
                            "brand": ["*"]
                        }
                    }),
                    link.set("fraudPreventionLink", {
                        "label": "Fraud Prevention",
                        "url": "https://www.highmark.com/hmk2/terms.shtml",
                        "newWindow": false,
                        filterData: {
                            "brand": ["*"]
                        }
                    }),
                    link.set("hippaLink", {
                        "label": "HIPAA",
                        "url": "https://www.highmark.com/hmk2/hipaa/index.shtml",
                        "newWindow": false,
                        filterData: {
                            "brand": ["*"]
                        }
                    }),
                    link.set("gdprLink", {
                        "label": "GDPR",
                        "url": " https://www.highmarkhealth.org/hmk/gdpr.shtml",
                        "newWindow": true,
                        filterData: {
                            "brand": ["*"]
                        }
                    })

                ]
            });

            var prePasswordLoginLink;
            var prePasswordRegisterLink;
            
            teamsiteManager.getContent('header', 'DEFAULT').then(
                function (content) {
                    if (!content.headerConfig.hideLoginRegister) {
                        /****
                         * Config Right-Side Links
                         ****/
                        prePasswordLoginLink = link.set("prePasswordLogin", {
                            label: "Log In",
                            url: false,
                            "filterData": {
                                "userType": ['GUEST', 'TELESALES', 'AGENT'],
                                portal: 'shop',
                                postPassword: false,
                                brand: ['unbrandedhm']
                            }
                        });

                        prePasswordRegisterLink = link.set("prePasswordLogin", {
                            label: "Register",
                            url: "#!/registration",
                            "filterData": {
                                "userType": ['GUEST', 'TELESALES', 'AGENT'],
                                portal: 'shop',
                                postPassword: false,
                                brand: ['unbrandedhm']
                            }
                        });

                        // // Get existing right-side links
                        // var headerExtraLinks = navigation.set('headerExtraLinks');

                        // console.log("NON TEAMSITE HEADER");
                        // // add right-side links
                        // headerExtraLinks.links.push(prePasswordLoginLink, prePasswordRegisterLink);

                        function _setupRightSideHeaderLinks() {
                            // Get existing right-side links
                            var headerExtraLinks = navigation.set('headerExtraLinks');

                            // add right-side links
                            headerExtraLinks.links.push(prePasswordLoginLink, prePasswordRegisterLink);

                            navigation.update();
                        };
                        _setupRightSideHeaderLinks();
                    }
                }
            )
            
            teamsiteManager.getASPConfig()
            .then(function(response) {
                if (response && response.data && response.data.commerceFlags) {
                    storage.set(SALES_CONSTANTS.commerce.useLogin, response.data.commerceFlags.useLogin);
                    storage.set(SALES_CONSTANTS.commerce.useProductService, response.data.commerceFlags.useProductService);
                }
            });

            headerTabs.links.push(discoverLink, shopLink, findADoctorLink, medicareLink);

            /****
             * Build Footer
             ****/
            var footerLinks = navigation.set('footerLinks');
            footerLinks.links = [];
            footerLinks.links.push(discoverLink);
            footerLinks.links.push(shopLink);
            footerLinks.links.push(findADoctorLink);
            footerLinks.links.push(medicareLink);
            footerLinks.links.push(additionalLinks);

            
            /****
             * ON LOG OUT
             ****/
            hook.register("btsLogoutEvent", function () {
                _setupRightSideHeaderLinks();
                localStorage.clear();
                storage.del("familyRatingInfo");
                
                teamsiteManager.getASPConfig()
                .then(function(response) {
                    if (response && response.data && response.data.commerceFlags) {
                        storage.set(SALES_CONSTANTS.commerce.useLogin, response.data.commerceFlags.useLogin);
                        storage.set(SALES_CONSTANTS.commerce.useProductService, response.data.commerceFlags.useProductService);
                    }
                    $state.go("findInsurance.build-your-family");
                });
    
            });

            /****
             * ON LOG IN
             ****/
            hook.register("ltpaLoginStarted", function () {
                var headerExtraLinksReset = navigation.get('headerExtraLinks');
                var linksToRemove = [prePasswordRegisterLink, prePasswordLoginLink];

                for (var startedFromTheBottomIndex = headerExtraLinksReset.length - 1; startedFromTheBottomIndex >= 0; startedFromTheBottomIndex--) {

                    angular.forEach(headerExtraLinksReset, function (linkCollection) {

                        for (var i = linkCollection.links.length - 1; i >= 0; i--) {
                            // angular.forEach(linkCollection.links, function(link){
                            var canRemove = false;

                            angular.forEach(linksToRemove, function (linkToRemove) {
                                if (linkToRemove) {
                                    if (angular.equals(linkCollection.links[i].label, linkToRemove.label) &&
                                        angular.equals(linkCollection.links[i].url, linkToRemove.url) &&
                                        angular.equals(linkCollection.links[i].filterData.brand, linkToRemove.filterData.brand) &&
                                        angular.equals(linkCollection.links[i].filterData.userType, linkToRemove.filterData.userType) &&
                                        angular.equals(linkCollection.links[i].filterData.portal, linkToRemove.filterData.portal) &&
                                        angular.equals(linkCollection.links[i].filterData.postPassword, linkToRemove.filterData.postPassword)) {

                                        canRemove = true;
                                    }
                                }
                            })

                            if (canRemove) {
                                console.log("removing ", linksToRemove[i])
                                linkCollection.links.splice(i, 1);
                            }
                            // })
                        }
                    })


                }

                ddSetup.set();

            });
        }

    }]);