module.exports = function(bootstrap) {
    bootstrap.run(["Service", function (Service) {
        return new Service({
            name: 'subsidyService',
            group: "cmcrst",
            url: "/cmcrst/x-services/subsidy",
            cache: false,

            postSubsidyService: function(payload){
                return this.post({ratingInfo:[payload]});
            }

        });
    }]);
};