module.exports = function (app) {
    'use strict';
    return app.factory('applicationFactory', [
        function () {
            var applicationFactory = {},
                personDataTemplate = {
                    'fullName': {
                        'firstName': "",
                        'middleName': null,
                        'lastName': null,
                        'suffix': {
                            label: "",
                            value: ""
                        }
                    },
                    'relationship': null,
                    'relationshipCode': null,
                    'ssn': {
                        'ssnNumber': null,
                        'noSsnInd': null
                    },
                    'gender': null,
                    'dateOfBirth': "10-01-1991",
                    'homeAddress': {
                        'addressLine1': "123 main steet",
                        'addressLine2': "address line 2",
                        'city': "Pittsburgh",
                        'state': "PA",
                        'zipcode': "15232",
                        'county': "ALLEGHENY",
                        'countyCode': 3
                    },
                    'sameAddressAsSubscriber': null,
                    'sameMailingAddress': null,
                    'mailingAddress': {
                        'addressLine1': null,
                        'addressLine2': null,
                        'city': null,
                        'state': null,
                        'zipcode': null,
                        'county': null,
                        'countyCode': null
                    },
                    'primaryPhoneNumber': "4123209589",
                    'otherPhoneNumber': null,
                    'tobaccoInd': null,
                    'tobaccoLastUsed': null,
                    'receiveVia': {
                        'electronically': null,
                        'mail': null
                    },
                    'email': null,
                    'emailInd': null,
                    'cellNumber': null,
                    'cellNumberInd': null,
                    'spokenLanguage': null,
                    'spokenLanguageCode': null,
                    'readingLanguage': null,
                    'readingLanguageCode': null,
                    'specialAssistance': null,
                    'eSignature': null,
                    'guardianFullName': {
                        'firstName': null,
                        'middleName': null,
                        'lastName': null
                    },
                    "pcp": {
                        "name": "",
                        "number": "",
                        "pcpInd": null
                    }
                },

                Application = function () {
                    this.personData = angular.copy(personDataTemplate);
                };

            applicationFactory.createBlankApplication = function () {
                return new Application();
            };

            applicationFactory.getDependentsData = function () {
                //TODO retrieve persons who are NOT subscriber
                var dpList = [];
                var personData1 = angular.copy(personDataTemplate);
                personData1.fullName.firstName = "dependentfirstname";
                personData1.relationship = "SPOUSE";
                personData1.sameMailingAddress = "true";
                personData1.relationshipCode = "02";
                dpList.push(personData1);
                var personData2 = angular.copy(personDataTemplate);
                dpList.push(personData2);
                var personData3 = angular.copy(personDataTemplate);
                dpList.push(personData3);

                return dpList;
            };

            applicationFactory.save = function (application) {
                angular.extend(this, application);
            };
            return applicationFactory;

        }
    ]);
};