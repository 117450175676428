module.exports = function(app) {
    return app.service("teamsiteManager", ["$q", "$http", function($q, $http){

        this.getContent = function(fileName, brand){

            // Brands get defined to collect the correct json from teamsite
            var brandFolders = {
               'Highmark': ['hmbcbs', 'hbcbs', 'highmarkbcbs', 'hbs', 'pbs', 'hmbs', "hmbcbswv", "ms", "highmarkbcbswv", "mbh:wv", "hmbcbsde", "highmarkbcbsde", "de", "unbrandedhm"],
               'Minnesota': ['mincr'],
               'Wyoming': ['6012', 'wybcbs'],
               'Northdakota': ['6011']
            };
            
            var teamsiteBrandedRegion = "Highmark";

            var dynamicTeamsiteBrandedRegion = (function(){
                if (brand) {
                    for (var region in brandFolders) {
                        if (brand.override) {
                            if (brandFolders[region].indexOf(brand.override.toLowerCase()) !== -1) {
                                return region;
                            }
                        } if (brand.current && brand.current.sharedBrandCode) {
                            if (brandFolders[region].indexOf(brand.current.sharedBrandCode.toLowerCase()) !== -1) {
                                return region;
                            }
                        }
                    }
                }
            })();

            if (dynamicTeamsiteBrandedRegion) {
                teamsiteBrandedRegion = dynamicTeamsiteBrandedRegion;
            }
            
            // Setup the path to teamsite
            var path = "../content/HCR/" + teamsiteBrandedRegion + "/jsonContent/";
            var fileName = fileName + ".json";
            var teamsiteTarget = path + fileName;
            
            //console.log("Attempting to retrieve the following file:", teamsiteTarget);
            
            return $http.get(teamsiteTarget).then(
               function(response){
                   if (brand) {
                       if (brand && brand.current && brand.current.sharedBrandCode && (response.data[brand.current.sharedBrandCode.toLowerCase()] || response.data[brand.current.sharedBrandCode.toUpperCase()])) {
                            if (response.data[brand.current.sharedBrandCode.toUpperCase()]) {
                                return response.data[brand.current.sharedBrandCode.toUpperCase()];
                            } else {
                                return response.data[brand.current.sharedBrandCode.toLowerCase()];
                            }
                       }
                       //console.log("Successfully retrieved -" + teamsiteTarget + "- with content:", response.data["DEFAULT"]);
                       return response.data["DEFAULT"];
                   }
               }
            )
        };

        this.getMultiContent = function(fileNames, brand) {
        	var toReturn = {};
        	var promises = [];
        	var self = this;

        	angular.forEach(fileNames, function(value, key) {
        		promises[key] = self.getContent(value, brand);
        	});

        	return $q.all(promises).then(function(results) {
        		angular.forEach(results, function(value, key) {
        			var fileKey = fileNames[key];
        			toReturn[fileKey] = value;
        		});
        		return toReturn;
        	});
        };

        this.getASPConfig = function() {
            var asp = null;
            if (window.location.hostname.indexOf('bluecrossmnonline.com') > 0) {
                asp = "Minnesota";
            } else if (window.location.hostname.indexOf('highmark.com') > 0) {
                asp = "Highmark";
            } else if (window.location.hostname.indexOf('yourwyoblue.com') > 0) {
                asp = "Wyoming";
            } else if (window.location.hostname.indexOf('bcbsndportals.com') > 0) {
                asp = "NorthDakota";
            }

            return $http.get('../content/HCR/' + asp + '/config/aspConfig.json');
        }

        // TODO: Need this for dynamically setting up URLs per region
        //TeamsiteFetcher.prototype.injectDynamicContent = function (jsonObj) {
        //    var jsonStr = JSON.stringify(jsonObj);
        //
        //    // try catch bc it can return undefined????
        //
        //    if (jsonStr.indexOf('{{') === -1 && jsonStr.indexOf('}}') === -1) {
        //        // dynamic content does not exist in this json
        //        return jsonObj;
        //    }
        //
        //    var productData = stateService.getData('productSelected') || {};
        //    var planData = stateService.getData('planSelected') || {};
        //    var dynamicData = {
        //        // add any new dynamic keys to this object
        //        COUNTY: (stateService.getData('homeCounty') || {}).county,
        //        DOB: stateService.getData('subscriberDob'),
        //        ENROLLMENT_YEAR: (stateService.getData('selectedYear') || new Date().getFullYear()).toString(),
        //        PLAN: planData.name || productData.planDescription,
        //        PLAN_PRICE: planData.pricing && planData.pricing.current && ('$' + planData.pricing.current),
        //        PRODUCT: productData.planDescription,
        //        ZIPCODE: stateService.getData('homeZipCode')
        //    };
        //
        //    // make sure this key exists before performing extra logic
        //    if (jsonStr.indexOf('{{TENV}}') !== -1) {
        //        var tenvEnv = '';
        //        var hostname = document.location.hostname;
        //        tenvEnv = hostname.indexOf('denv2') !== -1 ? 'denv2' : tenvEnv;
        //        tenvEnv = hostname.indexOf('denv3') !== -1 ? 'denv3' : tenvEnv;
        //        tenvEnv = hostname.indexOf('denv6') !== -1 ? 'denv6' : tenvEnv;
        //        tenvEnv = hostname.indexOf('denv7') !== -1 ? 'denv7' : tenvEnv;
        //        tenvEnv = hostname.indexOf('tenv2') !== -1 ? 'tenv2' : tenvEnv;
        //        tenvEnv = hostname.indexOf('tenv3') !== -1 ? 'tenv3' : tenvEnv;
        //        tenvEnv = hostname.indexOf('tenv6') !== -1 ? 'tenv6' : tenvEnv;
        //        tenvEnv = hostname.indexOf('tenv7') !== -1 ? 'tenv7' : tenvEnv;
        //        dynamicData.TENV = tenvEnv;
        //    }
        //
        //    // inject dynamicData into jsonStr, then parse/return as a json object
        //    var regEx = new RegExp('{{(' + Object.keys(dynamicData).join('|') + ')}}', 'g');
        //    jsonStr = jsonStr.replace(regEx, function(fullMatch, groupMatch) {
        //        var newVal = (dynamicData[groupMatch] || '');
        //        if (newVal.indexOf('"') > -1) {
        //            // escape double quotes, if they exist
        //            newVal = newVal.replace(/[\\"]/g, '\\$&');
        //        }
        //        return newVal;
        //    });
        //    return JSON.parse(jsonStr);
        //};
        //return new TeamsiteFetcher();

    }]);
};