/**
 * Summary: this directive creates a plan drawer for the view-plans page
 *  Params:
 *      plan: plan to be displayed in the drawer
 *
 */

module.exports = function(app){
    'use strict';
    app.controller('Controller',['$scope', function($scope) {

    }]);
    app.directive('narrowBy',['$document','$state', function($document, $state) {
        return {
            template: require('./narrowBy.html'),
            scope: {
                narrowBy: '=',
                myCheckboxModel: '=',
                myRangeModel: '='
            },
            link: function ($scope, elem, attrs, parentController) {
                $scope.isDrawerOpen = false;
                $scope.toggleDrawer = function() {
                    $scope.isDrawerOpen = !$scope.isDrawerOpen;
                };
            }
        };
    }]);
};