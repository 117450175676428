module.exports = function(app){

    app.service('SEPDocument', ['$http',
        function($http){
            var url = "/cmcrst/x-services/application/upload"; //files are saved to NAS: V:\CORPDATA05\CSS and EMARKETING\NasTempDrive\SEP_UPLOAD

            this.upload = function(fileList){
                var
                    formData = new FormData(),
                    i = fileList.length;

                //add all of the assigned files
                while(i--){
                    formData.append('uploadedFile' + i, fileList[i]);
                }

                return $http.post(url, formData, {
                    headers:{
                        'Content-Type': undefined
                    },
                    transformRequest: angular.identity
                }).error(
                    function(error){
                        console.error('An error occurred while uploading files: ', error);
                    }
                );
            };
        }
    ]);
};