module.exports = function(app) {
    app.controller('pages.findInsurance.find-a-provider-or-drug', ["$scope", "$state", "pageContent", "storage",
    	function($scope, $state, pageContent, storage) {

			$scope.gotoProviderSearch = function(){
				$scope.activeView = "provider";
				$state.go("findInsurance.find-a-provider-or-drug.provider");
			};

			$scope.gotoDrugSearch = function(){
				$scope.activeView = "drug";
				$state.go("findInsurance.find-a-provider-or-drug.drug");
			};

			$scope.gotoLanding = function(){
				$scope.activeView = "landing";
				$state.go("findInsurance.find-a-provider-or-drug.landing");
			};

			(function onInit(){
				$scope.showFindADrugFromCatalog = storage.get('catalogueConfiguredShowDrugTab');
				$scope.pageContent = pageContent;
			})();
    	}
    ]);
};