module.exports = function (app) {

    return app.service('preserveUrl', [function () {
        this.url = "/home";

        this.getUrl = function () {
            return this.url;
        };

        this.setUrl = function (urlInput) {
            if(urlInput && (urlInput.indexOf("/register") < 0)){
                this.url = urlInput;
            }
        };
    }]);
};
