module.exports = function (app) {
    'use strict';
    app.directive('maskSsn', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, elem, $attrs, ctrl) {
                var formatSsn = function () {
                    var displayValue,
                        valueEntered = ctrl.$viewValue;
                    if (/^(\d|\*){3}-*(\d|\*){2}-*\d{4}$/.test(valueEntered)) {
                        displayValue = valueEntered.replace(/\d(?=\d{4})/g, "*");
                        elem.val(displayValue);
                    }
                };

                $scope.$watch("ssn", function (ssn) {
                    if (ssn && ssn.value) {
                        formatSsn();
                    }
                });

                elem.bind('blur', formatSsn);
                elem.bind('click', function () {
                    elem.val(ctrl.$viewValue);
                });
            }
        };
    });
};