module.exports = function(app) {
    'use strict';
    app.directive('suffix', function() {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                suffix: "="
            },
            template: require('directives/enrollment/suffix.html'),

            link: function($scope, elem) {
                $scope.suffixes = [{
                    label: 'Suffix',
                    value: ''
                }, {
                    label: 'ATTY',
                    value: '9'
                }, {
                    label: 'DDS',
                    value: '16'
                }, {
                    label: 'DMD',
                    value: '20'
                }, {
                    label: 'ESQ',
                    value: '23'
                }, {
                    label: 'I',
                    value: '27'
                }, {
                    label: 'II',
                    value: '28'
                }, {
                    label: 'III',
                    value: '29'
                }, {
                    label: 'IV',
                    value: '30'
                }, {
                    label: 'V',
                    value: '52'
                }, {
                    label: 'VI',
                    value: '31'
                }, {
                    label: 'VII',
                    value: '53'
                }, {
                    label: 'VIII',
                    value: '54'
                }, {
                    label: 'JR',
                    value: '33'
                }, {
                    label: 'ATTY',
                    value: '9'
                }, {
                    label: 'MD',
                    value: '39'
                }, {
                    label: 'PHD',
                    value: '44'
                }, {
                    label: 'RN',
                    value: '45'
                }, {
                    label: 'SR',
                    value: '48'
                }];
            }
        };
    });
};