module.exports = function (app) {
    'use strict';
    app.directive('applicationDrawer', ['prospectService', function (prospectService) {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                savedResults: '='
            },
            template: require('directives/consumer/applicationDrawer.html'),

            link: function (scope) {

                scope.toPlanDetails = function (result) {
                    prospectService.saveConsumerUserData(result).then(function () {
                        window.open('/#!/findInsurance/plan-details/:' + result.selectedPlan.planId + '?prospectId=' + result.prospectId, '_blank');
                    });
                };

                scope.toViewPlans = function (result) {
                    prospectService.saveConsumerUserData(result).then(function () {
                        window.open('/#!/findInsurance/view-plans?prospectId=' + result.prospectId, '_blank');
                    });
                };

                scope.toComparePlans = function (result) {
                    prospectService.saveConsumerUserData(result).then(function () {
                        var planIds = [];
                        angular.forEach(result.comparePlans, function (plan) {
                            planIds.push(plan.planId);
                        });

                        window.open('/#!/findInsurance/compare-plans/productIds=' + planIds.join(',') + "&prospectId=" + result.prospectId, '_blank');
                    });
                };

                scope.toViewPlans = function (result) {
                    prospectService.saveConsumerUserData(result).then(function () {
                        window.open('/#!/findInsurance/view-plans-grid?prospectId=' + result.prospectId, '_blank');
                    });
                };
            }
        };
    }]);
};