module.exports = [
    "$scope",
    "storage",
    "ffmEligibilityUtility",
    "brand",
    "teamsiteManager",
    function (
        $scope,
        storage,
        ffmEligibilityUtility,
        brand,
        teamsiteManager) {

        $scope.backToViewPlans = function() {
            $scope.closeModal();
        };

        $scope.continueToMarketplace = function() {
            console.log("$scope.ffmRedirect", $scope.ffmRedirect);
            var saml = storage.get("saml");
            storage.del("saml");
            $scope.$broadcast("FFMSAMLUpdated", saml);
        };

        (function onInit(){
            teamsiteManager.getMultiContent(
                [
                    "ffmRedirect"
                ], brand).then(
                function(teamsiteContent){
                    $scope.ffmRedirect = teamsiteContent["ffmRedirect"];
                });
	    })();
}];