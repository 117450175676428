/***************************************
 Module to set and get DD token
 ***************************************/
module.exports = function (app) {

    return app.service('ddSetup', ["storage", "$cookies", function (storage, $cookies) {
        this.set = function (forcedDDValue) {
            var hostValue = window.location.hostname.split(".");
            
            var ddDataObject = [
                {
                    url: "salesplatform",
                    number: "14",
                    name: "Minnesota"
                },
                {
                    url: "highmark",
                    number: "1,3,7",
                    name: "Highmark"
                },
                {
                    url: "bluecrossmnonline",
                    number: "14",
                    name: "Minnesota"
                },
                {
                    url: "yourwyoblue",
                    number: "17",
                    name: "Wyoming"
                },
                {
                    url: "bcbsndportals",
                    number: "16",
                    name: "North Dakota"
                }
            ];

            angular.forEach(ddDataObject, function (data) {
                if (data.url === hostValue[1]) {
                    storage.set("ddStorage-" + hostValue[0], data);

                    var ddValue = forcedDDValue || data.number;

                    $cookies.put(
                        'ddToken',
                        btoa(ddValue),
                        {
                            'domain': "." + data.url + ".com",
                            'path': "/"
                        }
                    );

                }
            });

            return storage.get("ddStorage-" + hostValue[0]);
        };

        this.get = function () {
            var hostValue = window.location.hostname.split(".");
            return storage.get("ddStorage-" + hostValue[0]);
        };

        /** In case we need to get the ddToken to be more specific than ASP */
        this.setDDTokenValueByRegion = function(region) {
            var mappings = {
                WPA: 1,
                HMBCBS: 1,
                CPA: 1,
                PBS: 1,
                NEPA: 1,
                WV: 3,
                HMBCBSWV: 3,
                DE: 7,
                HMBCBSDE: 7
            }

            this.set(mappings[region]);
        }
    }]);
};