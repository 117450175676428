/**
 * Created by lida1fh on 02/12/2016.
 */
module.exports = function(app){


    app.directive('paymentPreference', function () {
        return {
            replace: true,
            template: require('./paymentPreference.html'),
            scope: {
                displayModule: '=',
                contentModel: '='
            },
            link: function ($scope, elem) {

            }
        }
    });
};