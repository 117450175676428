module.exports = function (app) {
    'use strict';
    return app.factory("plansAcceptedService", ["$q", "$http",
        function ($q,  $http) {
            var urlPattern = '/dpsext/x-services/SapphireCareSelectAPIs/api/providers/{providerId}/locations/{locationId}/networks_accepted.json';

            // networks_accepted.json - required params are the providerId and locationId returned from
            // the Sapphire Digital search Summary call.
            // https://vitals.atlassian.net/wiki/spaces/VTPRG/pages/132127997/Provider+Networks+Accepted+-+API

            var plansAcceptedService = {
                stub : "stubs/plansAcceptedService.json",
                getPlansForProvider: function(providerId,locationId){
                    var svcUrl = urlPattern.split("{env}").join(env)
                        .split("{providerId}").join(providerId)
                        .split("{locationId}").join(locationId);

                    var deferred = $q.defer();

                    $http.get(svcUrl).then(function(response){
                        var plans = [];
                        for(var i=0; i < response.data.networks_accepted.length; i++){
                            var thisNw = response.data.networks_accepted[i];
                            if(parseInt(thisNw.id, 10)){
                                var network = { "name": thisNw.name, "networkId" : thisNw.id};
                                plans.push(network);
                            }
                        }
                        deferred.resolve(plans);
                    }, function(error){
                        deferred.resolve(error);
                    });
                    return deferred.promise;
                }
            };
            return plansAcceptedService;
        }
    ]);
};