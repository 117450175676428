module.exports = function(app){
    'use strict';
    app.directive('appCompleteReview', function () {
        return {
            restrict: 'A',
            template: require('directives/enrollment/appCompleteReview.html'),
            replace: true,
            scope: {
                appData: "=",
                entity: "="
            },
            link: function ($scope, elem, $attrs) {
              
            }
        };
    });
};