module.exports = function (app) {

    app.controller("pages.findInsurance.plan-details", ["$scope", "$http", "teamsiteService", "$state", "pageContent", "$timeout", "storage","brand", "hmShopDateAndTimeUtility", "sessionData",
        function ($scope, $http, teamsiteService, $state, pageContent, $timeout, storage, brand, hmShopDateAndTimeUtility, sessionData) {


            /****
             * VARIABLES
             ****/
            $scope.$parent.sidebar.links = [{
                "heading": "Plan Details",
                "label": "Plan Details",
                "state": "findInsurance.plan-details",
                "description": "Find out your plan details and benefits coverage."
            }];

            $scope.$parent.backText = "Back to Plan List";

            $scope.showFooter = true; // determines whether HEALTH_SHERPA changes are needed
            
            $scope.planDisplaySettings = {};
            $scope.shouldEnableEmulation = false;
            $scope.maxWidthPerTable = 0;
            /****
             * FUNCTION DECLARATIONS
             ****/
            $scope.printWindow = printWindow;


            /****
             * FUNCTION DEFINITIONS
             ****/
            function printWindow(){
                $timeout(function(){
                    window.print();
                }, 0);
            }


            $scope.emulateRegular_yesOON = function(howManyPlansToDisplay){
                _emulatePlanDetails("stubs/planDetails_REGULAR_updatedNewAttrs.json", "PPO", howManyPlansToDisplay);
            };

            $scope.emulateEnhanced_yesOON = function(howManyPlansToDisplay){
                _emulatePlanDetails("stubs/planDetails_ENHANCED(2 columns)_updatedNewAttrs.json", "PPO", howManyPlansToDisplay);
            };

            $scope.emulatePreferred_yesOON = function(howManyPlansToDisplay){
                _emulatePlanDetails("stubs/planDetails_PREFERRED(3 columns)_updatedNewAttrs.json", "PPO", howManyPlansToDisplay);
            };

            $scope.emulateRegular_noOON = function(howManyPlansToDisplay){
                _emulatePlanDetails("stubs/planDetails_REGULAR_updatedNewAttrs.json", "HMO", howManyPlansToDisplay);
            };

            $scope.emulateEnhanced_noOON = function(howManyPlansToDisplay){
                _emulatePlanDetails("stubs/planDetails_ENHANCED(2 columns)_updatedNewAttrs.json", "HMO", howManyPlansToDisplay);
            };

            $scope.emulatePreferred_noOON = function(howManyPlansToDisplay){
                _emulatePlanDetails("stubs/planDetails_PREFERRED(3 columns)_updatedNewAttrs.json", "HMO", howManyPlansToDisplay);
            };

            var _emulatePlanDetails = function(planPath, productType, howManyPlansToDisplay){

                $scope.planData = null;
                $scope.applicant = {
                    "adultChildComma"     : "",
                    "adultLabel"          : "Adult",
                    "childLabel"          : "",
                    "county"              : "WASHINGTON",
                    "effectiveDate"       : "04/01/2016",
                    "name"                : "John",
                    "numberOfAdults"      : 1,

                    "numberOfChildren"    : "",
                    "zipCode"             : "55001"
                };

                if ($scope.isComparePlans) {
                    
                    if (!$scope.comparablePlans) {
                        $scope.comparablePlans = [];
                    }

                    $http.get(planPath).then( function (response) {
                        var i = 0;
                        while (i < howManyPlansToDisplay) {
                            $scope.comparablePlans.push(response.data);
                            i++;
                        }

                        if (productType === "HMO") {
                            angular.forEach($scope.comparablePlans, function(planData) {
                                planData.lineOfBusiness = "HMO";
                            });
                        } else if (productType === "PPO") {
                            angular.forEach($scope.comparablePlans, function(planData) {
                                planData.lineOfBusiness = "PPO";
                            });
                        }
                        $timeout(function(){_normalizeComparableTables()},0);
                    })
                } else {
                    $http.get(planPath).then( function (response) {
                        $scope.planData = response.data;

                        if (productType === "HMO") {
                            $scope.planData.lineOfBusiness = "HMO";
                        } else if (productType === "PPO") {
                            $scope.planData.lineOfBusiness = "PPO";
                        }
                    });
                }
            };

            /****
             * In order to have the compare plans tables be the same size, we need to double back and adjust the size of each row
             ****/
            var _normalizeComparableTables = function(){
                var planTables              = document.querySelectorAll(".plan-details-grid");
                var maxHeightForRows        = [];
                var tableWithoutLabelWidth  = 0;
                var numberOfTables          = 0;
                var benefitLabelSize        = 0;
                var nonBenefitLabelSize     = 0;


                /****
                 * Adjust width so tables appear side-by-side
                 ****/
                $scope.maxWidthPerTable = (function(){
                    var returnable = Math.floor(100 / planTables.length);
                    var marginingModifier = 1;
                    return (returnable - marginingModifier);
                })();

                $timeout(function() {
                    /****
                     * Find the tallest of each row in each table...
                     ****/
                    angular.forEach(planTables, function(table, index) {
                        angular.forEach(table.children, function(child, index2) {
                            angular.forEach(child.children, function(row, index3) {

                                /****
                                 * Set row height equal to the greatest variant of height
                                 ****/
                                if (!maxHeightForRows[index3]) {
                                    maxHeightForRows[index3] = row.clientHeight;
                                } else if (maxHeightForRows[index3] < row.clientHeight) {
                                    maxHeightForRows[index3] = row.clientHeight;
                                }
                            })
                        })
                    })

                    /****
                     * ...assign the tallest row's height to each row
                     ****/
                    angular.forEach(planTables, function(table, index) {

                        console.log("table", table);
                        // angular.element(table)[0].style.width = table.offsetWidth + "px";

                        angular.forEach(table.children, function(child, index2) {
                            angular.forEach(child.children, function(row, index3) {
                                var ele = angular.element(row)[0];
                                if (angular.element(row)[0].id.indexOf("nonPrescriptionDrugBenefit_") !== -1 || 
                                    angular.element(row)[0].id.indexOf("prescriptionDrugBenefit_") !== -1) {
                                    angular.element(row)[0].style.height = (maxHeightForRows[index3])*1.75 + "px";
                                } else {
                                    angular.element(row)[0].style.height = (maxHeightForRows[index3]) + "px";
                                }
                            })
                        })
                    })
                }, 10);
            };


            /****
             * INITIALIZATION
             ****/
            (function onInit() {

                $scope.isComparePlans   = $state.params.isComparePlans;
                /****
                 * You can test compare plans by doing the following
                 ****/
                /**/
                // $scope.emulateRegular_yesOON(1);
                // $scope.emulateRegular_yesOON(1);
                // $scope.emulateEnhanced_noOON(1);
                // $scope.emulatePreferred_yesOON(1);
                //  $scope.fontStyling = { "font-size": "85%" };
                /**/
                $scope.pageContent      = pageContent;
                $scope.showFooter = (function() {
                    /* Don't show if the value is true */
                    if (
                        sessionData &&
                        sessionData.appConfig &&
                        sessionData.appConfig.SALES &&
                        sessionData.appConfig.SALES.HEALTH_SHERPA_ACTIVE &&
                        sessionData.appConfig.SALES.HEALTH_SHERPA_ACTIVE.booleanValue
                    ) {
                        return false;
                    }
                    return true;
                })();

                switch (brand.current.sharedBrandCode.toUpperCase()) {
                    //case 'hmbcbs':
                    case "NEPA":
                    case "HMBCBS":
                        pageContent.viewPlansFindInsurance.companyName = 'Highmark Blue Cross Blue Shield';
                        break;
                    //case 'pbs':
                    //case 'hmbs':
                    case "PBS":
                    case "HMBS":

                        pageContent.viewPlansFindInsurance.companyName = 'Highmark Select Resources';
                        break;
                    //case 'hmbcbswv':
                    case "HMBCBSWV":
                        pageContent.viewPlansFindInsurance.companyName = 'Highmark Blue Cross Blue Shield West Virginia';
                        break;
                    //case 'hmbcbsde':
                    case "HMBCBSDE":
                        pageContent.viewPlansFindInsurance.companyName = 'Highmark Blue Cross Blue Shield Delaware';
                        break;
                    case "WYBCBS":
                        pageContent.viewPlansFindInsurance.companyName = 'Wyoming Blue Cross Blue Shield';
                        break;
                    case "NDBCBS":
                        pageContent.viewPlansFindInsurance.companyName = 'North Dakota Blue Cross Blue Shield';
                        break;
                    default:
                        pageContent.viewPlansFindInsurance.companyName = 'Highmark Blue Cross Blue Shield';
                        break;
                }
                var companyName = pageContent.viewPlansFindInsurance.companyName;
                $scope.pageContent.compareGridBase.footerAttention = pageContent.compareGridBase.footerAttention.replace("storeName", companyName);

                // Get plan
                if (!$scope.isComparePlans && $state.params.productObjects) {
                    $scope.planData = $state.params.productObjects[0];
                    $scope.featuresAdjustedPremium = $scope.planData.featuresAdjustedPremium;
                    $scope.adjustedPremiumDisclaimer = $scope.planData.adjustedPremiumDisclaimer;
                } else if ($scope.isComparePlans && $state.params.productObjects) {
                    $scope.featuresAdjustedPremium = $state.params.productObjects[0].featuresAdjustedPremium;
                    $scope.adjustedPremiumDisclaimer = $state.params.productObjects[0].adjustedPremiumDisclaimer;
                    angular.forEach($state.params.productObjects, function(plan, index) {

                        
                        if (plan.formatted.enhanced) {
                            $scope.fontStyling = { "font-size": "85%" };
                        }

                        if (!$scope.comparablePlans) {
                            $scope.comparablePlans = [];
                        }

                        /****
                         * Push a duplicate first plan so that we can use it for benefit only table
                         ****/
                        if (index === 0) {
                            $scope.comparablePlans.push(plan);
                        }

                        $scope.comparablePlans.push(plan);
                    })
                }

                var currentFamilyInfo = storage.get("familyRatingInfo");

                if (currentFamilyInfo && currentFamilyInfo.family && currentFamilyInfo.family.members && currentFamilyInfo.family.members.length > 0) {
                    $scope.applicant = {
                        "adultLabel"          : "Adult",
                        "childLabel"          : "Child",
                        "county"              : currentFamilyInfo.countyInfo.county,
                        "effectiveDate"       : currentFamilyInfo.family.consumerEffectiveDate,
                        "name"                : currentFamilyInfo.family.members[0].fullName.first,
                        "numberOfAdults"      : 0,
                        "numberOfChildren"    : 0,
                        "zipCode"             : currentFamilyInfo.zipCode
                    };

                    // Determine number of adults vs children
                    angular.forEach(currentFamilyInfo.family.members, function(person) {
                        var personAge = new Date(person.dob);

                        if (hmShopDateAndTimeUtility.isDateFromTodayYoungerOrOlder(personAge, 18, "older")) {
                            $scope.applicant.numberOfAdults++;
                            if ($scope.applicant.numberOfAdults > 1) {
                                $scope.applicant.adultLabel = "Adults"
                            }
                        } else {
                            $scope.applicant.numberOfChildren++;
                            if ($scope.applicant.numberOfChildren > 1) {
                                $scope.applicant.childLabel = "Children"
                            }
                        }
                    });
                }

                $timeout(function(){_normalizeComparableTables()},0);

            })();
        }]);
};