module.exports = function(app) {
    app.controller('medicareOrderForms', ["$state", "$scope", "services", "modal", "content", "brand",
        function($state, $scope, services, modal, content, brand) {

            /**
             * This app used ... /sales/#!/order-forms
             * Shopx uses ...... /order-forms
             */
            window.location.href = window.location.origin + '/order-forms'
            
            $scope.yearSelected = "";
            $scope.headings = "SHOP";
            $scope.sidebar.links = [];
            $scope.formData = {};
            $scope.stateOptions = ["AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WI", "WV", "WY"];
            $scope.content = content['request-enrollment-kit'];
            $scope.years = [];
            $scope.requestBooks = [
                $scope.content.kitDirectory,
                $scope.content.kitEOC
            ];
            $scope.eocBucket = 0;
            $scope.anocBucket = 0;

            var today = new Date();
            var startOfAEP = new Date(today.getFullYear(), 9, 1);
            var nextYear = today.getFullYear() + 1;
            var endOfAEP = new Date(nextYear, 0, 1);

            if (startOfAEP <= today && today <= endOfAEP) {
                $scope.years.push(today.getFullYear());
                $scope.years.push(nextYear);
            } else if (endOfAEP <= today) {
                $scope.years.push(nextYear);
            } else {
                $scope.years.push(today.getFullYear());
            }
            if (today.getFullYear() > 2020 && brand && brand.current && brand.current.sharedBrandCode && (brand.current.sharedBrandCode.toLowerCase() === 'mincr' || brand.current.sharedBrandCode.toLowerCase() === 'bcbsmn')) {
                $scope.requestBooks.push($scope.content.kitDentalDirectory);
            }
            angular.forEach($scope.content.eoc, function(yearObj, index) {
                angular.forEach(yearObj, function(plan) {
                    plan.isSelected = false;
                })
            })

            // Adding after dental to keep in 4th position on UI
            if ((today.getFullYear() >= 2022) || (today >= startOfAEP)) {
                $scope.requestBooks.push($scope.content.kitANOC);
            }            

            $scope.cleanUpInventory = function(year) {
                if (year){
                    $scope.yearSelected = year.toString();
                    var indexOfKitDentalDirectory = $scope.requestBooks.indexOf($scope.content.kitDentalDirectory);
                    if($scope.yearSelected > 2020) {
                        if(indexOfKitDentalDirectory < 0) {
                            $scope.requestBooks.push($scope.content.kitDentalDirectory);
                        }
                    } else {
                        if(indexOfKitDentalDirectory >= 0) {
                            $scope.requestBooks.splice(indexOfKitDentalDirectory, 1);
                        }
                    }

                    
                    var indexOfKitANOC = $scope.requestBooks.indexOf($scope.content.kitANOC);

                    // Adding after dental to keep in 4th position on UI
                    if ( (today.getFullYear() >= 2022) || (today >= startOfAEP) ) {
                        if ($scope.yearSelected > 2021) {
                            if (indexOfKitANOC < 0) {
                                $scope.requestBooks.push($scope.content.kitANOC);
                            }
                        } else {
                            if (indexOfKitANOC >= 0) {
                                $scope.requestBooks.splice(indexOfKitANOC, 1);
                            }
                        }
                    }
                }

                angular.forEach($scope.content.eoc[$scope.yearSelected], function(plan){
                    plan.isSelected = false;
                });
                $scope.eocBucket = 0;
                $scope.formData.directory = '';
                if(!!year){
                    $scope.formData.bookRequested = '';
                }
                
            }

            $scope.checkMaximumSelectionEOC = function(plan){
                if(plan.isSelected) {
                    ++$scope.eocBucket;
                }else{
                    --$scope.eocBucket;
                }
                if($scope.eocBucket > 3){
                    plan.isSelected = false;
                    --$scope.eocBucket;
                    modalScope = $scope.$new();
                    modalScope.title = 'Sorry';
                    modalScope.text = 'You can only select up to 3 plans';
                    modal.open({
                        scope: modalScope,
                        template: require('pages/medicare/orderFormsModal.html')
                    });
                }
            }

            $scope.checkMaximumSelectionANOC = function(anoc){
                if(anoc.isSelected) {
                    ++$scope.anocBucket;
                }else{
                    --$scope.anocBucket;
                }
                if($scope.anocBucket > 3){
                    anoc.isSelected = false;
                    --$scope.anocBucket;
                    modalScope = $scope.$new();
                    modalScope.title = 'Sorry';
                    modalScope.text = 'You can only select up to 3 Annual Notice of Change documents';
                    modal.open({
                        scope: modalScope,
                        template: require('pages/medicare/orderFormsModal.html')
                    });
                }
            }

            $scope.validateZip = function () {
                services.cmcrst.countyList().postData({"zipCode": $scope.formData.zipCode}).then(function (response) {
                    if(response.data.payload.countyList && response.data.payload.countyList.length) {
                        $scope.formData.state = response.data.payload.countyList[0].state;
                    }
                }, function () {
                    console.log(error);
                });
            };

            $scope.submit = function () {
                if($scope.formData.bookRequested == 'Evidence of Coverage' && $scope.eocBucket<1){
                    modalScope = $scope.$new();
                    modalScope.title = 'Sorry';
                    modalScope.text = 'You need to select at least 1 plan for Evidence of Coverage';
                    modal.open({
                        scope: modalScope,
                        template: require('pages/medicare/orderFormsModal.html')
                    });
                }else if ($scope.orderDirectory.$valid) {
                    var payload = {
                        baseMessage: {},
                        payload: {
                            firstName: $scope.formData.firstName,
                            lastName: $scope.formData.lastName,
                            address1: $scope.formData.address1,
                            address2: $scope.formData.address2 || "",
                            city: $scope.formData.city,
                            state: $scope.formData.state,
                            zip: $scope.formData.zipCode,
                            umi: null,
                            group: null,
                            bookRequested: $scope.formData.bookRequested,
                            requestType: null,
                            year: $scope.formData.year,
                            directory: '',
                            evidenceOfCoverage: [],
                            annualNoticeOfChange: null
                        }
                    };

                    if(!!$scope.formData.bookRequested){
                        if($scope.formData.bookRequested == 'Directory'){
                            payload.payload.directory = '';
                            payload.payload.directoryLabel = '';
                            payload.payload.evidenceOfCoverage = [];
                            payload.payload.directory = $scope.formData.directory.value;
                            payload.payload.directoryLabel = $scope.formData.directory.label;
                        } else if($scope.formData.bookRequested == 'Dental Directory') {
                            payload.payload.directory = '';
                            payload.payload.directoryLabel = '';
                            payload.payload.directory = $scope.formData.directory.value;
                            payload.payload.directoryLabel = $scope.formData.directory.label;
                        } else if ($scope.formData.bookRequested == 'Annual Notice of Changes') {
                            payload.payload.directory = '';
                            annualNoticeOfChange = [];
                            
                            angular.forEach($scope.content.anoc[$scope.yearSelected], function(anoc){
                                if (anoc.isSelected){
                                    annualNoticeOfChange.push({label: anoc.label, value: anoc.value});
                                }
                            });
                            payload.payload.annualNoticeOfChange = annualNoticeOfChange;
                            payload.payload.evidenceOfCoverage = [];
                        } else{
                            payload.payload.directory = '';

                            angular.forEach($scope.content.eoc[$scope.yearSelected], function(plan){
                                if(plan.isSelected){
                                    payload.payload.annualNoticeOfChange = [];
                                    payload.payload.evidenceOfCoverage.push({planName: plan.planName, planId: plan.planId});
                                }
                            });
                        }
                    }

                    var modalScope = $scope.$new();
                    var onClose = function () {
                        $scope.formData = {};
                        $scope.orderDirectory.$setPristine();
                        $scope.orderDirectory.$setUntouched()
                    };

                    services.cmcsnr.requestEnrollKit().post(payload).then(function () {
                        modalScope.title = 'THANK YOU';
                        modalScope.text = 'Your request has been submitted';
                        modal.open({
                            scope: modalScope,
                            template: require('pages/medicare/orderFormsModal.html'),
                            onClose: onClose
                        });
                    }, function () {
                        modalScope.title = 'We\'re sorry.';
                        modalScope.text = 'The site is currently unavailable. We know your time is valuable and we\'re sorry for your inconvenience. Please check back soon.';
                        modal.open({
                            scope: modalScope,
                            template: require('pages/medicare/orderFormsModal.html')
                        });
                    });
                }             
            }

            $scope.checkANOCLimit = function () {
                var x = $scope.content.anoc[$scope.yearSelected].filter(function (anocItem) {
                    return anocItem.isSelected === true;
                });
                $scope.anocCheckboxLimit = x.length === 3;
            }
        }
    ]);
    return [{
        state: "medicareOrderForms",
        url: "/medicare/order-forms",
        views: {
            "body@": {
                template: bootstrap.templates["page-templates/category.html"],
                controller: [function () {}]
            },
            "content@medicareOrderForms": {
                template: require("pages/medicare/orderForms.html"),
                controller: "medicareOrderForms"
            }
        },
        resolve: {
            content: function (teamsiteService) {
                return teamsiteService.loadFile("medicareProviderDirectory");
            }
        }
    }];
};