module.exports = function (app) {
    'use strict';

    app.directive('aptcSlider', ["$window", "$timeout", function($window, $timeout) {
        return {
            restrict: 'A',
            replace: true,
            template: require('./aptcSlider.html'),
            scope: {
                aptc: "="
            },
            link: function($scope){
                var updateAptcValues = function () {
                    //change the aptc slider values to reflect the input
                    var totalAptc = $scope.aptc.aptcSliderMax * 12;
                    var towardsMonthlyPremiums = $scope.aptc.aptcSlider * 12;
                    var federalTaxCreditReturn = totalAptc - towardsMonthlyPremiums;
                    $scope.aptc.towardsMonthlyPremiums = "$" + towardsMonthlyPremiums.toFixed(2);
                    $scope.aptc.federalTaxCreditReturn = "+ $" + federalTaxCreditReturn.toFixed(2);
                    //on update, get a list of all the beforePremiumAptcs and all of the afterPremiumAptcs
                };
                $scope.aptc.sliderUpdate = function () {
                    //maintain standard appearances, keep $ if already there, don't put it in if it's not
                    $scope.aptc.aptcInput = "$" + $scope.aptc.aptcSlider;
                    updateAptcValues();
                };
                var displayInputError = function (){
                    $window.alert("Invalid values are entered.\nOnly numeric characters and '$' allowed.\nNo Alpha characters permitted.");
                    $scope.aptc.aptcInput = "";
                };
                $scope.aptc.inputUpdate = function () {
                    //test the REGEX first
                    var regex = /^\$?(\d+(\.\d*)?)*$/;
                    //regex should allow empty string, no decimals included, just decimal point, and including up to two decimals, leading digit must be included for decimal acceptance
                    //this will get angry when: impoperly formatted information
                    if(regex.test($scope.aptc.aptcInput)) {
                        var strippedAptcInput = $scope.aptc.aptcInput.replace("$","");
                        //if it passes the first regex test, no need to alert user about bad input, just don't update
                        var aptcInputVal = Number(strippedAptcInput);

                        //test to see if the data can be transformed into a valid number
                        if(!isNaN(aptcInputVal)){
                            //check against min/max for aptc slider values
                            if(aptcInputVal >= 0 && aptcInputVal <= $scope.aptc.aptcSliderMax) {
                                //test for proper currency input
                                regex = /^(\d+(\.\d\d)?)$/;
                                if (regex.test(strippedAptcInput)) {
                                    //verified valid number and proper numerical format
                                    //so update relevant APTC scope values

                                    $scope.aptc.aptcSlider = aptcInputVal.toFixed(2);
                                    updateAptcValues();
                                } else {
                                    var decimalDigitArray = $scope.aptc.aptcInput.split(".");
                                    //if there is a second half of the decimal arrray
                                    //and the length is longer than 2 (meaning that we are no longer able to reference cents)
                                    if (decimalDigitArray[1] && decimalDigitArray[1].length > 2) {
                                        displayInputError();
                                    }
                                }
                            } else {
                                displayInputError();
                            }
                        }
                    } else {
                        displayInputError();
                    }
                    //if it doesn't, don't change listed values (and maybe display an error message?
                    //todo: display the Invalid data entered error message on regex test failure

                };
                (function onInit(){
                    $timeout(function(){
                        $scope.aptc.aptcInput = "$" + $scope.aptc.aptcSliderMax.toFixed(2);
                        $scope.aptc.inputUpdate();
                    });
                })();
            }
        }
    }]);
}