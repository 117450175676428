module.exports = [
  '$scope',
  'services',
  'modal',
  function($scope, services, modal) {

    $scope.options = null;
    $scope.model = { locationSelected: null };
    $scope.noWayToEmitButLocationIsGood = false;

    $scope.confirmLocationSelection = function(locationSelected) {
      $scope.noWayToEmitButLocationIsGood = true;
      modal.close(locationSelected);
    }

    /** Feed [addresses] in to populate radio-group in view */
    var _populateOptionsFromArray = function(addressArray) {
      
      /** Create options array */
      var options = [];

      /** Population options array */
      angular.forEach(addressArray, function(a) {
        options.push({
          name: a.name,
          address: a.addr_line1,
          address2: a.addr_line2,
          city: a.city,
          state: a.state || a.state_code, // /professionals response returns state_code
          zip: a.postal_code,
          locationId: a.location_id
        });
      });

      /** Set options to view */
      $scope.options = options;

    }
    
    /** Bundle of async requests */
    var _locationRequests = {
      singleLocation: function() {
        
        services.dpsext
        .sapphireDigitalProviderSearch()
        .professionalsDataRetrieval($scope.provider.id)
        .then(function(response) {
          
          var responsePayload = response.data;

          /** Access deep address */
          var professionals = responsePayload && responsePayload.professionals;
          var locations = professionals && professionals[0] && professionals[0].locations; // Hopefully no additional listings returned....
          var address = locations && locations[0] && locations[0].address; // Hopefully no additional locations.... ( as per 1 found)...
          
          _populateOptionsFromArray([address]);

        });
      },
      multipleLocations: function() {

        services.dpsext
        .sapphireDigitalProviderSearch()
        .otherLocationsRetrieval(
          $scope.provider.provider_id,
          $scope.provider.locations[0].id
        )
        .then(function(response) {
          _populateOptionsFromArray(response.data.other_provider_locations);
        });

      }
    };

    if ($scope.provider.locations_count === 1) {
      _locationRequests.singleLocation();
    } else {
      _locationRequests.multipleLocations();
    }

    
  }
];
