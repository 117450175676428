/**
 * Summary: this directive creates a button which triggers the file upload dialog of the browser
 *  Params:
 *      fileUpload: the 2-way bound container for the selected files
 *      multiple (boolean): if "true" multiple file selection is allowed
 *      accept (string): the list of file types accepted
 */

module.exports = function(app){
    'use strict';

    app.directive('fileUpload', function () {
        return {
            restrict: 'A',
            replace: true,
           template: require('./fileUploadDirective.html'),
            scope: {
                fileUpload: '=',
                multiFile: '@?',
                accept: '@?',
                title: '@?'
            },
            link: function (scope, elem) {
                var fileUploadElement = angular.element(elem.find('input[type="file"]')[0]);

                //set defaults
                scope.title = scope.title || 'Upload File';

                if(scope.multiFile){
                    fileUploadElement.prop('multiple', true);
                }
                if(scope.accept){
                    fileUploadElement.attr('accept', scope.accept);
                }

                //select files
                fileUploadElement.bind('change', function (event) {
                    scope.$apply(function(){
                        //scope.fileUpload = Array.prototype.slice.call(event.target.files);
                        scope.fileUpload = event.target.files;
                    });
                });
            }
        };
    });
};