module.exports = function(app) {
	app.controller('pages.marketplace.eligibility', ["$state", "$scope",
		function($state, $scope) {
			var tmpList = [];

			tmpList.push({
				"name":"Cari Cathleen Kinnett",
				"dob":"04/03/1988",
				"zip":"19963",
				"county":"Wood",
				"isChild":false});
			tmpList.push({
				"name":"Neil 'Little Toy' Kinnett",
				"dob":"04/03/1986",
				"zip":"19963",
				"county":"Wood",
				"isChild":true});

			$scope.inEligibleList = tmpList;
			$scope.familyList = tmpList;
			$scope.tooltip = "Have you smoked or used any form of tobacco regularly (4 or more times per week on average excluding religious or ceremonial use) within the last six months?";

			$scope.viewPlans = function() {
				$state.go("marketplace.view-plans");
			};

			$scope.shopWithHM = function() {
				$state.go("findInsurance.build-your-family");
			};
		}
	]);
};