module.exports = function(app){
    app.controller("enrollment.payment-information",["$scope","teamsiteService",
        function($scope,teamsiteService){
            $scope.data = {
                "paymentPreference" :{
                    "bank": {
                        "title": "PAY DIRECTLY FROM MY BANK ACCOUNT",
                        "subText1": "You will need your bank account information in front of you to complete this section. " ,
                        "subText2": "One month's premium will be charged to your bank account.",
                        "subText3": "Please note that coverage does not begin until your effective date.",
                        "subText4": "Note: Your financial information is collected only to process a one-time payment. Highmark will not store this information. All personal information will be protected in accordance with Highmark's",
                        "privacy":"Online Privacy Policy.",
                        "privacyLink":"Link",
                        "bankAccount": "BANK ACCOUNT INFORMATION",
                        "routingNumber": "Routing Number",
                        "accountNumber": "Bank Account Number",
                        "primaryName": "Primary Name on account",
                        "billingAddress": "Billing Address",
                        "payment": "PAYMENT",
                        "paymentAmount": "Payment Amount:",
                        "paymentDate": "Payment Date :"

                    },
                    "credit":{
                        "title": "MAKE A CREDIT CARD/DEBIT CARD PAYMENT",
                        "subText1": "You will need your credit or debit card in front of you to complete this section.",
                        "subText2":"One month's premium will be charged to your credit/debit card (or pre-paid debit card)",
                        "subText3":"Please note coverage does not begin until your effective date.",
                        "subText4":"Note: Your financial information is collected only to process a one-time payment. Highmark will not store this information. All personal information will be protected in accordance with Highmark's",
                        "privacy":"Online Privacy Policy.",
                        "privacyLink":"Link",
                        "account": "ACCOUNT INFORMATION",
                        "cardType": "Credit Card/Debit Card (Including Pre-Paid Debit Cards)",
                        "primaryName": "Full Name as it appears on card",
                        types:[
                            {label:"Visa",selected: true},
                            {label:"Master"},
                            {label:"American Express"},
                            {label:"Discover"}
                        ],
                        "months":[
                            {label:"Jan", value:"01-January"},
                            {label:"Feb", value:"02-February"},
                            {label:"Mar", value:"01-march"},
                            {label:"Apr", value:"01-April"},
                            {label:"may", value:"01-May"},
                            {label:"Jun", value:"01-June"},
                            {label:"Jul", value:"01-July"},
                            {label:"Aug", value:"01-August"},
                            {label:"Sep", value:"01-September"},
                            {label:"Oct", value:"01-October"},
                            {label:"Nov", value:"01-November"},
                            {label:"Dec", value:"01-December"}

                        ],
                        years:[
                            {label:"Year", value:"Year"},
                            {label:"Year16", value:"2016"},
                            {label:"Year17", value:"2017"},
                            {label:"Year18", value:"2018"},
                            {label:"Year19", value:"2019"},
                            {label:"Year20", value:"2020"},
                            {label:"Year21", value:"2021"},
                            {label:"Year22", value:"2022"},
                            {label:"Year23", value:"2023"},
                            {label:"Year24", value:"2024"},
                            {label:"Year25", value:"2025"},
                            {label:"Year26", value:"2026"},
                            {label:"Year27", value:"2027"},
                            {label:"Year28", value:"2028"},
                            {label:"Year29", value:"2029"},
                            {label:"Year30", value:"2030"},
                            {label:"Year31", value:"2031"},
                            {label:"Year32", value:"2032"},
                            {label:"Year33", value:"2033"},
                            {label:"Year34", value:"2034"},
                            {label:"Year35", value:"2035"},
                            {label:"Year36", value:"2036"},
                            {label:"Year37", value:"2037"},
                            {label:"Year38", value:"2038"},
                            {label:"Year39", value:"2039"},
                            {label:"Year40", value:"2040"},
                            {label:"Year41", value:"2041"}
                        ],
                        "cardNumber": "Card Number",
                        "expirationDate": "Expiration Date",
                        "billingAddress": "Billing Address",
                        "payment": "PAYMENT",
                        "paymentAmount": "Payment Amount:",
                        "paymentDate": "Payment Date :"
                    }
                }
            }
            var paymentModel ={
                "billingAddress": {
                    "address":{
                        "addressLine1": "",
                        "addressLine2": "",
                        "city": ""
                    },
                    zipCounty: {
                        "state": "",
                        "zip": "",
                        "county": ""
                    },
                    "homeAddressTooltip":"Home address"
                },
                "bankAccount":{
                    "routingNumber":"",
                    "accountNumber":"",
                    "nameOnAccount":"",
                    "bankDisplay":""
                },
                "creditCard":{
                    "cardType":"",
                    "cardNumber":"",
                    "expDate":"",
                    "expYear":"",
                    "creditCardDisplay":""
                }
            }
            $scope.paymentModel=paymentModel;
            $scope.paymentModel.creditCard.cardType=$scope.data.paymentPreference.credit.types[0];
            $scope.paymentModel.creditCard.expDate=$scope.data.paymentPreference.credit.months[0];
            $scope.paymentModel.creditCard.expYear=$scope.data.paymentPreference.credit.years[0];
            $scope.paymentModel.creditCard.creditCardDisplay=true;
            $scope.paymentModel.bankAccount.bankDisplay=false;
        }
    ]);
	function maskNum(num) {
        return '*'.repeat(num.length - 4)+num.substr(num.length - 4);
      }
}