module.exports = function(app){
    app.service('sbcsData', ["$http",
        function($http){
              this.get = function(year){
                return  deferred = $http.get('/content/sbcs/'+year+'/sbcData.json').then ( 
                    function(response){
                        return response.data;
                    },function(errorData)
                    {
                        return null;
                    }

                );
            };
        }
    ]);
};