module.exports = function(app) {

	return app.factory("coveredPrescriptionDrugsUtility", ["storage", "$q", "services", "sessionData", "ratingInfoModel", function(storage, $q, services, sessionData, ratingInfoModel) {

		var utilities = {
			coveredDrugsByPlanListAndSelectedDrugs: _coveredDrugsByPlanListAndSelectedDrugs,
		}

		function _coveredDrugsByPlanListAndSelectedDrugs(planList, selectedDrugs) {
			var deferred 		= $q.defer();
	        var promises 		= [];
	        var totalCoverage 	= {};

	        // Copy so we don't overwrite anything important
	        var copiedPlanList 	= angular.copy(planList)

	        var commaSeparatedHIOSIds = (function(){
	            var sizeTenContainer = [];
	            var bucketIndex = 0;

	            // get remove endings
	            angular.forEach(copiedPlanList, function(plan){
	                plan.hiosId = plan.hiosId.split("-")[0];
	            });

	            // get unique hios
	            var uniquePlanIds = [];
	            angular.forEach(copiedPlanList, function(plan) {
	                uniquePlanIds.push(plan.hiosId);
	            });
	            uniquePlanIds = uniquePlanIds.filter(function(value, index){ return uniquePlanIds.indexOf(value) == index });

	            // bucket hios
	            angular.forEach(uniquePlanIds, function(uniqHios, index) {

	                if (sizeTenContainer[bucketIndex] && sizeTenContainer[bucketIndex].length === 10) {
	                    bucketIndex++;
	                }
	                
	                if (!sizeTenContainer[bucketIndex]) {
	                    sizeTenContainer[bucketIndex] = [];
	                }

	                sizeTenContainer[bucketIndex].push(uniqHios);
	            });


	            var sizeTenStrings = [];
	            angular.forEach(sizeTenContainer, function(arrayOfHiosIds, index) {
	                sizeTenStrings[index] = arrayOfHiosIds.join(",");
	            });

	            return sizeTenStrings;
	        })();

	        var commaSeparatedDrugIds = (function(){

	            var sizeTenContainer = [];
	            var bucketIndex = 0;
	            angular.forEach(storage.get("selectedPrescriptionDrugs"), function(drug, index) {

	                if (sizeTenContainer[bucketIndex] && sizeTenContainer[bucketIndex].length === 10) {
	                    bucketIndex++;
	                }
	                
	                if (!sizeTenContainer[bucketIndex]) {
	                    sizeTenContainer[bucketIndex] = [];
	                }

	                sizeTenContainer[bucketIndex].push(drug.rxcui);
	            });


	            var sizeTenStrings = [];
	            angular.forEach(sizeTenContainer, function(arrayOfDrugIds, index) {
	                sizeTenStrings[index] = arrayOfDrugIds.join(",");
	            });

	            return sizeTenStrings;
	        })();

	        // var ratingInfoPayload = storage.get("ratingInfoPayload");
	        var ratingInfoPayload = ratingInfoModel.get();
	        angular.forEach(commaSeparatedHIOSIds, function(hiosString){
	            angular.forEach(commaSeparatedDrugIds, function(drugsString) {
	                var deferredArrayItem = $q.defer();

	                services
	                    .cmcrst
	                    .coveredDrugs()
	                    .postData(hiosString, drugsString, parseInt(ratingInfoPayload.effectiveDate.substr(0,4)))
	                    .then(
	                        function success(response){
	                            var coverageStatuses = response.data.coverage;

	                            angular.forEach(coverageStatuses, function(drugStatus){
	                                
	                                if (!totalCoverage[drugStatus.plan_id]) {
	                                    totalCoverage[drugStatus.plan_id] = {}
	                                }

	                                if (!totalCoverage[drugStatus.plan_id][drugStatus.coverage]){
	                                    totalCoverage[drugStatus.plan_id][drugStatus.coverage] = [];
	                                }

	                                totalCoverage[drugStatus.plan_id][drugStatus.coverage].push(drugStatus);
	                                
	                            });
	                            
	                            deferredArrayItem.resolve();
	                        },
	                        function failure(failedResponse) {
	                            deferredArrayItem.reject();
	                        }
	                    );
	                promises.push(deferredArrayItem.promise);
	            })
	        });

	        $q.all(promises).then(function(){
	        	console.log("sessionData", sessionData);
	        	var getGenericMapping = sessionData && sessionData.appConfig && sessionData.appConfig.SALES && sessionData.appConfig.SALES.FOUR_DOT_OH_GENERIC_DRUG_MAPPING && sessionData.appConfig.SALES.FOUR_DOT_OH_GENERIC_DRUG_MAPPING.booleanValue;

	        	if (getGenericMapping) {
		        	var genericDrugDeferredArray = [];

		        	angular.forEach(totalCoverage, function(hiosItem){
		        		angular.forEach(hiosItem, function(value, key){
			        		if (key === "GenericCovered") {
			        			angular.forEach(value, function(drugInfo){

			        				var genericDrugDeferred = $q.defer();

						        	services
						        		.cmcrst
						        		.searchByRXCUI()
						        		.postData(drugInfo.generic_rxcui, parseInt(ratingInfoPayload.effectiveDate.substr(0,4)))
						        		.then(function(response) {
						        			// var response = {
						        			drugInfo.genericDrugData = response.data.drug;
						        			genericDrugDeferred.resolve();
						        		}, function(failure) {
						        			genericDrugDeferred.reject();
						        		});

						        	genericDrugDeferredArray.push(genericDrugDeferred.promise);
			        				
			        			})
			        		}
			        	})
		        	});

		        	$q.all(genericDrugDeferredArray).then(function(){
		            	deferred.resolve(totalCoverage);
		        	})
		        } else {
		        	deferred.resolve(totalCoverage);
		        }
	        });

	        return deferred.promise;
	    }

	    return utilities;

	}])
}